import { Box, Dialog, DialogProps, Icon, IconButton } from "@mui/material";

export const CustomDialog = (
  props: DialogProps & { showCloseButton?: boolean }
) => {
  const { showCloseButton, ...dialogProps } = props;
  return (
    <Dialog {...dialogProps}>
      <Box sx={{ padding: 3, paddingBottom: 2 }}>
        {props.showCloseButton && (
          <Box
            sx={{
              position: "absolute",
              right: (theme) => theme.spacing(1),
              top: (theme) => theme.spacing(1),
            }}
          >
            <IconButton
              onClick={(event) => props?.onClose?.(event, "backdropClick")}
            >
              <Icon sx={{ color: "common.neutral06" }}>close</Icon>
            </IconButton>
          </Box>
        )}

        <Box>{props.children}</Box>
      </Box>
    </Dialog>
  );
};
