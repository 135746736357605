import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ChangeEvent, ClipboardEvent, useState, useEffect } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import InputMask from "react-input-mask";
import { format } from "date-fns";
import { useGeneralDataContext } from "../../../contexts/RegistrationContexts/GeneralDataContext";
import { GenderIdentityEnum } from "../types";
import { formatCorrectBirthDate } from "../helpers";
import { InformationSectionContainer } from "./InformationSectionContainer";
import { UpdateSectionContainer } from "./UpdateSectionContainer";
import { FormSectionHeader } from "../../../components/Form/FormSectionHeader";
import { FormGroupContainer } from "../../../components/Form/FormGroupContainer";
import { useUpdateLife } from "../hooks/useLives";
import { renderNotifier } from "../../../providers/Notifier";
import { messages } from "../../../strings/messages";
import { errors as messageErrors } from "../../../strings/errors";
import { validateCPF } from "../formUtils";

const GenderValues = {
  male: "MALE",
  female: "FEMALE",
  other: "OTHER",
};

const GenderIdentity = {
  cisgender: "Cisgênero",
  transgender: "Transgênero",
  nonBinary: "Não-binário",
  other: "Outro",
  notSpecified: "Não especificado",
};

const getGenderLabel = (gender: string) => {
  switch (gender) {
    case GenderValues.female:
      return "feminino";
    case GenderValues.male:
      return "masculino";
    case GenderValues.other:
      return "outro";
    default:
      return "não especificado";
  }
};

const getGenderIdentityLabel = (genderIdentity: string) => {
  switch (genderIdentity) {
    case GenderIdentityEnum.CIS:
      return GenderIdentity.cisgender;
    case GenderIdentityEnum.NON_BINARY:
      return GenderIdentity.nonBinary;
    case GenderIdentityEnum.TRANS:
      return GenderIdentity.transgender;
    case GenderIdentityEnum.OTHER:
      return GenderIdentity.other;
    default:
      return GenderIdentity.notSpecified;
  }
};

type GeneralDataProps = {
  formMode: "create" | "edit" | null;
  enabled?: boolean;
  lifeId: string | null;
  handleOnActivateEditMode: (active: boolean) => void;
  errors: FieldErrors<{
    cpf: string;
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    sex: string;
    socialName?: string | undefined;
    birthDate?: string;
  }>;
  register: UseFormRegister<{
    firstName: string;
    lastName: string;
    email?: string;
    sex?: string;
    gender?: string;
    socialName?: string | undefined;
    birthDate?: string;
  }>;
};

const GeneralDataFormSection = ({
  register,
  errors,
  formMode,
  enabled = true,
  lifeId,
  handleOnActivateEditMode,
}: GeneralDataProps) => {
  const [isEditionOpen, setEditionOpen] = useState(formMode === "create");

  const {
    cpf,
    cpfError,
    firstName,
    lastName,
    socialName,
    email,
    gender,
    genderIdentity,
    birthDate,
    setCpf,
    setCpfError,
    setEmail,
    setFirstName,
    setLastName,
    setSocialName,
    setGender,
    setGenderIdentity,
    setBirthDate,
  } = useGeneralDataContext();

  const {
    data: updateLifeData,
    isError: updateLifeError,
    isPending: updateLifeLoading,
    mutate: updateLife,
  } = useUpdateLife();

  const formatCPF = (value: string) => {
    const validCpf = value.replace(/[^0-9]/g, "");
    if (validCpf) {
      if (validCpf.length === 11 && !validateCPF(validCpf)) {
        setCpfError("Digite um cpf válido");
      } else {
        setCpfError("");
      }
      setCpf(validCpf);
    }
  };

  const handleChangeBirthDate = (e: ChangeEvent<HTMLInputElement>) => {
    const validDate = formatCorrectBirthDate(e.target.value);
    setBirthDate(validDate);
  };

  useEffect(() => {
    setEditionOpen(formMode === "create");
  }, [formMode]);

  useEffect(() => {
    if (updateLifeData) {
      renderNotifier(messages.lifeSuccessfullyUpdated, { variant: "success" });
      handleOnChangeMode(false);
    }
  }, [updateLifeData]);

  useEffect(() => {
    if (updateLifeError) {
      renderNotifier(messageErrors.generic, { variant: "error" });
      handleOnChangeMode(false);
    }
  }, [updateLifeError]);

  const handleOnChangeMode = (active: boolean) => {
    setEditionOpen(!isEditionOpen);
    handleOnActivateEditMode(active);
  };

  return (
    <FormGroupContainer formMode={formMode}>
      <FormSectionHeader header="Dados gerais" />
      {isEditionOpen ? (
        <UpdateSectionContainer
          formMode={formMode}
          handleOnChangeMode={() => handleOnChangeMode(false)}
          loading={updateLifeLoading}
          handleOnSave={() =>
            updateLife({
              id: lifeId!,
              cpf,
              firstName,
              lastName,
              socialName,
              email,
              gender,
              genderIdentity,
              birthDate: formatCorrectBirthDate(String(birthDate)),
            })
          }
        >
          <InputMask
            mask="999.999.999-99"
            value={cpf || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formatCPF(e.target.value)
            }
            onPaste={(e: ClipboardEvent<HTMLInputElement>) => {
              const pastedCPF = e.clipboardData.getData("text");
              formatCPF(pastedCPF);
            }}
          >
            {(props: React.InputHTMLAttributes<HTMLInputElement>) => (
              <TextField
                inputProps={props}
                label="CPF"
                variant="outlined"
                error={!!cpfError}
                helperText={cpfError}
                required
              />
            )}
          </InputMask>

          <TextField
            label="Primeiro nome"
            variant="outlined"
            {...register("firstName")}
            value={firstName || ""}
            onChange={(e) => setFirstName(e.target.value)}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            required
          />
          <TextField
            label="Último nome"
            variant="outlined"
            {...register("lastName")}
            value={lastName || ""}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <TextField
            label="Nome social"
            variant="outlined"
            {...register("socialName")}
            value={socialName || ""}
            onChange={(e) => setSocialName(e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            {...register("email")}
            value={email || ""}
            error={!!errors.email}
            helperText={errors.email?.message}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormControl>
            <InputLabel id="genderIdentity" error={!!errors.sex}>
              Sexo
            </InputLabel>
            <Select
              labelId="genderIdentity"
              id="genderIdentity"
              {...register("sex")}
              value={genderIdentity}
              label="Sexo"
              variant="outlined"
              onChange={(e) => setGenderIdentity(e.target.value)}
            >
              <MenuItem value={GenderIdentityEnum.CIS}>
                {GenderIdentity.cisgender}
              </MenuItem>
              <MenuItem value={GenderIdentityEnum.TRANS}>
                {GenderIdentity.transgender}
              </MenuItem>
              <MenuItem value={GenderIdentityEnum.NON_BINARY}>
                {GenderIdentity.nonBinary}
              </MenuItem>
              <MenuItem value={GenderIdentityEnum.OTHER}>
                {GenderIdentity.other}
              </MenuItem>
            </Select>
            {errors.sex && (
              <Typography
                color="error"
                variant="caption"
                sx={{ fontSize: "12px", pl: "10px", pt: "3px" }}
              >
                {errors.sex?.message}
              </Typography>
            )}
          </FormControl>
          <FormControl>
            <InputLabel id="gender" error={!!errors.gender}>
              Gênero
            </InputLabel>
            <Select
              labelId="gender"
              id="gender"
              {...register("gender")}
              value={gender}
              label="Gênero"
              variant="outlined"
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value={GenderValues.male}>Masculino</MenuItem>
              <MenuItem value={GenderValues.female}>Feminino</MenuItem>
              <MenuItem value={GenderValues.other}>Outro</MenuItem>
            </Select>
            {errors.gender && (
              <Typography
                color="error"
                variant="caption"
                sx={{ fontSize: "12px", pl: "10px", pt: "3px" }}
              >
                {errors.gender?.message}
              </Typography>
            )}
          </FormControl>
          <InputMask
            mask="99/99/9999"
            value={
              birthDate instanceof Date
                ? format(new Date(birthDate), "dd-MM-yyyy")
                : birthDate
            }
            {...register("birthDate")}
            onChange={handleChangeBirthDate}
          >
            {(props: React.InputHTMLAttributes<HTMLInputElement>) => (
              <TextField
                inputProps={props}
                label="Data de nascimento"
                variant="outlined"
                error={!!errors.birthDate}
                helperText={errors.birthDate?.message}
              />
            )}
          </InputMask>
        </UpdateSectionContainer>
      ) : (
        <InformationSectionContainer
          handleOnChangeMode={() => handleOnChangeMode(true)}
          enabled={enabled}
        >
          {cpf ? (
            <>
              <Typography variant="body2">
                CPF: {cpf || "Não providenciado"}
              </Typography>
              <Typography variant="body2">
                Primeiro nome: {firstName || "Não providenciado"}
              </Typography>
              <Typography variant="body2">
                Último nome: {lastName || "Não providenciado"}
              </Typography>
              <Typography variant="body2">
                Nome social: {socialName || "Não providenciado"}
              </Typography>
              <Typography variant="body2">
                Email: {email || "Não providenciado"}
              </Typography>
              <Typography variant="body2">
                Sexo: {gender ? getGenderLabel(gender) : "Não providenciado"}
              </Typography>
              <Typography variant="body2">
                Gênero:{" "}
                {genderIdentity
                  ? getGenderIdentityLabel(genderIdentity)
                  : "Não providenciado"}
              </Typography>
              <Typography variant="body2">
                <>Data de nascimento: {birthDate || "Não providenciada"}</>
              </Typography>
            </>
          ) : (
            <CircularProgress size={24} />
          )}
        </InformationSectionContainer>
      )}
    </FormGroupContainer>
  );
};

export default GeneralDataFormSection;
