import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { SnackbarCloseButton } from "./components/SnackbarWithCloseButton";
import { styled } from "@mui/material";
import { DEFAULT_BORDER_RADIUS } from "../../theme/design-tokens";

const StyledMaterialDesignContent = styled(MaterialDesignContent)((props) => ({
  borderRadius: DEFAULT_BORDER_RADIUS,
  "&.notistack-MuiContent-success": {
    backgroundColor: props.theme.palette.success.main,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: props.theme.palette.error.main,
  },
  ".notistack-MuiContent-info": {
    backgroundColor: props.theme.palette.info.main,
  },
  ".notistack-MuiContent-warning": {
    backgroundColor: props.theme.palette.warning.main,
  },
}));

export const Notifier = () => {
  return (
    <SnackbarProvider
      action={(snackbarKey) => (
        <SnackbarCloseButton snackbarKey={snackbarKey} />
      )}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      autoHideDuration={5000}
      maxSnack={1}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
    />
  );
};
