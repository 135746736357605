import { Box, Stack } from "@mui/material";
import Card from "../components/Card";
import MenuItems from "../utils/navigation";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      spacing={5}
      alignItems="flex-start"
      sx={{ padding: "2.0rem 2.5rem" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: 4,
        }}
      >
        {MenuItems.map((item) => (
          <Card
            disabled={item.disabled}
            key={item.label}
            title={item.label}
            iconName={item.icon}
            cardAction={() => {
              navigate(item.route);
            }}
            cardText={item.text}
            cardActionLabel={item.actionLabel}
          />
        ))}
      </Box>
    </Stack>
  );
}

export default Home;
