import { Autocomplete, TextField } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

import { TCreateTaskForm } from "../helpers/createTaskSchema";
import {
  SystemUser,
  useGetSystemUsers,
} from "../../../../Chat/components/ChatView/components/AssignForm/hooks/useGetSystemUsers";
import { removeDuplicatedAssignUsers } from "../../../../Chat/components/ChatView/components/AssignForm/assignFormUtils";

type TAssigneesAutocompleteFieldProps = {
  form: UseFormReturn<TCreateTaskForm, unknown, undefined>;
};

export const AssigneesAutocompleteField = (
  props: TAssigneesAutocompleteFieldProps
) => {
  const { form } = props;
  const { errors } = form.formState;

  const systemUsers = useGetSystemUsers();
  const isLoading = systemUsers.isLoading;
  const systemUsersData = (systemUsers.data?.data?.data || [])?.reduce(
    removeDuplicatedAssignUsers,
    [] as SystemUser[]
  );

  return (
    <Controller
      name="assignee"
      control={form.control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          onChange={(_, data) => {
            field.onChange(data);
          }}
          openOnFocus
          options={systemUsersData || []}
          noOptionsText="Nenhum dado encontrado"
          loading={isLoading}
          loadingText="Carregando..."
          getOptionLabel={(option) =>
            (option as SystemUser)?.attributes?.name || ""
          }
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Nome do responsável"
              error={!!errors?.assignee}
              helperText={errors?.assignee?.message as string}
            />
          )}
        />
      )}
    />
  );
};
