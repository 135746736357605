import { useContext, createContext, useState, useEffect } from "react";
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  User,
} from "firebase/auth";
import { CircularProgress } from "@mui/material";
import { setAPIAuthToken } from "../api";

type FeatureFlagProps =
  | {
      [key: string]: boolean | string;
    }
  | undefined;

type AuthUser = User & { accessToken?: string };

type AuthContextStateProps = {
  user: AuthUser | null;
  featureFlags: FeatureFlagProps;
};

const INITIAL_STATE: AuthContextStateProps = {
  user: null,
  featureFlags: {},
};

const AuthContext = createContext(INITIAL_STATE);

export const useAuthContext = () => {
  return useContext(AuthContext);
};

const Loading = () => (
  <div className="flex items-center justify-center w-full h-full">
    <CircularProgress />
  </div>
);

type AuthContextProviderProps = {
  children: React.ReactNode;
  value: {
    featureFlags: FeatureFlagProps;
  };
};

export const AuthContextProvider = ({
  children,
  value,
}: AuthContextProviderProps) => {
  const auth = getAuth();
  const [user, setUser] = useState<AuthUser | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (user) {
        const refreshedToken = await auth.currentUser?.getIdToken(true);
        setUser({ ...user, accessToken: refreshedToken });
      }
    }, 30 * 60 * 1000); // 30 minutes

    return () => clearInterval(interval);
  }, [auth, user]);

  useEffect(() => {
    onIdTokenChanged(auth, async (currentUser) => {
      if (currentUser) {
        const refreshedToken = await currentUser.getIdToken();
        setAPIAuthToken(refreshedToken);
        setUser({ ...currentUser, accessToken: refreshedToken });
      }
    });

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(false);
      if (currentUser) {
        setUser({ ...currentUser });
        try {
          const accessToken = await currentUser.getIdToken();
          setAPIAuthToken(accessToken);
        } catch (error) {
          console.error("Error getting access token", error);
        }
      } else {
        setUser(null);
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auth]);

  const { featureFlags } = value;

  return (
    <AuthContext.Provider value={{ user, featureFlags }}>
      {loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
