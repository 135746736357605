import { api } from "../../../api";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  API_LIVES_POST_TYPE,
  API_LIVES_GET_TYPE,
  EmployerType,
  KinShipEnum,
} from "../types";
import { DataTypeEnum } from "../Lives/components/lifeUtils";

const createNewLife = async (live: API_LIVES_POST_TYPE) => {
  return await api.post("/api/v1/lives", { data: live });
};

const getEmployers = async () => {
  return await api.get("/api/v1/employers");
};

const getLifeByRegistrationNumber = async (cpf: string) => {
  return await api.get(`/api/v1/lives?registration_number=${cpf}`);
};

const getLifeById = async (id: string) => {
  return await api.get(`/api/v1/lives/${id}`);
};

type GetLivesProps = {
  limit?: number;
  page?: number;
  keySearch: { type: DataTypeEnum | null; value: string };
};
const getLives = async ({ limit = 10, page = 1, keySearch }: GetLivesProps) => {
  let query = `/api/v1/lives?limit=${limit}&page=${page}`;

  if (keySearch?.type === DataTypeEnum.CPF) {
    query += `&registration_number=${keySearch.value}`;
  } else if (keySearch?.type === DataTypeEnum.Name) {
    query += `&name=${keySearch.value}`;
  } else if (keySearch?.type === DataTypeEnum.PhoneNumber) {
    query += `&phone_number=${keySearch.value}`;
  }

  const searchParams = {
    query: keySearch.value,
  };

  try {
    const response = await api.get(query, {
      params: searchParams,
    });

    const lives = response.data.data.map((life: API_LIVES_GET_TYPE) => ({
      id: life.id,
      chat_id: life.attributes?.chat_id,
      firstName: life.attributes?.first_name,
      lastName: life.attributes?.last_name,
      socialName: life.attributes?.preferred_name,
      gender: life.attributes?.gender,
      genderIdentity: life.attributes.gender_identity,
      birthDate: life.attributes?.birth_date,
      email: life.attributes?.email,
      registrationNumber: life.attributes?.registration_number,
      phoneNumbers:
        life.attributes?.phone_numbers?.map(
          (phone) => phone.attributes.number
        ) || [],
      addresses: life.attributes?.addresses,
      relatives:
        life.attributes?.relatives?.map((relative) => ({
          relationshipId: relative.id,
          kinshipLife: relative.attributes.life_kinship as KinShipEnum,
          kinshipRelative: relative.attributes.relative_kinship as KinShipEnum,
          relativeId: relative.attributes.relative_id,
          firstName: relative.attributes?.relative?.attributes.first_name,
          lastName: relative.attributes?.relative?.attributes.last_name,
          coverages: life.attributes?.coverages?.map((coverage) => ({
            coverageId: coverage.id,
            benefitId: coverage.attributes?.benefit.id,
          })),
        })) || [],
      benefits:
        life.attributes?.coverages?.map((coverage) => ({
          coverageId: coverage.id,
          cardNumber: coverage.attributes?.number,
          benefitId: coverage.attributes?.benefit.id,
          employer:
            coverage.attributes.benefit.attributes.employer.attributes.name,
          healthPlanRole:
            coverage.attributes.holder_coverage_id === life.id
              ? "HOLDER"
              : "DEPENDENT",
          benefit: coverage.attributes.benefit.attributes.name,
          activationDate: coverage.attributes.activation_date,
          product: coverage.attributes.benefit.attributes.product,
        })) || [],
    }));

    return lives;
  } catch (_) {
    throw new Error();
  }
};

type UpdateLifeProps = {
  id: string;
  cpf: string;
  firstName: string;
  lastName: string;
  socialName: string;
  email: string;
  gender: string;
  genderIdentity: string;
  birthDate: string | null;
};

const updateLife = async ({
  id,
  cpf,
  firstName,
  lastName,
  socialName,
  email,
  gender,
  genderIdentity,
  birthDate,
}: UpdateLifeProps) => {
  return await api.patch(`/api/v1/lives/${id}`, {
    data: {
      id,
      type: "lives",
      attributes: {
        registration_number: cpf || null,
        first_name: firstName || null,
        last_name: lastName || null,
        preferred_name: socialName || null,
        email: email || null,
        gender: gender || null,
        gender_identity: genderIdentity || null,
        birth_date: birthDate
          ? birthDate?.split("-").reverse().join("-")
          : null,
      },
    },
  });
};

export const useAddNewLife = () => {
  return useMutation({
    mutationKey: ["create-live"],
    mutationFn: createNewLife,
  });
};

export const useGetEmployers = (employers: EmployerType[]) => {
  return useQuery({
    queryKey: ["get-employers"],
    queryFn: getEmployers,
    enabled: employers.length === 0,
  });
};

export const useGetLife = (cpf: string, enabled: boolean) => {
  return useQuery({
    queryKey: ["get-life", cpf],
    queryFn: () => getLifeByRegistrationNumber(cpf),
    enabled: !!cpf && enabled,
  });
};

export const useGetLifeById = (id: string | undefined) => {
  return useQuery({
    queryKey: ["get-life-by-id", id],
    queryFn: () => getLifeById(id!),
    enabled: !!id,
  });
};

type KeySearchType = {
  type: DataTypeEnum;
  value: string;
};
export const useGetLives = (
  limit: number,
  page: number,
  keySearch: KeySearchType | { type: null; value: string },
  enabled: boolean
) => {
  return useQuery({
    queryKey: ["get-lives"],
    queryFn: () => getLives({ limit, page, keySearch }),
    enabled,
  });
};

export const useUpdateLife = () => {
  return useMutation({
    mutationKey: ["update-life"],
    mutationFn: ({
      id,
      cpf,
      firstName,
      lastName,
      socialName,
      email,
      gender,
      genderIdentity,
      birthDate,
    }: UpdateLifeProps) =>
      updateLife({
        id,
        cpf,
        firstName,
        lastName,
        socialName,
        email,
        gender,
        genderIdentity,
        birthDate,
      }),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: (data) => {
      console.log("Life updated successfully: ", data);
    },
  });
};
