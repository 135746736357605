import {
  format as dateFnsFormat,
  subHours,
  parseISO,
  differenceInHours,
  isYesterday,
  isToday,
  format,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { ptBR } from "date-fns/locale";

const getElapsedTime = (pastDate: Date): string => {
  const now = new Date();
  const diffInMs = now.getTime() - pastDate.getTime();
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) {
    return `há ${diffInSeconds}s`;
  } else if (diffInMinutes < 60) {
    return `há ${diffInMinutes}m`;
  } else if (diffInHours < 24) {
    return `há ${diffInHours}h`;
  } else {
    return `há ${diffInDays}d`;
  }
};

export const formatWithBrUtc: typeof dateFnsFormat = (
  date,
  format,
  options
) => {
  try {
    const brUtcDate = subHours(date, 3);
    return dateFnsFormat(brUtcDate, format, options);
  } catch (_error) {
    return "-";
  }
};

export const formatDateToElapsedTime = (date: string) => {
  if (!date) {
    return "-";
  }
  const localDate = subHours(date, 3);
  const timeElapsed = getElapsedTime(localDate);
  return timeElapsed;
};

export const formatDateToTimeOrEntireDate = (
  date: string,
  entireDateFormat?: string
) => {
  if (isDateOlderThan24Hours(date)) {
    return formatDate(date, entireDateFormat || "dd/MM/yyyy");
  } else {
    return formatTime(date);
  }
};

export const formatDate = (date: string, customFormat?: string) => {
  try {
    if (!customFormat)
      return formatWithBrUtc(new Date(date), "dd/MM/yyyy HH:mm:ss", {
        locale: ptBR,
      });

    return formatWithBrUtc(new Date(date), customFormat, { locale: ptBR });
  } catch (_error) {
    return "-";
  }
};

export const formatTime = (date: string) => {
  try {
    return formatWithBrUtc(new Date(date), "HH:mm:ss", { locale: ptBR });
  } catch (_error) {
    return "-";
  }
};

export const isDateOlderThan24Hours = (
  utcDateString: string | null
): boolean => {
  try {
    const localDate = toZonedTime(parseISO(utcDateString || ""), "UTC");

    const now = new Date();
    const localNow = toZonedTime(now, "America/Sao_Paulo");
    const difference = differenceInHours(localNow, localDate);

    return difference >= 24;
  } catch (_error) {
    return false;
  }
};

export const getDateDiffInHours = (firstDate?: string, secondDate?: string) => {
  try {
    return differenceInHours(
      new Date(firstDate || ""),
      new Date(secondDate || "")
    );
  } catch (_error) {
    return 0;
  }
};

export const getIsYesterday = (date?: string) =>
  isYesterday(new Date(date || ""));

export const getIsToday = (date?: string) => {
  const localDate = toZonedTime(new Date(date || ""), "America/Sao_Paulo");
  return isToday(localDate);
};

export const renderChatDateLabel = (date: string = "") => {
  if (getIsToday(date)) return `HOJE ÀS ${formatTime(date)}`;
  if (getIsYesterday(date)) return `ONTEM ÀS ${formatTime(date)}`;
  return formatDate(date || "");
};

export const formatDateToBE = (date: Date) =>
  format(date?.toDateString?.(), "yyyy-MM-dd");

export * from "date-fns";
