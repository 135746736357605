import { Box, Button, Typography } from "@mui/material";
import { muiThemeColors } from "../../../../../../theme/colors";

const templateCardStyle = {
  border: `1px solid ${muiThemeColors.secondary.neutral}`,
  borderRadius: "25px",
};

const contentCardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: 2,
  padding: "5px 10px",
};

const headerStyle = {
  fontSize: "15px",
  fontWeight: "bold",
};

const contentStyle = {
  fontSize: "14px",
  textAlign: "left",
  lineHeight: "26px",
};

type TemplateCardProps = {
  description: string;
  content: string;
  handleOnClick: (value: string) => void;
};

export const TemplateCard = ({
  description,
  content,
  handleOnClick,
}: TemplateCardProps) => {
  return (
    <Box sx={templateCardStyle}>
      <Button onClick={() => handleOnClick(content)} sx={{ padding: "10px" }}>
        <Box sx={contentCardStyle}>
          <Typography variant="h4" sx={headerStyle}>
            {description}
          </Typography>
          <Typography variant="body1" sx={contentStyle}>
            {content}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};
