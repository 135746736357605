import { Box, CircularProgress, Link, Typography } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { SummaryBox } from "./components/SummaryBox";
import { SummaryCollapsable } from "./components/SummaryCollapsable";
import { SummaryTagList } from "./components/SummaryTagList";
import { useRegistrationData } from "./hooks/useRegistrationData";
import { useComplementaryData } from "./hooks/useComplementaryData";
import { useGetLifeById } from "./lifeApi/useGetLifeById";
import { getLifeFullName } from "../ConversationList/conversationListHelpers";
import { ChatBubbleOutline } from "@mui/icons-material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../../contexts/AuthContext";
import { useGetConversations } from "../../hooks/useGetConversations";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { useGetCurrentLifeId } from "../../hooks/useGetCurrentLifeId";
import RelativesCard from "../../../Registration/RegistrationFormSections/RelativesCard";
import { KinShipEnum } from "../../../Registration/types";

export const LifeSummary = () => {
  const auth = useAuthContext();
  const location = useLocation();
  const pathname = location.pathname;
  const isTimeline = pathname.includes("timeline");

  const IS_TIMELINE_ENABLED = auth.featureFlags?.is_timeline_enabled;

  const lifeId = useGetCurrentLifeId();

  const lifeSummaryQuery = useGetLifeById(lifeId || "");

  const { isLoading: isGetConversationsLoading } = useGetConversations({
    skip: Boolean(lifeId),
  });

  const lifeData = lifeSummaryQuery.data?.data;

  const registrationData = useRegistrationData(lifeData);
  const complementaryData = useComplementaryData(lifeData);

  const currentChatId = lifeData?.attributes?.chat_id;

  const isLoading = isGetConversationsLoading || lifeSummaryQuery.isLoading;

  const isChatLinkEnabled = isTimeline && currentChatId;

  const summaryTitle = getLifeFullName(lifeData?.attributes || {});
  const regexNumberOrPlusSign = /^[+0-9]/; // Check if the first character is a number or a plus sign
  const isPhoneNumber = regexNumberOrPlusSign.test(summaryTitle);

  if (!lifeData && !isLoading) {
    return (
      <SummaryCollapsable>
        <Box />
      </SummaryCollapsable>
    );
  }
  return (
    <SummaryCollapsable>
      {!isLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              mb: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant={isPhoneNumber ? "h6" : "h4"} color="primary">
              {summaryTitle}
            </Typography>
            {IS_TIMELINE_ENABLED && (
              <Box sx={{ display: "flex", ml: 1 }}>
                <Box>
                  <CustomIconButton>
                    <Link
                      component={RouterLink}
                      to={`/pacientes/${lifeId}`}
                      sx={{ lineHeight: "50%" }}
                    >
                      <EditIcon fontSize="small" />
                    </Link>
                  </CustomIconButton>
                </Box>
                <Box>
                  <CustomIconButton disabled={!isChatLinkEnabled}>
                    <Link
                      component={RouterLink}
                      to={isChatLinkEnabled ? `/chat/${currentChatId}` : ""}
                      sx={{ lineHeight: "50%" }}
                    >
                      <ChatBubbleOutline
                        fontSize="small"
                        color="primary"
                        sx={[
                          !isChatLinkEnabled && {
                            color: "common.neutral06",
                          },
                        ]}
                      />
                    </Link>
                  </CustomIconButton>
                </Box>
                <Box>
                  <CustomIconButton disabled={isTimeline}>
                    <Link
                      component={RouterLink}
                      to={!isTimeline ? `/chat/${lifeData?.id}/timeline` : ""}
                      sx={{ lineHeight: "50%" }}
                    >
                      <InsertDriveFileOutlinedIcon
                        fontSize="small"
                        color="primary"
                        sx={[
                          isTimeline && {
                            color: "common.neutral06",
                          },
                        ]}
                      />
                    </Link>
                  </CustomIconButton>
                </Box>
              </Box>
            )}
          </Box>
          <Box mt={2} mb={1}>
            <SummaryTagList tags={lifeData?.attributes?.tagged_items || []} />
          </Box>
          {/* TODO: Add small summary by nurse
        <Box my={1}>
          <Typography variant="button" color="common.neutral08">
            Lorem ipsum dolor sit a met
          </Typography>
        </Box> */}
          <Box mb={1}>
            <SummaryBox
              title={
                <Typography variant="body2" color="primary">
                  Dados cadastrais
                </Typography>
              }
              details={registrationData?.map?.((item) => (
                <Box key={item.title}>
                  <Typography fontWeight={700} variant="button">
                    {item.title}
                  </Typography>{" "}
                  <Typography variant="button">{item.value}</Typography>
                </Box>
              ))}
            />
          </Box>
          <Box mb={1}>
            <SummaryBox
              title={
                <Typography variant="body2" color="primary">
                  Dados complementares
                </Typography>
              }
              details={complementaryData?.map?.((item) => (
                <Box key={item.title}>
                  <Typography fontWeight={700} variant="button">
                    {item.title}
                  </Typography>{" "}
                  <Typography variant="button">{item.value}</Typography>
                </Box>
              ))}
            />
          </Box>
          <SummaryBox
            title={
              <Typography variant="body2" color="primary">
                Links rápidos
              </Typography>
            }
            details={
              <Box>
                <Typography fontWeight={700} variant="button">
                  Relação familiar
                </Typography>{" "}
                {lifeData?.attributes?.relatives &&
                  lifeData.attributes.relatives.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        padding: "8px 4px",
                        gap: "12px",
                      }}
                    >
                      {lifeData.attributes.relatives.map((item) => (
                        <RelativesCard
                          key={item.id}
                          relativeLink={
                            IS_TIMELINE_ENABLED
                              ? `/chat/${item.attributes?.relative_id}/timeline`
                              : ""
                          }
                          kinship={
                            item.attributes?.relative_kinship as KinShipEnum
                          }
                          fullName={`${
                            item.attributes?.relative?.attributes?.first_name ||
                            ""
                          } ${
                            item.attributes?.relative?.attributes?.last_name ||
                            ""
                          }`}
                        />
                      ))}
                    </Box>
                  )}
              </Box>
            }
          />
        </>
      ) : (
        <Box
          data-testid="summary-loading"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      )}
    </SummaryCollapsable>
  );
};
