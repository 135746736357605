import { Box, Button, Typography, CircularProgress } from "@mui/material";
import Icon from "@mui/material/Icon";
import { InputSearch } from "../../../../../../../../components/Form/InputSearch";
import { useState } from "react";
import { customColors } from "../../../../../../../../theme/colors";
import { commands as commandsItems, CommandsTypesEnum } from "./commands";

type CommandButtonIconProps = {
  icon: string;
  label: string;
  disabled?: boolean;
  visible?: boolean;
  loading: boolean;
  handleOnClick: () => void;
};

const CommandButtonIcon = ({
  icon,
  label,
  disabled = false,
  visible = true,
  loading,
  handleOnClick,
}: CommandButtonIconProps) => (
  <>
    {visible && (
      <Button
        fullWidth
        onClick={handleOnClick}
        size="large"
        variant={"text"}
        sx={{
          textDecoration: "underline",
          maxWidth: "345px",
          display: "flex",
          justifyContent: "flex-start",
        }}
        startIcon={<Icon>{icon}</Icon>}
        disabled={disabled}
      >
        {loading ? (
          <CircularProgress size={16} />
        ) : (
          <Typography variant="button" sx={{ color: customColors.PurpleMain }}>
            {label}
          </Typography>
        )}
      </Button>
    )}
  </>
);

type ChatCommandsProps = {
  isChatClosed: boolean;
  canBeMerged: boolean;
  loading: boolean;
  handleOnChooseCommand: (commandName: CommandsTypesEnum) => void;
};
export const ChatCommands = ({
  handleOnChooseCommand,
  loading,
  isChatClosed,
  canBeMerged,
}: ChatCommandsProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [commands, setCommands] = useState(commandsItems);
  const handleSearchCommand = (term: string) => {
    setSearchTerm(term);
    if (!term) {
      setCommands(commandsItems);
      return;
    }
    const filteredCommands = commands.filter((command) =>
      command.label.toLowerCase().includes(term.toLocaleLowerCase())
    );
    setCommands([...filteredCommands]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <InputSearch
        label="Busque o comando"
        value={searchTerm}
        handleOnChange={handleSearchCommand}
        style={{ margin: "0 0 10px 0" }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        {commands.map((command) => (
          <CommandButtonIcon
            key={command.onChooseCommand}
            loading={loading}
            handleOnClick={() => handleOnChooseCommand(command.onChooseCommand)}
            disabled={
              isChatClosed &&
              command.onChooseCommand === CommandsTypesEnum.CREATE_HEALTH_SURVEY
            }
            visible={
              command.onChooseCommand !== CommandsTypesEnum.MERGE_LIFE ||
              (command.onChooseCommand === CommandsTypesEnum.MERGE_LIFE &&
                canBeMerged)
            }
            label={command.label}
            icon={command.icon}
          />
        ))}
      </Box>
    </Box>
  );
};
