import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { getInitialNameLetter } from "../../utils/chatUtils";
import { UnreadBadge } from "./components/UnreadBadge";
import { customColors } from "../../../../theme/colors";
import { muiThemeColors } from "../../../../theme/colors";

export type TItemStatus = "default" | "expired";

type TUserAvatarProps = {
  nameInitialLetter?: string;
  status: TItemStatus;
  active?: boolean;
  hasUnreadMessages?: boolean;
};

export const UserAvatar = (props: TUserAvatarProps) => {
  const muiAvatarProps = {
    alt: "Avatar",
    sx: {
      width: "40px",
      height: "40px",
      backgroundColor: () => (props.active ? "secondary.main" : "primary.main"),
    },
  };
  return (
    <Box sx={{ position: "relative" }}>
      <Avatar {...muiAvatarProps}>{props.nameInitialLetter}</Avatar>
      {props.hasUnreadMessages && <UnreadBadge active={props.active} />}
    </Box>
  );
};

type TConversationItemProps = {
  name?: string;
  phoneNumber?: string;
  time: string;
  status: TItemStatus;
  active?: boolean;
  hasUnreadMessages?: boolean;
};

export const ConversationItem = (props: TConversationItemProps) => {
  const textColor = props.active
    ? muiThemeColors.primary.contrastText
    : muiThemeColors.secondary.contrastText;

  const getTimeColor = () => {
    if (props.active) {
      return muiThemeColors.primary.contrastText;
    } else {
      if (props.time.endsWith("s") || props.time.endsWith("1m")) {
        return muiThemeColors.secondary.contrastText;
      } else {
        if (props?.hasUnreadMessages) {
          return customColors.ErrorMain;
        } else {
          return muiThemeColors.secondary.contrastText;
        }
      }
    }
  };

  return (
    <Tooltip title={props.name || props.phoneNumber} placement="right" arrow>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          borderRadius: "90px",
          backgroundColor: props.active ? "primary.main" : "transparent",
        }}
      >
        <Box
          sx={{
            maxWidth: "80%",
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "space-around",
          }}
        >
          <UserAvatar
            nameInitialLetter={getInitialNameLetter(props.name)}
            status={props.status}
            active={props.active}
            hasUnreadMessages={props.hasUnreadMessages}
          />
          <Typography
            variant="body1"
            data-testid="conversation-item-name"
            sx={{
              color: textColor,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {props.name || props.phoneNumber}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            sx={{
              color: getTimeColor(),
            }}
          >
            {props.time}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};
