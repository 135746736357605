import { useMutation } from "@tanstack/react-query";
import { api } from "../../../api";
import { AxiosResponse } from "axios";

type CreateBenefitProps = {
  attributes: {
    number: string;
    benefitId: string;
    lifeId: string;
    holderCoverageId: string;
    activationDate: Date | string;
  };
};

const createBenefits = async ({
  benefits,
}: {
  benefits: CreateBenefitProps[];
}) => {
  const promises: Promise<AxiosResponse<CreateBenefitProps[]>>[] = [];

  benefits.forEach((benefit) => {
    const promise = api.post("/api/v1/coverages", {
      data: {
        type: "coverages",
        attributes: {
          number: benefit.attributes.number,
          benefit_id: benefit.attributes.benefitId,
          life_id: benefit.attributes.lifeId,
          holder_coverage_id: benefit.attributes.holderCoverageId,
          activation_date: benefit.attributes?.activationDate,
        },
      },
    });

    promises.push(promise);
  });

  return Promise.allSettled(promises)
    .then((responses) => {
      return responses;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

const removeBenefit = async ({ coverageId }: { coverageId: string }) => {
  return await api.delete(`/api/v1/coverages/${coverageId}`);
};

export const useCreateBenefits = () => {
  return useMutation({
    mutationKey: ["create-benefit"],
    mutationFn: ({ benefits }: { benefits: CreateBenefitProps[] }) =>
      createBenefits({ benefits }),
  });
};

export const useRemoveBenefit = () => {
  return useMutation({
    mutationKey: ["remove-benefit"],
    mutationFn: ({ coverageId }: { coverageId: string }) =>
      removeBenefit({ coverageId }),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: (data) => {
      console.log("Benefit removed successfully: ", data);
    },
  });
};
