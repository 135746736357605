import React from "react";
import { Box } from "@mui/material";

type InputFormGroupWrapperProps = {
  children: React.ReactNode;
};

export const InputFormGroupWrapper = ({
  children,
}: InputFormGroupWrapperProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2.5,
      }}
    >
      {children}
    </Box>
  );
};
