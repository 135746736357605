import { customColors, muiThemeColors } from "../colors";
import { DEFAULT_BORDER_RADIUS } from "../design-tokens";

export const MuiTextFieldNode = {
  styleOverrides: {
    root: {
      width: "100%",
      // regular
      "& .MuiOutlinedInput-root": {
        "&.MuiInputBase-root fieldset": {
          borderRadius: DEFAULT_BORDER_RADIUS,
          borderColor: muiThemeColors.secondary.contrastText,
        },
        "&.MuiInputBase-root.Mui-error fieldset": {
          borderColor: muiThemeColors.error.main,
        },
      },

      // disabled
      "& .Mui-disabled": {
        backgroundColor: customColors.BoxBackgroundColor,
        color: muiThemeColors.secondary.contrastText,
        "&.MuiInputBase-root": {
          borderRadius: DEFAULT_BORDER_RADIUS,
          borderColor: muiThemeColors.secondary.contrastText,
        },
        "&.MuiInputLabel-root": {
          // color: muiThemeColors.primary.neutral,
          fontSize: "16px",
          lineHeight: "100%",
        },
      },
      // outlined and standard
      "& .MuiOutlinedInput-input, & .MuiInputBase-input": {
        color: muiThemeColors.secondary.contrastText,
        backgroundColor: "transparent",
        fontSize: "16px",
        paddingLeft: "27px",
        marginBottom: "5px",
        marginTop: "5px",
        boxSizing: "border-box",
        "&::placeholder": {
          color: muiThemeColors.secondary.contrastText,
        },
      },

      // "& input:-webkit-internal-autofill-selected": {
      //   backgroundColor: "red !important",
      // },
      "& .MuiInputLabel-root": {
        // color: muiThemeColors.primary.neutral,
        fontSize: "16px",
        lineHeight: "100%",
      },
      "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "0 3px",
        paddingLeft: "12px",
      },
    },
  },
};
