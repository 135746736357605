import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";

type TCustomDatePickerProps = DatePickerProps<Date> & {
  errorMessage?: string;
};

export const CustomDatePicker = (props: TCustomDatePickerProps) => {
  return (
    <>
      <DatePicker
        {...props}
        slotProps={{
          textField: {
            error: !!props.errorMessage,
            helperText: props.errorMessage,
          },
        }}
      />
    </>
  );
};
