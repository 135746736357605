import { useQueryClient } from "@tanstack/react-query";
import { AppointmentsResponse } from "../../../timelineTypes";

type TRemoveAppointmentFromQueryDataParams = {
  lifeId: string;
  appointmentId: string;
};

export const useRemoveAppointmentFromQueryData = () => {
  const queryClient = useQueryClient();

  const removeAppointmentFromQueryData = ({
    lifeId,
    appointmentId,
  }: TRemoveAppointmentFromQueryDataParams) => {
    queryClient.setQueryData(
      ["get-appoinments", lifeId],
      (oldData: { data: AppointmentsResponse }) => {
        return {
          data: {
            data: oldData?.data?.data?.filter(
              (item) => item?.id !== appointmentId
            ),
          },
        };
      }
    );

    queryClient.refetchQueries({
      queryKey: ["timeline-events", lifeId],
      exact: false,
    });
  };

  return {
    removeAppointmentFromQueryData,
  };
};
