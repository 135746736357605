import { Box, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

type BottomBarProps = {
  summary: string;
  editable: boolean;
  threadsLoading: boolean;
  generateThreadsReady: boolean;
  setEditable: (value: boolean) => void;
  handleOnSave: () => void;
};

export const BottomBar = ({
  summary,
  editable,
  threadsLoading,
  generateThreadsReady,
  setEditable,
  handleOnSave,
}: BottomBarProps) => {
  return (
    <Box sx={{ display: "flex", gap: "15px", pt: "20px" }}>
      <Button
        color="primary"
        variant="outlined"
        sx={{ width: "140px", display: "flex", gap: 2 }}
        onClick={() => setEditable(!editable)}
      >
        {editable ? (
          "Cancelar"
        ) : (
          <>
            <EditIcon />
            Editar
          </>
        )}
      </Button>
      <Button
        variant="contained"
        size="small"
        disabled={generateThreadsReady || threadsLoading || summary === ""}
        sx={{
          width: "140px",
        }}
        onClick={handleOnSave}
      >
        Salvar
      </Button>
    </Box>
  );
};
