import { Box, Typography } from "@mui/material";
import {
  AddressType,
  AddressMap,
} from "../../../../contexts/RegistrationContexts/AddressesContext";

const NOT_FOUND_FALLBACK_TEXT = "Não providenciado";

const AddressLine = ({
  label,
  value,
}: {
  label: string;
  value?: string | null;
}) => (
  <Typography variant="body3">
    {`${label}: ${value || NOT_FOUND_FALLBACK_TEXT}`}
  </Typography>
);

type ListAddressProps = {
  addresses?: AddressType[];
};

export const ListAddress = ({ addresses }: ListAddressProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {addresses?.map((address, index) => (
        <Box key={address.id}>
          <Typography variant="h6">Endereço {index + 1}</Typography>

          <Box
            sx={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
            }}
          >
            <AddressLine label={"CEP"} value={address?.attributes?.zip_code} />
            <AddressLine
              label={"Endreço"}
              value={address?.attributes?.address_line_1}
            />
            <AddressLine
              label={"Complemento"}
              value={address?.attributes?.address_line_2}
            />
            <AddressLine
              label={"Bairro"}
              value={address?.attributes?.neighborhood}
            />
            <AddressLine label={"Estado"} value={address?.attributes?.state} />
            <AddressLine label={"Cidade"} value={address?.attributes?.city} />
            <AddressLine label={"País"} value={address?.attributes?.country} />
            <AddressLine
              label={"Tipo de endereço"}
              value={AddressMap[address?.attributes?.type || "OTHER"]}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
