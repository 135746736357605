import { ChangeEvent, useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import InputMask from "react-input-mask";
import { v4 as uuid } from "uuid";
import RadioButtonHouse from "../RadioButtonHouse";
import { StatesAndCities, UFStateMap } from "../../formUtils";
import { useGetAddress } from "../../hooks/useAddress";
import { customColors } from "../../../../theme/colors";
import { type AddressType } from "../../../../contexts/RegistrationContexts/AddressesContext";
import { InputFormGroupWrapper } from "../Wrappers/InputFormGroupWrapper";
import { RemoveIcon } from "../components/RemoveIcon";
import { renderNotifier } from "../../../../providers/Notifier";
import { errors as messageErrors } from "../../../../strings/errors";
import { UpdateAddressByZipCodeProps } from "./CreateAddress";

const AddressTypeOptions = {
  home: "HOME",
  work: "WORK",
  other: "OTHER",
};

const RadioButtoStyle = {
  color: "secondary.contrastText",
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
};

const RadioLabelStyle = {
  fontSize: "14px",
  color: customColors.PrimaryNeutral,
};

type InputAddressProps = {
  address?: AddressType;
  id: number;
  handleOnChange: (key: string, value: string, id: number) => void;
  updateAddressByZipCode: ({ values, id }: UpdateAddressByZipCodeProps) => void;
  handleOnRemove: (id: number) => void;
};

export const InputAddress = ({
  address,
  id,
  handleOnChange,
  updateAddressByZipCode,
  handleOnRemove,
}: InputAddressProps) => {
  const [allowSearchZipCode, setAllowSearchZipCode] = useState(false);
  const { data: zipCodeData, isLoading: isZipCodeLoading } = useGetAddress(
    address?.attributes?.zip_code,
    allowSearchZipCode
  );

  useEffect(() => {
    if (zipCodeData && allowSearchZipCode) {
      if (zipCodeData.data.erro) {
        renderNotifier(messageErrors.fetchingZipCodeError, {
          variant: "error",
        });
        return;
      }

      const values = {
        state: UFStateMap[zipCodeData.data.uf as keyof typeof UFStateMap],
        address_line_1: zipCodeData.data.logradouro,
        address_line_2: zipCodeData.data.complemento,
        city: zipCodeData.data.localidade,
        neighborhood: zipCodeData.data.bairro,
      };
      updateAddressByZipCode({ values, id });
    }
  }, [zipCodeData]);

  return (
    <InputFormGroupWrapper>
      <RemoveIcon loading={false} handleOnRemove={() => handleOnRemove(id)} />
      <InputMask
        mask="99999-999"
        value={address?.attributes?.zip_code || ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setAllowSearchZipCode(true);
          handleOnChange("zip_code", e.target.value, id);
        }}
      >
        {(props: React.InputHTMLAttributes<HTMLInputElement>) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <TextField inputProps={props} label="CEP" variant="outlined" />
            {isZipCodeLoading && <CircularProgress />}
          </Box>
        )}
      </InputMask>
      <TextField
        label="Endereço"
        value={address?.attributes?.address_line_1 || ""}
        variant="outlined"
        onChange={(e) => handleOnChange("address_line_1", e.target.value, id)}
      />
      <TextField
        label="Complemento"
        value={address?.attributes?.address_line_2 || ""}
        variant="outlined"
        onChange={(e) => handleOnChange("address_line_2", e.target.value, id)}
      />
      <TextField
        label="Bairro"
        value={address?.attributes?.neighborhood || ""}
        variant="outlined"
        onChange={(e) => handleOnChange("neighborhood", e.target.value, id)}
      />
      <FormControl>
        <InputLabel id="state">Estado</InputLabel>
        <Select
          labelId="state"
          id="state"
          value={address?.attributes?.state || ""}
          label="Estado"
          variant="outlined"
          onChange={(e) => handleOnChange("state", e.target.value, id)}
        >
          {Object.keys(StatesAndCities).map((state) => (
            <MenuItem key={uuid()} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="city">Cidade</InputLabel>
        <Select
          labelId="city"
          id="city"
          value={address?.attributes?.city || ""}
          onChange={(e) => handleOnChange("city", e.target.value, id)}
          disabled={!address?.attributes?.state}
        >
          {address?.attributes?.state &&
            StatesAndCities[
              address?.attributes?.state as keyof typeof StatesAndCities
            ]?.map((city) => (
              <MenuItem key={uuid()} value={city}>
                {city}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl>
        <RadioGroup
          row
          value={address?.attributes?.type || AddressTypeOptions.home}
          onChange={(e) => handleOnChange("type", e.target.value, id)}
          sx={{ gap: "10px" }}
        >
          <RadioButtonHouse>
            <FormControlLabel
              value={AddressTypeOptions.home}
              control={<Radio sx={RadioButtoStyle} />}
              label={<Typography sx={RadioLabelStyle}>Casa</Typography>}
              sx={{ fontSize: "22px" }}
            />
          </RadioButtonHouse>
          <RadioButtonHouse>
            <FormControlLabel
              value={AddressTypeOptions.work}
              control={<Radio sx={RadioButtoStyle} />}
              label={<Typography sx={RadioLabelStyle}>Trabalho</Typography>}
            />
          </RadioButtonHouse>
          <RadioButtonHouse>
            <FormControlLabel
              value={AddressTypeOptions.other}
              control={<Radio sx={RadioButtoStyle} />}
              label={<Typography sx={RadioLabelStyle}>Outros</Typography>}
            />
          </RadioButtonHouse>
        </RadioGroup>
      </FormControl>
    </InputFormGroupWrapper>
  );
};
