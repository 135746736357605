import TimelineContent from "@mui/lab/TimelineContent";
import { Box, Button, Typography } from "@mui/material";
import { AppointmentResource, TimelineEventSchema } from "../timelineTypes";
import {
  ExpandLess,
  ExpandMore,
  VideocamOffOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { CustomTimelineSeparator } from "./CustomTimelineSeparator";
import { formatDate } from "../../Chat/utils/dateUtils";
import { cancellationReasonsUI } from "../helpers/chatTimelineHelpers";
import { useMemo } from "react";

type TAppointmentTimelineEventProps = {
  encounterEventAttributes: TimelineEventSchema;
  isExpandedRow: boolean;
  index: number;
  handleExpand: (index: number) => void;
};

export const AppointmentTimelineEvent = (
  props: TAppointmentTimelineEventProps
) => {
  const encounterEventAttributes = props.encounterEventAttributes;
  const encounterEvent = encounterEventAttributes.event as AppointmentResource;
  const appointmentStatus = encounterEvent?.attributes?.status || "";
  const reason = encounterEvent?.attributes?.reason || "";
  const cancellationReason =
    encounterEvent?.attributes?.cancellation_reason?.split?.(";");

  const getCancellationLabel = (cancellationReason: string) =>
    cancellationReasonsUI[
      (cancellationReason as keyof typeof cancellationReasonsUI) || ""
    ] || "";

  const description = useMemo(() => {
    if (appointmentStatus === "CANCELLED") {
      return cancellationReason
        ?.map?.((reason) => getCancellationLabel(reason))
        .join(", ");
    }
    if (appointmentStatus === "NOSHOW") {
      return "Paciente não compareceu";
    }

    return "";
  }, [appointmentStatus, cancellationReason]);

  const appointmentIcon = {
    CANCELLED: <VideocamOffOutlined />,
    COMPLETED: <VideocamOutlined />,
    PROPOSED: <VideocamOutlined />,
    PENDING: <VideocamOutlined />,
    BOOKED: <VideocamOutlined />,
    ARRIVED: <VideocamOutlined />,
    FULFILLED: <VideocamOutlined />,
    NOSHOW: <VideocamOutlined />,
    ENTERED_IN_ERROR: <VideocamOutlined />,
    CHECKED_IN: <VideocamOutlined />,
    WAITLIST: <VideocamOutlined />,
    "": <VideocamOutlined />,
  };

  const appointmentLabelTitle = {
    CANCELLED: "cancelada",
    COMPLETED: "concluída",
    PROPOSED: "",
    PENDING: "",
    BOOKED: "agendada",
    ARRIVED: "",
    FULFILLED: "",
    NOSHOW: "",
    ENTERED_IN_ERROR: "",
    CHECKED_IN: "",
    WAITLIST: "",
    "": "",
  };

  const appointmentReasonLabel = {
    CANCELLED: "Motivo do cancelamento:",
    COMPLETED: "",
    PROPOSED: "",
    PENDING: "",
    BOOKED: "",
    ARRIVED: "",
    FULFILLED: "Motivo da consulta",
    NOSHOW: "Não comparecimento:",
    ENTERED_IN_ERROR: "",
    CHECKED_IN: "",
    WAITLIST: "",
    "": "",
  };

  const currentIcon = appointmentIcon[appointmentStatus];
  const currentAppointmentLabelTitle = appointmentLabelTitle[appointmentStatus];

  const currentAppointmentReasonLabel =
    appointmentReasonLabel[appointmentStatus];

  return (
    <>
      <CustomTimelineSeparator>{currentIcon}</CustomTimelineSeparator>
      <TimelineContent sx={{ padding: 2 }}>
        <Box>
          <Typography variant="caption">
            {formatDate(encounterEventAttributes?.date || "")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body3" fontWeight="bold">
            {encounterEventAttributes.title}{" "}
            {currentAppointmentLabelTitle &&
              `- ${currentAppointmentLabelTitle}`}
          </Typography>
        </Box>

        <Box
          sx={[
            {
              minHeight: "56px",
            },
            !props.isExpandedRow && {
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "3em",
              lineHeight: "1.5em",
              margin: 0,
            },
          ]}
        >
          <Box mb={1}>
            <Typography variant="button" fontWeight="bold">
              Motivo da consulta:{" "}
            </Typography>{" "}
            <Typography variant="button">{reason}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="button" fontWeight="bold">
              {currentAppointmentReasonLabel}{" "}
            </Typography>{" "}
            <Typography variant="button">{description}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="text"
            color="primary"
            endIcon={props.isExpandedRow ? <ExpandLess /> : <ExpandMore />}
            onClick={() => props.handleExpand(props.index)}
          >
            {props.isExpandedRow ? "Ver menos" : "Ver mais"}
          </Button>
        </Box>
      </TimelineContent>
    </>
  );
};
