import { Box, IconButton, Typography } from "@mui/material";
import { KinShipEnum } from "../types";
import Close from "@mui/icons-material/Close";
import React from "react";
import { Link } from "react-router-dom";
import { customColors } from "../../../theme/colors";

const RELATIVES_MAP = {
  PARENT: "Pai ou Mãe",
  CHILD: "Filho",
  SIBLING: "Irmão",
  SPOUSE: "Cônjuge",
};

const getCapitalized = (name: string) => {
  const words = name.split(" ").slice();
  const wordsWithoutPrepositions = words.filter((word) => {
    return !["de", "da", "do", "dos", "das"].includes(word);
  });

  const capitalizedWords = [
    wordsWithoutPrepositions[0],
    wordsWithoutPrepositions.pop(),
  ].map((word) => word?.substring(0, 1).toUpperCase());

  return capitalizedWords.join(" ");
};

const NameHouse = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",
      width: "36px",
      height: "36px",
      backgroundColor: customColors.PurpleMain,
      borderRadius: "18px",
    }}
  >
    <Typography variant="body3" color={customColors.White}>
      {children}
    </Typography>
  </Box>
);

type RelativesCardProps = {
  kinship: KinShipEnum;
  relativeId?: string;
  fullName: string;
  isOpen?: boolean;
  loadingUpdate?: boolean;
  handleOnRemove?: (relativeId: string) => void;
  relativeLink?: string | null;
};

const RelativesCard = ({
  kinship,
  relativeId,
  fullName,
  loadingUpdate,
  isOpen,
  handleOnRemove,
  relativeLink,
}: RelativesCardProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.light",
        height: "80px",
        width: "80px",
        padding: "4px 0 0",
        borderRadius: "22px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {isOpen && relativeId && (
        <IconButton
          onClick={() => handleOnRemove?.(relativeId)}
          disabled={loadingUpdate}
          sx={{
            position: "absolute",
            backgroundColor: customColors.PrimaryNeutral,
            color: customColors.White,
            right: "-5px",
            top: "-5px",
            width: "25px",
            height: "25px",
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      )}
      <Link to={relativeLink || ""}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <NameHouse>{getCapitalized(fullName)}</NameHouse>
          <Typography variant="caption" sx={{ color: customColors.White }}>
            {RELATIVES_MAP[kinship]}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default RelativesCard;
