import { createContext, useContext, useEffect, useMemo } from "react";
import { Socket, io } from "socket.io-client";
import { useAuthContext } from "./AuthContext";

type TSocketContextProps = {
  children: React.ReactNode;
};

type TSocketContext = {
  socket: Socket | null;
};

const SocketContext = createContext<TSocketContext>({ socket: null });

export const SocketContextProvider = ({ children }: TSocketContextProps) => {
  const { user } = useAuthContext();
  const socket = useMemo(() => {
    console.log("socket connecting", new Date().toTimeString());
    return io("https://api.navigation.carecode.com.br", {
      path: "/ws",
      auth: {
        token: user?.accessToken,
      },
    });
  }, [user]);

  useEffect(() => {
    socket.on("disconnect", () => {
      console.log("socket disconnected", new Date().toTimeString());
    });
    socket.on("connect", () => {
      console.log("socket connected", new Date().toTimeString());
    });
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  return useContext(SocketContext);
};
