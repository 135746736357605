import { CoverageType, RelativesType } from "../Registration/types";
import { AddressType } from "../../contexts/RegistrationContexts/AddressesContext";
import { ConversationStatus } from "./slices/conversationSlice";
import { ApiTypes } from "../../app-types";
import { TFetchTags } from "./components/ChatView/hooks/useChat";

export enum MessageEvenType {
  RecommendationCreated = "recommendation.created",
  MessagesReceived = "messages.received",
  MessagesUpdated = "messages.updated",
  MessagesAccepted = "messages.accepted",
  MessagesSent = "messages.sent",
  MessagesDelivered = "messages.delivered",
  MessagesRead = "messages.read",
  MessagesFailed = "messages.failed",
  MessagesQueued = "messages.queued",
  // MessagesReceivedv2 = "v2.messages.received",
  // MessagesAcceptedv2 = "v2.messages.accepted",
  // MessagesSentv2 = "v2.messages.sent",
  // MessagesDeliveredv2 = "v2.messages.delivered",
  // MessagesReadv2 = "v2.messages.read",
  // MessagesFailedv2 = "v2.messages.failed",
  // MessagesQueuedv2 = "v2.messages.queued",
}

export enum ThreadEventType {
  ThreadsCreated = "threads.created",
}

export enum ChatEventType {
  ChatsClosed = "chats.closed",
  ChatsOpen = "chats.open",
  ChatsAssigned = "chats.assigned",
}

export type ThreadsType = {
  data: {
    id: string;
    type: string;
    attributes?: {
      summary: string;
      title: string;
      messages: [
        {
          id: string;
          type: string;
          attributes?: {
            message_id: string;
          };
        }
      ];
    };
  }[];
};

export type TagsType = ApiTypes["schemas"]["TagItemResource"] & {
  attributes: ApiTypes["schemas"]["TagItemResource"]["attributes"] & {
    entity?: TFetchTags | null;
  };
};

export type InferredTagsType = {
  type: "tag-explanation";
  attributes?: {
    label: string;
    explanation: string;
  };
};

export type SummaryDataType = {
  summary: {
    title: string;
    content: string;
  };
  tags?: TagsType[];
  inferredTags?: TagsType[];
};

export type MessageProps = {
  id: string;
  type: "messages";
  attributes?: {
    content: string;
    sender: { type: "users" | "lives"; id: string };
    created_at: string;
  };
};

export type SocketEventData = MessageDataType | ChatDataType | ThreadDataType;

export type SocketEvent = {
  type: string;
  id: string;
  event: MessageEvenType | ChatEventType | ThreadEventType;
  created_at: string; // date string "2024-04-24T18:50:11.007716"
  data: SocketEventData;
  chat_id?: string;
};

export type MessageDataType = {
  id: string;
  type: "messages" | "event";
  event: MessageEvenType;
  attributes: MessagesAttributes;
};

export type ChatAttributes = {
  life?: Life;
  last_life_message_at: string | null;
  last_user_message_at: string | null;
  status?: ConversationStatus;
  assignees?: Assignee[];
  id?: string | null;
  chat?: Chat | null;
};

export type FileTypes =
  | "application/pdf"
  | "image/jpeg"
  | "image/jpg"
  | "image/png"
  | "image/gif"
  | "image/webp";

type ChatUIType = {
  fullName?: string;
  formattedLastLifeMessageAt?: string;
  hasUnreadMessages?: boolean;
};

export type ThreadDataType = {
  id: string;
  type: "threads";
  attributes?: {
    tagged_items: TagsType[];
    summary: string;
    title: string;
    messages: [
      {
        id: string;
        type: "thread_messages";
        attributes?: {
          message_id: string;
        };
      }
    ];
    created_at: string;
    thread_ehr: null;
    chat_id: string;
  };
};

export type ChatDataType = {
  type: "chats" | string;
  id: string;
  chat_id?: string;
  event?: string | null;
  attributes: ChatAttributes;
} & ChatUIType;

type TChatStaus = "OPEN" | "CLOSED";

export type MessagesAttributes = {
  id: string;
  content: string;
  content_type?: string | null;
  public_url?: string | null;
  threads?: ThreadsType | null;
  created_at: string;
  accepted_at?: string | null;
  delivered_at?: string | null;
  failed_at?: string | null;
  read_at?: string | null;
  error_code?: string | null;
  error_message?: string | null;
  sent_at?: string | null;
  queued_at?: string | null;
  sender?: Sender | null;
  life?: Life | null;
  chat?: Chat | null;
  message_attachment?: MessageAttachment | null;
  last_life_message_at?: string | null;
  status?: TChatStaus | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type Sender = {
  id?: string;
  type?: "lives" | "users";
  attributes?: {
    firebase_id?: string;
    name?: string;
    tagged_items?: TagsType[] | null;
    first_name?: string | null;
    last_name?: string | null;
    preferred_name?: string | null;
    registration_number?: string | null;
    birth_date?: string | null;
    email?: string | null;
    gender?: string | null;
    gender_identity?: string | null;
    external_id?: string | null;
    chat_id?: string | null;
    addresses?: AddressType[] | null;
    phone_numbers?: PhoneNumber[] | null;
    coverages?: CoverageType[] | null;
    relatives?: RelativesType[] | null;
    chat?: ChatDataType | null;
    status?: TChatStaus | null;
    last_life_message_at?: string | null;
    last_user_message_at?: string | null;
    assignees?: string | null;
  };
};

export type Chat = {
  id: string;
  type: "chats";
  event?: ChatEventType | null;
  attributes?: ChatAttributes;
};

export type Life = {
  id: string;
  type: string;
  attributes: LifeAttributes;
};

type PhoneNumber = {
  id: string;
  type: string;
  attributes?: {
    number: string;
  };
};

export type Assignee = {
  type: "assignees";
  attributes?: {
    user_id: string;
  };
  id: string;
};

export type Benefit = {
  id: string;
  type: "benefits";
  attributes?: {
    type: string;
    name: string;
    product: string;
    category: string;
    employer: {
      id?: string | null;
      type?: string | null;
      attributes: {
        name: string;
        benefits?: string[] | null;
      };
    };
  };
};

export type Coverage = {
  id: string;
  type: "coverages";
  attributes?: {
    holder_coverage_id?: string | null;
    activation_date?: string | null;
    number: string;
    benefit: Benefit;
    life_id: string;
  };
};

export type LifeAddressType = "HOME" | "WORK" | "OTHER";

export type LifeAddress = {
  id: string;
  type: string;
  attributes?: {
    address_line_1: string;
    address_line_2: string;
    zip_code: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    type: LifeAddressType;
  };
};

export type LifeAttributes = {
  tagged_items: TagsType[];
  first_name: string;
  last_name: string;
  preferred_name: string;
  registration_number: string;
  birth_date: string;
  email: string;
  gender: string;
  gender_identity: string;
  external_id: string;
  addresses: LifeAddress[];
  phone_numbers: PhoneNumber[];
  coverages: Coverage[];
  relatives: RelativesType[];
  chat_id?: string;
};

export type LifeSummaryAPIResponse = ApiTypes["schemas"]["LifeResponse"];

export type TDocumentTypes = "image" | "audio" | "application" | "";

export type MessageAttachment = {
  id: string;
  type: string;
  attributes: {
    attachment: {
      id: string;
      type: string;
      attributes: {
        tagged_items: TagsType[];
        name: string;
        content_type: string;
        public_url: string;
        created_at: string;
      };
    };
  };
};

export type Attachments = {
  id: string;
  type: "attachments";
  attributes: {
    tagged_items: TagsType[];
    name: string;
    content_type: string;
    public_url: string;
    created_at: string;
  };
};

export type TMetaGetConversations = {
  counters: {
    closed: number;
    my_chats: number;
    other_chats: number;
    without_assignees: number;
  };
};
