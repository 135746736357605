import { Box, CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useAuthenticateMedia } from "../Chat/components/ChatView/hooks/useAuthenticateMedia";
import { AudioMessage } from "../Chat/components/ChatView/components/BalonContent/components/AudioMessage";
import { ImageMessage } from "../Chat/components/ChatView/components/BalonContent/components/ImageMessage";
import { DocumentMessage } from "../Chat/components/ChatView/components/BalonContent/components/DocumentMessage";
import { getContentType } from "../../utils/mediaUtils";

export const AuthenticatedMedia = () => {
  const [params, _] = useSearchParams();
  const publicUrl = params?.get("publicUrl") || "";
  const mediaType = params?.get("mediaType") || "";

  const authenticateMediaResponse = useAuthenticateMedia({
    url: publicUrl,
  });
  const mediaUrl = authenticateMediaResponse?.data;

  const contentType = getContentType(mediaType);

  const MessageContentComponent = {
    audio: AudioMessage,
    image: ImageMessage,
    application: DocumentMessage,
    "": () => <></>,
  }[contentType];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        minHeight: "100vh",
      }}
    >
      {authenticateMediaResponse?.isLoading ? (
        <CircularProgress size={42} data-testid="authenticated-media-loading" />
      ) : (
        MessageContentComponent && (
          <MessageContentComponent
            signedMediaUrl={mediaUrl || ""}
            publicUrl={publicUrl}
          />
        )
      )}
    </Box>
  );
};
