import PageHeader from "../../components/PageHeader";
import RegistrationTabs from "./RegistrationTabs";
import ScreenContainer from "../../components/ScreenContainer";

const Registration = () => {
  return (
    <ScreenContainer>
      <PageHeader title="Pacientes" icon="description_outlined_icon" />
      <RegistrationTabs />
    </ScreenContainer>
  );
};

export default Registration;
