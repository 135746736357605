import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuid } from "uuid";
import { useBenefitsContext } from "../../../contexts/RegistrationContexts/BenefitsContext";
import { ChangeEvent, useEffect } from "react";
import { BenefitType } from "../types";
import InputMask from "react-input-mask";
import { format } from "date-fns";
import { formatCorrectBirthDate } from "../helpers";

type InputBenefitProps = {
  id: number;
  editMode?: boolean;
  error?: boolean;
};

const InputBenefit = ({ id, editMode, error }: InputBenefitProps) => {
  const {
    employers,
    employersIds,
    benefitsIds,
    cardNumbers,
    activationDate,
    setEmployersIds,
    setBenefitsIds,
    setCardNumbers,
    setBenefits,
    setActivationDate,
  } = useBenefitsContext();

  const handleChangeCompanyNameById = (id: number, value: string) => {
    const newEmployersIds = [...employersIds];
    newEmployersIds[id] = value;
    handleChangeBenefitById(id, "");
    handleChangeCardNumberById(id, "");
    setEmployersIds(newEmployersIds);
  };

  const handleChangeBenefitById = (id: number, value: string) => {
    const newBenefitsIds = [...benefitsIds];
    newBenefitsIds[id] = value;
    setBenefitsIds(newBenefitsIds);
  };

  const handleChangeCardNumberById = (id: number, value: string) => {
    const newCardNumbers = [...cardNumbers];
    newCardNumbers[id] = value;
    setCardNumbers(newCardNumbers);
  };

  useEffect(() => {
    const newBenefits = benefitsIds
      .filter((benefitId) => benefitId !== "")
      .map((benefitId, index) => {
        return {
          benefitId,
          cardNumber: cardNumbers[index],
        };
      });
    if (newBenefits.length > 0) {
      setBenefits(newBenefits as BenefitType[]);
    }
  }, [cardNumbers, benefitsIds, setBenefits]);

  const handleChangeActivationDate = (e: ChangeEvent<HTMLInputElement>) => {
    const validDate = formatCorrectBirthDate(e.target.value);
    setActivationDate(validDate);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginTop: "15px",
        marginBottom: "20px",
        gap: 2,
      }}
    >
      {editMode && (
        <FormControl sx={{ width: "100%" }}>
          <InputMask
            mask="99/99/9999"
            value={
              activationDate instanceof Date
                ? format(new Date(activationDate), "dd-MM-yyyy")
                : activationDate
            }
            onChange={handleChangeActivationDate}
          >
            {(props: React.InputHTMLAttributes<HTMLInputElement>) => (
              <TextField
                inputProps={props}
                label="Data de ativação"
                variant="outlined"
              />
            )}
          </InputMask>
          {error && (
            <Typography variant="caption" color="error" pl={2}>
              Campo data de ativação é obrigatório.
            </Typography>
          )}
        </FormControl>
      )}

      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="company">Empresa</InputLabel>
        <Select
          labelId="company"
          label="Empresa"
          value={employersIds[id]}
          defaultValue=""
          variant="outlined"
          disabled={!employers?.length}
          onChange={(e) => handleChangeCompanyNameById(id, e.target.value)}
        >
          {employers?.map((employer) => (
            <MenuItem key={employer.id} value={employer.id}>
              {employer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="benefit">Benefício</InputLabel>
        <Select
          labelId="benefit"
          value={benefitsIds[id]}
          defaultValue=""
          variant="outlined"
          disabled={!employersIds[id]}
          onChange={(e) => handleChangeBenefitById(id, e.target.value)}
        >
          {employersIds[id] &&
            employers
              ?.find((employer) => employer.id === employersIds[id])
              ?.benefits.map((benefit) => (
                <MenuItem key={uuid()} value={benefit.id}>
                  {`${benefit.name} - ${benefit.product}`}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      <Box sx={{ width: "100%" }}>
        <TextField
          disabled={!employersIds[id]}
          value={cardNumbers[id]}
          variant="outlined"
          label="Nº da carteirinha"
          onChange={(e) => handleChangeCardNumberById(id, e.target.value)}
        />
      </Box>
    </Box>
  );
};

export default InputBenefit;
