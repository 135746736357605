import { z } from "zod";
import { isBefore } from "../../../Chat/utils/dateUtils";

export const documentFiltersSchemaValidation = z
  .object({
    startedAt: z
      .date({
        errorMap: (issue, { defaultError }) => ({
          message:
            issue.code === "invalid_date" ? "Data inválida" : defaultError,
        }),
      })
      .nullable()
      .optional(),
    endedAt: z
      .date({
        errorMap: (issue, { defaultError }) => ({
          message:
            issue.code === "invalid_date" ? "Data inválida" : defaultError,
        }),
      })
      .nullable()
      .optional(),
  })
  .refine(
    (data) => {
      if (data.startedAt && data.endedAt) {
        return isBefore(data.startedAt, data.endedAt);
      }
      return true;
    },
    {
      message: "Data de início deve ser anterior a data final",
      path: ["endedAt"],
    }
  );
