import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Icon,
  Link,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ChatBalon } from "./components/ChatBalon";
import { ChatInput } from "./components/ChatInput";
import { useAppDispatch, useAppSelector } from "../../../../store";

import { useGetTemplates } from "../../hooks/useGetTemplates";
import {
  useCloseChat,
  useGetOrphanThreads,
  useOpenChat,
} from "./hooks/useChat";
import {
  appendMessagesToConversation,
  createConversationList,
  updateConversationStatus,
} from "../../slices/conversationSlice";

import { Chat, MessageDataType, MessageProps, ChatDataType } from "../../types";
import { CloseChatModal } from "./components/CloseChatModal";
import { useMessageTemplatesContext } from "../../../../contexts/MessagesContexts/MessageTemplatesContext";
import { AssignForm } from "./components/AssignForm";
import { customColors } from "../../../../theme/colors";
import {
  isTMessageUIEvent,
  isTThreadUIEvent,
} from "../../slices/conversationHelpers";
import { ChatSummary } from "./components/ChatSummary";
import { ChatDate } from "./components/ChatDate";
import { useGetChatEvents } from "../../hooks/useGetChatEvents";
import { useGetAttendenceFilters } from "../../hooks/useGetAttendenceFilters";
import { useGetChatByIdQuery } from "./hooks/useGetChatByIdQuery";
import { renderNotifier } from "../../../../providers/Notifier";
import { closeSnackbar } from "../../../../providers/Notifier/closeNotifier";
import { useMeQuery } from "../../../../api/hooks/useMeQuery";
import { ChatModal, CommandLabelType } from "./components/ChatModal";
import { useSendMessageHandlers } from "./hooks/useSendMessageHandlers";

export type ModalContentType = "templates" | "commands" | "search_life";

export const ChatView = () => {
  const [inputMessage, setInputMessage] = useState("");
  const [error, setError] = useState("");
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [closeChatModalOpened, setCloseChatModalOpened] = useState(false);
  const [isChatClosed, setIsChatClosed] = useState(false);
  const [isGetOrphanThreadsReady, setIsGetOrphanThreadsReady] = useState(false);
  const [orphanThreads, setOrphanThreads] = useState<MessageProps[]>([]);
  const conversation = useAppSelector((state) => state.conversation);
  const dispatch = useAppDispatch();
  const params = useParams();
  const currentChatId = params?.id || "";
  const [changedChatId, setChangedChatId] = useState("");
  const [page, setPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [modalContentType, setModalContentType] =
    useState<ModalContentType>("commands");
  const [canBeMerged, setCanBeMerged] = useState(false);

  const {
    data: openChatData,
    isSuccess: openChatSuccess,
    isError: openChatError,
    isPending: openChatLoading,
    mutate: refetchOpenChat,
  } = useOpenChat(currentChatId);

  const {
    data: recevingMessageData,
    isSuccess: closeChatSuccess,
    isError: closeChatError,
    isPending: closeChatLoading,
    mutate: refetchCloseChat,
  } = useCloseChat(currentChatId);

  const {
    data: orphanThreadsData,
    isSuccess: orphanThreadsSuccess,
    isFetching: orphanThreadsLoading,
    isError: orphanThreadsError,
    refetch: refetchOrphanThreads,
  } = useGetOrphanThreads(currentChatId, isGetOrphanThreadsReady);

  useEffect(() => {
    if (closeChatSuccess && recevingMessageData?.data?.data) {
      setError("");
      dispatch(updateConversationStatus(recevingMessageData.data.data as Chat));
    } else if (closeChatError) {
      setError("Ocorreu um erro ao fechar a conversa, tente mais tarde.");
    }
  }, [closeChatSuccess, recevingMessageData, closeChatError, dispatch]);

  useEffect(() => {
    if (openChatSuccess && openChatData?.data?.data) {
      dispatch(updateConversationStatus(openChatData.data.data as Chat));
      setError("");
    } else if (openChatError) {
      setError("Ocorreu um erro ao abrir a conversa, tente mais tarde.");
    }
  }, [openChatSuccess, openChatData, openChatError, openChatLoading, dispatch]);

  useEffect(() => {
    if (currentChatId !== changedChatId) {
      setChangedChatId(currentChatId);
      setOrphanThreads([]);
      return;
    }

    if (orphanThreadsSuccess && orphanThreadsData?.data?.data) {
      setError("");
      if (orphanThreadsData.data.data.length > 0) {
        setCloseChatModalOpened(true);
        setOrphanThreads([...orphanThreadsData.data.data]);
      } else {
        refetchCloseChat();
      }
    } else if (orphanThreadsError) {
      setError("Ocorreu um erro ao buscar as threads órfãs, tente mais tarde.");
    }
    setIsGetOrphanThreadsReady(false);
  }, [
    orphanThreadsSuccess,
    orphanThreadsData,
    orphanThreadsError,
    refetchCloseChat,
  ]);

  const attendenceFilters = useGetAttendenceFilters();
  const enableMessageHistory = Boolean(currentChatId);

  const messageHistory = useGetChatEvents({
    currentChatId,
    page,
    enableMessageHistory,
  });

  const result = messageHistory.data;
  const currentConversationMessages = conversation?.conversationList?.find?.(
    (conversation) => {
      return conversation?.chatData?.id === currentChatId;
    }
  );

  const { meQueryData } = useMeQuery();

  const previousChatId = useRef("");

  const chatResponse = useGetChatByIdQuery(currentChatId);

  const hasPhoneNumber =
    Number(
      chatResponse?.data?.data?.data?.attributes?.life?.attributes
        ?.phone_numbers?.length
    ) > 0;

  useEffect(() => {
    const hasChatIdChanged =
      previousChatId.current !== currentChatId || !previousChatId.current;

    const chatData = chatResponse.data?.data?.data as unknown as ChatDataType;

    if (hasChatIdChanged && chatData) {
      closeSnackbar();

      dispatch(
        createConversationList({
          chatList: [chatData],
          currentLoggedUserId: meQueryData?.id || "",
        })
      );

      dispatch(appendMessagesToConversation(result?.data?.data || []));
      previousChatId.current = currentChatId;

      if (!hasPhoneNumber) {
        renderNotifier("O paciente não possui telefone cadastrado.", {
          variant: "error",
          persist: true,
        });
      }
    }
  }, [
    attendenceFilters,
    chatResponse,
    currentChatId,
    dispatch,
    hasPhoneNumber,
    result,
  ]);

  useEffect(() => {
    if (chatResponse) {
      if (chatResponse?.data?.data?.data?.attributes?.life?.attributes) {
        const lifeProps = {
          ...chatResponse.data.data.data.attributes.life.attributes,
        };
        const lifePropsEntries = Object.entries(lifeProps);
        const notEmptyLifePropsKeys = lifePropsEntries
          .filter((entry) => Array.isArray(entry[1]) && entry[1].length > 0)
          .map((entry) => entry[0]);

        const canMerge = notEmptyLifePropsKeys.every((propKey) => {
          return (
            propKey === "phone_numbers" ||
            propKey === "coverages" ||
            propKey === "relatives"
          );
        });
        setCanBeMerged(canMerge);
      }
    }
  }, [chatResponse]);

  useEffect(() => {
    dispatch(appendMessagesToConversation(result?.data?.data || []));
  }, [dispatch, result]);

  useEffect(() => {
    if (result) {
      if (result?.data?.data?.length === 0) {
        setHasMorePages(false);
        return;
      }
      chatViewRef.current?.scrollTo({
        top: chatViewRef.current?.scrollTop + 10,
        behavior: "smooth",
      });
    }
  }, [
    dispatch,
    result,
    currentChatId,
    currentConversationMessages?.chatData?.id,
    currentConversationMessages?.messages,
  ]);

  useEffect(() => {
    if (currentConversationMessages) {
      const currentConversationStatus =
        currentConversationMessages.chatData?.attributes?.status;
      setIsChatClosed(currentConversationStatus === "CLOSED");
    }
  }, [currentConversationMessages]);

  const autoScroll = useRef(true);

  const chatViewRef = useRef<HTMLDivElement>(null);

  const chatHeight = Number(chatViewRef.current?.clientHeight);

  const previousMessages = useRef(currentConversationMessages?.messages || []);

  useEffect(() => {
    const isScrollable = chatHeight > 100;
    const messagesDifference =
      Number(currentConversationMessages?.messages?.length) -
      Number(previousMessages.current.length);

    if (
      (isScrollable && autoScroll.current) ||
      (messagesDifference >= 1 && messagesDifference < 10)
    ) {
      chatViewRef.current?.scrollTo({
        top: chatViewRef.current?.scrollHeight,
        behavior: "instant",
      });
      autoScroll.current = false;
      previousMessages.current = currentConversationMessages?.messages || [];
    }
  }, [chatHeight, currentConversationMessages]);

  const disabledScrollStyle = {
    overflowY:
      messageHistory.isFetching || messageHistory.isPending ? "hidden" : "auto",
  };

  const onManualScrollHandler = async (
    event: React.UIEvent<HTMLDivElement>
  ) => {
    const { scrollTop } = event.currentTarget;
    if (scrollTop === 0 && hasMorePages) {
      setPage((prev) => prev + 1);
    }
  };

  const [isTemplateMessage, setIsTemplateMessage] = useState(false);

  const { handleSendMessage } = useSendMessageHandlers({
    isTemplateMessage,
    currentChatId,
  });

  const onSendMessageHandler = (message: string) =>
    handleSendMessage({
      message,
      afterSendMessageCallback: () => {
        setIsTemplateMessage(false);
      },
    });

  const handleOnChooseTemplate = (templateMessage: string) => {
    setChatModalOpen(false);
    setIsTemplateMessage(true);
    setModalContentType("commands");
    setInputMessage(templateMessage);
  };

  const handleCreateHealthSurvey = (value: string) => {
    console.log("creating health survey... ", value);
  };

  const commands = [
    {
      command: "select_template" as CommandLabelType,
      callback: (value: string) => handleOnChooseTemplate(value),
    },
    {
      command: "create_health_survey" as CommandLabelType,
      callback: (value: string) => handleCreateHealthSurvey(value),
    },
    {
      command: "merge_life" as CommandLabelType,
    },
  ];

  const {
    setTemplates,
    setIsLoading,
    setError: setTemplateError,
  } = useMessageTemplatesContext();

  const {
    data: templates,
    error: templateErrors,
    isLoading: loadingTemplates,
  } = useGetTemplates();

  useEffect(() => {
    if (templates) {
      setTemplates(templates.data.data);
    } else if (templateErrors) {
      setTemplateError("Ocorreu um erro ao buscar os templates.");
    }
    setIsLoading(loadingTemplates);
  }, [
    templates,
    templateErrors,
    loadingTemplates,
    setIsLoading,
    setTemplates,
    setTemplateError,
  ]);

  useEffect(() => {}, [closeChatModalOpened, isGetOrphanThreadsReady]);

  const handleOnCloseChat = () => {
    setIsGetOrphanThreadsReady(true);
    refetchOrphanThreads();
  };

  const handleOnReopenChat = () => {
    refetchOpenChat();
  };

  const handleOnChangeModalContentType = (contentType: ModalContentType) => {
    setModalContentType(contentType);
  };

  const externalUserId =
    currentConversationMessages?.chatData?.attributes?.life?.attributes
      ?.external_id;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: 2,
        height: "100%",
        position: "relative",
        paddingX: (theme) => theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "130px",
          paddingBottom: "20px",
          borderBottom: `1px solid ${customColors.SecondaryNeutral}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            gap: 1,
            width: "100%",
          }}
        >
          <Box>
            {externalUserId && (
              <Link
                href={`https://pro.nabla.com/patients/${externalUserId}`}
                target="_blank"
                rel="noreferrer noreferrer"
                color="primary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Abrir no Nabla{" "}
                <Icon sx={{ marginLeft: "4px" }} fontSize="small">
                  open_in_new
                </Icon>
              </Link>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box>
              <AssignForm />
            </Box>
            {currentChatId && (
              <Box width="200px">
                {isChatClosed ? (
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleOnReopenChat}
                    size="large"
                  >
                    {openChatLoading ? (
                      <CircularProgress
                        data-testid="open-chat-loading"
                        size={24}
                      />
                    ) : (
                      "Reabrir conversa"
                    )}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleOnCloseChat}
                    size="large"
                  >
                    {closeChatLoading || orphanThreadsLoading ? (
                      <CircularProgress
                        sx={{
                          color: (theme) => theme.palette.primary.contrastText,
                        }}
                        size={24}
                      />
                    ) : (
                      "Fechar conversa"
                    )}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {error && (
        <Typography
          variant="h5"
          color="error"
          sx={{
            textAlign: "right",

            width: "100%",
          }}
        >
          Ocorreu um erro. Tente mais tarde.
        </Typography>
      )}

      {(messageHistory.isLoading || chatResponse?.isLoading) && (
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          data-testid="message-history-loading"
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        onScroll={onManualScrollHandler}
        ref={chatViewRef}
        sx={{
          width: "100%",
          paddingBottom: 2,
          paddingRight: 2,
          ...disabledScrollStyle,
        }}
      >
        {(currentConversationMessages?.messages || []).map((message, index) => {
          if (isTMessageUIEvent(message)) {
            return (
              <Box key={index}>
                <ChatDate
                  message={message}
                  allMessages={currentConversationMessages?.messages}
                />
                <ChatBalon
                  message={message?.content || ""}
                  senderName={message?.senderName || ""}
                  direction={message.messageDirection}
                  eventType={message.event}
                  time={message.createdAtTime}
                  messageStatus={message.messageStatus}
                  errorCode={
                    (message?.originalMessage?.data as MessageDataType)
                      ?.attributes?.error_code || ""
                  }
                  errorMessage={
                    (message?.originalMessage?.data as MessageDataType)
                      ?.attributes?.error_message || ""
                  }
                  originalMessage={message.originalMessage}
                />
              </Box>
            );
          }

          if (isTThreadUIEvent(message)) {
            return <ChatSummary key={message.id} summary={message.summary} />;
          }
        })}
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!messageHistory.isLoading && currentConversationMessages && (
          <ChatInput
            data-testid={"chat-input"}
            message={inputMessage}
            handleOnChange={setInputMessage}
            onSend={onSendMessageHandler}
            disabled={isChatClosed && !inputMessage}
            chatId={currentChatId}
            toggleCommandModal={() => setChatModalOpen(!chatModalOpen)}
          />
        )}
      </Box>
      <ChatModal
        open={chatModalOpen}
        loading={false}
        canBeMerged={canBeMerged}
        handleOnClose={() => setChatModalOpen(false)}
        setTemplatesCallback={handleOnChooseTemplate}
        handleOnChangeModalContentType={handleOnChangeModalContentType}
        contentType={modalContentType}
        isChatClosed={isChatClosed}
        commands={commands}
      />
      {closeChatModalOpened && (
        <CloseChatModal
          open={closeChatModalOpened}
          currentChatId={currentChatId}
          handleOnClose={setCloseChatModalOpened}
          existingOrphanThreads={orphanThreads}
        />
      )}
    </Box>
  );
};
