import { Box, Tab, Tabs, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Lives } from "./Lives/Lives";
import Benefits from "./Benefits";
import { useGetLives } from "./hooks/useLives";
import { useLivesContext } from "../../contexts/RegistrationContexts/LivesContext";
import { useAddressesContext } from "../../contexts/RegistrationContexts/AddressesContext";
import { useRelativesContext } from "../../contexts/RegistrationContexts/RelativesContext";
import { useBenefitsContext } from "../../contexts/RegistrationContexts/BenefitsContext";
import { DataTypeEnum } from "./Lives/components/lifeUtils";
import { GridPaginationModel } from "@mui/x-data-grid";

const MAX_PAGE_LIMIT = 30;
const PAGE_SIZE = 10;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        margin: "20px 0 0 0",
        "&.MuiContainer-root": {
          width: "100%",
          maxWidth: "99%",
          paddingRight: 0,
          paddingLeft: 0,
        },
      }}
    >
      {value === index && <Box>{children}</Box>}
    </Container>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabStyle = {
  width: "206px",
  height: "42px",
};

type DataType = {
  type: DataTypeEnum;
  value: string;
};

const RegistrationTabs = () => {
  const [value, setValue] = useState(0);
  const [offset, setOffset] = useState(1);
  const [lastPageFetched, setLastPageFetched] = useState(false);
  const [keySearch, setKeySearch] = useState<
    DataType | { type: null; value: string }
  >({ type: null, value: "" });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const { lives, setLives, resetLivesContext } = useLivesContext();
  const { resetAddressesState } = useAddressesContext();
  const { resetRelativesContext } = useRelativesContext();
  const { resetBenefitsContext } = useBenefitsContext();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    data: livesData,
    isFetching: livesLoading,
    isError: livesError,
    isSuccess: livesSuccess,
    refetch: refetchLives,
  } = useGetLives(MAX_PAGE_LIMIT, offset, keySearch, true);

  useEffect(() => {
    if (livesSuccess && livesData) {
      if (livesData.length < MAX_PAGE_LIMIT) {
        setLastPageFetched(true);
      }
      if (keySearch.type !== null) {
        setLives([...livesData]);
        setKeySearch({ type: null, value: "" });
      } else {
        setLives([...lives, ...livesData]);
      }
    }
  }, [livesData, livesSuccess]);

  useEffect(() => {
    if (keySearch.type !== null) {
      return;
    }
    refetchLives();
  }, [offset]);

  useEffect(() => {
    if (keySearch.type === null) {
      return;
    }
    refetchLives();
  }, [keySearch, refetchLives]);

  const handleOnPageChange = (model: GridPaginationModel) => {
    if ((model.page + 1) * model.pageSize >= lives.length && !lastPageFetched) {
      setOffset(offset + 1);
    }
    setPaginationModel({
      pageSize: model.pageSize,
      page: model.page,
    });
  };

  type LifeSearchProps = {
    type: DataTypeEnum;
    value: string;
  };
  const handleLifeSearch = ({ type, value }: LifeSearchProps) => {
    if (type === DataTypeEnum.Error) {
      return;
    }
    setOffset(1);
    setKeySearch({ type, value });
  };

  useEffect(() => {
    return () => {
      resetLivesContext();
      resetAddressesState();
      resetRelativesContext();
      resetBenefitsContext();
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="secondary"
          indicatorColor="secondary"
          sx={{ p: 0 }}
        >
          <Tab label="Vidas" sx={TabStyle} {...a11yProps(0)} />
          <Tab label="Benefícios" sx={TabStyle} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Lives
          livesError={livesError}
          livesLoading={livesLoading}
          maxPageLimit={MAX_PAGE_LIMIT}
          pageSize={PAGE_SIZE}
          paginationModel={paginationModel}
          handleOnPageChange={handleOnPageChange}
          handleLifeSearch={handleLifeSearch}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Benefits />
      </CustomTabPanel>
    </Box>
  );
};

export default RegistrationTabs;
