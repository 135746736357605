import {
  InboxOutlined,
  Inventory2Outlined,
  LabelOutlined,
  PersonOutlined,
} from "@mui/icons-material";

export const attendenceFilterStatus = {
  withoutAssignees: "without_assignees",
  myChats: "my_chats",
  otherChats: "other_chats",
  status: "status",
};

export const options = [
  {
    label: "Não atribuídas",
    status: attendenceFilterStatus.withoutAssignees,
    icon: <InboxOutlined />,
    filters: {
      [attendenceFilterStatus.withoutAssignees]: true,
      [attendenceFilterStatus.status]: "OPEN",
    },
  },
  {
    label: "Minhas",
    status: attendenceFilterStatus.myChats,
    icon: <PersonOutlined />,
    filters: {
      [attendenceFilterStatus.myChats]: true,
      [attendenceFilterStatus.status]: "OPEN",
    },
  },
  {
    label: "Atribuídas a outros",
    status: attendenceFilterStatus.otherChats,
    icon: <LabelOutlined />,
    filters: {
      [attendenceFilterStatus.otherChats]: true,
      [attendenceFilterStatus.status]: "OPEN",
    },
  },
  {
    label: "Encerradas",
    status: attendenceFilterStatus.status,
    icon: <Inventory2Outlined />,
    filters: {
      [attendenceFilterStatus.status]: "CLOSED",
    },
  },
];

export type TAttendenceFilterParam = {
  [key: string]: string | boolean;
};
