import { ThemeOptions } from "@mui/material";
import { customColors } from "../colors";

const baseFontSize = 16;
const getFontSize = (size: number) => `${size / baseFontSize}rem`;

export const TypographyNode: ThemeOptions["typography"] = {
  fontFamily: ["HafferSQXH-Regular", "Arial"].join(","),
  allVariants: {
    color: customColors.Neutral07,
  },

  h1: {
    fontSize: getFontSize(36),
    fontWeight: "700",
  },
  h2: {
    fontSize: getFontSize(32),
    fontWeight: "700",
  },
  h3: {
    fontSize: getFontSize(28),
    fontWeight: "700",
  },
  h4: {
    fontSize: getFontSize(24),
    fontWeight: "400",
  },
  h5: {
    fontSize: getFontSize(20),
    fontWeight: "700",
  },
  h6: {
    fontSize: getFontSize(18),
    fontWeight: "700",
  },
  body1: {
    fontSize: getFontSize(18),
    fontWeight: "400",
  },
  body2: {
    fontSize: getFontSize(16),
    fontWeight: "400",
  },
  body3: {
    fontSize: getFontSize(16),
    fontWeight: "700",
  },
  button: {
    fontSize: getFontSize(14),
    textTransform: "none",
  },
  subtitle1: {
    fontSize: getFontSize(25),
    fontWeight: 700,
    lineHeight: "100%",
  },
  subtitle2: {
    fontSize: getFontSize(13),
  },
  caption: {
    fontSize: getFontSize(12),
    letterSpacing: "inherit",
  },
};
