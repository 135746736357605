import { useParams } from "react-router-dom";
import { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";

import { Box, CircularProgress, Typography } from "@mui/material";
// import { useTimelineFilters } from "../hooks/useTimelineFilters";

// import { useEventsListFormDefinition } from "./TimelineFilters/useEventsListFormDefinition";
import { useGetLifeById } from "../../Chat/components/LifeSummary/lifeApi/useGetLifeById";
import { TimelineScrollList } from "./TimelineScrollList";
import { ConsultationList } from "./ConsultationList";
import { useGetTimelineEventsQuery } from "../hooks/useGetTimelineEventsQuery";
import {
  isAppointmentTimelineResource,
  isEncounterTimelineResource,
  isThreadTimelineResource,
} from "../timelineTypeGuards";
import { ThreadTimelineEvent } from "./ThreadTimelineEvent";
import { ThreadResource } from "../timelineTypes";
import { EncounterTimelineEvent } from "./EncounterTimelineEvent";
import { AppointmentTimelineEvent } from "./AppointmentTimelineEvent";

export const EventList = () => {
  const params = useParams();
  const lifeId = params?.lifeId || "";

  // const { currentForm } = useTimelineFilters();

  const lifeSummaryQuery = useGetLifeById(lifeId || "");

  const timelineEventsQuery = useGetTimelineEventsQuery({
    lifeId,
  });

  // const chatId = lifeSummaryQuery?.data?.data?.attributes?.chat_id;

  // const eventListFormDefinition = useEventsListFormDefinition({
  //   form: currentForm,
  // });

  // const { debouncedTextFilter, debouncedStartedAt, debouncedEndedAt, tags } =
  //   watchValues;

  const [isExpanded, setIsExpanded] = useState<boolean[]>([]);

  // const chatEvents = useGetChatThreads({
  //   currentChatId: chatId || "",
  //   freeTextFilter: debouncedTextFilter,
  //   tagFilter: tags,
  //   startedAtFilter: debouncedStartedAt,
  //   endedAtFilter: debouncedEndedAt,
  //   enabled: Object.keys(currentForm?.formState?.errors).length === 0,
  // });

  const timelineEvents = timelineEventsQuery?.data?.data?.data;

  const handleExpand = (index: number) => {
    setIsExpanded((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const isConsultationListEnabled = true;

  return (
    <Timeline
      sx={{
        padding: 0,
        "& .MuiTimelineItem-missingOppositeContent::before": {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {/* <Box mb={2}>
        <TimelineFilters formDefinition={eventListFormDefinition} />
      </Box> */}
      {isConsultationListEnabled && <ConsultationList />}
      <Box sx={{ mb: 2 }}>
        <Typography variant="caption">HISTÓRICO</Typography>
      </Box>
      {(timelineEventsQuery?.isLoading ||
        timelineEventsQuery.isRefetching ||
        lifeSummaryQuery?.isLoading) && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress data-testid="event-list-loading" />
        </Box>
      )}
      <TimelineScrollList>
        {timelineEvents?.map((event, index) => {
          const isExpandedRow = isExpanded?.[index];
          const isThreadTimeline = isThreadTimelineResource(
            event?.attributes?.event
          );

          const isEncounterTimline = isEncounterTimelineResource(
            event?.attributes?.event
          );

          const isAppointmentTimeline = isAppointmentTimelineResource(
            event?.attributes?.event
          );

          return (
            <TimelineItem key={event?.id}>
              {isThreadTimeline && (
                <ThreadTimelineEvent
                  threadEvent={event?.attributes?.event as ThreadResource}
                  isExpandedRow={isExpandedRow}
                  index={index}
                  handleExpand={handleExpand}
                />
              )}
              {isEncounterTimline && (
                <EncounterTimelineEvent
                  encounterEventAttributes={event?.attributes}
                  isExpandedRow={isExpandedRow}
                  index={index}
                  handleExpand={handleExpand}
                />
              )}
              {isAppointmentTimeline && (
                <AppointmentTimelineEvent
                  encounterEventAttributes={event?.attributes}
                  isExpandedRow={isExpandedRow}
                  index={index}
                  handleExpand={handleExpand}
                />
              )}
            </TimelineItem>
          );
        })}
      </TimelineScrollList>
    </Timeline>
  );
};
