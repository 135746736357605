import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../store";

export const useGetCurrentChat = (chatId?: string) => {
  const params = useParams();
  const currentChatId = chatId || params?.id || "";

  const conversation = useAppSelector((state) => state.conversation);

  const currentChat = conversation?.conversationList?.find?.(
    (conversation) => conversation?.chatData?.id === currentChatId
  );

  return currentChat;
};
