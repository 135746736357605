import { Box, Button, Tab, Tabs } from "@mui/material";
import { Add, WatchLaterOutlined } from "@mui/icons-material";
import { ScreenHeader } from "../../components/ScreenHeader";
import { LifeSummary } from "../Chat/components/LifeSummary";
import { SyntheticEvent, useState } from "react";
import { DocumentList } from "./components/DocumentList";
import { EventList } from "./components/EventList";

export const ChatTimeline = () => {
  const [value, setValue] = useState("1");

  const handleChange = (_: SyntheticEvent<unknown>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%", paddingRight: 3 }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <ScreenHeader title="Atividades" icon={WatchLaterOutlined} />
            <Box>
              <Button
                disabled
                variant="outlined"
                color="primary"
                endIcon={<Add />}
              >
                Adicionar atividade
              </Button>
            </Box>
          </Box>
          <Box>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Linha do tempo" value="1" />
              <Tab label="Documentos" value="2" />
            </Tabs>
            <Box mt={2}>
              {value === "1" && (
                <Box sx={{ width: "100%" }}>
                  <EventList />
                </Box>
              )}
              {value === "2" && (
                <Box sx={{ width: "100%" }}>
                  <DocumentList />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <LifeSummary />
        </Box>
      </Box>
    </Box>
  );
};
