import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import emojisPt from "@emoji-mart/data/i18n/pt.json";
import "./emojiPickerStyle.css";

emojisPt.categories.people = "Sorrisos e pessoas";

export type TEmoji = {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string;
};

type TCustomEmojiPicker = {
  onEmojiSelect: (emoji: TEmoji) => void;
};

export const CustomEmojiPicker = (props: TCustomEmojiPicker) => {
  return (
    <Picker
      onEmojiSelect={props.onEmojiSelect}
      skinTonePosition="search"
      i18n={emojisPt}
      data={data}
      previewEmoji={"none"}
    />
  );
};
