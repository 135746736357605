import { useQuery } from "@tanstack/react-query";
import { api } from "../../../api";

import { AppointmentsResponse } from "../timelineTypes";

type TUserGetAppointmentsParams = {
  lifeId: string;
};

export const useGetAppointments = (params: TUserGetAppointmentsParams) => {
  return useQuery({
    queryKey: ["get-appoinments", params.lifeId],
    queryFn: async () => {
      // Making request params manually because we need to send multiple values for the same key (status)
      const searchParams = new URLSearchParams();
      searchParams.append("status", "BOOKED");
      searchParams.append("status", "PENDING");
      searchParams.append("life_id", params.lifeId);
      searchParams.append("limit", "100");

      const response = await api.get<AppointmentsResponse>(
        "/api/v1/appointments",
        {
          params: searchParams,
        }
      );
      return response;
    },
  });
};
