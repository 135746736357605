import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { DEFAULT_BORDER_RADIUS } from "../../../../../theme/design-tokens";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode } from "react";

type TSummaryBoxProps = {
  title: ReactNode;
  details: ReactNode;
};

export const SummaryBox = (props: TSummaryBoxProps) => {
  return (
    <Box>
      <Accordion
        disableGutters
        defaultExpanded
        sx={{
          background: "transparent",
          backgroundImage: "none",
          boxShadow: "none",
          border: "1px solid",
          borderColor: "common.neutral05",
          paddingY: 2.5,
          paddingX: 1.5,
          "&&": {
            borderRadius: DEFAULT_BORDER_RADIUS,
          },
        }}
      >
        <AccordionSummary
          sx={{
            height: "0px",
            minHeight: "0px",
            margin: 0,
            padding: 0,
          }}
          expandIcon={
            <ExpandMoreIcon sx={{ color: "secondary.contrastText" }} />
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {props.title}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0, margin: 0, marginTop: 2 }}>
          {props.details}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
