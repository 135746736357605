import { FormGroup } from "@mui/material";

type FormGroupContainerProps = {
  children: React.ReactNode;
  formMode?: "create" | "edit" | null;
};

export const FormGroupContainer = ({
  children,
  formMode,
}: FormGroupContainerProps) => {
  return (
    <FormGroup
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        width: `${formMode === "edit" ? "460px" : "100%"}`,
      }}
    >
      {children}
    </FormGroup>
  );
};
