import { ThemeOptions } from "@mui/material";
import { customColors, muiThemeColors } from "../colors";

type TCustomPalleteNode = {
  common: {
    box: string;
    neutral03: string;
    neutral05: string;
    neutral06: string;
    neutral07: string;
    neutral08: string;
  };
};

export const PaletteNode: ThemeOptions["palette"] & TCustomPalleteNode = {
  mode: "light",
  background: {
    default: customColors.OffWhite,
  },
  common: {
    box: customColors.BoxBackgroundColor,
    neutral03: customColors.Neutral03,
    neutral05: customColors.Neutral05,
    neutral06: customColors.Neutral06,
    neutral07: customColors.Neutral07,
    neutral08: customColors.Neutral08,
  },
  primary: {
    main: muiThemeColors.primary.main,
    light: muiThemeColors.primary.light,
    dark: muiThemeColors.primary.dark,
    contrastText: "#ffffff",
  },
  secondary: {
    main: muiThemeColors.secondary.main,
    light: muiThemeColors.secondary.light,
    dark: muiThemeColors.secondary.dark,
    contrastText: customColors.Neutral07,
  },
  grey: {
    A200: customColors.BoxBackgroundColor,
  },
  error: {
    main: muiThemeColors.error.main,
    light: muiThemeColors.error.light,
    dark: muiThemeColors.error.dark,
  },
  warning: {
    main: muiThemeColors.warning.main,
    light: muiThemeColors.warning.light,
    dark: muiThemeColors.warning.dark,
  },
  info: {
    main: muiThemeColors.info.main,
    light: muiThemeColors.info.light,
    dark: muiThemeColors.info.dark,
  },
  success: {
    main: muiThemeColors.success.main,
    light: muiThemeColors.success.light,
    dark: muiThemeColors.success.dark,
  },
};
