import { customColors, muiThemeColors } from "../colors";
import { DEFAULT_BORDER_RADIUS } from "../design-tokens";

export const MuiAlertNode = {
  styleOverrides: {
    root: {
      borderRadius: DEFAULT_BORDER_RADIUS,
    },
    filledWarning: {
      backgroundColor: customColors.WarningBackground,
      color: muiThemeColors.secondary.contrastText,
    },
  },
};
