import { format } from "../../Chat/utils/dateUtils";

export type TFilterParams = {
  freeTextFilter?: string;
  fileTypeFilter?: string;
  tagFilter?: string;
  startedAtFilter?: Date | null;
  endedAtFilter?: Date | null;
};

export const getFilterObject = (
  params: TFilterParams,
  type?: "eventList" | "attachmentList"
) => {
  const normalizedFileType = params?.fileTypeFilter?.split("/")?.[0] || "";
  const startedAt = params?.startedAtFilter
    ? format(params?.startedAtFilter.toDateString(), "yyyy-MM-dd")
    : "";
  const endedAt = params?.endedAtFilter
    ? format(params?.endedAtFilter.toDateString(), "yyyy-MM-dd")
    : "";

  return {
    ...(params?.freeTextFilter
      ? {
          [type === "eventList" ? "title" : "name"]:
            params?.freeTextFilter || "",
        }
      : {}),
    ...(params?.fileTypeFilter
      ? { content_type: normalizedFileType } || ""
      : {}),
    ...(params?.tagFilter
      ? { tagged_item_label: params?.tagFilter || "" }
      : {}),
    ...(startedAt ? { started_at: startedAt || "" } : {}),
    ...(endedAt ? { ended_at: endedAt || "" } : {}),
  };
};

export const cancellationReasonsUI = {
  noSymptomsAnymore: "Não está mais com os sintomas",
  consultedAnotherDoctor: "Já se consultou com outra médica",
  hadToReschedule: "Precisou mudar de data/horário",
  noSpecificReason: "Sem motivo específico",
  otherReasons: "Outros",
};

export const cancellationReasonsObj = Object.keys(cancellationReasonsUI).reduce(
  (acc, key) => {
    acc[key as keyof typeof cancellationReasonsUI] = false;
    return acc;
  },
  {} as Record<keyof typeof cancellationReasonsUI, boolean>
);
