import { UseFormReturn } from "react-hook-form";
import { TFilterDocumentsForm } from "../DocumentList";
import { StartedAtFilter } from "./filter-fields/StartedAtFilter";
import { EndedAtFilter } from "./filter-fields/EndedAtFilter";
import { FreeTextFilter } from "./filter-fields/FreeTextFilter";
import { FileTypesFilter } from "./filter-fields/FileTypeFilter";
import { AttachmentsTagsFilter } from "./filter-fields/AttachmentsTagsFilter";

type TUseAttachmentsFormDefinitionParams = {
  form: UseFormReturn<TFilterDocumentsForm>;
};

export type TFilterFormDefinition = {
  component: JSX.Element;
  parentSx: {
    width: string;
  };
};

export const useAttachmentsFormDefinition = (
  params: TUseAttachmentsFormDefinitionParams
): TFilterFormDefinition[] => {
  const formComponents = [
    {
      component: <FreeTextFilter {...params.form} />,
      parentSx: { width: "45%" },
    },
    {
      component: <StartedAtFilter {...params.form} />,
      parentSx: { width: "25%" },
    },
    {
      component: <EndedAtFilter {...params.form} />,
      parentSx: { width: "25%" },
    },
    {
      component: <FileTypesFilter {...params.form} />,
      parentSx: { width: "45%" },
    },
    {
      component: <AttachmentsTagsFilter {...params.form} entity="ATTACHMENT" />,
      parentSx: { width: "50%" },
    },
  ];

  return formComponents;
};
