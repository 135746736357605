import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const columns: GridColDef<(typeof rows)[number]>[] = [
  {
    field: "priority",
    headerName: "Prioridade",
    width: 150,
  },
  {
    field: "task",
    headerName: "Tarefa",
    width: 180,
  },
  {
    field: "assignee",
    headerName: "Responsável",
    width: 180,
  },
  {
    field: "lifeName",
    headerName: "Paciente",
    sortable: false,
    width: 180,
  },
  {
    field: "endDate",
    headerName: "Prazo",
    width: 110,
  },
  {
    field: "status",
    headerName: "Status",
    width: 180,
  },
];

const rows = [
  {
    id: "1",
    priority: "Normal",
    task: "Acompanhamento",
    assignee: "Stephany Martins",
    lifeName: "Luci Ferraz",
    endDate: "11/09/2024",
    status: "Em andamento",
  },
  {
    id: "2",
    priority: "Alta",
    task: "Acompanhamento",
    assignee: "Ricardo Faé",
    lifeName: "Antônio Antunes",
    endDate: "09/09/2024",
    status: "Em andamento",
  },
];

export const TasksTable = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        sx={{
          border: 0,
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 900,
            fontFamily: "HafferSQXH-Bold",
          },
        }}
      />
    </Box>
  );
};
