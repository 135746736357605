import { useQuery } from "@tanstack/react-query";
import { api } from "../../../api";
import { EncounterDocumentsResponse } from "../timelineTypes";

type TUseGetEncounterListDocumentsQuery = {
  encounterId: string;
  enabled: boolean;
};

export const useGetEncounterListDocumentsQuery = (
  params: TUseGetEncounterListDocumentsQuery
) => {
  const { encounterId } = params;

  const getEncounterListDocumentsQuery = useQuery({
    queryKey: ["encounter-documents", encounterId],
    queryFn: async () => {
      return api.get<EncounterDocumentsResponse>(
        `/api/v1/encounter-documents`,
        {
          params: {
            encounter_id: encounterId,
          },
        }
      );
    },
    enabled: Boolean(encounterId && params.enabled),
  });

  return { getEncounterListDocumentsQuery };
};
