import { useMutation } from "@tanstack/react-query";
import { SocketEvent } from "../../../types";
import { api } from "../../../../../api";

type TSendMessageMutationArgs = {
  message: string;
  chatId: string;
  isTemplate: boolean;
};

export const useSendMessageMutation = () => {
  const sendMessageMutation = useMutation({
    mutationFn: ({ message, chatId, isTemplate }: TSendMessageMutationArgs) =>
      api.post<SocketEvent>(`/api/v1/chats/${chatId}/messages`, {
        data: {
          type: "messages",
          attributes: {
            content: message,
            type: isTemplate ? "TEMPLATE" : "CONVERSATION",
          },
        },
      }),
  });

  return { sendMessageMutation };
};
