import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  Chat,
  ChatDataType,
  SocketEvent,
  MessageEvenType,
  Assignee,
  MessageDataType,
} from "../types";

import {
  formatChatData,
  formatIncomingMessage,
  keepMessagesWithMostRecentStatus,
  lessRecentFirst,
  removeDuplicatedIds,
  reorderByLastLifeMessage,
} from "./conversationFormatting";
import {
  addOrEditConversation,
  getConversationByChatID,
  syncConversationFiltersWithChatList,
} from "./conversationHelpers";
import { getMessageCurrentFilters } from "../components/ConversationList/getMessageCurrentFilters";

export enum EMessageDirection {
  "receiving" = "receiving",
  "sending" = "sending",
}

export enum MessageStatus {
  "sending" = "sending",
  "sent" = "sent",
  "read" = "read",
  "failed" = "failed",
  "delivered" = "delivered",
}

export enum ConversationStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  CREATED = "CREATED",
}

export type TMessageUIEvent = {
  id: string;
  content?: string;
  senderId: string;
  chatId?: string;
  messageDirection: EMessageDirection;
  senderName: string;
  fullName?: string;
  event: MessageEvenType;
  createdAt: string;
  createdAtFullDate: string;
  createdAtTime: string;
  originalMessage: SocketEvent;
  isExpired: boolean;
  messageStatus: MessageStatus;
};

export type TThreadUIEvent = {
  id: string;
  chat_id: string;
  created_at: string;
  summary: string;
  originalMessage: SocketEvent;
  messageDirection?: EMessageDirection;
};

export type TMessage = TMessageUIEvent | TThreadUIEvent | undefined;

export type TConversation = {
  messages: TMessage[];
  chatData?: ChatDataType;
  currentFilters?: string[];
};

const initialState = {
  conversationList: [] as TConversation[],
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    // create the list of conversations
    createConversationList: (
      state,
      action: PayloadAction<{
        chatList: ChatDataType[];
        currentLoggedUserId: string;
      }>
    ) => {
      const { chatList = [] } = action.payload;

      chatList.forEach((conversation) => {
        const messageCurrentFilters = getMessageCurrentFilters(
          {
            status: conversation.attributes?.status,
            assignees: conversation.attributes?.assignees,
          },
          action.payload.currentLoggedUserId
        );

        addOrEditConversation(state.conversationList, {
          conversation,
          currentFilters: messageCurrentFilters,
          chatList,
        });
      });

      state.conversationList = syncConversationFiltersWithChatList(
        chatList,
        state.conversationList,
        action.payload.currentLoggedUserId
      );
    },
    appendMessagesToConversation: (
      state,
      action: PayloadAction<SocketEvent[]>
    ) => {
      const normalizedMessagesHistory: TMessage[] = action.payload
        .map(formatIncomingMessage)
        .filter(Boolean);

      const firstEventWithChatId = action.payload.find(
        (event) => event?.chat_id
      );

      const currentChatId = firstEventWithChatId?.chat_id;

      const { hasFoundIndex, conversationIndex } = getConversationByChatID(
        state.conversationList,
        currentChatId || ""
      );

      const messagesToAppend = [
        ...(state.conversationList?.[conversationIndex]?.messages || []),
        ...normalizedMessagesHistory,
      ]
        .filter(keepMessagesWithMostRecentStatus)
        .reduce(removeDuplicatedIds, [])
        .sort(lessRecentFirst);

      if (hasFoundIndex) {
        state.conversationList[conversationIndex].messages = messagesToAppend;
      } else {
        state.conversationList.push({
          chatData: {
            ...formatChatData(
              (action.payload[0]?.data as MessageDataType)?.attributes
                ?.chat as ChatDataType
            ),
            id: action.payload[0]?.chat_id || "",
          },
          messages: messagesToAppend || [],
        });
      }
    },
    // used for optimistic updates after sending a message
    updateMessage: (
      state,
      action: PayloadAction<{
        messageId: string;
        chatId: string;
        messagePayload: SocketEvent;
      }>
    ) => {
      const { messageId, chatId, messagePayload } = action.payload;

      const { hasFoundIndex: hasFoundChatIndex, conversationIndex } =
        getConversationByChatID(state.conversationList, chatId);

      if (hasFoundChatIndex) {
        const messageIndex = state.conversationList[
          conversationIndex
        ].messages.findIndex((message) => message?.id === messageId);

        if (messageIndex !== -1) {
          state.conversationList[conversationIndex].messages[messageIndex] =
            formatIncomingMessage(messagePayload);
        }
      }
    },
    updateConversationStatus: (state, action: PayloadAction<Chat>) => {
      const { hasFoundIndex, conversationIndex } = getConversationByChatID(
        state.conversationList,
        action.payload.id || ""
      );

      if (hasFoundIndex) {
        state.conversationList[conversationIndex].chatData!.attributes!.status =
          action.payload.attributes?.status;
      }
    },
    updateChatData: (
      state,
      action: PayloadAction<{
        chatData: ChatDataType;
        shouldReOrder?: boolean;
        currentFilters?: string[];
      }>
    ) => {
      const { chatData, shouldReOrder, currentFilters } = action.payload;
      const { hasFoundIndex, conversationIndex } = getConversationByChatID(
        state.conversationList,
        chatData.id || ""
      );

      if (hasFoundIndex) {
        state.conversationList[conversationIndex] = {
          messages: state.conversationList[conversationIndex].messages,
          chatData: formatChatData(chatData),
          currentFilters,
        };
      }

      if (shouldReOrder) {
        state.conversationList = state.conversationList.sort(
          reorderByLastLifeMessage
        );
      }
    },
    updateChatAssignees: (
      state,
      action: PayloadAction<{ chatId: string; assignees: Assignee[] }>
    ) => {
      const { chatId, assignees } = action.payload;
      const { hasFoundIndex, conversationIndex } = getConversationByChatID(
        state.conversationList,
        chatId
      );

      if (hasFoundIndex) {
        state.conversationList[
          conversationIndex
        ].chatData!.attributes!.assignees = assignees;
      }
    },
  },
});

export const {
  createConversationList,
  appendMessagesToConversation,
  updateMessage,
  updateConversationStatus,
  updateChatData,
  updateChatAssignees,
} = conversationSlice.actions;

export const conversationReducer = conversationSlice.reducer;
