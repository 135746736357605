import { Box, Typography } from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { customColors } from "../../../../../../../theme/colors";

const FileIconStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "32px",
  height: "32px",
  borderRadius: "8px",
  backgroundColor: customColors.PurpleLight,
};

const FileIcon = ({
  iconType,
}: {
  iconType: "image/*" | "application/pdf" | "audio/ogg";
}) => {
  const getIconByType = (type: string) => {
    switch (type) {
      case "image/*":
        return (
          <ImageOutlinedIcon
            sx={{ color: customColors.White, width: "32px" }}
            fontSize="small"
          />
        );
      case "application/pdf":
        return (
          <PictureAsPdfOutlinedIcon
            sx={{ color: customColors.White, width: "32px" }}
            fontSize="small"
          />
        );
    }
  };

  return <Box sx={{ ...FileIconStyles }}>{getIconByType(iconType)}</Box>;
};

type FileMessageFooterProps = {
  fileName: string;
  fileType: "image/*" | "application/pdf" | "audio/ogg";
};

export const FileMessageFooter = ({
  fileName,
  fileType,
}: FileMessageFooterProps) => {
  return (
    <Box
      sx={{
        pt: 1,
      }}
    >
      <Typography
        variant="body3"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <FileIcon iconType={fileType} />
        {fileName}
      </Typography>
    </Box>
  );
};
