import { Chip, ChipProps } from "@mui/material";

type TChipTagProps = ChipProps & {
  bgColor?: string;
  textColor?: string;
  removeIconColor?: string;
};

export const ChipTag = (props: TChipTagProps) => {
  const { bgColor, textColor, removeIconColor, ...rest } = props;
  return (
    <Chip
      {...rest}
      sx={{
        backgroundColor: bgColor || "#ccc",
        color: textColor || "#ffffff",
        "& .MuiChip-deleteIcon": {
          color: removeIconColor || "rgb(255 255 255 / 70%)",
          fontSize: (theme) => theme.typography.body3,
          "&:hover": {
            color: removeIconColor || "rgb(255 255 255 / 70%)",
          },
        },
      }}
    />
  );
};
