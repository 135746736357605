import { muiThemeColors } from "../colors";

export const MuiInputLabelNode = {
  styleOverrides: {
    root: {
      color: muiThemeColors.primary.neutral,
      fontSize: "16px",
      lineHeight: "100%",

      "&.Mui-error": {
        color: muiThemeColors.error.main,
      },
    },
  },
};
