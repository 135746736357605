import { Box, Card as MUICard, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import CardButton from "./CardButton";
import CardActions from "@mui/material/CardActions";

const iconStyle = {
  fontSize: 32,
  color: "secondary.contrastText",
  marginRight: 1,
};

type CardProps = {
  title: string;
  cardText?: string;
  cardAction: () => void;
  cardActionLabel?: string;
  iconName?: string;
  disabled?: boolean;
};

const Card = ({
  title,
  cardAction,
  cardText,
  iconName,
  cardActionLabel,
  disabled = false,
}: CardProps) => {
  return (
    <MUICard
      sx={{
        borderRadius: "40px",
        minHeight: "197px",
        width: "262px",
        backgroundColor: disabled
          ? (theme) => theme.palette.grey[200]
          : (theme) => theme.palette.grey[300],
        padding: "0 15px 15px 15px",
        display: "flex",
        boxShadow: "none",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          {iconName && (
            <Icon sx={{ ...iconStyle, color: "primary.main" }}>{iconName}</Icon>
          )}
          <Typography variant="h5" color="primary.main">
            {title}
          </Typography>
        </Box>
        {cardText && (
          <Box mt={2}>
            <Typography variant="body2">{cardText}</Typography>
          </Box>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <CardButton
          disabled={disabled}
          label={cardActionLabel}
          handleOnClick={cardAction}
        />
      </CardActions>
    </MUICard>
  );
};

export default Card;
