import React, { createContext, useState, useContext } from "react";
import { AddressTypeEnum } from "../../screens/Registration/types";
// import { ApiTypes } from "../../app-types";

export const AddressMap = {
  HOME: "Casa",
  WORK: "Trabalho",
  OTHER: "Outro",
};

// export type AddressType = ApiTypes["schemas"]["AddressResponse"];
// export type AddressTypeData = ApiTypes["schemas"]["AddressResource-Output"];
export type AddressType = {
  id?: string;
  type: "addresses";
  attributes?: {
    zip_code?: string | null;
    address_line_1?: string | null;
    address_line_2?: string | null;
    city?: string | null;
    neighborhood?: string | null;
    state?: string | null;
    country?: string | null;
    type?: AddressTypeEnum | null;
  };
};

type ContextType = {
  addresses?: AddressType[];
  cacheAddresses?: AddressType[];
  setAddresses: React.Dispatch<React.SetStateAction<AddressType[]>>;
  setCacheAddresses: React.Dispatch<React.SetStateAction<AddressType[]>>;
  resetAddressesState: () => void;
};

const AddressesContext = createContext<ContextType | undefined>(undefined);

export const AddressesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [addresses, setAddresses] = useState<AddressType[]>([]);
  const [cacheAddresses, setCacheAddresses] = useState<AddressType[]>([]);

  const resetAddressesState = () => {
    setAddresses([]);
    setCacheAddresses([]);
  };

  return (
    <AddressesContext.Provider
      value={{
        addresses,
        cacheAddresses,
        setAddresses,
        setCacheAddresses,
        resetAddressesState,
      }}
    >
      {children}
    </AddressesContext.Provider>
  );
};

export const useAddressesContext = () => {
  const context = useContext(AddressesContext);
  if (!context) {
    throw new Error(
      "useAddressesContext must be used within a AddressesContextProvider"
    );
  }
  return context;
};
