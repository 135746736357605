export default [
  {
    label: "Conversas",
    icon: "chat_bubble_outline_icon",
    text: "Visualize, busque e acesse conversas com pacientes",
    route: "chat/",
    actionLabel: "Veja mais",
  },
  {
    label: "Agenda",
    icon: "date_range",
    text: "Visualize a agenda de atendimentos aos pacientes",
    route: "agenda/",
    actionLabel: "",
    disabled: true,
  },
  {
    label: "Tarefas",
    icon: "playlist_add_check_icon",
    text: "Consulte, edite e adicione novas tarefas",
    route: "tarefas/",
    actionLabel: "",
    disabled: true,
  },
  {
    label: "Pacientes",
    icon: "description_outlined_icon",
    text: "Acesse dados, registre novos pacientes e faça edições",
    route: "pacientes/",
    actionLabel: "",
  },
];
