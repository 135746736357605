import React, { createContext, useState, useContext } from "react";
import {
  GenderEnum,
  GenderIdentityEnum,
} from "../../screens/Registration/types";

export type PhoneNumberType = {
  id?: string;
  type?: "phone_numbers";
  attributes: {
    number: string;
  };
};

type ContextType = {
  cpf: string;
  cpfError: string;
  firstName: string;
  lastName: string;
  socialName: string;
  gender: GenderEnum | string;
  genderIdentity: GenderIdentityEnum | string;
  birthDate: string | Date;
  email: string;
  phoneNumbers: PhoneNumberType[];
  phonesToRemove: PhoneNumberType[];
  chatId?: string | null;
  setCpf: React.Dispatch<React.SetStateAction<string>>;
  setCpfError: React.Dispatch<React.SetStateAction<string>>;
  setChatId: React.Dispatch<React.SetStateAction<string | null>>;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setSocialName: React.Dispatch<React.SetStateAction<string>>;
  setGender: React.Dispatch<React.SetStateAction<GenderEnum | string>>;
  setGenderIdentity: React.Dispatch<
    React.SetStateAction<GenderIdentityEnum | string>
  >;
  setBirthDate: React.Dispatch<React.SetStateAction<string | Date>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setPhoneNumbers: React.Dispatch<React.SetStateAction<PhoneNumberType[]>>;
  setPhonesToRemove: React.Dispatch<React.SetStateAction<PhoneNumberType[]>>;
  resetGeneralDataState: () => void;
};

const GeneralDataContext = createContext<ContextType | undefined>(undefined);

export const GeneralDataContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [cpf, setCpf] = useState("");
  const [cpfError, setCpfError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [socialName, setSocialName] = useState("");
  const [gender, setGender] = useState<GenderEnum | string>("");
  const [genderIdentity, setGenderIdentity] = useState<
    GenderIdentityEnum | string
  >("");
  const [birthDate, setBirthDate] = useState<string | Date>("");
  const [email, setEmail] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberType[]>([
    { attributes: { number: "" } },
  ]);
  const [phonesToRemove, setPhonesToRemove] = useState<PhoneNumberType[]>([]);
  const [chatId, setChatId] = useState<string | null>(null);

  const resetGeneralDataState = () => {
    setCpf("");
    setCpfError("");
    setFirstName("");
    setLastName("");
    setSocialName("");
    setGender("");
    setGenderIdentity("");
    setBirthDate("");
    setEmail("");
    setPhoneNumbers([]);
    setPhonesToRemove([]);
    setChatId("");
  };

  return (
    <GeneralDataContext.Provider
      value={{
        cpf,
        cpfError,
        firstName,
        lastName,
        socialName,
        gender,
        genderIdentity,
        birthDate,
        email,
        phoneNumbers,
        phonesToRemove,
        chatId,
        setCpf,
        setCpfError,
        setFirstName,
        setLastName,
        setSocialName,
        setGender,
        setGenderIdentity,
        setBirthDate,
        setEmail,
        setPhoneNumbers,
        setPhonesToRemove,
        resetGeneralDataState,
        setChatId,
      }}
    >
      {children}
    </GeneralDataContext.Provider>
  );
};

export const useGeneralDataContext = () => {
  const context = useContext(GeneralDataContext);
  if (!context) {
    throw new Error(
      "useGeneralDataContext must be used within a GeneralDataContextProvider"
    );
  }
  return context;
};
