import { Button, ButtonProps } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CustomIconButton } from "../CustomIconButton";

type CardButtonProps = {
  label?: string;
  handleOnClick: () => void;
  disabled?: boolean;
};
const CardButton = ({ label, handleOnClick, disabled }: CardButtonProps) => {
  const buttonProps: ButtonProps = {
    onClick: handleOnClick,
    disabled,
    variant: "contained",
  };

  if (!label)
    return (
      <CustomIconButton {...buttonProps}>
        <ArrowForwardIosIcon />
      </CustomIconButton>
    );

  return (
    <Button {...buttonProps} endIcon={<ArrowForwardIosIcon />}>
      {label}
    </Button>
  );
};

export default CardButton;
