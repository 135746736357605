import { Box, IconButton, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { DataTypeEnum } from "./lifeUtils";
import { useRef } from "react";

type DataType = {
  type: DataTypeEnum;
  value: string;
};

type LifeSearchProps = {
  handleOnSearch: (dataType: DataType) => void;
};
export const LifeSearch = ({ handleOnSearch }: LifeSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnSubmit = () => {
    if (typeof inputRef?.current?.value === "string") {
      handleOnSearch({
        type: DataTypeEnum.Generic,
        value: inputRef.current.value,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        component={"form"}
        width="100%"
        onSubmit={(e) => {
          e.preventDefault();
          handleOnSubmit();
        }}
      >
        <TextField
          fullWidth
          inputRef={inputRef}
          placeholder="Busque por CPF, telefone ou nome"
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ position: "relative", right: "10px" }}
                onClick={handleOnSubmit}
                data-testid="life-search-button"
              >
                <Search fontSize="small" sx={{ color: "common.neutral06" }} />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
