import { ApiTypes } from "../../../app-types";
import { getLifeFullName } from "../components/ConversationList/conversationListHelpers";
import {
  ChatDataType,
  MessageDataType,
  MessageEvenType,
  SocketEvent,
} from "../types";
import {
  formatDate,
  formatDateToElapsedTime,
  formatDateToTimeOrEntireDate,
  isDateOlderThan24Hours,
} from "../utils/dateUtils";
import {
  getMessageDirection,
  getMessageStatus,
  isMessageSocketEvent,
  isTMessageUIEvent,
  isTThreadUIEvent,
  isThreadSocketEvent,
} from "./conversationHelpers";
import {
  ConversationStatus,
  EMessageDirection,
  TConversation,
  TMessage,
  TMessageUIEvent,
  TThreadUIEvent,
} from "./conversationSlice";
import { getMessageByMostRecentDeliveryField } from "./formatters/getMessageByMostRecentDeliveryField";

const formatMessage = (message: SocketEvent): TMessageUIEvent => {
  if (!isMessageSocketEvent(message.data)) return {} as TMessageUIEvent;
  const messageData = message.data;
  const messageDirection = getMessageDirection(messageData);
  const messageStatus = getMessageStatus(messageData);
  const content = message.data?.attributes?.content;

  const createdAt = messageData?.attributes?.created_at;
  const lastLifeMessageAt =
    messageData?.attributes?.chat?.attributes?.last_life_message_at;

  const isExpired = lastLifeMessageAt
    ? isDateOlderThan24Hours(lastLifeMessageAt!)
    : false;

  const senderName =
    messageDirection === EMessageDirection.sending
      ? messageData?.attributes?.sender?.attributes?.name || ""
      : getLifeFullName(
          (messageData?.attributes?.chat?.attributes?.life?.attributes ||
            {}) as ApiTypes["schemas"]["LifeResponse"]["data"]["attributes"]
        );

  return {
    messageDirection,
    content,
    id: messageData?.id,
    chatId: messageData?.attributes?.chat?.id || "",
    senderId: messageData?.attributes?.sender?.id || "",
    createdAtFullDate: formatDate(createdAt),
    createdAtTime: formatDateToTimeOrEntireDate(
      createdAt,
      "dd/MM/yyyy HH:mm:ss"
    ),
    createdAt,
    senderName,
    event: message?.event as MessageEvenType,
    messageStatus,
    originalMessage: message,
    isExpired: messageDirection === EMessageDirection.receiving && isExpired,
  };
};

const formatThread = (message: SocketEvent): TThreadUIEvent => {
  if (!isThreadSocketEvent(message.data)) return {} as TThreadUIEvent;
  return {
    originalMessage: message,
    id: message.id,
    chat_id: message.data.id,
    created_at: message.created_at,
    summary: message.data?.attributes?.summary || "",
  };
};

export const formatIncomingMessage = (
  message: SocketEvent
): TMessage | undefined => {
  if (isMessageSocketEvent(message.data)) {
    return formatMessage(message);
  }

  if (isThreadSocketEvent(message.data)) {
    return formatThread(message);
  }
};

export const formatChatData = (conversation: ChatDataType): ChatDataType => {
  const {
    first_name = "",
    last_name = "",
    phone_numbers,
  } = conversation?.attributes?.life?.attributes || {};
  const { last_life_message_at = "" } = conversation?.attributes || {};

  const firstPhoneNumber = phone_numbers?.[0]?.attributes?.number || "";

  const fullName =
    !first_name && !last_name ? firstPhoneNumber : `${first_name} ${last_name}`;

  const formattedLastLifeMessageAt =
    formatDateToElapsedTime(last_life_message_at || "") || "";

  const hasUnreadMessages = Boolean(
    !conversation?.attributes?.last_user_message_at
  );

  return {
    ...conversation,
    id: conversation?.id || "",
    fullName,
    formattedLastLifeMessageAt,
    hasUnreadMessages,
    attributes: {
      ...conversation?.attributes,
      assignees: Array.from(new Set(conversation?.attributes?.assignees || [])),
      status: conversation?.attributes?.status || ConversationStatus.OPEN,
    },
  };
};

export const keepMessagesWithMostRecentStatus = (
  currentMessage: TMessage,
  _index: number,
  currentMessages: TMessage[]
) => {
  if (isTThreadUIEvent(currentMessage)) return true;

  const allMessages = currentMessages;

  const groupedMessages = allMessages.filter(
    (item) => item?.id === currentMessage?.originalMessage?.data?.id
  );

  const selectedEvent = getMessageByMostRecentDeliveryField(
    groupedMessages as TMessageUIEvent[]
  );

  return (
    selectedEvent?.originalMessage?.id === currentMessage?.originalMessage?.id
  );
};

export const lessRecentFirst = (a: TMessage, b: TMessage) => {
  const originalMessageA = a?.originalMessage?.data as MessageDataType;
  const originalMessageB = b?.originalMessage?.data as MessageDataType;
  return (
    new Date(originalMessageA?.attributes?.created_at || "").getTime() -
    new Date(originalMessageB?.attributes?.created_at || "").getTime()
  );
};

export const removeDuplicatedIds = (acc: TMessage[], message: TMessage) => {
  if (!acc.find((m) => m?.id === message?.id)) {
    acc.push(message);
  }
  return acc;
};

export const removeNonMessageEvents = (item: TMessage) => {
  if (isTMessageUIEvent(item))
    return Object.values(MessageEvenType).includes(
      item.originalMessage?.event as MessageEvenType
    );
};

export const reorderByLastLifeMessage = (
  a: TConversation,
  b: TConversation
) => {
  return (
    new Date(b.chatData?.attributes?.last_life_message_at || "").getTime() -
    new Date(a.chatData?.attributes?.last_life_message_at || "").getTime()
  );
};
