import { useMutation } from "@tanstack/react-query";
import { api } from "../../../api";
import { KinShipEnum } from "../types";
import { AxiosResponse } from "axios";
import { RelativesType } from "../../../contexts/RegistrationContexts/RelativesContext";
import { deepEqual } from "../helpers";

type CreateRelativesProps = {
  attributes: {
    life_kinship: KinShipEnum;
    relative_kinship: KinShipEnum;
    relative_id: string;
    life_id: string;
    relative: {
      id: string;
      type: string;
      attributes: {
        first_name: string;
        last_name: string;
      };
    };
  };
};

type UpdateRelativesProps = {
  relatives: RelativesType[];
  cachedRelatives: RelativesType[];
};

const findMissingRelatives = (
  relatives?: RelativesType[],
  cachedRelatives?: RelativesType[]
): RelativesType[] => {
  const removed: RelativesType[] = [];

  cachedRelatives?.forEach((cachedRelative) => {
    const found = relatives?.find(
      (relative) => relative?.id === cachedRelative?.id
    );
    if (!found) {
      removed.push(cachedRelative);
    }
  });

  return removed;
};

function findModifiedEntries(
  relatives?: RelativesType[],
  cachedRelatives?: RelativesType[]
) {
  const updated: RelativesType[] = [];

  relatives?.forEach((relative) => {
    const cachedRelative = cachedRelatives?.find(
      (cr) => cr?.id === relative?.id
    );
    if (cachedRelative) {
      const isModified = Object.keys(relative).some(
        (key) =>
          !deepEqual(
            relative[key as keyof RelativesType],
            cachedRelative[key as keyof RelativesType]
          )
      );
      if (isModified) {
        updated.push(relative);
      }
    }
  });

  return updated;
}

const updateRelatives = async ({
  relatives,
  cachedRelatives,
}: UpdateRelativesProps) => {
  const relativesToCreate = relatives?.filter((relative) => !relative.id);
  const relativesToRemove = findMissingRelatives(relatives, cachedRelatives);
  const relativesToUpdate = findModifiedEntries(relatives, cachedRelatives);

  const promises: Promise<
    AxiosResponse<{ data: RelativesType } | { data: object }>
  >[] = [];

  relativesToCreate?.forEach((relative) => {
    const promise = api.post<{ data: RelativesType }>(`/api/v1/relatives`, {
      data: relative,
    });
    promises.push(promise);
  });

  relativesToUpdate?.forEach((relative) => {
    const promise = api.patch<{ data: RelativesType }>(
      `/api/v1/relatives/${relative?.id}`,
      {
        data: relative,
      }
    );
    promises.push(promise);
  });

  relativesToRemove?.forEach((relative) => {
    const promise = api.delete<{ data: object }>(
      `/api/v1/relatives/${relative?.id}`
    );
    promises.push(promise);
  });

  return Promise.all(promises)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const useCreateRelatives = () => {
  return useMutation({
    mutationKey: ["create-relatives"],
    mutationFn: ({ relatives }: { relatives: CreateRelativesProps[] }) => {
      const promises: Promise<AxiosResponse<CreateRelativesProps[]>>[] = [];

      relatives.forEach((relative) => {
        const promise = api.post(`/api/v1/relatives`, {
          data: {
            type: "relatives",
            attributes: {
              ...relative.attributes,
            },
          },
        });
        promises.push(promise);
      });

      return Promise.all(promises)
        .then((responses) => {
          return responses;
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
  });
};

export const useUpdateRelatives = () => {
  return useMutation({
    mutationKey: ["update-relatives"],
    mutationFn: ({ relatives, cachedRelatives }: UpdateRelativesProps) =>
      updateRelatives({ relatives, cachedRelatives }),
  });
};
