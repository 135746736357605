//...
import * as Sentry from "@sentry/react";
import * as SentryBrowser from "@sentry/browser";

Sentry.init({
  dsn: "https://8f5c09c9fa90f6b5bf5611dbfe315c3e@o4506418793086976.ingest.us.sentry.io/4506905067454464",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    SentryBrowser.captureConsoleIntegration({
      levels: ["error", "warn", "info"],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/navegacao\.carecode\.com\.br/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.PROD,
});
console.log("sentry initialized!");
