import { Box, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

type TemplateWarningProps = {
  message: string;
};

export const TemplateWarning = ({ message }: TemplateWarningProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        width: "100%",
        padding: "5px 40px 25px 0",
      }}
    >
      <WarningAmberIcon fontSize="large" sx={{ color: "#EF6C00" }} />
      <Typography sx={{ color: "#EF6C00", fontSize: "14px" }}>
        {message}
      </Typography>
    </Box>
  );
};
