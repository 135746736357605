import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid";
import { DataGrid } from "./components";
import { LifeSearch } from "./components";
import { DataTypeEnum } from "./components/lifeUtils";
import { renderNotifier } from "../../../providers/Notifier";
import { errors } from "../../../strings/errors";
import { useLivesContext } from "../../../contexts/RegistrationContexts/LivesContext";

const MIN_TABLE_AREA_HEIGHT = "400px";

type LivesProps = {
  livesError: boolean;
  livesLoading: boolean;
  pageSize: number;
  maxPageLimit: number;
  paginationModel: GridPaginationModel;
  handleOnPageChange: (model: GridPaginationModel) => void;
  handleLifeSearch: (dataType: { type: DataTypeEnum; value: string }) => void;
};

export const Lives = ({
  livesError,
  livesLoading,
  pageSize,
  maxPageLimit,
  paginationModel,
  handleOnPageChange,
  handleLifeSearch,
}: LivesProps) => {
  const [error, setError] = useState<string | null>();
  const navigate = useNavigate();

  const { lives } = useLivesContext();

  useEffect(() => {
    if (livesError) {
      setError("Erro ao buscar vidas");
    } else {
      setError(null);
    }
  }, [livesError]);

  const handleOnChange = (model: GridPaginationModel) => {
    handleOnPageChange(model);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 5,
        minHeight: MIN_TABLE_AREA_HEIGHT,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
          height: "100%",
        }}
      >
        <Box sx={{ width: "50%", maxWidth: "470px" }}>
          <LifeSearch handleOnSearch={(data) => handleLifeSearch(data)} />
        </Box>
        <Button
          variant="contained"
          onClick={() => navigate("/pacientes/novo-paciente")}
        >
          Cadastrar vida
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <>
          {livesLoading ? (
            <Box
              sx={{
                width: "100%",
                minHeight: MIN_TABLE_AREA_HEIGHT,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <CircularProgress
                color="primary"
                size={32}
                data-testid="loading-spinner"
              />
            </Box>
          ) : (
            <>
              {error ? (
                renderNotifier(errors.fetchingLivesError, { variant: "error" })
              ) : (
                <>
                  {lives && (
                    <DataGrid
                      lives={lives}
                      paginationModel={paginationModel}
                      handleOnChangePage={handleOnChange}
                      pageSize={pageSize}
                      maxPageLimit={maxPageLimit}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      </Box>
    </Box>
  );
};
