import React from "react";

type WrapperProps = {
  children: React.ReactNode;
};

const Wrapper = ({ children }: WrapperProps) => (
  <div className="w-full h-full flex flex-col justify-start items-center overflow-y-auto">
    {children}
  </div>
);

export default Wrapper;
