import { Box } from "@mui/material";

import { Route, Routes, useSearchParams } from "react-router-dom";

import { useAuthContext } from "../../contexts/AuthContext";

import { ChatTimeline } from "../ChatTimeline";
import { ChatArea } from "./ChatArea";
import { LifeSummary } from "./components/LifeSummary";
import { useEffect } from "react";
import { useGetCurrentFilterParam } from "./hooks/useGetCurrentFilterParam";
import { attendenceFilterStatus } from "./components/ConversationList/components/AttendencesMenu/attendenceMenuOptions";
import { useSocketEvent } from "./hooks/useSocketEvent";
import { useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../../store";
import { useMeQuery } from "../../api/hooks/useMeQuery";
import {
  ChatDataType,
  ChatEventType,
  MessageDataType,
  MessageEvenType,
  ThreadEventType,
} from "./types";
import {
  ConversationStatus,
  appendMessagesToConversation,
  updateChatData,
} from "./slices/conversationSlice";
import { isMessageSocketEvent } from "./slices/conversationHelpers";
import { getMessageCurrentFilters } from "./components/ConversationList/getMessageCurrentFilters";
import { useGetConversations } from "./hooks/useGetConversations";

export function ChatScreen() {
  const auth = useAuthContext();

  const eventData = useSocketEvent("events.new");

  const receivingEventData = eventData.data;

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { meQueryData } = useMeQuery();

  const conversationListQuery = useGetConversations();

  // TODO: Refactor this to a useMessageListener hook
  useEffect(() => {
    if (!eventData.isSuccess || !receivingEventData) return;
    const isMessageEvent = Object.values(MessageEvenType).includes(
      receivingEventData.event as MessageEvenType
    );
    const isThreadEvent = Object.values(ThreadEventType).includes(
      receivingEventData.event as ThreadEventType
    );

    if (!isMessageEvent && !isThreadEvent) return;

    dispatch(appendMessagesToConversation([receivingEventData]));
    const chatData = (receivingEventData.data as MessageDataType).attributes
      .chat as ChatDataType;

    if (isMessageSocketEvent(receivingEventData.data)) {
      const messageCurrentFilters = getMessageCurrentFilters(
        {
          status: chatData?.attributes?.status,
          assignees: chatData?.attributes?.assignees || [],
        },
        meQueryData?.id
      );
      dispatch(
        updateChatData({
          currentFilters: messageCurrentFilters.length
            ? messageCurrentFilters
            : conversationListQuery?.data?.currentFilters || [],
          chatData: {
            ...chatData,
            attributes: {
              ...chatData.attributes,
              status: ConversationStatus.OPEN,
            },
          },
          shouldReOrder: true,
        })
      );
    }
  }, [
    conversationListQuery?.data?.currentFilters,
    dispatch,
    eventData.isSuccess,
    meQueryData?.id,
    receivingEventData,
  ]);

  // TODO: Refactor this to a useChatEventListener hook
  useEffect(() => {
    if (!eventData.isSuccess || !receivingEventData) return;

    const chatEventData = receivingEventData.data as ChatDataType;
    const chatEvent = receivingEventData.event as ChatEventType;

    const isChatEvent =
      chatEventData && Object.values(ChatEventType).includes(chatEvent);

    if (isChatEvent) {
      dispatch(updateChatData({ chatData: chatEventData }));
      queryClient.refetchQueries({
        queryKey: ["conversation-history"],
        exact: false,
      });
    }
  }, [dispatch, eventData.isSuccess, receivingEventData, queryClient]);

  const IS_LIFE_SUMMARY_ENABLED = auth.featureFlags?.is_life_summary_enabled;

  const [searchParams, setSearchParams] = useSearchParams();
  const attendancesMenu = useGetCurrentFilterParam();

  useEffect(() => {
    if (!attendancesMenu) {
      setSearchParams(
        {
          attendencesMenu: attendenceFilterStatus.withoutAssignees,
          without_assignees: "true",
          status: "OPEN",
        },
        { replace: true }
      );
    }
  }, [searchParams, setSearchParams, attendancesMenu]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: 0,
        flex: 1,
        width: "100%",
        padding: "20px",
        maxHeight: "calc(100vh - 50px)",
      }}
    >
      <Box sx={{ display: "flex", height: "100%" }}>
        <Routes>
          <Route path="/" Component={ChatArea} />
          <Route path="/:id" Component={ChatArea} />
          <Route path="/:id/timeline" Component={ChatTimeline} />
        </Routes>
        {IS_LIFE_SUMMARY_ENABLED && (
          <Box maxWidth="294px" height="100%">
            <Routes>
              <Route path="/:id" Component={LifeSummary} />
            </Routes>
          </Box>
        )}
      </Box>
    </Box>
  );
}
