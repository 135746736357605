import { Box, CircularProgress, Typography } from "@mui/material";
import { MessageDataType } from "../../../../types";
import { TextMessage } from "./components/TextMessage";
import { AudioMessage } from "./components/AudioMessage";
import { ImageMessage } from "./components/ImageMessage";
import { DocumentMessage } from "./components/DocumentMessage";
import { AddMediaTag } from "./components/AddMediaTag";
import { useAuthenticateMedia } from "../../hooks/useAuthenticateMedia";
import { getContentType } from "../../../../../../utils/mediaUtils.ts";

export type TBalonContentProps = {
  direction?: string;
  messageData?: MessageDataType;
  isLoading?: boolean;
};

export type TMediaComponentProps = TBalonContentProps & {
  signedMediaUrl: string;
  publicUrl?: string | null;
};

export const BalonContent = (props: TBalonContentProps) => {
  const messageType = getContentType(
    props.messageData?.attributes?.content_type
  );
  const messageUrl = props?.messageData?.attributes?.public_url || "";

  const MessageContentComponent = {
    audio: AudioMessage,
    image: ImageMessage,
    application: DocumentMessage,
    "": TextMessage,
  }[messageType];

  const isMediaMessage = ["audio", "image", "application"].includes(
    messageType
  );

  const authenticateMedia = useAuthenticateMedia({
    url: messageUrl,
  });

  const signedMediaUrl = authenticateMedia?.data || "";

  const messageContentProps = {
    ...props,
    signedMediaUrl,
    publicUrl: props.messageData?.attributes?.public_url,
  };

  if (authenticateMedia.isLoading) {
    return (
      <Box sx={{ display: "flex", ml: 1 }}>
        <CircularProgress size={16} />
      </Box>
    );
  }

  if (authenticateMedia.isError) {
    return (
      <Typography variant="body2" color="error">
        Houve um erro ao carregar o conteúdo de media
      </Typography>
    );
  }

  if (MessageContentComponent) {
    return (
      <>
        <MessageContentComponent {...messageContentProps} />
        {isMediaMessage && (
          <Box my={4}>
            <AddMediaTag {...messageContentProps} />
          </Box>
        )}
      </>
    );
  }

  return <TextMessage {...props} />;
};
