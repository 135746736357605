import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { FileCard, FileCardData } from "./FileCard";
import { FileTypes } from "../screens/Chat/types";

type FileCardList = {
  files: FileCardData[];
  onChangeFiles?: (files: FileCardData[]) => void;
};

export const FileCardList = (props: FileCardList) => {
  const [currentFiles, setCurrentFiles] = React.useState<FileCardData[]>(
    props.files
  );

  useEffect(() => {
    setCurrentFiles(props.files);
  }, [props.files]);

  const handleFileRemove = (indexToRemove: string) => {
    setCurrentFiles((prev) => {
      const filteredFiles = prev.filter(
        (_file, fileIndex) => `${fileIndex}` !== indexToRemove
      );
      props?.onChangeFiles?.(filteredFiles);
      return filteredFiles;
    });
  };

  return (
    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
      {currentFiles.map((file, index) => (
        <FileCard
          {...file}
          key={index}
          name={file.name}
          uuid={`${index}`}
          type={file.type as FileTypes}
          handleOnRemove={
            file?.isRemovable ? () => handleFileRemove(`${index}`) : undefined
          }
          isLoading={false}
        />
      ))}
    </Box>
  );
};
