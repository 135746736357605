import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { CustomDialog } from "../../../../components/CustomDialog/CustomDialog";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CustomSelect } from "../../../../components/CustomSelect";
import { CustomDatePicker } from "../../../../components/CustomDatePicker";

import { zodResolver } from "@hookform/resolvers/zod";
import { LivesAutocompleteField } from "./components/LivesAutocompleteField";
import {
  createTaskFormDefaultValues,
  createTaskSchemeValidation,
  taskTypes,
  TCreateTaskForm,
} from "./helpers/createTaskSchema";
import { AssigneesAutocompleteField } from "./components/AssigneesAutocompleteField";
import { useCreateTaskMutation } from "../../api/useCreateTaskMutation";

export const CreateTaskDialog = () => {
  const taskForm = useForm<TCreateTaskForm>({
    defaultValues: createTaskFormDefaultValues,
    resolver: zodResolver(createTaskSchemeValidation),
  });

  const { control, handleSubmit } = taskForm;
  const { errors } = taskForm.formState;

  const { createTaskMutation } = useCreateTaskMutation();

  const onSubmit: SubmitHandler<TCreateTaskForm> = async (data) => {
    console.log("submitting");
    console.log(data);
    await createTaskMutation.mutateAsync(data);
  };

  return (
    <CustomDialog open fullWidth showCloseButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4">Criar tarefa</Typography>
        </Box>
        <Box>
          <SFieldSpacing>
            <LivesAutocompleteField form={taskForm} />
          </SFieldSpacing>
          <SFieldSpacing>
            <Controller
              name="taskType"
              control={control}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  label="Tipo de tarefa"
                  options={taskTypes}
                  error={!!errors?.taskType}
                  helperText={errors?.taskType?.message}
                />
              )}
            />
          </SFieldSpacing>
          <SFieldSpacing>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descrição"
                  multiline
                  rows={2}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                />
              )}
            />
          </SFieldSpacing>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography variant="body1" fontWeight="700">
              Atribuição
            </Typography>
          </Box>
          <SFieldSpacing>
            <AssigneesAutocompleteField form={taskForm} />
          </SFieldSpacing>
        </Box>
        <Box sx={{ display: "flex", gap: 2, mb: 6 }}>
          <Box>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <CustomDatePicker
                  slotProps={{ field: { clearable: true } }}
                  timezone="America/Sao_Paulo"
                  label="Prazo"
                  defaultValue={field.value}
                  value={field.value}
                  inputRef={field.ref}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  errorMessage={errors?.endDate?.message}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="highPriority"
              control={control}
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label={
                      <Typography variant="body2">Prioridade alta</Typography>
                    }
                  />
                </FormGroup>
              )}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
          <Button variant="text" type="submit">
            Salvar e criar nova tarefa
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Salvar tarefa
          </Button>
        </Box>
      </form>
    </CustomDialog>
  );
};

const SFieldSpacing = styled(Box)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
