import { Box, Button, Typography } from "@mui/material";
import { customColors } from "../../../../../../theme/colors";

type ChatCommandButtonProps = {
  disabled: boolean;
  handleOnClick: () => void;
};

export const ChatCommandButton = ({
  disabled,
  handleOnClick,
}: ChatCommandButtonProps) => {
  return (
    <Box>
      <Button
        data-testid="open-commands-button"
        component="label"
        role="button"
        variant="outlined"
        tabIndex={-1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "20px",
          height: "20px",
          border: `1px solid ${customColors.Neutral06}`,
          margin: 0,
          padding: 0,
          borderRadius: "4px",
          minWidth: "auto",
          outline: "none",
          "&:hover": {
            border: 0,
          },
          "&:disabled": {
            backgroundColor: "transparent",
          },
        }}
        disabled={disabled}
        onClick={handleOnClick}
      >
        <Typography variant="body3" sx={{ color: customColors.Neutral06 }}>
          /
        </Typography>
      </Button>
    </Box>
  );
};
