import { UseFormReturn } from "react-hook-form";
import { CustomSelect } from "../../../../../components/CustomSelect";
import { useGetAllTagsQuery } from "../../../../Chat/components/LifeSummary/lifeApi/useGetAllTagsQuery";
import { TFilterDocumentsForm } from "../../DocumentList";
import { TFetchTags } from "../../../../Chat/components/ChatView/hooks/useChat";

type TAttachmentsTagsFilterProps = UseFormReturn<TFilterDocumentsForm> & {
  entity: TFetchTags["entity"];
};

export const AttachmentsTagsFilter = (props: TAttachmentsTagsFilterProps) => {
  const allTagsQuery = useGetAllTagsQuery({ entity: props.entity });

  const tagsSelectOptions =
    allTagsQuery?.data?.map((tag) => ({
      title: tag?.attributes?.label || "",
      value: tag?.attributes?.label || "",
    })) || [];

  return (
    <CustomSelect
      {...props.register("tags")}
      label="Selecione tags"
      options={tagsSelectOptions}
    />
  );
};
