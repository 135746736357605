import { Box, CircularProgress, Typography } from "@mui/material";

const centerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 3,
  alignItems: "center",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
};

const MESSAGES = {
  close: "Encerrando conversa...",
  open: "Abrindo conversa...",
  summary: "Gerando o resumo...",
  threads: "Gerando threads...",
};

enum LoadingEnum {
  CLOSE = "close",
  OPEN = "open",
  SUMMARY = "summary",
  THREADS = "threads",
}
type LoadingProps = {
  type: LoadingEnum;
};
const Loading = ({ type }: LoadingProps) => {
  return (
    <Box sx={centerStyle}>
      <CircularProgress />
      <Typography
        variant="h4"
        sx={{ textAlign: "center", alignSelf: "center", width: "100%" }}
      >
        {MESSAGES[type]}
      </Typography>
    </Box>
  );
};

export default Loading;
