import { Box, CircularProgress, Typography } from "@mui/material";

import { FileCard } from "../../../components/FileCard";
import { useGetAttachments } from "../hooks/useGetAttachments";
import { formatDate } from "../../Chat/utils/dateUtils";
import { FileTypes } from "../../Chat/types";
import { AddAttachmentTag } from "./AddAttachmentTag";

import { useGetConversations } from "../../Chat/hooks/useGetConversations";

import { useTimelineFilters } from "../hooks/useTimelineFilters";
import { useAttachmentsFormDefinition } from "./TimelineFilters/useAttachmentsFormDefinition";
import { TimelineFilters } from "./TimelineFilters";
import { useParams } from "react-router-dom";
import { TimelineScrollList } from "./TimelineScrollList";

export type TFilterDocumentsForm = {
  freeTextFilter: string;
  fileType: string;
  tags: string;
  startedAt: Date | null;
  endedAt: Date | null;
};

export const DocumentList = () => {
  const params = useParams();
  const lifeId = params?.lifeId || "";

  const { currentForm: formFilter, watchValues } = useTimelineFilters();

  const attachmentsFormDefinition = useAttachmentsFormDefinition({
    form: formFilter,
  });

  const {
    debouncedTextFilter,
    debouncedStartedAt,
    debouncedEndedAt,
    fileType,
    tags,
  } = watchValues;

  const {
    formState: { errors },
  } = formFilter;

  const attachmentsResponse = useGetAttachments({
    lifeId: lifeId || "",
    freeTextFilter: debouncedTextFilter,
    fileTypeFilter: fileType,
    tagFilter: tags,
    startedAtFilter: debouncedStartedAt,
    endedAtFilter: debouncedEndedAt,
    enabled: Object.keys(errors).length === 0,
  });

  const { isLoading: isGetConversationsLoading } = useGetConversations();

  const attachmentsListData = attachmentsResponse.data?.data?.data || [];

  const isLoading = attachmentsResponse.isLoading || isGetConversationsLoading;

  return (
    <Box>
      <Box>
        <Box mb={2}>
          <TimelineFilters formDefinition={attachmentsFormDefinition} />
        </Box>
        {isLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress data-testid="document-list-loading" />
          </Box>
        )}
        {attachmentsListData?.length === 0 && !isLoading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="body1">Nenhum documento encontrado</Typography>
          </Box>
        )}
        <TimelineScrollList>
          {attachmentsListData?.map((attachment) => {
            return (
              <Box mb={1} key={attachment?.id}>
                <FileCard
                  fullWidth
                  documentLink={attachment?.attributes?.public_url}
                  name={attachment?.attributes?.name}
                  uuid={attachment?.id}
                  type={attachment?.attributes?.content_type as FileTypes}
                  subtitle={`Criado em ${formatDate(
                    attachment?.attributes?.created_at
                  )}`}
                  size="large"
                  isLoading={false}
                >
                  <AddAttachmentTag
                    inferredTags={attachment?.attributes?.tagged_items}
                    attachmentId={attachment?.id}
                  />
                </FileCard>
              </Box>
            );
          })}
        </TimelineScrollList>
      </Box>
    </Box>
  );
};
