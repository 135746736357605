import { useState } from "react";
import {
  Box,
  Button,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { DeleteOutlined, PersonOffOutlined } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type TConsultationActionsProps = {
  onClickJoinCall?: () => void;
  onClickOpenCopilot?: () => void;
  onClickCancelAppointment?: () => void;
  onClickNoShow?: () => void;
};

export const ConsultationActions = (props: TConsultationActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelAppointment = () => {
    setAnchorEl(null);
    props.onClickCancelAppointment?.();
  };

  const handleNoShow = () => {
    setAnchorEl(null);
    props.onClickNoShow?.();
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Box minWidth="141px">
        <Button
          size="small"
          fullWidth
          variant="outlined"
          endIcon={<OpenInNewIcon />}
          onClick={(event) => {
            event.stopPropagation();
            props.onClickOpenCopilot?.();
          }}
        >
          Abrir copiloto
        </Button>
      </Box>
      <Box minWidth="187px">
        <Button
          size="small"
          fullWidth
          variant="contained"
          endIcon={<ChevronRightIcon />}
          onClick={(event) => {
            event.stopPropagation();
            props.onClickJoinCall?.();
          }}
        >
          Juntar-se a chamada
        </Button>
      </Box>
      <Box>
        <IconButton
          size="small"
          aria-label="more"
          data-testid="appointment-menu-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleNoShow}>
            <ListItemIcon>
              <PersonOffOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Marcar não comparecimento</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleCancelAppointment}>
            <ListItemIcon>
              <DeleteOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Cancelar a consulta</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
