import React, { useEffect, useState, useRef } from "react";
import { Box, TextField } from "@mui/material";

import { TemplateWarning } from "../TemplateWarning";
import { CustomIconButton } from "../../../../../../components/CustomIconButton";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { ChatAttatchFile } from "../ChatAttachFile";
import { ChatCommandButton } from "../ChatCommands";
import { customColors } from "../../../../../../theme/colors";
import { FileCard } from "../../../../../../components/FileCard";
import { FileTypes } from "../../../../types";
import { useAddAttachment } from "../../hooks/useChat";
import { CustomEmojiPicker } from "../../../../../../components/CustomEmojiPicker";
import { useEmojiHandlers } from "./hooks/useEmojiHandlers";
import { v4 as uuid } from "uuid";

const inputStyle = {
  lineHeight: "26px",
};

const isPendingPlaceholderReplacement = (content: string) => {
  return content?.includes("{{");
};

type FileSelectionType = {
  id: string;
  file: File;
};

type TChatInputProps = {
  message: string;
  disabled?: boolean;
  chatId: string;
  onSend: (message: string) => void;
  handleOnChange: (message: string) => void;
  toggleCommandModal: () => void;
};

export const ChatInput = ({
  message,
  disabled = false,
  chatId,
  onSend,
  handleOnChange,
  toggleCommandModal,
}: TChatInputProps) => {
  const textFieldInput = useRef<HTMLInputElement>(null);
  const [filesOverSize, setFilesOverSize] = useState<FileSelectionType[]>([]);

  const { handleToggleEmoji, handleEmojiClick, isEmojiSelectionOpen } =
    useEmojiHandlers({
      inputRef: textFieldInput,
      message,
      handleMessageChange: handleOnChange,
    });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (files.length > 0) {
      mutate({ files: files.map((item) => item.file), chatId });
    }
    if (message) {
      onSend(message);
      handleOnChange("");
    }
    setFilesOverSize([]);
  };
  const [files, setFiles] = useState<FileSelectionType[]>([]);

  const {
    data: attachmentData,
    isSuccess: attachmentSuccess,
    isError: attachmentError,
    isPending: attachmentLoading,
    mutate,
  } = useAddAttachment();

  const removeFile = (id: string) => {
    setFilesOverSize([]);
    setFiles([...files.filter((file) => file.id !== id)]);
  };

  const handleOnSelectFiles = (fileList: FileList) => {
    setFilesOverSize([]);
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    const allowedFiles = Array.from(fileList).filter(
      (file) => file.size <= maxSize
    );
    const rejectedFiles = Array.from(fileList).filter(
      (file) => file.size > maxSize
    );
    if (rejectedFiles.length > 0) {
      setFilesOverSize([
        ...rejectedFiles.map((file) => ({ id: uuid(), file })),
      ]);
    }
    setFiles([...files, ...allowedFiles.map((file) => ({ id: uuid(), file }))]);
  };

  useEffect(() => {
    if (attachmentSuccess && attachmentData) {
      console.log("Attachment added successfully: ", attachmentData);
      setFiles([]);
    } else if (attachmentError) {
      console.log("Attachment failed to add: ", attachmentError);
    }
  }, [attachmentSuccess, attachmentData, attachmentError]);

  return (
    <Box
      sx={{
        marginTop: "auto",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        height: "auto",
      }}
    >
      <Box
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {isPendingPlaceholderReplacement(message) && (
          <TemplateWarning
            message="Não esqueça de alterar os campos indicados no modelo antes de enviar ao
        paciente"
          />
        )}
        {filesOverSize.length > 0 && (
          <TemplateWarning message="Alguns arquivos estão acima do tamanho permitido e foram removidos" />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            gap: 0.5,
            border: `1px solid ${customColors.Neutral07}`,
            borderRadius: "32px",
            padding: "16px 8px 12px 8px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box position="relative">
              {isEmojiSelectionOpen && (
                <Box
                  data-testid="emoji-selection-box"
                  sx={{
                    position: "absolute",
                    bottom: "80%",
                    right: "10%",
                    zIndex: 1,
                  }}
                >
                  <CustomEmojiPicker onEmojiSelect={handleEmojiClick} />
                </Box>
              )}
              <TextField
                inputRef={textFieldInput}
                fullWidth
                disabled={disabled}
                variant="standard"
                placeholder={
                  disabled
                    ? "Reabra a conversa para continuar" // after 24h Reabra a conversa e selecione um modelo para enviar uma mensagem
                    : "Digite sua mensagem ou comando"
                }
                value={message}
                multiline
                maxRows={7}
                InputProps={{
                  sx: {
                    ...inputStyle,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ marginRight: "10px" }}>
                        <ChatCommandButton
                          disabled={disabled}
                          handleOnClick={toggleCommandModal}
                        />
                      </Box>
                      <ChatAttatchFile
                        disabled={disabled}
                        handleOnSelectFiles={handleOnSelectFiles}
                      />
                      <CustomIconButton
                        data-testid="open-emojis-button"
                        color="primary"
                        variant="text"
                        aria-label="Emojis"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleToggleEmoji();
                        }}
                        disabled={
                          disabled || isPendingPlaceholderReplacement(message)
                        }
                      >
                        <SentimentSatisfiedAltIcon
                          sx={{ color: "common.neutral06" }}
                          fontSize="medium"
                        />
                      </CustomIconButton>
                      <CustomIconButton
                        data-testid="send-message-button"
                        color="primary"
                        variant="contained"
                        aria-label="Enviar"
                        onClick={handleSubmit}
                        disabled={
                          disabled ||
                          (!message && files.length === 0) ||
                          isPendingPlaceholderReplacement(message)
                        }
                      >
                        <ArrowRight fontSize="small" />
                      </CustomIconButton>
                    </Box>
                  ),
                }}
                onChange={(event) => handleOnChange(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flex: 1,
                  gap: "8px",
                }}
              >
                {Array.from(files).map((file) => (
                  <FileCard
                    key={file.id}
                    name={file.file.name}
                    uuid={file.id}
                    type={file.file.type as FileTypes}
                    handleOnRemove={removeFile}
                    isLoading={attachmentLoading}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
