import {
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import RadioButtonHouse from "./RadioButtonHouse";
import AddIcon from "@mui/icons-material/Add";
import InputBenefit from "./InputBenefit";
import { useBenefitsContext } from "../../../contexts/RegistrationContexts/BenefitsContext";
import { useRelativesContext } from "../../../contexts/RegistrationContexts/RelativesContext";
import { BenefitType, HealthPlanRoleTypeEnum } from "../types";
import {
  getEmployersByRelatives,
  getBenefitsIdsByEmployers,
  getInputBenefitsByEmployersAndRelatives,
} from "../helpers";
import { useCallback, useEffect, useState } from "react";
import { customColors } from "../../../theme/colors";
import { InformationSectionContainer } from "./InformationSectionContainer";
import { UpdateSectionContainer } from "./UpdateSectionContainer";
import { FormSectionHeader } from "../../../components/Form/FormSectionHeader";
import { FormGroupContainer } from "../../../components/Form/FormGroupContainer";
import { useCreateBenefits, useRemoveBenefit } from "../hooks/useBenefits";
import { renderNotifier } from "../../../providers/Notifier";
import { messages } from "../../../strings/messages";
import { errors as messageErrors } from "../../../strings/errors";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatISODate } from "../helpers";

const getBenefitPlanRole = (role: HealthPlanRoleTypeEnum) => {
  switch (role) {
    case HealthPlanRoleTypeEnum.HOLDER:
      return "Titular";
    case HealthPlanRoleTypeEnum.DEPENDENT:
      return "Dependente";
    default:
      return "Titular";
  }
};

type BenefitsListProps = {
  existingBenefits: BenefitType[];
  edit?: boolean;
  loading?: boolean;
  handleOnRemoveBenefit?: (coverageId: string) => void;
};

const BenfitsList = ({
  existingBenefits,
  edit,
  loading,
  handleOnRemoveBenefit,
}: BenefitsListProps) => {
  const [coverageLoading, setCoverageLoading] = useState("");

  useEffect(() => {
    if (!loading) {
      setCoverageLoading("");
    }
  }, [loading]);

  return (
    <>
      {existingBenefits.map((benefit, index) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
          key={benefit.coverageId}
        >
          {edit && handleOnRemoveBenefit && (
            <Box sx={{ position: "absolute", top: 0, right: 0 }}>
              {loading && coverageLoading === benefit.coverageId ? (
                <CircularProgress color="info" size={24} />
              ) : (
                <Button
                  onClick={() => {
                    setCoverageLoading(benefit.coverageId);
                    handleOnRemoveBenefit(benefit.coverageId!);
                  }}
                >
                  <DeleteIcon
                    fontSize="small"
                    sx={{ color: customColors.PurpleMain }}
                  />
                  <Typography
                    variant="button"
                    sx={{
                      color: customColors.PurpleMain,
                      textDecoration: "underline",
                    }}
                  >
                    Remover
                  </Typography>
                </Button>
              )}
            </Box>
          )}
          <Typography variant="body1">Benefício {index + 1}</Typography>
          <Typography variant="caption">
            {getBenefitPlanRole(benefit.healthPlanRole!)}
          </Typography>
          <Typography variant="caption">{benefit.employer}</Typography>
          <Typography variant="caption">{`${benefit.benefit} - ${benefit.product}`}</Typography>
          {benefit.activationDate && (
            <Typography variant="caption">
              Data de ativação: {formatISODate(benefit.activationDate)}
            </Typography>
          )}
          <Typography variant="caption">
            Carteirinha: {benefit.cardNumber}
          </Typography>
        </Box>
      ))}
    </>
  );
};

const BenefitsTypeOptions = {
  holder: "HOLDER",
  dependent: "DEPENDENT",
};

const RadioButtoStyle = {
  color: "secondary.contrastText",
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
};

const RadioLabelStyle = {
  fontSize: "14px",
  color: customColors.PrimaryNeutral,
};

type BenefitsFormSectionProps = {
  formMode: "create" | "edit" | null;
  enabled?: boolean;
  lifeId: string | null;
  handleOnActivateEditMode: (active: boolean) => void;
};

const BenefitsFormSection = ({
  formMode,
  enabled = true,
  lifeId,
  handleOnActivateEditMode,
}: BenefitsFormSectionProps) => {
  const [loadingBenefits, setLoadingBenefits] = useState(false);
  const [hasFullFilledBenefit, setHasFullFilledBenefit] = useState(false);
  const [activationDateError, setActivationDateError] = useState(false);
  const [isEditionOpen, setEditionOpen] = useState(formMode === "create");

  const {
    healthPlanRole,
    inputBenefits,
    cardNumbers,
    employersIds,
    benefitsIds,
    allEmployers,
    benefits,
    existingBenefits,
    activationDate,
    setInputBenefits,
    setCardNumbers,
    setEmployersIds,
    setBenefitsIds,
    setEmployers,
    switchEmployersByPlanRole,
  } = useBenefitsContext();

  const {
    data: createBenefitsData,
    isError: createBenefitsError,
    isSuccess: createBenefitsSuccess,
    isPending: createBenefitsLoading,
    mutate: createBenefits,
  } = useCreateBenefits();

  const {
    data: removeBenefitData,
    isError: removeBenefitError,
    isSuccess: removeBenefitSuccess,
    isPending: removeBenefitLoading,
    mutate: removeBenefit,
  } = useRemoveBenefit();

  useEffect(() => {
    if (createBenefitsSuccess && createBenefitsData) {
      renderNotifier(messages.benefitSuccessfullyCreated, {
        variant: "success",
      });
      handleOnChangeMode(false);
    } else if (createBenefitsError) {
      renderNotifier(messageErrors.generic, { variant: "error" });
      handleOnChangeMode(false);
    }
  }, [createBenefitsSuccess, createBenefitsData, createBenefitsError]);

  useEffect(() => {
    if (removeBenefitSuccess && removeBenefitData) {
      renderNotifier(messages.benefitSuccessfullyRemoved, {
        variant: "success",
      });
      handleOnChangeMode(false);
    } else if (removeBenefitError) {
      renderNotifier(messageErrors.generic, { variant: "error" });
      handleOnChangeMode(false);
    }
  }, [removeBenefitSuccess, removeBenefitData, removeBenefitError]);

  const { relatives } = useRelativesContext();

  const handleChangeAddInputBenefits = () => {
    setHasFullFilledBenefit(false);
    setCardNumbers([...cardNumbers, ""]);
    setEmployersIds([...employersIds, ""]);
    setBenefitsIds([...benefitsIds, ""]);
    setInputBenefits([...inputBenefits, ""]);
  };

  const handleChangePlanRole = (role: HealthPlanRoleTypeEnum) => {
    switchEmployersByPlanRole(role);
    if (relatives.length > 0 && role === HealthPlanRoleTypeEnum.DEPENDENT) {
      const allowedEmployers = getEmployersByRelatives(allEmployers, relatives);
      setEmployers(allowedEmployers);
      setEmployersIds([...allowedEmployers.map((employer) => employer.id)]);
      setBenefitsIds([...getBenefitsIdsByEmployers(allowedEmployers)]);
      setInputBenefits([
        ...getInputBenefitsByEmployersAndRelatives(
          benefitsIds,
          relatives,
          allowedEmployers
        ),
      ]);
    }
    setEmployersIds([""]);
    setBenefitsIds([""]);
    setInputBenefits([""]);
    setCardNumbers([""]);
  };

  useEffect(() => {
    const relativesEmployers = getEmployersByRelatives(allEmployers, relatives);

    if (relativesEmployers) {
      if (healthPlanRole === HealthPlanRoleTypeEnum.DEPENDENT) {
        setEmployers(relativesEmployers);
      }
    }
  }, [relatives]);

  useEffect(() => {
    const targetBenefit = benefits[inputBenefits.length - 1];

    if (targetBenefit) {
      if (targetBenefit.benefitId && targetBenefit.cardNumber) {
        setHasFullFilledBenefit(true);
      }
    }
  }, [benefits]);

  useEffect(() => {
    setEditionOpen(formMode === "create");
  }, [formMode]);

  const isAddBenefitButtonEnabled = useCallback(() => {
    if (
      healthPlanRole === HealthPlanRoleTypeEnum.DEPENDENT &&
      !relatives.length
    ) {
      return false;
    }

    if (benefits.length === 0 || !hasFullFilledBenefit) {
      return false;
    }

    return true;
  }, [benefits, hasFullFilledBenefit, healthPlanRole, relatives]);

  const renderBenefitFields = ({ editMode }: { editMode?: boolean }) => (
    <>
      <FormControl>
        <RadioGroup
          row
          value={healthPlanRole}
          onChange={(e) =>
            handleChangePlanRole(e.target.value as HealthPlanRoleTypeEnum)
          }
          sx={{ gap: "10px" }}
        >
          <RadioButtonHouse>
            <FormControlLabel
              value={BenefitsTypeOptions.holder}
              control={<Radio sx={RadioButtoStyle} />}
              label={<Typography sx={RadioLabelStyle}>Titular</Typography>}
              sx={{ fontSize: "22px" }}
            />
          </RadioButtonHouse>
          <RadioButtonHouse>
            <FormControlLabel
              value={BenefitsTypeOptions.dependent}
              control={<Radio sx={RadioButtoStyle} />}
              label={<Typography sx={RadioLabelStyle}>Dependente</Typography>}
            />
          </RadioButtonHouse>
        </RadioGroup>
      </FormControl>
      <Box sx={{ width: "100%" }}>
        {healthPlanRole === HealthPlanRoleTypeEnum.DEPENDENT &&
          relatives.length === 0 && (
            <Typography
              color="error"
              variant="inherit"
              sx={{
                wordWrap: "break-word",
                width: "400px",
                fontSize: "14px",
              }}
            >
              Para cadastrar um dependente adicione um ou mais vínculos
              familiares.
            </Typography>
          )}
      </Box>
      <Box sx={{ width: "100%" }}>
        {inputBenefits.map((_, index: number) => {
          return (
            <Box key={index} sx={{ marginTop: "20px" }}>
              <Typography variant="h5">
                Benefício {existingBenefits.length + index + 1}
              </Typography>
              <InputBenefit
                id={index}
                editMode={editMode}
                error={activationDateError}
              />
            </Box>
          );
        })}
      </Box>
      <Button
        variant="outlined"
        onClick={handleChangeAddInputBenefits}
        disabled={!isAddBenefitButtonEnabled()}
        startIcon={
          <AddIcon fontSize="large" sx={{ color: "secondary.contrastText" }} />
        }
      >
        Adicionar benefício
      </Button>
    </>
  );

  const handleOnChangeMode = (active: boolean) => {
    setEditionOpen(!isEditionOpen);
    handleOnActivateEditMode(active);
  };

  const getHolderCoverageIdByBenefitId = (benefitId: string) => {
    let holderCoverageId: string | null = null;

    relatives.some((relative) => {
      const relativeWithTargetBenefit = relative?.attributes?.coverages?.find(
        (coverage) => coverage.benefitId === benefitId
      );
      if (relativeWithTargetBenefit) {
        holderCoverageId = relativeWithTargetBenefit.coverageId;
        return true;
      }
      return false;
    });

    return holderCoverageId;
  };

  const handleOnSave = () => {
    const regex = /^(?:\d|-){10}$/;

    if (!regex.test(activationDate?.toString())) {
      setActivationDateError(true);
      return;
    }

    createBenefits({
      benefits: benefits.map((benefit) => {
        return {
          attributes: {
            number: benefit.cardNumber,
            benefitId: benefit.benefitId,
            lifeId: lifeId!,
            holderCoverageId: getHolderCoverageIdByBenefitId(
              benefit.benefitId
            )!,
            activationDate: activationDate
              .toString()
              .split("-")
              .reverse()
              .join("-"),
          },
        };
      }),
    });
  };

  useEffect(() => {
    isLoadingBenefits();
  }, []);

  const isLoadingBenefits = () => {
    setLoadingBenefits(true);

    const timer = setTimeout(() => {
      setLoadingBenefits(false);
    }, 2000);

    if (existingBenefits.length > 0) {
      clearTimeout(timer);
      setLoadingBenefits(false);
    }
  };

  return (
    <FormGroupContainer formMode={formMode}>
      <FormSectionHeader header="Benefício" />
      {isEditionOpen && !lifeId && (
        <UpdateSectionContainer
          formMode={formMode}
          handleOnChangeMode={() => handleOnChangeMode(false)}
          handleOnSave={() => {}}
        >
          {renderBenefitFields({})}
        </UpdateSectionContainer>
      )}
      {isEditionOpen && lifeId && (
        <UpdateSectionContainer
          formMode={formMode}
          handleOnChangeMode={() => handleOnChangeMode(false)}
          loading={createBenefitsLoading}
          handleOnSave={handleOnSave}
        >
          <BenfitsList
            existingBenefits={existingBenefits}
            edit
            loading={removeBenefitLoading}
            handleOnRemoveBenefit={(coverageId: string) =>
              removeBenefit({ coverageId })
            }
          />
          {renderBenefitFields({ editMode: true })}
        </UpdateSectionContainer>
      )}
      {formMode === "edit" && !isEditionOpen && (
        <InformationSectionContainer
          handleOnChangeMode={() => handleOnChangeMode(true)}
          enabled={enabled}
        >
          {loadingBenefits ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {existingBenefits.length > 0 ? (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
                >
                  <BenfitsList existingBenefits={existingBenefits} />
                </Box>
              ) : (
                <Typography variant="body2">Não providenciado</Typography>
              )}
            </>
          )}
        </InformationSectionContainer>
      )}
    </FormGroupContainer>
  );
};

export default BenefitsFormSection;
