import { useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetCurrentChat } from "../../../../hooks/useGetCurrentChat";
import { ChipTag } from "../../../../../../components/ChipTag";
import { customColors } from "../../../../../../theme/colors";
import { removeDuplicatedAssignUsers } from "./assignFormUtils";
import { SystemUser, useGetSystemUsers } from "./hooks/useGetSystemUsers";
import { useCreateAssignMutation } from "./hooks/useCreateAssignMutation";
import { useDeleteAssignMutation } from "./hooks/useDeleteAssignMutation";
import { useQueryClient } from "@tanstack/react-query";

export const AssignForm = () => {
  const params = useParams();
  const currentChatId = params?.id || "";

  const queryClient = useQueryClient();

  const currentChat = useGetCurrentChat(currentChatId);
  const systemUsers = useGetSystemUsers();

  const createAssign = useCreateAssignMutation({
    currentChatId,
    currentChat,
  });

  const deleteAssign = useDeleteAssignMutation({
    currentChatId,
    currentChat,
  });

  const isMutating = createAssign?.isPending || deleteAssign?.isPending;
  const isInitialLoading = systemUsers.isLoading || !currentChat;

  const systemUsersData = systemUsers.data?.data?.data || [];
  const assignees = useMemo(
    () => currentChat?.chatData?.attributes?.assignees || [],
    [currentChat?.chatData?.attributes?.assignees]
  );
  const assigneesUserIds = useMemo(
    () => assignees?.map?.((item) => item?.attributes?.user_id || "") || [],
    [assignees]
  );

  const [selectedIds, setSelectedIds] = useState<string[]>(assigneesUserIds);
  const previousSelectedIds = useRef<string[]>(assigneesUserIds);

  useEffect(() => {
    previousSelectedIds.current = assigneesUserIds;
    setSelectedIds(assigneesUserIds);
  }, [assigneesUserIds]);

  const handleSelectChange = async (selectedIds: string[] = []) => {
    const isAdding = selectedIds.length > previousSelectedIds.current.length;

    try {
      if (isAdding) {
        const lastSelectedId = selectedIds[selectedIds.length - 1];
        await createAssign.mutateAsync({ userId: lastSelectedId });
      } else {
        const lastRemovedId =
          previousSelectedIds.current.find((id) => !selectedIds.includes(id)) ||
          "";

        const assigneeId =
          assignees?.find?.(
            (item) => item.attributes?.user_id === lastRemovedId
          )?.id || "";

        await deleteAssign.mutateAsync({ assigneeId });
      }
      setSelectedIds(selectedIds);
      previousSelectedIds.current = selectedIds;
    } catch (error) {
      console.error("Error on changing assign", error);
    } finally {
      queryClient.refetchQueries({
        queryKey: ["conversation-history"],
        exact: false,
      });
    }
  };
  const systemUsersDataValues = systemUsersData
    ?.reduce(removeDuplicatedAssignUsers, [] as SystemUser[])
    ?.map((item) => item.id);

  const displayingTagsLimit = 1;

  return (
    <Box sx={{ width: "260px" }}>
      <FormControl sx={{ width: "100%" }}>
        <Autocomplete
          openOnFocus
          disabled={isMutating}
          multiple
          fullWidth
          clearIcon={false}
          onChange={(_, value) => {
            const selectedIds = value as string[];
            handleSelectChange(selectedIds);
          }}
          options={systemUsersDataValues}
          getOptionLabel={(option) =>
            systemUsersData.find((item) => item?.id === option)?.attributes
              ?.name || ""
          }
          value={selectedIds}
          renderTags={(value, getTagProps) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  overflow: "hidden",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {value?.slice(0, displayingTagsLimit).map((option, index) => (
                    <ChipTag
                      {...getTagProps({ index })}
                      key={`${option}-${index}`}
                      variant="filled"
                      label={
                        systemUsersData.find((item) => item?.id === option)
                          ?.attributes?.name || ""
                      }
                      bgColor={customColors.Neutral03}
                      textColor={customColors.Neutral07}
                      removeIconColor={customColors.Neutral06}
                    />
                  ))}
                </Box>
                <Box sx={{ marginX: 0.5 }}>
                  <Typography variant="body3">
                    {value?.length > displayingTagsLimit
                      ? `+${value.length - 1}`
                      : ""}
                  </Typography>
                </Box>
              </Box>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  ...(isInitialLoading && {
                    startAdornment: (
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                        data-testid="assign-options-loading"
                      >
                        <CircularProgress size={16} />
                      </Box>
                    ),
                  }),
                }}
                variant="standard"
                label="Atribuição"
                sx={{
                  "& .MuiInputBase-root": {
                    display: "flex",
                    flexWrap: "nowrap",
                  },
                  "& .MuiChip-root": {},
                }}
              />
            );
          }}
        />
      </FormControl>
    </Box>
  );
};
