import { styled } from "@mui/material";
import { customColors } from "../../../theme/colors";

const RadioButtonHouse = styled("div", { name: "radio-button-house" })({
  border: `1px solid ${customColors.PrimaryNeutral}`,
  height: "40px",
  padding: "0 0 0 10px",
  borderRadius: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "red",
});

export default RadioButtonHouse;
