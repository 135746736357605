import { Controller, UseFormReturn } from "react-hook-form";
import { CustomDatePicker } from "../../../../../components/CustomDatePicker";
import { TFilterDocumentsForm } from "../../DocumentList";

type TEndedAtFilterProps = UseFormReturn<TFilterDocumentsForm>;

export const EndedAtFilter = (props: TEndedAtFilterProps) => {
  return (
    <Controller
      control={props.control}
      name="endedAt"
      rules={{ required: true }}
      render={({ field }) => {
        return (
          <CustomDatePicker
            label="Recebido até"
            slotProps={{ field: { clearable: true } }}
            defaultValue={field.value}
            value={field.value}
            inputRef={field.ref}
            onChange={(date) => {
              field.onChange(date);
            }}
            errorMessage={props.formState.errors.endedAt?.message}
          />
        );
      }}
    />
  );
};
