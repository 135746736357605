import { Box, IconButton, TextField } from "@mui/material";
import Search from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { customColors, muiThemeColors } from "../../theme/colors";

const containerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: "40px",
  position: "relative",
  borderRadius: "40px",
  margin: "20px 0",
  border: `1px solid ${muiThemeColors.secondary.neutral}`,
};

const inputStyle = {
  color: customColors.Neutral07,
  width: "92%",
  height: "40px",
  "& fieldset": { border: "none" },
  border: "none",
  fontSize: "16px",
  padding: "0 15px 0 10px",
  background: "transparent",
  outline: "none",
};

type InputSearchProps = {
  label: string;
  value: string;
  disabled?: boolean;
  style?: { [key: string]: string | number };
  handleOnChange: (value: string) => void;
};

export const InputSearch = ({
  value,
  label,
  disabled = false,
  style = {},
  handleOnChange,
}: InputSearchProps) => {
  return (
    <Box sx={{ ...containerStyle, ...style }}>
      <TextField
        sx={inputStyle}
        style={{ border: "none" }}
        placeholder={label}
        onChange={(e) => handleOnChange(e.target.value)}
        value={value}
        disabled={disabled}
      />
      <IconButton
        sx={{ position: "relative", right: "10px" }}
        onClick={() => handleOnChange("")}
        disabled={!value}
      >
        {value ? <CloseIcon fontSize="small" /> : <Search fontSize="small" />}
      </IconButton>
    </Box>
  );
};
