import React, { createContext, useState, useContext } from "react";

export type TemplateType = {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    description: string;
    content: string;
  };
};

type ContextType = {
  templates: TemplateType[];
  isLoading: boolean;
  error: string;
  setTemplates: React.Dispatch<React.SetStateAction<TemplateType[]>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
};

const MessageTemplatesContext = createContext<ContextType | undefined>(
  undefined
);

export const MessageTemplatesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [templates, setTemplates] = useState<TemplateType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  return (
    <MessageTemplatesContext.Provider
      value={{
        templates,
        isLoading,
        error,
        setTemplates,
        setIsLoading,
        setError,
      }}
    >
      {children}
    </MessageTemplatesContext.Provider>
  );
};

export const useMessageTemplatesContext = () => {
  const context = useContext(MessageTemplatesContext);
  if (!context) {
    throw new Error(
      "useMessageTemplatesContext must be used within a MessageTemplatesContextProvider"
    );
  }
  return context;
};
