import { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  useGeneralDataContext,
  PhoneNumberType,
} from "../../../contexts/RegistrationContexts/GeneralDataContext";

import { CustomIconButton } from "../../../components/CustomIconButton";
import InputPhone from "./InputPhone";
import { InformationSectionContainer } from "./InformationSectionContainer";
import { UpdateSectionContainer } from "./UpdateSectionContainer";
import { FormSectionHeader } from "../../../components/Form/FormSectionHeader";
import { FormGroupContainer } from "../../../components/Form/FormGroupContainer";
import { useUpdatePhone } from "../hooks/usePhone";
import { renderNotifier } from "../../../providers/Notifier";
import { messages } from "../../../strings/messages";
import { errors } from "../../../strings/errors";

type PhoneFormSectionProps = {
  formMode: "create" | "edit" | null;
  enabled?: boolean;
  lifeId: string | null;
  handleOnActivateEditMode: (active: boolean) => void;
};

const PhoneFormSection = ({
  formMode,
  enabled = true,
  lifeId,
  handleOnActivateEditMode,
}: PhoneFormSectionProps) => {
  const [loadingPhoneNumbers, setLoadingPhoneNumbers] = useState(false);
  const [isEditionOpen, setEditionOpen] = useState(formMode === "create");
  const [initialPhoneNumbers, setInitialPhoneNumbers] = useState<
    PhoneNumberType[]
  >([]);
  const { phoneNumbers, setPhoneNumbers, phonesToRemove, setPhonesToRemove } =
    useGeneralDataContext();

  const handleChangePhoneNumberById = (
    phoneId: number,
    phone: PhoneNumberType
  ) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[phoneId] = phone;
    setPhoneNumbers(newPhoneNumbers);
  };

  const addAdditionalPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, { attributes: { number: "" } }]);
  };

  const {
    data: updatePhoneData,
    isPending: updatePhoneDataLoading,
    error: updatePhoneDataError,
    mutate: updatePhone,
  } = useUpdatePhone();

  useEffect(() => {
    if (updatePhoneDataError) {
      renderNotifier(errors.errorUpdatingPhone, {
        variant: "error",
      });
    }
  }, [updatePhoneDataError]);

  useEffect(() => {
    if (updatePhoneData) {
      renderNotifier(messages.phoneSuccessfullyUpdated, {
        variant: "success",
      });
    }
  }, [updatePhoneData]);

  useEffect(() => {
    if (phoneNumbers[0]?.id && initialPhoneNumbers.length === 0) {
      setInitialPhoneNumbers(phoneNumbers);
    }
  }, [phoneNumbers]);

  useEffect(() => {
    setEditionOpen(formMode === "create");
  }, [formMode]);

  useEffect(() => {
    isLoadingPhoneNumbers();
  }, []);

  const handleOnChangeMode = (active: boolean) => {
    setEditionOpen(!isEditionOpen);
    handleOnActivateEditMode(active);
  };

  const isLoadingPhoneNumbers = () => {
    setLoadingPhoneNumbers(true);

    const timer = setTimeout(() => {
      setLoadingPhoneNumbers(false);
    }, 2000);

    if (phoneNumbers.length > 0 && phoneNumbers[0].attributes.number) {
      clearTimeout(timer);
      setLoadingPhoneNumbers(false);
    }
  };

  const handleRemovePhoneNumber = (id: number) => {
    const newPhoneNumbers = phoneNumbers.filter((_, index) => index !== id);

    if (phoneNumbers[id].id) {
      setPhonesToRemove([...phonesToRemove, phoneNumbers[id]]);
    }

    setPhoneNumbers([...newPhoneNumbers]);
  };

  return (
    <FormGroupContainer formMode={formMode}>
      <FormSectionHeader header="Telefones" />
      {isEditionOpen ? (
        <UpdateSectionContainer
          formMode={formMode}
          handleOnChangeMode={() => handleOnChangeMode(false)}
          handleOnSave={() => {
            updatePhone({
              lifeId: lifeId!,
              phoneNumbers,
              initialPhoneNumbers,
              phoneNumbersIds: phonesToRemove.map((phone) => phone.id!),
            });
          }}
          loading={updatePhoneDataLoading}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              width: "100%",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "16px",
              }}
            >
              {phoneNumbers.length === 0 && (
                <InputPhone
                  phoneNumber={phoneNumbers[0]}
                  formMode={formMode}
                  handleChangePhoneNumber={(
                    phoneId: number,
                    phone: PhoneNumberType
                  ) => handleChangePhoneNumberById(phoneId, phone)}
                  key={0}
                  id={0}
                />
              )}
              {phoneNumbers.map((_, index: number) => {
                return (
                  <Box key={index}>
                    <InputPhone
                      phoneNumber={phoneNumbers[index]}
                      formMode={formMode}
                      handleChangePhoneNumber={(
                        phoneId: number,
                        phone: PhoneNumberType
                      ) => handleChangePhoneNumberById(phoneId, phone)}
                      handleRemovePhoneNumber={(id) =>
                        handleRemovePhoneNumber(id)
                      }
                      id={index}
                    />
                  </Box>
                );
              })}
            </Box>
            <CustomIconButton
              variant="contained"
              sx={{
                marginTop: "-2px",
                marginLeft: "7px",
                width: "48px",
                height: "48px",
              }}
              onClick={addAdditionalPhoneNumber}
            >
              <AddIcon />
            </CustomIconButton>
          </Box>
        </UpdateSectionContainer>
      ) : (
        <InformationSectionContainer
          handleOnChangeMode={() => handleOnChangeMode(true)}
          enabled={enabled}
        >
          {loadingPhoneNumbers ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {phoneNumbers.length === 0 && "Não providenciado"}
              {phoneNumbers.map((phone, index) => (
                <Typography key={phone.attributes.number} variant="body2">
                  Telefone {index + 1}: {phone.attributes.number}
                </Typography>
              ))}
            </>
          )}
        </InformationSectionContainer>
      )}
    </FormGroupContainer>
  );
};

export default PhoneFormSection;
