import { Typography, CircularProgress, Box, Button } from "@mui/material";
import { customColors } from "../../../../theme/colors";
import DeleteIcon from "@mui/icons-material/Delete";

type RemoveIconProps = {
  loading?: boolean;
  handleOnRemove: () => void;
};

export const RemoveIcon = ({ loading, handleOnRemove }: RemoveIconProps) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "0",
      }}
    >
      <Box sx={{ position: "absolute", top: "-48px", right: 0 }}>
        {loading ? (
          <CircularProgress color="info" size={24} />
        ) : (
          <Button onClick={() => handleOnRemove()}>
            <DeleteIcon
              fontSize="small"
              sx={{ color: customColors.PurpleMain }}
            />
            <Typography
              variant="button"
              sx={{
                color: customColors.PurpleMain,
                textDecoration: "underline",
              }}
            >
              Remover
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};
