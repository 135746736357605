import { useMutation } from "@tanstack/react-query";
import { AppointmentStatus } from "../timelineTypes";
import { api } from "../../../api";

export const useCancelAppointmentMutation = () => {
  const cancelAppointmentMutation = useMutation({
    mutationKey: ["cancel-appoinment"],
    mutationFn: async (params: {
      appointmentId: string;
      reason: string;
      status: AppointmentStatus;
    }) => {
      return api.patch(`/api/v1/appointments/${params.appointmentId}`, {
        data: {
          id: params.appointmentId,
          type: "appointments",
          attributes: {
            status: params.status,
            ...(params.reason ? { cancellation_reason: params.reason } : {}),
          },
        },
      });
    },
    onError: (error) => {
      console.log("Error cancelling appointment", error);
    },
  });

  return {
    cancelAppointmentMutation,
  };
};
