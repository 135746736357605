import React from "react";
import { Box } from "@mui/material";

type RegistrationWrapperProps = {
  formMode: "create" | "edit" | null;
  children: React.ReactNode;
};

export const RegistrationWrapper = ({
  children,
  formMode,
}: RegistrationWrapperProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: formMode === "create" ? "center" : "flex-start",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "480px",
        }}
      >
        {formMode && children}
      </Box>
    </Box>
  );
};
