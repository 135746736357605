import { api } from "../../../api";
import { useQuery, useMutation } from "@tanstack/react-query";
// import { AddressType, AddressTypeData } from "../../../contexts/RegistrationContexts/AddressesContext";
import { AddressType } from "../../../contexts/RegistrationContexts/AddressesContext";
import { AxiosResponse } from "axios";

const getAddressByCEP = async (cep: string) => {
  return await api.get(`https://viacep.com.br/ws/${cep}/json/`);
};

const findMissingAddresses = (
  addresses?: AddressType[],
  cacheAddresses?: AddressType[]
): AddressType[] => {
  const removed: AddressType[] = [];

  cacheAddresses?.forEach((cachedAddress) => {
    const found = addresses?.find((address) => address.id === cachedAddress.id);
    if (!found) {
      removed.push(cachedAddress);
    }
  });

  return removed;
};

function findModifiedEntries(
  addresses?: AddressType[],
  cacheAddresses?: AddressType[]
) {
  const updated: AddressType[] = [];

  addresses?.forEach((address) => {
    const cachedAddress = cacheAddresses?.find((ca) => ca.id === address.id);
    if (cachedAddress) {
      const isModified = Object.keys(address).some(
        (key) =>
          address[key as keyof AddressType] !==
          cachedAddress[key as keyof AddressType]
      );
      if (isModified) {
        updated.push(address);
      }
    }
  });

  return updated;
}

type UpdateAddressesProps = {
  lifeId?: string | null;
  addresses?: AddressType[];
  cacheAddresses?: AddressType[];
};
const updateAddresses = ({
  lifeId,
  addresses,
  cacheAddresses,
}: UpdateAddressesProps) => {
  const addressesToCreate = addresses?.filter((address) => !address.id);
  const addressesToRemove = findMissingAddresses(addresses, cacheAddresses);
  const addressesToUpdate = findModifiedEntries(addresses, cacheAddresses);

  const promises: Promise<
    AxiosResponse<{ data: AddressType } | { data: object }>
  >[] = [];

  addressesToCreate?.forEach((address) => {
    const promise = api.post<{ data: AddressType }>(
      `/api/v1/lives/${lifeId}/addresses`,
      {
        data: address,
      }
    );
    promises.push(promise);
  });

  addressesToUpdate?.forEach((address) => {
    const promise = api.patch<{ data: AddressType }>(
      `/api/v1/lives/${lifeId}/addresses/${address.id}`,
      {
        data: address,
      }
    );
    promises.push(promise);
  });

  addressesToRemove?.forEach((address) => {
    const promise = api.delete<{ data: object }>(
      `/api/v1/lives/${lifeId}/addresses/${address.id}`
    );
    promises.push(promise);
  });

  return Promise.all(promises)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log({ error });
      throw new Error(error);
    });
};

export const useGetAddress = (cep?: string | null, enabled?: boolean) => {
  const validCep = cep?.replace(/[^0-9]/g, "");

  return useQuery({
    queryKey: ["get-address", cep],
    queryFn: () => getAddressByCEP(cep!),
    enabled: enabled && !!cep && validCep?.length === 8,
  });
};

export const useUpdateAdresses = () => {
  return useMutation({
    mutationKey: ["update-addresses"],
    mutationFn: ({ lifeId, addresses, cacheAddresses }: UpdateAddressesProps) =>
      updateAddresses({ lifeId, addresses, cacheAddresses }),
  });
};
