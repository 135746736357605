import { useQuery } from "@tanstack/react-query";
import { api } from "../api";

type TMeQueryData = {
  id: string;
  type: string;
  attributes: {
    firebase_id: string;
    name: string;
  };
};

export const useMeQuery = () => {
  const meQuery = useQuery({
    queryKey: ["me"],
    queryFn: () => api.get<{ data: TMeQueryData }>("api/v1/users/me"),
  });

  const meQueryData = meQuery.data?.data?.data;

  return { meQueryData };
};
