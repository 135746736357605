import { ApiTypes } from "../../../../app-types";
import { TConversation } from "../../slices/conversationSlice";
import { isDateOlderThan24Hours } from "../../utils/dateUtils";
import { TItemStatus } from "../ConversationItem/ConversationItem";

export const getPhoneNumber = (conversation: TConversation) => {
  const phoneNumber =
    conversation?.chatData?.attributes?.life?.attributes?.phone_numbers?.[0]
      ?.attributes?.number;

  if (phoneNumber) {
    return phoneNumber;
  }
  return "";
};

export type TGender = "MALE" | "FEMALE" | "OTHER" | "NOT_SPECIFIED";
export const getGender = (gender: TGender) => {
  const genderMap = {
    MALE: "Masculino",
    FEMALE: "Feminino",
    OTHER: "Outro",
    NOT_SPECIFIED: "Não especificado",
  };

  return genderMap[gender];
};

export type TIdentityGender =
  | "CIS"
  | "TRANS"
  | "NON_BINARY"
  | "OTHER"
  | "NOT_SPECIFIED";
export const getIdentityGender = (gender: TIdentityGender) => {
  const identityGenderMap = {
    CIS: "Cisgênero",
    TRANS: "Transgênero",
    NON_BINARY: "Não binário",
    OTHER: "Outro",
    NOT_SPECIFIED: "Não especificado",
  };

  return identityGenderMap[gender];
};

export const getLifeFullName = (
  lifeDataAttributes: ApiTypes["schemas"]["LifeResponse"]["data"]["attributes"]
) => {
  const firstName = lifeDataAttributes?.first_name || "";
  const lastName = lifeDataAttributes?.last_name || "";
  if (!firstName && !lastName)
    return lifeDataAttributes?.phone_numbers?.[0]?.attributes?.number || "";

  return `${firstName} ${lastName}`;
};

export const getAddressType = (type: string) => {
  const addressTypeMap = {
    HOME: "Casa",
    WORK: "Trabalho",
    OTHER: "Outro",
  };

  return addressTypeMap[type as keyof typeof addressTypeMap];
};

export const getKinshipType = (type: string) => {
  const kinshipTypeMap = {
    PARENT: "Pai ou mãe",
    CHILD: "Filho ou Filha",
    SPOUSE: "Cônjuge",
    SIBLING: "Irmão ou irmã",
  };

  return kinshipTypeMap[type as keyof typeof kinshipTypeMap];
};

export const getStatus = (conversation: TConversation): TItemStatus => {
  if (
    isDateOlderThan24Hours(
      conversation.chatData?.attributes?.last_life_message_at || ""
    )
  ) {
    return "expired";
  }

  return "default";
};

export const filterByNameOrPhoneNumber = (
  item: TConversation,
  fieldValue: string
) => {
  if (item.chatData?.attributes && item.chatData.attributes.life?.attributes) {
    try {
      return (
        `${item.chatData?.attributes?.life?.attributes?.first_name} ${item.chatData?.attributes?.life?.attributes?.last_name}`
          ?.toLowerCase()
          .includes(fieldValue.toLowerCase()) ||
        item.chatData?.attributes?.life?.attributes?.phone_numbers?.[0].attributes?.number.includes(
          fieldValue
        )
      );
    } catch (_) {
      return "";
    }
  }
};
