import { Box } from "@mui/material";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";

export const ConsultationIcon = () => {
  return (
    <Box mr={1} mt={0.5}>
      <Box
        sx={{
          display: "flex",
          width: "24px",
          height: "24px",
          alignItems: "center",
          justifyContent: "center",
          padding: "2px",
          borderRadius: "50%",
          backgroundColor: "common.box",
        }}
      >
        <VideocamOutlinedIcon color="secondary" sx={{ fontSize: "14px" }} />
      </Box>
    </Box>
  );
};
