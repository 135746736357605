import { useCallback } from "react";
import {
  Chat,
  MessageDataType,
  MessageEvenType,
  SocketEvent,
} from "../../../types";
import { useAppDispatch } from "../../../../../store";
import {
  appendMessagesToConversation,
  updateMessage,
} from "../../../slices/conversationSlice";
import { useSendMessageMutation } from "./useSendMessageMutation";

type TUseSendMessageHandlersParams = {
  currentChatId: string;
  isTemplateMessage: boolean;
};

type THandleSendMessageParams = {
  message: string;
  afterSendMessageCallback: () => void;
};

export const useSendMessageHandlers = (
  params: TUseSendMessageHandlersParams
) => {
  const { currentChatId, isTemplateMessage } = params;

  const dispatch = useAppDispatch();
  const { sendMessageMutation } = useSendMessageMutation();

  const handleSendMessage = useCallback(
    async (sendMessageParams: THandleSendMessageParams) => {
      try {
        const tempId = Date.now().toString();

        const optimisticMessage: SocketEvent = {
          type: "messages",
          created_at: "",
          chat_id: currentChatId,
          data: {
            id: tempId,
            type: "messages",
            event: MessageEvenType.MessagesQueued,
            attributes: {
              id: tempId,
              created_at: "",
              content: sendMessageParams.message,
              last_life_message_at: "",
              chat: {
                id: currentChatId,
              } as Chat,
              sender: {
                type: "users",
              },
            },
          },
          event: MessageEvenType.MessagesQueued,
          id: "",
        };
        dispatch(appendMessagesToConversation([optimisticMessage]));

        const response = await sendMessageMutation.mutateAsync({
          chatId: currentChatId,
          message: sendMessageParams.message,
          isTemplate: isTemplateMessage,
        });

        dispatch(
          updateMessage({
            messageId: tempId,
            chatId: currentChatId,
            messagePayload: {
              ...response.data,
              created_at:
                (response.data.data as MessageDataType).attributes
                  ?.created_at || "",
              event: MessageEvenType.MessagesSent,
            },
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
        sendMessageParams.afterSendMessageCallback();
      }
    },
    [currentChatId, dispatch, isTemplateMessage, sendMessageMutation]
  );

  return { handleSendMessage };
};
