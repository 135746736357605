// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJuxBtuQQGoABATAXXRFW4D54xDQpFCTs",
  authDomain: "primary-care-378721-5192b.firebaseapp.com",
  projectId: "primary-care-378721",
  storageBucket: "primary-care-378721.appspot.com",
  messagingSenderId: "229275068045",
  appId: "1:229275068045:web:d014fbefb9357d9a66a4c4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = isSupported().then((isSupported) => {
  if (isSupported) {
    return getAnalytics(app);
  }
});
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 10; // 10 seconds
const GoogleProvider = new GoogleAuthProvider();
const MicrosoftProvider = new OAuthProvider("microsoft.com");

export { auth, analytics, GoogleProvider, MicrosoftProvider };
