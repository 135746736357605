import { UseFormReturn } from "react-hook-form";
import { CustomSelect } from "../../../../../components/CustomSelect";
import { TFilterDocumentsForm } from "../../DocumentList";

const fileTypes = [
  {
    title: "Audio",
    value: "audio",
  },
  {
    title: "Vídeo",
    value: "video",
  },
  {
    title: "Imagem",
    value: "image",
  },
  {
    title: "Documento",
    value: "application",
  },
];

export const FileTypesFilter = (props: UseFormReturn<TFilterDocumentsForm>) => {
  return (
    <CustomSelect
      {...props.register("fileType")}
      label="Selecione a categoria"
      options={fileTypes}
    />
  );
};
