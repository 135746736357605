import { useEffect, useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FormSectionHeader } from "../../../../components/Form/FormSectionHeader";
import { useAddressesContext } from "../../../../contexts/RegistrationContexts/AddressesContext";
import { InputAddress } from "./InputAddress";
import { FormGroupContainer } from "../../../../components/Form/FormGroupContainer";
import { UpdateSectionContainer } from "../UpdateSectionContainer";
import { InformationSectionContainer } from "../InformationSectionContainer";
import { ListAddress } from "./ListAddress";
import { useUpdateAdresses } from "../../hooks/useAddress";
import { renderNotifier } from "../../../../providers/Notifier";
import { messages } from "../../../../strings/messages";
import { errors } from "../../../../strings/errors";

export type UpdateAddressByZipCodeProps = {
  id: number;
  values: {
    [key: string]: string | undefined | null;
  };
};

type CreateAddressProps = {
  lifeId?: string | null;
  formMode: "create" | "edit" | null;
  enabled?: boolean;
  handleOnActivateEditMode: (active: boolean) => void;
};

export const CreateAddress = ({
  lifeId,
  formMode,
  enabled = true,
  handleOnActivateEditMode,
}: CreateAddressProps) => {
  const [inputAddresses, setInputAddresses] = useState([""]);
  const [isEditionOpen, setEditionOpen] = useState(formMode === "create");
  const { addresses, cacheAddresses, setCacheAddresses, setAddresses } =
    useAddressesContext();

  const {
    data: addressesData,
    isError: addressesError,
    isPending: addressesLoading,
    mutate: updateAddresses,
  } = useUpdateAdresses();

  useEffect(() => {
    if (addressesData) {
      renderNotifier(messages.addressSuccessfullyUpdated, {
        variant: "success",
      });
      setCacheAddresses([...addresses!]);
      handleOnActivateEditMode(false);
    }
  }, [addressesData]);

  useEffect(() => {
    if (addressesError) {
      renderNotifier(errors.errorUpdatingAddresses, {
        variant: "error",
        persist: true,
      });
      handleOnActivateEditMode(false);
    }
  }, [addressesError]);

  useEffect(() => {
    if (addresses?.length) {
      setInputAddresses([...addresses!.map((_) => "")]);
    } else {
      setInputAddresses([""]);
    }
  }, [addresses]);

  useEffect(() => {
    setEditionOpen(formMode === "create");
  }, [formMode]);

  const handleOnChange = (key: string, value: string, id: number) => {
    const localAddresses = addresses?.slice() || [];
    localAddresses[id] = {
      ...localAddresses[id],
      attributes: {
        ...localAddresses[id]?.attributes,
        [key]: value,
      },
    };
    setAddresses([...localAddresses]);
  };

  const updateAddressByZipCode = ({
    values,
    id,
  }: UpdateAddressByZipCodeProps) => {
    const localAddresses = addresses?.slice() || [];

    localAddresses[id] = {
      ...localAddresses[id],
      attributes: {
        ...localAddresses[id]?.attributes,
        ...values,
      },
    };
    setAddresses([...localAddresses]);
  };

  const handleAddNewAddress = () => {
    if (inputAddresses.length === 3) {
      return;
    }
    setInputAddresses([...inputAddresses, ""]);
  };

  const handleOnRemoveAddress = (id: number) => {
    const localAddresses = addresses?.slice() || [];
    const filtered = [...localAddresses.filter((_, index) => index !== id)];

    setAddresses(filtered);
    if (inputAddresses.length > 1) {
      setInputAddresses([...filtered!.map((_) => "")]);
    }
  };

  const handleOnChangeMode = (active: boolean) => {
    if (isEditionOpen) {
      setAddresses([...cacheAddresses!]);
      setInputAddresses([...cacheAddresses!.map((_) => "")]);
    }
    setEditionOpen(!isEditionOpen);
    handleOnActivateEditMode(active);
  };

  const handleOnSave = () => {
    updateAddresses({ lifeId, addresses, cacheAddresses });
    setEditionOpen(false);
  };

  return (
    <FormGroupContainer formMode={formMode}>
      <FormSectionHeader header="Endereços" />
      {isEditionOpen ? (
        <UpdateSectionContainer
          formMode={formMode}
          handleOnChangeMode={() => handleOnChangeMode(false)}
          handleOnSave={() => handleOnSave()}
          loading={addressesLoading}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            {inputAddresses.map((_, index) => (
              <Box key={index}>
                <Typography variant="h6" sx={{ margin: "16px 0" }}>
                  Endereço {index + 1}
                </Typography>
                <InputAddress
                  id={index}
                  handleOnChange={handleOnChange}
                  address={addresses?.[index]}
                  handleOnRemove={handleOnRemoveAddress}
                  updateAddressByZipCode={updateAddressByZipCode}
                />
              </Box>
            ))}
            <Button
              variant="outlined"
              onClick={handleAddNewAddress}
              disabled={inputAddresses.length === 3}
              startIcon={
                <AddIcon
                  fontSize="large"
                  sx={{ color: "secondary.contrastText" }}
                />
              }
              sx={{
                marginTop: "24px",
              }}
            >
              Adicionar endereço
            </Button>
          </Box>
        </UpdateSectionContainer>
      ) : (
        <InformationSectionContainer
          handleOnChangeMode={() => handleOnChangeMode(true)}
          enabled={enabled}
        >
          <ListAddress addresses={addresses} />
        </InformationSectionContainer>
      )}
    </FormGroupContainer>
  );
};
