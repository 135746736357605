import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AutocompleteChangeReason } from "@mui/material";
import { LifeSummaryAPIResponse, TagsType } from "../../../types";
import { api } from "../../../../../api";
import { renderNotifier } from "../../../../../providers/Notifier";
import { errors } from "../../../../../strings/errors";

type TTagOnLifeInput = {
  lifeId?: string;
  reason: AutocompleteChangeReason;
  data: {
    type: "tag-items";
    attributes: {
      tag_id: string;
    };
  };
};

type TUseToggleTagParams = {
  endpoint?: string;
  entityId?: string;
};

export const useToggleTagMutation = (params?: TUseToggleTagParams) => {
  const queryClient = useQueryClient();

  const currentEndpoint = params?.endpoint || "/api/v1/lives";
  // /api/v1/attachments/{id}/tags

  return useMutation({
    mutationKey: ["add-tag-to-user"],
    mutationFn: async (addTagToUserInput: TTagOnLifeInput) => {
      const currentEntityId = params?.entityId || addTagToUserInput?.lifeId;

      if (addTagToUserInput.reason === "selectOption") {
        return api.post<{ data: TagsType }>(
          `${currentEndpoint}/${currentEntityId}/tags`,
          {
            data: addTagToUserInput?.data,
          }
        );
      }
      if (addTagToUserInput.reason === "removeOption")
        return api.delete<{ data: TagsType }>(
          `${currentEndpoint}/${currentEntityId}/tags/${addTagToUserInput?.data?.attributes?.tag_id}`
        );
    },
    onError: () => {
      renderNotifier(errors.generic, { variant: "error" });
    },
    onSuccess: (response, variables) => {
      if (!response) {
        renderNotifier(errors.generic, { variant: "error" });
        return;
      }

      const responseData = response?.data;

      queryClient.setQueryData(
        ["life-summary", variables.lifeId],
        (oldData: LifeSummaryAPIResponse) => {
          if (variables.reason === "selectOption") {
            const newTag: TagsType = {
              id: responseData?.data?.id || "",
              type: "tags",
              attributes: {
                label: responseData?.data?.attributes?.label || "",
                color: responseData?.data?.attributes?.color,
              },
            };
            return {
              ...oldData,
              data: {
                ...oldData?.data,
                attributes: {
                  ...oldData?.data?.attributes,
                  tagged_items: [
                    ...(oldData?.data?.attributes?.tagged_items || []),
                    newTag,
                  ],
                },
              },
            };
          } else {
            return {
              ...oldData,
              data: {
                ...oldData?.data,
                attributes: {
                  ...oldData?.data?.attributes,
                  tagged_items: oldData?.data?.attributes?.tagged_items?.filter(
                    (tag) => tag.id !== variables?.data?.attributes?.tag_id
                  ),
                },
              },
            };
          }
        }
      );
    },
  });
};
