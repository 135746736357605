import { Box, Button } from "@mui/material";
import { customColors } from "../../../theme/colors";

type InformationSectionContainerProps = {
  children: React.ReactNode;
  enabled?: boolean;
  handleOnChangeMode: () => void;
};
export const InformationSectionContainer = ({
  children,
  enabled = true,
  handleOnChangeMode,
}: InformationSectionContainerProps) => {
  return (
    <Box
      sx={{
        border: `1px solid ${customColors.Neutral05}`,
        borderRadius: "32px",
        padding: "24px",
        width: "100%",
      }}
    >
      <Box sx={{ position: "relative", top: "-24px", right: "-24px" }}>
        <Button
          onClick={handleOnChangeMode}
          sx={{ position: "absolute", right: "0", top: "-48px" }}
          disabled={!enabled}
          variant="outlined"
          size="small"
        >
          Editar dados
        </Button>
      </Box>
      {children}
    </Box>
  );
};
