import { useMutation } from "@tanstack/react-query";
import { api } from "../../../api";
import { GridRowId } from "@mui/x-data-grid";
import { ApiTypes } from "../../../app-types";

type TUseCreateChatMutationParams = {
  lifeId: GridRowId;
};

export const useCreateChatMutation = (params: TUseCreateChatMutationParams) => {
  const { lifeId } = params;

  const createChatMutation = useMutation({
    mutationKey: ["create-chat"],
    mutationFn: async () => {
      const response = await api.post<ApiTypes["schemas"]["ChatResponse"]>(
        "/api/v1/chats",
        {
          data: {
            type: "chats",
            attributes: {
              life_id: lifeId,
            },
          },
        }
      );
      return response;
    },
  });

  return { createChatMutation };
};
