import { useSearchParams } from "react-router-dom";

export enum TAttendenceFilterNames {
  withoutAssignees = "without_assignees",
  otherChats = "other_chats",
  myChats = "my_chats",
  statusClosed = "status_closed",
  statusOpen = "status_open",
}

export type TAttendenceFilters = {
  [TAttendenceFilterNames.withoutAssignees]?: string;
  [TAttendenceFilterNames.otherChats]?: string;
  [TAttendenceFilterNames.myChats]?: string;
  [TAttendenceFilterNames.statusClosed]?: string;
  [TAttendenceFilterNames.statusOpen]?: string;
};

export const useGetAttendenceFilters = (): TAttendenceFilters => {
  const [searchParams] = useSearchParams();

  const withoutAssigneesFilter = searchParams.get("without_assignees");
  const otherChatsFilter = searchParams.get("other_chats");
  const myChatsFilter = searchParams.get("my_chats");
  const statusFilter = searchParams.get("status");

  return {
    ...(withoutAssigneesFilter && {
      [TAttendenceFilterNames.withoutAssignees]: withoutAssigneesFilter,
    }),
    ...(otherChatsFilter && {
      [TAttendenceFilterNames.otherChats]: otherChatsFilter,
    }),
    ...(myChatsFilter && { [TAttendenceFilterNames.myChats]: myChatsFilter }),
    ...(statusFilter === "CLOSED" && {
      [TAttendenceFilterNames.statusClosed]: "true",
    }),
    ...(statusFilter === "OPEN" && {
      [TAttendenceFilterNames.statusOpen]: "true",
    }),
  };
};
