import { Box, Avatar } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { ReactNode, useState } from "react";
import { getInitialNameLetter } from "../../../utils/chatUtils";
import { useGetCurrentChat } from "../../../hooks/useGetCurrentChat";

type TSummaryCollapsableProps = {
  children: ReactNode;
};
export const SummaryCollapsable = (props: TSummaryCollapsableProps) => {
  const currentChat = useGetCurrentChat();

  const [isSummaryOpen, setIsSummaryOpen] = useState(true);

  const toggleSummary = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  return (
    <Box
      sx={[
        {
          borderRadius: "32px",
          height: "100%",
          paddingY: 2,
          paddingX: 2,
          backgroundColor: "common.box",
          transition: "width 0.3s",
        },
        isSummaryOpen && {
          width: "294px",
          overflowY: "auto",
        },
        !isSummaryOpen && {
          width: "68px",
          backgroundColor: "transparent",
        },
      ]}
    >
      <Box
        sx={[
          !isSummaryOpen && {
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 0.5,
          },
        ]}
      >
        <Box sx={{ cursor: "pointer" }} onClick={toggleSummary}>
          {isSummaryOpen ? (
            <KeyboardDoubleArrowRightIcon fontSize="small" color="primary" />
          ) : (
            <KeyboardDoubleArrowRightIcon
              fontSize="small"
              color="primary"
              sx={{ transform: "rotate(180deg)" }}
            />
          )}
        </Box>
        {!isSummaryOpen && (
          <Box sx={{ cursor: "pointer" }} onClick={toggleSummary}>
            <Avatar
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "common.neutral08",
              }}
            >
              {getInitialNameLetter(currentChat?.chatData?.fullName)}
            </Avatar>
          </Box>
        )}
        {isSummaryOpen && props.children}
      </Box>
    </Box>
  );
};
