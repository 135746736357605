import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, ClipboardEvent, useEffect, useState } from "react";
import { validateCPF } from "../formUtils";
import { colors } from "../../../theme";
import { API_LIVES_GET_TYPE, KinShipEnum } from "../types";
import InputMask from "react-input-mask";
import Search from "@mui/icons-material/Search";
import { useGetLife } from "../hooks/useLives";
import { customColors } from "../../../theme/colors";
import { KINSHIP_INVERSE_MAP } from "../formUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  bgcolor: colors.secondary.dark,
  border: "2px solid #000",
  backgroundColor: customColors.OffWhite,
  boxShadow: 24,
  borderRadius: "20px",
  p: 2,
};

const KINSHIP = {
  PARENT: "Pai ou Mãe",
  CHILD: "Filho",
  SIBLING: "Irmão",
  SPOUSE: "Cônjuge",
};

type KinshipFormType = {
  kinshipLife: KinShipEnum;
  kinshipRelative: KinShipEnum;
  firstName: string;
  lastName: string;
  fullName?: string;
};

const INVALID_CPF_ERROR_MESSAGE = "Forneça um CPF válido";
const FETCH_ERROR_MESSAGE =
  "Erro ao buscar informações. Tente novamente mais tarde.";

type RelativesModalProps = {
  open?: boolean;
  handleCreateRelative: ({
    life,
    kinship,
  }: {
    life: API_LIVES_GET_TYPE;
    kinship: { life_kinship: string; relative_kinship: string };
  }) => void;
  handleOnClose: () => void;
};

export const RelativesModal = ({
  open = false,
  handleCreateRelative,
  handleOnClose,
}: RelativesModalProps) => {
  const [validCPF, setValidCPF] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [localCpf, setLocalCpf] = useState("");
  const [localKinship, setLocalKinship] = useState<KinshipFormType | null>();
  const [fetchedLife, setFetchedLife] = useState<API_LIVES_GET_TYPE | null>(
    null
  );
  const [error, setError] = useState("");

  const {
    data: relativesLife,
    error: fetchError,
    isLoading,
  } = useGetLife(validCPF, isReady);

  useEffect(() => {
    if (relativesLife?.data.data[0]) {
      setFetchedLife(relativesLife.data.data[0]);
      setError("");
    } else if (relativesLife?.data.data.length === 0) {
      setError("CPF não encontrado");
    }
  }, [relativesLife]);

  useEffect(() => {
    if (fetchError) {
      setError(FETCH_ERROR_MESSAGE);
    }
  }, [fetchError]);

  const handleChangeLocalKinship = (e: SelectChangeEvent<KinShipEnum>) => {
    setLocalKinship({
      kinshipLife:
        KINSHIP_INVERSE_MAP[e.target.value as keyof typeof KinShipEnum],
      kinshipRelative: e.target.value as KinShipEnum,
      firstName: fetchedLife?.attributes?.first_name || "",
      lastName: fetchedLife?.attributes?.last_name || "",
    });
  };

  const handleChangeCpf = (value: string) => {
    const cpfStr = value.replace(/\D/g, "");
    if (cpfStr) {
      setLocalCpf(cpfStr);

      if (validateCPF(cpfStr)) {
        setValidCPF(cpfStr);
      } else {
        setValidCPF("");
      }
    }
  };

  const handleSubmit = () => {
    if (!validCPF) {
      setError(INVALID_CPF_ERROR_MESSAGE);
      return;
    }

    setIsReady(true);
  };

  const handleOnSave = () => {
    handleCreateRelative({
      life: fetchedLife!,
      kinship: {
        life_kinship: localKinship!.kinshipLife,
        relative_kinship: localKinship!.kinshipRelative,
      },
    });
  };

  return (
    <Modal open={open} onClose={handleOnClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h2"
            sx={{ width: "100%" }}
          >
            Preencher o vínculo
          </Typography>
          <IconButton onClick={handleOnClose} sx={{ position: "absolute" }}>
            <Close fontSize="large" />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                gap: 1,
              }}
            >
              <InputMask
                mask="999.999.999-99"
                value={localCpf}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeCpf(e.target.value)
                }
                onPaste={(e: ClipboardEvent<HTMLInputElement>) => {
                  const pastedCPF = e.clipboardData.getData("Text");
                  handleChangeCpf(pastedCPF);
                }}
              >
                {(props: React.InputHTMLAttributes<HTMLInputElement>) => (
                  <TextField
                    inputProps={props}
                    label="CPF"
                    variant="outlined"
                    required
                    error={!!error}
                    helperText={error}
                  />
                )}
              </InputMask>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <IconButton
                  disabled={localCpf.length < 11}
                  onClick={handleSubmit}
                >
                  <Search fontSize="large" />
                </IconButton>
              )}
            </Box>
            {fetchedLife && (
              <>
                <TextField
                  label="Nome completo"
                  value={`${fetchedLife?.attributes?.first_name} ${fetchedLife?.attributes?.last_name}`}
                  variant="outlined"
                />
                <FormControl fullWidth>
                  <InputLabel id="relationship" required>
                    Relação
                  </InputLabel>
                  <Select
                    labelId="relationship"
                    value={
                      KINSHIP_INVERSE_MAP[
                        localKinship?.kinshipLife as keyof typeof KinShipEnum
                      ] || ""
                    }
                    label="Relação"
                    variant="outlined"
                    required
                    onChange={handleChangeLocalKinship}
                  >
                    <MenuItem value={KinShipEnum.PARENT}>
                      {KINSHIP.PARENT}
                    </MenuItem>
                    <MenuItem value={KinShipEnum.CHILD}>
                      {KINSHIP.CHILD}
                    </MenuItem>
                    <MenuItem value={KinShipEnum.SIBLING}>
                      {KINSHIP.SIBLING}
                    </MenuItem>
                    <MenuItem value={KinShipEnum.SPOUSE}>
                      {KINSHIP.SPOUSE}
                    </MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </Box>
          <Button
            onClick={handleOnSave}
            disabled={!fetchedLife}
            sx={{
              width: "130px",
              height: "30px",
            }}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
