import { useMutation } from "@tanstack/react-query";
import { api } from "../../../../../../../api";
import {
  TConversation,
  updateChatAssignees,
} from "../../../../../slices/conversationSlice";
import { useAppDispatch } from "../../../../../../../store";
import { renderNotifier } from "../../../../../../../providers/Notifier";
import { errors } from "../../../../../../../strings/errors";

type CreateAssignResponse = {
  data: {
    id: string;
    type: "assignees";
    attributes: {
      user_id: string;
    };
  };
};

export type TAssignOperation = {
  currentChatId: string;
  currentChat?: TConversation;
};

export const useCreateAssignMutation = (params: TAssignOperation) => {
  const { currentChatId, currentChat } = params;
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: ["create-assign"],
    mutationFn: ({ userId }: { userId: string }) => {
      return api.post<CreateAssignResponse>(
        `/api/v1/chats/${currentChatId}/assignees`,
        {
          data: {
            type: "assignees",
            attributes: {
              user_id: userId,
            },
            id: userId,
          },
        }
      );
    },
    onSuccess: (response) => {
      if (!currentChat?.chatData) return;
      const responseUserId = response.data.data.attributes?.user_id;
      const responseAssigneeId = response.data.data.id;

      dispatch(
        updateChatAssignees({
          chatId: currentChatId,
          assignees:
            currentChat?.chatData?.attributes?.assignees?.concat?.({
              id: responseAssigneeId,
              type: "assignees",
              attributes: {
                user_id: responseUserId,
              },
            }) || [],
        })
      );
    },
    onError: () => {
      renderNotifier(errors.generic, { variant: "error" });
    },
  });
};
