import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { TFilterDocumentsForm } from "../components/DocumentList";
import { useDebounce } from "use-debounce";
import { isValid } from "../../Chat/utils/dateUtils";
import { documentFiltersSchemaValidation } from "../components/TimelineFilters/filtersZodSchema";

export const useTimelineFilters = () => {
  const formFilter = useForm<TFilterDocumentsForm>({
    defaultValues: {
      freeTextFilter: "",
      fileType: "",
      tags: "",
      startedAt: null,
      endedAt: null,
    },
    resolver: zodResolver(documentFiltersSchemaValidation),
    mode: "onChange",
  });

  const { watch } = formFilter;

  const [debouncedTextFilter] = useDebounce(watch("freeTextFilter"), 500);

  const [debouncedStartedAt] = useDebounce(
    isValid(watch("startedAt")) ? watch("startedAt") : null,
    500
  );

  const [debouncedEndedAt] = useDebounce(
    isValid(watch("endedAt")) ? watch("endedAt") : null,
    500
  );

  const fileType = watch("fileType");
  const tags = watch("tags");

  return {
    currentForm: formFilter,
    watchValues: {
      debouncedTextFilter,
      debouncedStartedAt,
      debouncedEndedAt,
      fileType,
      tags,
    },
  };
};
