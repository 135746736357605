import { LifeSummaryAPIResponse } from "../../../types";
import { format } from "../../../utils/dateUtils";
import {
  TGender,
  TIdentityGender,
  getGender,
  getIdentityGender,
} from "../../ConversationList/conversationListHelpers";

export const useRegistrationData = (
  lifeData: LifeSummaryAPIResponse["data"] | undefined
) => {
  return [
    {
      title: "CPF:",
      value: lifeData?.attributes?.registration_number || "-",
    },
    {
      title: "Sexo:",
      value: getGender(lifeData?.attributes?.gender as TGender) || "-",
    },
    {
      title: "Gênero:",
      value:
        getIdentityGender(
          lifeData?.attributes?.gender_identity as TIdentityGender
        ) || "-",
    },
    {
      title: "Nome social",
      value: lifeData?.attributes?.preferred_name || "-",
    },
    {
      title: "Nascimento:",
      value: lifeData?.attributes?.birth_date
        ? format(new Date(lifeData?.attributes?.birth_date || ""), "dd/MM/yyyy")
        : "-",
    },
    {
      title: "Telefone:",
      value:
        lifeData?.attributes?.phone_numbers?.[0]?.attributes?.number || "-",
    },
    {
      title: "Carterinha:",
      value: lifeData?.attributes?.coverages?.[0]?.attributes?.number || "-",
    },
    {
      title: "Produto:",
      value:
        lifeData?.attributes?.coverages?.[0]?.attributes?.benefit?.attributes
          ?.product || "-",
    },
  ];
};
