import TimelineContent from "@mui/lab/TimelineContent";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { EncounterResource, TimelineEventSchema } from "../timelineTypes";
import { ExpandLess, ExpandMore, VideocamOutlined } from "@mui/icons-material";
import { CustomTimelineSeparator } from "./CustomTimelineSeparator";
import { formatDate } from "../../Chat/utils/dateUtils";

import { useGetEncounterListDocumentsQuery } from "../hooks/useGetEncounterListDocumentsQuery";
import { FileCardData } from "../../../components/FileCard";
import { FileTypes } from "../../Chat/types";
import { FileCardList } from "../../../components/FileCardList";

type TThreadTimelineEventProps = {
  encounterEventAttributes: TimelineEventSchema;
  isExpandedRow: boolean;
  index: number;
  handleExpand: (index: number) => void;
};

export const EncounterTimelineEvent = (props: TThreadTimelineEventProps) => {
  const encounterEventAttributes = props.encounterEventAttributes;
  const encounterEvent = encounterEventAttributes.event as EncounterResource;
  const encounterId = encounterEvent?.id;
  const { getEncounterListDocumentsQuery } = useGetEncounterListDocumentsQuery({
    encounterId: encounterId || "",
    enabled: Boolean(encounterId) && props.isExpandedRow,
  });

  const medicalNotes = encounterEvent?.attributes?.medical_notes;
  const files = getEncounterListDocumentsQuery.data?.data?.data || [];

  const normalizedFilesCardData: FileCardData[] = files.map((file, index) => ({
    name: file?.attributes?.name || "",
    type: file?.attributes?.content_type as FileTypes,
    uuid: `${index}`,
    isLoading: false,
    documentLink: file?.attributes?.public_url || "",
  }));

  return (
    <>
      <CustomTimelineSeparator>
        <VideocamOutlined />
      </CustomTimelineSeparator>
      <TimelineContent sx={{ padding: 2 }}>
        <Box>
          <Typography variant="caption">
            {formatDate(encounterEventAttributes?.date || "")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body3" fontWeight="bold">
            {encounterEventAttributes.title}
          </Typography>
        </Box>

        <Box
          sx={[
            {
              minHeight: "56px",
            },
            !props.isExpandedRow && {
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "3em",
              lineHeight: "1.5em",
              margin: 0,
            },
          ]}
        >
          <Box>
            <Typography variant="button" fontWeight="bold">
              Motivo da consulta:
            </Typography>{" "}
            <Typography variant="button">
              {encounterEvent?.attributes?.reason}
            </Typography>
          </Box>
        </Box>
        {props.isExpandedRow && (
          <Box
            sx={{
              borderRadius: (theme) => theme.shape.borderRadius,
              backgroundColor: (theme) => theme.palette.common.white,
              padding: 2,
              display: !medicalNotes && !files.length ? "none" : "block",
            }}
          >
            <Typography
              variant="button"
              fontWeight="bold"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {medicalNotes}
            </Typography>
            {getEncounterListDocumentsQuery?.isLoading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress
                  size={18}
                  data-testid="encounter-documents-loading"
                />
              </Box>
            )}
            <Box mt={2}>
              {normalizedFilesCardData?.length > 0 && (
                <FileCardList files={normalizedFilesCardData} />
              )}
            </Box>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="text"
            color="primary"
            endIcon={props.isExpandedRow ? <ExpandLess /> : <ExpandMore />}
            onClick={() => props.handleExpand(props.index)}
          >
            {props.isExpandedRow ? "Ver menos" : "Ver mais"}
          </Button>
        </Box>
      </TimelineContent>
    </>
  );
};
