import { Components } from "@mui/material";
import { CustomSxProps } from "../theme-types";

const getFontFamily = (sx: CustomSxProps) => {
  const fontFamilies = {
    900: "HafferSQXH-Bold",
    700: "HafferSQXH-Medium",
    400: "HafferSQXH-Regular",
    bold: "HafferSQXH-Bold",
    medium: "HafferSQXH-Medium",
    normal: "HafferSQXH-Regular",
  };

  return (
    fontFamilies[sx.fontWeight as keyof typeof fontFamilies] ||
    "HafferSQXH-Regular"
  );
};

export const MuiTypographyNode = {
  styleOverrides: {
    root: (props: { sx: CustomSxProps }) => {
      return {
        fontFamily: getFontFamily(props.sx),
      };
    },
  },
} as Components["MuiTypography"];
