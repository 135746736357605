import { useEffect } from "react";
import { Button } from "@mui/material";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../../theme/colors";
import { MicrosoftProvider } from "../../../infra/firebase";
import { getAuth, signInWithPopup } from "firebase/auth";

type MicrosoftButtonProps = {
  callbackURL: string;
  tenantId: string;
};

export const MicrosoftButton = ({
  callbackURL,
  tenantId,
}: MicrosoftButtonProps) => {
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (tenantId === "aurora-qcwms") {
      auth.tenantId = tenantId;
    } else {
      auth.tenantId = null;
    }
  }, [auth, tenantId]);

  const handleMicrosoftLogin = () => {
    signInWithPopup(auth, MicrosoftProvider)
      .then((_) => {
        navigate(callbackURL);
      })
      .catch((err) => {
        console.log("Error signing in with Microsoft: ", err);
      });
  };

  return (
    <Button
      color="primary"
      variant="contained"
      sx={{
        width: "294px",
        height: "40px",
        borderRadius: 20,
        textTransform: "none",
        fontSize: "1.0rem",
        fontWeight: "bold",
        color: customColors.White,
        backgroundColor: customColors.MicrosoftButtonColor,
      }}
      onClick={handleMicrosoftLogin}
      startIcon={<MicrosoftIcon />}
    >
      Continuar com a Microsoft
    </Button>
  );
};
