import { TAttendenceFilterNames } from "../../hooks/useGetAttendenceFilters";
import { Assignee } from "../../types";
import { attendenceFilterStatus } from "./components/AttendencesMenu/attendenceMenuOptions";

export const getMessageCurrentFilters = (
  data: {
    status?: string;
    assignees?: Assignee[];
  },
  userId?: string
) => {
  const { status, assignees = [] } = data;
  const isClosed = status === "CLOSED";
  const hasAssignees = Boolean(assignees?.length);

  const isAssignedToMe = assignees?.find?.(
    (item) => item?.attributes?.user_id === userId
  );

  const filters: string[] = [];

  if (isClosed) return [TAttendenceFilterNames.statusClosed];

  if (!isClosed) filters.push(TAttendenceFilterNames.statusOpen);
  if (!hasAssignees) filters.push(attendenceFilterStatus.withoutAssignees);
  if (hasAssignees && !isAssignedToMe)
    filters.push(attendenceFilterStatus.otherChats);
  if (hasAssignees && isAssignedToMe)
    filters.push(attendenceFilterStatus.myChats);
  if (assignees.length > 2 && isAssignedToMe)
    filters.push(attendenceFilterStatus.otherChats);

  return filters;
};
