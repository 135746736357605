import { api } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { PhoneNumberType } from "../../../contexts/RegistrationContexts/GeneralDataContext";

type UpdatePhoneResponseProps = {
  data: {
    id: string;
    type: "phone_numbers";
    attributes: {
      number: string[];
    };
  };
};

type UpdatePhoneProps = {
  lifeId: string;
  phoneNumbers: PhoneNumberType[];
  initialPhoneNumbers: PhoneNumberType[];
  phoneNumbersIds: string[];
};

const updatePhone = async ({
  lifeId,
  phoneNumbers,
  initialPhoneNumbers,
  phoneNumbersIds,
}: UpdatePhoneProps) => {
  const phonesToUpdate: PhoneNumberType[] = [];
  const phonesToCreate: PhoneNumberType[] = [];

  initialPhoneNumbers.forEach((initialPhone) => {
    const phoneToUpdate = phoneNumbers.find(
      (phoneNumber) =>
        phoneNumber.id === initialPhone.id &&
        phoneNumber.attributes.number !== initialPhone.attributes.number
    );

    if (phoneToUpdate) {
      phonesToUpdate.push(phoneToUpdate);
    }
  });

  phoneNumbers.forEach((phoneNumber) => {
    if (!phoneNumber.id) {
      phonesToCreate.push(phoneNumber);
    }
  });

  if (
    phonesToUpdate.length === 0 &&
    phonesToCreate.length === 0 &&
    phoneNumbersIds.length === 0
  ) {
    return;
  }

  const promises: Promise<
    AxiosResponse<UpdatePhoneResponseProps | { data: object }>
  >[] = [];

  [...phonesToCreate, ...phonesToUpdate].forEach((phoneNumber) => {
    let validPhoneNumber = phoneNumber.attributes.number.replace("-", "");

    if (!validPhoneNumber.startsWith("+")) {
      validPhoneNumber = "+" + validPhoneNumber;
    }

    if (phoneNumber.id) {
      const promise = api.patch(
        `/api/v1/lives/${lifeId}/phone_numbers/${phoneNumber.id}`,
        {
          data: {
            id: phoneNumber.id,
            type: "phone_numbers",
            attributes: {
              number: validPhoneNumber,
            },
          },
        }
      );
      promises.push(promise);
    } else {
      const promise = api.post(`/api/v1/lives/${lifeId}/phone_numbers`, {
        data: {
          type: "phone_numbers",
          attributes: {
            number: validPhoneNumber,
          },
        },
      });
      promises.push(promise);
    }
  });

  if (phoneNumbersIds.length > 0) {
    phoneNumbersIds.forEach((phoneNumberId) => {
      const promise = api.delete(
        `/api/v1/lives/${lifeId}/phone_numbers/${phoneNumberId}`
      );
      promises.push(promise);
    });
  }

  return Promise.all(promises)
    .then((responses) => {
      return responses;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const useUpdatePhone = () => {
  return useMutation({
    mutationKey: ["update-phone"],
    mutationFn: ({
      lifeId,
      phoneNumbers,
      initialPhoneNumbers,
      phoneNumbersIds,
    }: UpdatePhoneProps) =>
      updatePhone({
        lifeId,
        phoneNumbers,
        initialPhoneNumbers,
        phoneNumbersIds,
      }),
  });
};
