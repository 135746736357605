import React, { createContext, useState, useContext, useEffect } from "react";
import { BenefitType, EmployerType } from "../../screens/Registration/types";

enum HealthPlanRoleTypeEnum {
  HOLDER = "HOLDER",
  DEPENDENT = "DEPENDENT",
}

type ContextType = {
  employers: EmployerType[];
  allEmployers: EmployerType[];
  holderEmployers: EmployerType[];
  cardNumbers: string[];
  employersIds: string[];
  benefitsIds: string[];
  inputBenefits: string[];
  benefits: BenefitType[];
  existingBenefits: BenefitType[];
  healthPlanRole: HealthPlanRoleTypeEnum;
  activationDate: string | Date;
  setAllEmployers: React.Dispatch<React.SetStateAction<EmployerType[]>>;
  setEmployers: React.Dispatch<React.SetStateAction<EmployerType[]>>;
  setHolderEmployers: React.Dispatch<React.SetStateAction<EmployerType[]>>;
  setCardNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  setEmployersIds: React.Dispatch<React.SetStateAction<string[]>>;
  setBenefitsIds: React.Dispatch<React.SetStateAction<string[]>>;
  setInputBenefits: React.Dispatch<React.SetStateAction<string[]>>;
  setHealthPlanRole: React.Dispatch<
    React.SetStateAction<HealthPlanRoleTypeEnum>
  >;
  setBenefits: React.Dispatch<React.SetStateAction<BenefitType[]>>;
  setExistingBenefits: React.Dispatch<React.SetStateAction<BenefitType[]>>;
  resetBenefitsContext: () => void;
  switchEmployersByPlanRole: (employerType: HealthPlanRoleTypeEnum) => void;
  setActivationDate: React.Dispatch<React.SetStateAction<string | Date>>;
};

const BenefitsContext = createContext<ContextType | undefined>(undefined);

export const BenefitsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [employers, setEmployers] = useState<EmployerType[]>([]);
  const [allEmployers, setAllEmployers] = useState<EmployerType[]>([]);
  const [holderEmployers, setHolderEmployers] = useState<EmployerType[]>([]);
  const [cardNumbers, setCardNumbers] = useState<string[]>([""]);
  const [employersIds, setEmployersIds] = useState<string[]>([""]);
  const [benefitsIds, setBenefitsIds] = useState<string[]>([""]);
  const [inputBenefits, setInputBenefits] = useState<string[]>([""]);
  const [benefits, setBenefits] = useState<BenefitType[]>([]);
  const [existingBenefits, setExistingBenefits] = useState<BenefitType[]>([]);
  const [healthPlanRole, setHealthPlanRole] = useState<HealthPlanRoleTypeEnum>(
    HealthPlanRoleTypeEnum.HOLDER
  );
  const [activationDate, setActivationDate] = useState<string | Date>("");

  const switchEmployersByPlanRole = (employerType: HealthPlanRoleTypeEnum) => {
    if (employerType === HealthPlanRoleTypeEnum.DEPENDENT) {
      resetBenefitsContext();
      setEmployers(holderEmployers);
    } else {
      setEmployers(allEmployers);
    }
    setHealthPlanRole(employerType);
  };

  useEffect(() => {
    setEmployers(allEmployers);
  }, [allEmployers]);

  const resetBenefitsContext = () => {
    setCardNumbers([""]);
    setEmployersIds([""]);
    setBenefitsIds([""]);
    setExistingBenefits([]);
    setInputBenefits([""]);
    setActivationDate("");
    setHealthPlanRole(HealthPlanRoleTypeEnum.HOLDER);
  };

  return (
    <BenefitsContext.Provider
      value={{
        employers,
        allEmployers,
        holderEmployers,
        cardNumbers,
        employersIds,
        benefitsIds,
        inputBenefits,
        healthPlanRole,
        benefits,
        existingBenefits,
        activationDate,
        setAllEmployers,
        setBenefits,
        setExistingBenefits,
        setCardNumbers,
        setEmployersIds,
        setBenefitsIds,
        setInputBenefits,
        setHealthPlanRole,
        setHolderEmployers,
        resetBenefitsContext,
        setEmployers,
        switchEmployersByPlanRole,
        setActivationDate,
      }}
    >
      {children}
    </BenefitsContext.Provider>
  );
};

export const useBenefitsContext = () => {
  const context = useContext(BenefitsContext);
  if (!context) {
    throw new Error(
      "useBenefitsContext must be used within a BenefitsContextProvider"
    );
  }
  return context;
};
