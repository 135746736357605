import { useMutation } from "@tanstack/react-query";
import { TAssignOperation } from "./useCreateAssignMutation";
import { api } from "../../../../../../../api";
import { useAppDispatch } from "../../../../../../../store";
import { updateChatAssignees } from "../../../../../slices/conversationSlice";
import { renderNotifier } from "../../../../../../../providers/Notifier";
import { errors } from "../../../../../../../strings/errors";

export const useDeleteAssignMutation = (params: TAssignOperation) => {
  const { currentChatId, currentChat } = params;
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: ["delete-assign"],
    mutationFn: ({ assigneeId }: { assigneeId: string }) => {
      return api.delete(
        `/api/v1/chats/${currentChatId}/assignees/${assigneeId}`
      );
    },
    onSuccess: (_, { assigneeId }) => {
      dispatch(
        updateChatAssignees({
          chatId: currentChatId,
          assignees:
            currentChat?.chatData?.attributes?.assignees?.filter?.(
              (item) => item.id !== assigneeId
            ) || [],
        })
      );
    },
    onError: () => {
      renderNotifier(errors.generic, { variant: "error" });
    },
  });
};
