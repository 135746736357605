import {
  Box,
  Button,
  CircularProgress,
  DialogProps,
  Typography,
} from "@mui/material";
import { CustomDialog } from "../../../components/CustomDialog/CustomDialog";

type TNoShowDialogProps = {
  dialogProps: DialogProps;
  onClickBack: () => void;
  onClickProceed: () => void;
  isCancelLoading?: boolean;
};

export const NoShowDialog = (props: TNoShowDialogProps) => {
  const handleBack = () => {
    props?.onClickBack?.();
  };

  const handleProceedCancel = () => {
    props?.onClickProceed?.();
  };

  return (
    <CustomDialog {...props.dialogProps} onClose={() => handleBack()}>
      <Box>
        <Box mb={2}>
          <Typography variant="h4">Não comparecimento em consulta</Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body2">
            Deseja registrar a falta da paciente na consulta?
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Box width="141px">
            <Button
              fullWidth
              variant="outlined"
              onClick={handleBack}
              size="small"
            >
              Voltar
            </Button>
          </Box>
          <Box width="196px">
            <Button
              fullWidth
              variant="contained"
              onClick={handleProceedCancel}
              disabled={props.isCancelLoading}
              endIcon={
                props.isCancelLoading ? (
                  <CircularProgress
                    size={20}
                    data-testid="cancel-appointment-no-show-loading"
                  />
                ) : null
              }
              size="small"
            >
              Sim, registrar falta
            </Button>
          </Box>
        </Box>
      </Box>
    </CustomDialog>
  );
};
