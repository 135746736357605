import PrivateRoute from "./PrivateRoute";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import { Box } from "@mui/material";

const PrivateInternalComponent = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <PrivateRoute>
    <Box sx={{ display: "flex", flex: 1, width: "100%", overflowY: "auto" }}>
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <Topbar />
        {children}
      </Box>
    </Box>
  </PrivateRoute>
);

export default PrivateInternalComponent;
