import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../../api";
import { ApiTypes } from "../../../../../app-types";

export const useGetChatByIdQuery = (chatId: string) => {
  return useQuery({
    queryKey: ["chat", chatId],
    queryFn: async () => {
      const response = await api.get<{
        data: ApiTypes["schemas"]["ChatResource"];
      }>(`/api/v1/chats/${chatId}`);
      return response;
    },
    enabled: !!chatId,
    gcTime: 0,
    staleTime: 0,
  });
};
