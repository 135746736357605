import { Box } from "@mui/material";

import { TFilterFormDefinition } from "./useAttachmentsFormDefinition";

type TTimelineFiltersProps = {
  formDefinition: TFilterFormDefinition[];
};

export const TimelineFilters = (props: TTimelineFiltersProps) => {
  return (
    <form>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          width: "100%",
          mb: 2,
          flexWrap: "wrap",
        }}
      >
        {props.formDefinition?.map((field, index) => (
          <Box
            key={index}
            sx={[
              field.parentSx,
              { display: "flex", gap: 1, flexDirection: "column" },
            ]}
          >
            {field.component}
          </Box>
        ))}
      </Box>
    </form>
  );
};
