import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SocketEvent } from "../types";
import { useSocketContext } from "../../../contexts/SocketContext";

export const useSocketEvent = (eventName: string) => {
  const { socket } = useSocketContext();
  const queryClient = useQueryClient();

  socket?.onAny(() => {
    queryClient.invalidateQueries({ queryKey: [eventName] }).then(() => {
      console.log("---");
    });
  });

  return useQuery({
    queryKey: [eventName],
    queryFn: () =>
      new Promise<SocketEvent>((resolve) => {
        socket?.on(eventName, (data) => {
          const parsedData: SocketEvent = JSON.parse(data);
          // console.log({ parsedData }, parsedData.event);
          resolve(parsedData);
        });
      }),
    staleTime: 0,
  });
};
