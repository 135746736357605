import { AddressType } from "../../contexts/RegistrationContexts/AddressesContext";
export enum KinShipEnum {
  PARENT = "PARENT",
  CHILD = "CHILD",
  SPOUSE = "SPOUSE",
  SIBLING = "SIBLING",
}

export enum BenefitCategoryEnum {
  HEALTHCARE = "HEALTHCARE",
  FOOD = "FOOD",
  EDUCATIONAL = "EDUCATIONAL",
  TRANSPORTATION = "TRANSPORTATION",
}

export enum AddressTypeEnum {
  HOME = "HOME",
  WORK = "WORK",
  OTHER = "OTHER",
}

export enum HealthPlanRoleTypeEnum {
  HOLDER = "HOLDER",
  DEPENDENT = "DEPENDENT",
}

export enum GenderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
  NOT_SPECIFIED = "NOT_SPECIFIED",
}

export enum GenderIdentityEnum {
  CIS = "CIS",
  TRANS = "TRANS",
  NON_BINARY = "NON_BINARY",
  OTHER = "OTHER",
  NOT_SPECIFIED = "NOT_SPECIFIED",
}

export type BenefitType = {
  coverageId: string;
  cardNumber: string;
  benefitId: string;
  employer?: string;
  healthPlanRole?: HealthPlanRoleTypeEnum;
  benefit?: string;
  product?: string;
  activationDate?: string;
};

export type CoverageType = {
  coverageId: string;
  benefitId: string;
};

export type RelativesType = {
  relativeId: string;
  relationshipId?: string;
  kinshipLife: KinShipEnum;
  kinshipRelative: KinShipEnum;
  firstName: string;
  lastName: string;
  coverages: CoverageType[];
};

export type LivesType = {
  id: string;
  firstName: string;
  lastName: string;
  socialName: string;
  gender: string;
  genderIdentity: string;
  email: string;
  birthDate: string;
  registrationNumber: string;
  phoneNumbers: string[];
  addresses: AddressType[];
  benefits: BenefitType[];
  chatId?: string;
  chat_id?: string;
  relatives: RelativesType[];
};

export type API_REQUEST_LIVES_RELATIVES_TYPE = {
  life_kinship: KinShipEnum;
  relative_kinship: KinShipEnum;
  relative_id: string;
};

export type API_RELATES_TO_FROM_TYPE = {
  id: string;
  type: string;
  attributes: {
    kinship: KinShipEnum;
    relative_id: string;
  };
};

export type API_LIFE_BY_ID_TYPE = {
  data: [
    {
      id: string;
      type: string;
      attributes: {
        first_name: string;
        last_name: string;
        preferred_name?: string;
        registration_number: string;
        birth_date?: string;
        email?: string;
        gender?: string;
        gender_identity?: string;
        addresses?: AddressType[] | null;
        phone_numbers: {
          id: string;
          type: string;
          attributes: {
            number: string;
          };
        }[];
        coverages: {
          id: string;
          type: string;
          attributes: {
            number: string;
            benefit: {
              id: string;
              type: string;
              attributes: {
                type: string;
                name: string;
                product: string;
                category: BenefitCategoryEnum;
              };
            };
          };
        }[];
        relatives: API_RELATES_TO_FROM_TYPE[];
      };
    }
  ];
};

export type API_LIVES_POST_TYPE = {
  type: string;
  attributes: {
    first_name: string;
    last_name: string;
    preferred_name?: string;
    registration_number: string;
    birth_date?: string | null;
    email?: string;
    gender?: string;
    gender_identity?: string;
    addresses?:
      | {
          address_line_1?: string | null;
          address_line_2?: string | null;
          zip_code?: string | null;
          neighborhood?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
          type?: string | null;
        }[]
      | null;
    phone_numbers: {
      number: string;
    }[];
    coverages: {
      number: string;
      benefit_id: string;
      holder_coverage_id: string | null;
    }[];
    relatives: API_REQUEST_LIVES_RELATIVES_TYPE[];
  };
};

export type API_LIVES_GET_TYPE = {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    preferred_name?: string;
    registration_number: string;
    birth_date?: string | null;
    email?: string;
    gender?: string;
    gender_identity?: string;
    chat_id?: string;
    addresses?: AddressType[] | null;
    phone_numbers: {
      id: string;
      attributes: {
        number: string;
      };
    }[];
    coverages: {
      id: string;
      attributes: {
        number: string;
        holder_coverage_id: string;
        activation_date: string;
        life_id: string;
        benefit: {
          id: string;
          attributes: {
            name: string;
            product: string;
            employer: {
              id: string;
              attributes: {
                name: string;
              };
            };
          };
        };
      };
    }[];
    relatives: {
      id: string;
      attributes: {
        life_kinship: KinShipEnum;
        relative_kinship: KinShipEnum;
        relative_id: string;
        relative: {
          id: string;
          attributes: {
            first_name: string;
            last_name: string;
          };
        };
      };
    }[];
  };
};

export type EmployerType = {
  id: string;
  name: string;
  benefits: {
    id: string;
    name: string;
    product: string;
  }[];
};

export type API_EMPLOYERS_BY_ID_TYPE = {
  data: {
    id: string;
    type: string;
    attributes: {
      name: string;
      benefits: {
        id: string;
        type: string;
        attributes: {
          type: string;
          name: string;
          product: string;
          category: BenefitCategoryEnum;
        };
      }[];
    };
  };
};

export type API_EMPLOYERS_TYPE = {
  id: string;
  type: string;
  attributes: {
    name: string;
    benefits: {
      id: string;
      type: string;
      attributes: {
        type: string;
        name: string;
        product: string;
        category: BenefitCategoryEnum;
      };
    }[];
  };
}[];
