import { useQuery } from "@tanstack/react-query";
import { LifeSummaryAPIResponse } from "../../../types";
import { api } from "../../../../../api";
import { useEffect } from "react";
import { renderNotifier } from "../../../../../providers/Notifier";
import { errors } from "../../../../../strings/errors";

export const useGetLifeById = (lifeId: string) => {
  const lifeSummaryQuery = useQuery({
    queryKey: ["life-summary", lifeId],
    queryFn: async () => {
      const response = await api.get<LifeSummaryAPIResponse>(
        `api/v1/lives/${lifeId}`
      );

      return response.data;
    },
    enabled: Boolean(lifeId),
  });

  useEffect(() => {
    if (lifeSummaryQuery.isError) {
      console.error(lifeSummaryQuery.error);
      renderNotifier(errors.generic, { variant: "error" });
    }
  }, [lifeSummaryQuery]);

  return lifeSummaryQuery;
};
