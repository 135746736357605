import { Box, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import { customColors } from "../../../../../../../../theme/colors";
import { useEffect, useState } from "react";

type GetAvatarContentProps = {
  firstName?: string;
  lastName?: string;
};
const getAvatarContent = ({ firstName, lastName }: GetAvatarContentProps) => {
  if (firstName && lastName) {
    return `${firstName.charAt(0).toUpperCase()}${lastName
      .charAt(0)
      .toUpperCase()}`;
  }
  return "";
};

const AvatarHolder = ({ content }: { content: string }) => (
  <Box
    sx={{
      width: "40px",
      height: "40px",
      borderRadius: "20px",
      backgroundColor: customColors.PurpleMain,
      color: customColors.White,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {content || <PersonIcon />}
  </Box>
);

const CapsuleItem = ({
  value,
  variant,
}: {
  value: string;
  variant: TypographyProps["variant"];
}) => {
  return (
    <Typography
      variant={variant}
      color={customColors.Neutral07}
      sx={{ lineHeight: "110%" }}
    >
      {value}
    </Typography>
  );
};

type LifeCapsuleProps = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  registrationNumber?: string;
};

export const LifeCapsule = ({
  firstName,
  lastName,
  phoneNumber,
  registrationNumber,
}: LifeCapsuleProps) => {
  const [capsuleItems, setCapsuleItems] = useState<string[]>([]);

  useEffect(() => {
    const items = [];
    if (firstName && lastName) {
      items.push(`${firstName} ${lastName}`);
    }
    if (phoneNumber) {
      items.push(phoneNumber);
    }
    if (registrationNumber) {
      items.push(registrationNumber);
    }
    setCapsuleItems([...items]);
  }, [firstName, lastName, phoneNumber, registrationNumber]);

  return (
    <Box
      sx={{
        width: "218px",
        height: "50px",
        borderRadius: "32px",
        border: `1px solid ${customColors.Neutral06}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 1,
        padding: "0 4px",
      }}
    >
      <AvatarHolder content={getAvatarContent({ firstName, lastName })} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
        {capsuleItems.map((item, index) => (
          <CapsuleItem value={item} variant={index > 0 ? "caption" : "body2"} />
        ))}
      </Box>
    </Box>
  );
};
