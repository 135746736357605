import { Box, Button, TextField } from "@mui/material";
import { Modal } from "../../../../../../components/Modal/Modal";
import { useAppDispatch } from "../../../../../../store";
import { useEffect, useRef, useState } from "react";
import {
  useCloseChat,
  useGetSummary,
  useGenerateThreads,
} from "../../hooks/useChat";
import { updateConversationStatus } from "../../../../slices/conversationSlice";
import { renderNotifier } from "../../../../../../providers/Notifier";
import { messages } from "../../../../../../strings/messages";
import { errors } from "../../../../../../strings/errors";
import {
  Chat,
  MessageProps,
  SummaryDataType,
  TagsType,
} from "../../../../types";
import Loading from "./Loading";
import { BottomBar, SelectTags } from "./components";

enum LoadingEnum {
  CLOSE = "close",
  OPEN = "open",
  SUMMARY = "summary",
  THREADS = "threads",
}

type CloseChatModalProps = {
  open: boolean;
  currentChatId: string;
  handleOnClose: (value: boolean) => void;
  existingOrphanThreads?: MessageProps[];
};

export const CloseChatModal = ({
  open,
  currentChatId,
  handleOnClose,
  existingOrphanThreads,
}: CloseChatModalProps) => {
  const [summary, setSummary] = useState("");
  const [tags, setTags] = useState<TagsType[]>([]);
  const [inferredTags, setInferredTags] = useState<TagsType[]>([]);
  const [editable, setEditable] = useState(false);
  const [generateThreadsReady, setGenerateThreadsReady] = useState(false);
  const [loadingType, setLoadingType] = useState<LoadingEnum | null>();
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const {
    data: closeChatData,
    isSuccess: closeChatSuccess,
    isError: closeChatError,
    isPending: closeChatLoading,
    mutate: closeChat,
  } = useCloseChat(currentChatId);

  const {
    data: summaryData,
    isSuccess: summarySuccess,
    isError: summaryError,
    isPending: summaryLoading,
    mutate: refetchOrphanThreadsAndGetSummary,
  } = useGetSummary();

  const {
    data: threadsData,
    isSuccess: threadsSuccess,
    isError: threadsError,
    isPending: threadsLoading,
    mutate: refetchThreads,
  } = useGenerateThreads();

  useEffect(() => {
    if (closeChatSuccess && closeChatData?.data?.data) {
      dispatch(updateConversationStatus(closeChatData.data.data as Chat));
      refetchOrphanThreadsAndGetSummary({
        chatId: currentChatId,
        lifeId: closeChatData.data.data.attributes?.life.id,
        existingOrphanThreads,
      });
    }
  }, [
    closeChatSuccess,
    closeChatData,
    dispatch,
    refetchOrphanThreadsAndGetSummary,
    currentChatId,
    existingOrphanThreads,
  ]);

  useEffect(() => {
    closeChat();
  }, [closeChat]);

  useEffect(() => {
    if (summarySuccess && summaryData?.data) {
      const {
        summary: { content },
        tags,
        inferredTags,
      } = summaryData.data as SummaryDataType;

      setSummary(content);
      setTags(tags!);
      setInferredTags(inferredTags!);
    }
  }, [summarySuccess, summaryData]);

  useEffect(() => {
    if (threadsSuccess && threadsData?.data?.data) {
      console.log("Threads generated successfully!");
      renderNotifier(messages.summarySuccessfullyGenerated, {
        variant: "success",
      });
      handleOnClose(false);
    }
    setGenerateThreadsReady(false);
  }, [threadsSuccess, threadsData, handleOnClose]);

  useEffect(() => {
    if (editable) {
      inputRef.current?.focus();
    }
  }, [editable]);

  useEffect(() => {
    if (closeChatLoading) {
      setLoadingType(LoadingEnum.CLOSE);
    } else if (summaryLoading) {
      setLoadingType(LoadingEnum.SUMMARY);
    } else if (threadsLoading) {
      setLoadingType(LoadingEnum.THREADS);
    } else {
      setLoadingType(null);
    }
  }, [closeChatLoading, summaryLoading, threadsLoading]);

  useEffect(() => {
    if (closeChatError) {
      renderNotifier(errors.errorClosingTheChat, {
        variant: "error",
      });
      handleOnClose(false);
    } else if (summaryError) {
      renderNotifier(errors.errorGeneratingTheSummary, {
        variant: "error",
      });
      handleOnClose(false);
    } else if (threadsError) {
      renderNotifier(errors.errorGeneratingTheThreads, {
        variant: "error",
      });
      handleOnClose(false);
    }
  }, [closeChatError, summaryError, threadsError, handleOnClose]);

  const handleGenerateThreads = () => {
    setGenerateThreadsReady(true);
    const tagsIds = inferredTags.map((tag) => tag.id!);
    refetchThreads({ summary, tags: tagsIds, chatId: currentChatId });
  };

  return (
    <Modal
      open={open}
      title="Resumo atendimento"
      width="600px"
      minHeight="470px"
      handleOnClose={
        threadsSuccess || threadsError ? () => handleOnClose(false) : undefined
      }
    >
      {loadingType ? (
        <Loading type={loadingType} />
      ) : (
        <>
          {!closeChatError && (
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <TextField
                value={summary}
                inputRef={inputRef}
                onChange={(e) => setSummary(e.target.value)}
                variant="outlined"
                label={editable ? "Resumo" : ""}
                multiline
                maxRows={7}
                disabled={!editable}
                sx={{
                  width: "100%",
                  padding: "0 10px 0 0",
                  "& fieldset": {
                    border: `${editable ? "1px solid" : "none"}`,
                  },
                  background: "transparent",
                  outline: "none",
                  overflow: "auto",
                }}
                InputProps={{
                  sx: { lineHeight: "26px" },
                }}
              />
              {tags.length > 0 && (
                <SelectTags
                  tags={tags}
                  inferredTags={inferredTags}
                  handleOnChange={setInferredTags}
                  disabled={threadsSuccess}
                />
              )}
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <>
                  {!threadsSuccess ? (
                    <BottomBar
                      summary={summary}
                      editable={editable}
                      threadsLoading={threadsLoading}
                      generateThreadsReady={generateThreadsReady}
                      setEditable={setEditable}
                      handleOnSave={handleGenerateThreads}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      disabled={
                        generateThreadsReady || threadsLoading || summary === ""
                      }
                      sx={{
                        width: "140px",
                      }}
                      onClick={() => handleOnClose(false)}
                    >
                      Voltar
                    </Button>
                  )}
                </>
              </Box>
            </Box>
          )}
        </>
      )}
    </Modal>
  );
};
