import { Box, Typography } from "@mui/material";

type FormSectionHeaderProps = {
  header: string;
};

export const FormSectionHeader = ({ header }: FormSectionHeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography variant="h6">{header}</Typography>
    </Box>
  );
};
