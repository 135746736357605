import { Box, CircularProgress, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ConversationItem } from "../ConversationItem/ConversationItem";
import { useAppSelector } from "../../../../store";
import { useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { AttendencesMenu } from "./components/AttendencesMenu";
import {
  filterByNameOrPhoneNumber,
  getPhoneNumber,
  getStatus,
} from "./conversationListHelpers";
import { useGetConversations } from "../../hooks/useGetConversations";
import { TAttendenceFilterNames } from "../../hooks/useGetAttendenceFilters";
import { TConversation } from "../../slices/conversationSlice";

export const ConversationList = () => {
  const conversation = useAppSelector((state) => state.conversation);
  const [searchParams] = useSearchParams();
  const attendenceMenuParam = searchParams.get("attendencesMenu") || "";

  const [searchFieldValue, setSearchFieldValue] = useState("");

  const { search } = useLocation();

  const params = useParams();
  const currentChatId = params?.id || "";

  const conversationListQuery = useGetConversations();

  const oChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFieldValue(e.target.value);
  };

  const hasConversationFilterAttendenceMenu = (item: TConversation) =>
    item?.currentFilters?.includes(attendenceMenuParam);

  const filteredConversationList = conversation.conversationList.filter(
    (item) => {
      return (
        filterByNameOrPhoneNumber(item, searchFieldValue) &&
        (hasConversationFilterAttendenceMenu(item) ||
          /**
           * Refactor "Encerradas" menu to use status_closed on filter, instead of status
           * so we can remove this condition
           */
          (attendenceMenuParam === "status" &&
            item.currentFilters?.includes(TAttendenceFilterNames.statusClosed)))
      );
    }
  );

  return (
    <Box
      sx={{
        padding: "20px 0",
        backgroundColor: (theme) => theme.palette.grey.A200,
        borderRadius: "32px",
        height: "100%",
        overflow: "hidden",
        maxWidth: "420px",
        maxHeight: "92%",
      }}
    >
      <Box p={1}>
        <AttendencesMenu
          counters={conversationListQuery.data?.data?.meta?.counters}
          isLoading={conversationListQuery.isLoading}
        />
        <TextField
          value={searchFieldValue}
          onChange={oChangeSearch}
          placeholder="Busque ou comece uma conversa"
          InputProps={{
            endAdornment: (
              <SearchIcon
                sx={{
                  color: "secondary.contrastText",
                  fontSize: "20px",
                }}
              />
            ),
          }}
          sx={{
            "& ::placeholder": {
              fontSize: "12px",
              color: "secondary.contrastText",
            },
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          maxHeight: "85%",
          overflowY: "auto",
          paddingBottom: "100%",
          paddingX: 1,
        }}
      >
        {conversationListQuery.isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress data-testid="conversation-loading" />
          </Box>
        ) : (
          filteredConversationList.map((conversation, index) => {
            return (
              <Box
                mb={2}
                key={index}
                component={Link}
                to={`/chat/${conversation?.chatData?.id || ""}${search}`}
                data-testid="conversation-list--item"
              >
                <ConversationItem
                  name={conversation?.chatData?.fullName}
                  phoneNumber={getPhoneNumber(conversation)}
                  time={
                    conversation?.chatData?.formattedLastLifeMessageAt || ""
                  }
                  status={getStatus(conversation)}
                  active={conversation?.chatData?.id === currentChatId}
                  hasUnreadMessages={conversation?.chatData?.hasUnreadMessages}
                />
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
};
