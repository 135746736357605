import { useQuery } from "@tanstack/react-query";
import { TFetchTags, fetchTags } from "../../ChatView/hooks/useChat";

export const useGetAllTagsQuery = ({ entity }: TFetchTags) => {
  return useQuery({
    queryKey: ["tags", entity],
    queryFn: () => fetchTags({ entity }),
    staleTime: Infinity,
    gcTime: Infinity,
    networkMode: "offlineFirst",
  });
};
