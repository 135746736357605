import { useEffect, useRef, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { DEFAULT_BORDER_RADIUS } from "../../../../../../theme/design-tokens";

type TChatSummaryProps = {
  summary: string;
};
export const ChatSummary = (props: TChatSummaryProps) => {
  const { summary = "" } = props;
  const [showMore, setShowMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const summaryRef = useRef<HTMLSpanElement>(null);

  const summaryMaxHeight = 4.5;

  useEffect(() => {
    const element = summaryRef.current;
    if (element) {
      const maxHeight =
        summaryMaxHeight * parseFloat(getComputedStyle(element).lineHeight);
      if (element.scrollHeight > maxHeight) {
        setIsOverflowing(true);
      }
    }
  }, [summary]);

  const handleShowMore:
    | React.MouseEventHandler<HTMLAnchorElement>
    | undefined = (event) => {
    event.preventDefault();
    setShowMore(!showMore);
  };

  return (
    <Box
      data-testid="chat-summary"
      sx={{
        backgroundColor: "#F7E4C1",
        paddingY: 2,
        paddingX: 3,
        borderRadius: DEFAULT_BORDER_RADIUS,
        marginY: 2,
      }}
    >
      <Typography variant="body3">Resumo</Typography>
      <Typography
        ref={summaryRef}
        variant="body2"
        sx={{
          position: "relative",
          lineHeight: "1.5em",
          maxHeight: showMore ? "none" : `${summaryMaxHeight}em`,
          overflow: "hidden",
        }}
      >
        {props.summary}
      </Typography>
      {isOverflowing && (
        <Link
          onClick={handleShowMore}
          sx={{ display: "block", cursor: "pointer", mt: 0.5 }}
        >
          {showMore ? "Ver menos" : "Ver mais"}
        </Link>
      )}
    </Box>
  );
};
