import { Theme as MUITheme } from "@mui/material";
import { createTheme } from "@mui/material/styles";

import { muiThemeColors } from "./colors";
import { themeNodes } from "./themeNodes";

export type Theme = MUITheme;

export const colors = muiThemeColors;

export const customTheme = createTheme(themeNodes);
