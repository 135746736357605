import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import {
  EMessageDirection,
  MessageStatus,
  TMessageUIEvent,
} from "../../../../slices/conversationSlice";
import { getInitialNameLetter } from "../../../../utils/chatUtils";
import { MessageDataType, MessageEvenType } from "../../../../types";
import { customColors } from "../../../../../../theme/colors";
import { BalonContent } from "../BalonContent";
import { MessageStatusIcon } from "./MessageStatusIcon";
import { MessageErrorText } from "./MessageErrorText";

type TChatBalonProps = {
  message: string;
  direction: EMessageDirection;
  senderName: string;
  eventType: MessageEvenType;
  time: string;
  messageStatus: MessageStatus;
  errorCode?: string;
  errorMessage?: string;
  originalMessage: TMessageUIEvent["originalMessage"];
};

export const ChatBalon = (props: TChatBalonProps) => {
  const initialNameLetter = getInitialNameLetter(props.senderName);
  // console.log(initialNameLetter, props.senderName, props);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        marginBottom: (theme) => theme.spacing(1),
      }}
    >
      {props.direction === EMessageDirection.receiving && (
        <Tooltip title={props.senderName}>
          <Avatar
            sx={{
              backgroundColor: "#292929",
            }}
          >
            {initialNameLetter}
          </Avatar>
        </Tooltip>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          ...(props.direction === EMessageDirection.sending
            ? { alignItems: "flex-end" }
            : { alignItems: "flex-start" }),
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            padding: "8px 16px",
            borderRadius: "32px",
            width: "auto",
            minWidth: "120px",
            maxWidth: "550px",
            border: `1px solid ${customColors.Neutral05}`,
            backgroundColor:
              props.direction === EMessageDirection.sending
                ? (theme) => theme.palette.grey.A200
                : "transparent",
            ...(props.direction === EMessageDirection.sending
              ? { marginLeft: "auto" }
              : { marginRight: "auto" }),
          }}
        >
          <Box>
            <BalonContent
              direction={props.direction}
              messageData={props.originalMessage?.data as MessageDataType}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              minWidth: "99px",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: 0.5,
            }}
          >
            {props.time && (
              <Typography variant="caption" color="primary.neutral">
                {" " + props.time}
              </Typography>
            )}
            <Box ml={0.5}>
              <MessageStatusIcon messageStatus={props.messageStatus} />
            </Box>
          </Box>
        </Box>
        {props.errorCode && (
          <Box sx={{ mt: 0.5, maxWidth: "50%", textAlign: "right" }}>
            <MessageErrorText
              errorCode={props.errorCode}
              errorMessage={props.errorMessage}
            />
          </Box>
        )}
      </Box>
      {props.direction === EMessageDirection.sending && (
        <Tooltip title={props.senderName}>
          <Avatar
            sx={{
              backgroundColor: "common.neutral08",
              marginRight: (theme) => theme.spacing(1),
              marginBottom: (theme) => theme.spacing(1),
            }}
          >
            {initialNameLetter}
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};
