import { useState } from "react";
import { FileTypes } from "../../screens/Chat/types";
import { FileCardData } from ".";

const transformFilesToFileCardData = (files: File[]): FileCardData[] => {
  return files.map((file, index) => ({
    name: file.name,
    type: file.type as FileTypes,
    uuid: `${index}`,
    isRemovable: true,
  }));
};

export const useFileCardHandlers = () => {
  const [files, setFiles] = useState<File[]>([]);

  const handleOnSelectFiles = (fileList: FileList) => {
    setFiles(Array.from(fileList));
  };

  const filesCardData = transformFilesToFileCardData(files);

  return {
    files,
    filesCardData,
    handleOnSelectFiles,
  };
};
