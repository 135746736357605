import {
  Check,
  DoneAll,
  AccessTime,
  SvgIconComponent,
  ErrorOutline,
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import { MessageStatus } from "../../../../slices/conversationSlice";
import { muiThemeColors } from "../../../../../../theme/colors";

type TMessageStatusProps = {
  messageStatus: MessageStatus;
};

export const MessageStatusIcon = (props: TMessageStatusProps) => {
  const checkComponent = {
    sending: AccessTime,
    sent: Check,
    read: DoneAll,
    delivered: DoneAll,
    failed: ErrorOutline,
  }[props.messageStatus] as SvgIconComponent;

  const color = {
    sending: muiThemeColors.primary.neutral,
    sent: muiThemeColors.primary.neutral,
    read: muiThemeColors.success.main,
    failed: muiThemeColors.error.main,
    delivered: muiThemeColors.primary.neutral,
  }[props.messageStatus];

  if (!checkComponent) {
    return null;
  }

  return <Icon component={checkComponent} sx={{ color, fontSize: "20px" }} />;
};
