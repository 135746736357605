import { SnackbarKey, useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import IconClose from "@mui/icons-material/Close";

type TSnackbarCloseButtonProps = {
  snackbarKey: SnackbarKey;
};

export const SnackbarCloseButton = (props: TSnackbarCloseButtonProps) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(props.snackbarKey)}>
      <IconClose
        sx={{ color: (theme) => theme.palette.primary.contrastText }}
      />
    </IconButton>
  );
};
