import { Controller, UseFormReturn } from "react-hook-form";
import { CustomDatePicker } from "../../../../../components/CustomDatePicker";
import { TFilterDocumentsForm } from "../../DocumentList";

type TStartedAtFilterProps = UseFormReturn<TFilterDocumentsForm>;

export const StartedAtFilter = (props: TStartedAtFilterProps) => {
  return (
    <Controller
      control={props.control}
      name="startedAt"
      rules={{ required: true }}
      render={({ field }) => {
        return (
          <CustomDatePicker
            slotProps={{ field: { clearable: true } }}
            timezone="America/Sao_Paulo"
            label="Recebido de"
            defaultValue={field.value}
            value={field.value}
            inputRef={field.ref}
            onChange={(date) => {
              field.onChange(date);

              if (props.getValues("endedAt")) props.trigger("endedAt");
            }}
            errorMessage={props.formState.errors?.startedAt?.message}
          />
        );
      }}
    />
  );
};
