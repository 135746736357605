import React, { createContext, useState, useContext } from "react";
import { LivesType } from "../../screens/Registration/types";

type ContextType = {
  lives: LivesType[];
  setLives: React.Dispatch<React.SetStateAction<LivesType[]>>;
  resetLivesContext: () => void;
};

const LivesContext = createContext<ContextType | undefined>(undefined);

export const LivesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [lives, setLives] = useState<LivesType[]>([]);

  const resetLivesContext = () => {
    setLives([]);
  };

  return (
    <LivesContext.Provider
      value={{
        lives,
        setLives,
        resetLivesContext,
      }}
    >
      {children}
    </LivesContext.Provider>
  );
};

export const useLivesContext = () => {
  const context = useContext(LivesContext);
  if (!context) {
    throw new Error(
      "useLivesContext must be used within a LivesContextProvider"
    );
  }
  return context;
};
