import { Box } from "@mui/material";

type TUnreadBadgeProps = {
  active?: boolean;
};

export const UnreadBadge = (props: TUnreadBadgeProps) => (
  <Box
    sx={{
      width: "14px",
      height: "14px",
      borderRadius: "50%",
      backgroundColor: "secondary.main",
      position: "absolute",
      bottom: "-3px",
      border: "2px solid",
      borderColor: () => (props.active ? "primary.main" : "common.box"),
      right: "-2px",
      zIndex: 1,
    }}
  />
);
