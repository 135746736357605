import { Box, Button } from "@mui/material";
import { CreateTaskDialog } from "./CreateTaskDialog";

export const TasksActions = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button variant="contained" color="primary">
        Criar tarefa
      </Button>
      <CreateTaskDialog />
    </Box>
  );
};
