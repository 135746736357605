import { useEffect, useState } from "react";
import { SelectTags } from "../../Chat/components/ChatView/components/CloseChatModal/components";
import { TagsType } from "../../Chat/types";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material";
import { useToggleTagMutation } from "../../Chat/components/LifeSummary/lifeApi/useToggleTagMutation";
import { useGetAllTagsQuery } from "../../Chat/components/LifeSummary/lifeApi/useGetAllTagsQuery";

type TAddAttachmentTagProps = {
  inferredTags: TagsType[];
  attachmentId?: string;
};

export const AddAttachmentTag = (props: TAddAttachmentTagProps) => {
  const [currentTags, setCurrentTags] = useState<TagsType[]>([]);

  useEffect(() => {
    setCurrentTags(props.inferredTags || []);
  }, [props.inferredTags]);

  const allTagsQuery = useGetAllTagsQuery({ entity: "ATTACHMENT" });

  const toggleTagOnLifeMutation = useToggleTagMutation({
    endpoint: "/api/v1/attachments",
    entityId: props.attachmentId || "",
  });

  const handleTagChange = async (
    _: TagsType[],
    reason: AutocompleteChangeReason,
    option: AutocompleteChangeDetails<TagsType> | undefined
  ) => {
    const res = await toggleTagOnLifeMutation.mutateAsync({
      reason,
      data: {
        type: "tag-items",
        attributes: {
          tag_id: option?.option?.id || "",
        },
      },
    });

    if (reason === "selectOption") {
      setCurrentTags((curr) => curr.concat(res?.data?.data || []));
    } else {
      setCurrentTags((curr) =>
        curr.filter((tag) => tag?.id !== option?.option?.id)
      );
    }
  };

  return (
    <SelectTags
      fullWidth
      freeSolo
      inferredTags={props.inferredTags}
      value={currentTags}
      disabled={toggleTagOnLifeMutation.isPending}
      tags={allTagsQuery?.data || []}
      handleOnChange={handleTagChange}
    />
  );
};
