import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../../../../api";

export type SystemUser = {
  id: string;
  type: "users";
  attributes: {
    firebase_id: string;
    name: string;
  };
};

export const useGetSystemUsers = () => {
  return useQuery({
    queryKey: ["system-users"],
    queryFn: () =>
      api.get<{ data: SystemUser[] }>("/api/v1/users", {
        params: {
          limit: 100,
        },
      }),
  });
};
