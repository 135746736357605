import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useGetAppointments } from "../../hooks/useGetAppointmentsQuery";
import { useParams } from "react-router-dom";
import { ChipTag } from "../../../../components/ChipTag";
import { customColors } from "../../../../theme/colors";
import { formatDate, getIsToday } from "../../../Chat/utils/dateUtils";
import { useState } from "react";
import { AppointmentResource } from "../../timelineTypes";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TimelineScrollList } from "../TimelineScrollList";
import { CancelAppointmentDialog } from "../CancelAppointmentDialog";

import { useCancelHandlers } from "./hooks/useCancelHandlers";
import { ConsultationIcon } from "./ConsultationListIcon";
import { ConsultationActions } from "../ConsultationActions";
import { useEncounterHandlers } from "./hooks/useEncounterHandlers";
import { CustomFileInput } from "../../../../components/CustomFileInput";

import { FileCardList } from "../../../../components/FileCardList";
import { useFileCardHandlers } from "../../../../components/FileCard/useFileCardHandlers";
import { FileCardData } from "../../../../components/FileCard";
import { NoShowDialog } from "../NoShowDialog";
import { CreateEncounterConfirmDialog } from "../CreateEncounterConfirmDialog";

export const ConsultationList = () => {
  const [isActiveRow, setIsActiveRow] = useState<boolean[]>([]);

  const [isAppointmentsExpanded, setIsAppointmentsExpanded] = useState(false);
  const params = useParams();
  const lifeId = params?.lifeId || "";
  const appointmentsResults = useGetAppointments({ lifeId });

  const {
    setOpenedAppointment,
    handleEncounterChange,
    encounterText,
    handleSaveEncounter,
    createEncounterMutation,
    createEncounterDocumentsMutation,
    handleOpenCreateEncounterDialog,
    createEncounterConfirmDialogOpen,
    closeCreateEncounterDialog,
    notShowCreateEncounterConfirmDialogAgain,
  } = useEncounterHandlers({ lifeId });

  const {
    handleCancelClick,
    handleProceedCancel,
    isCancelDialogOpen,
    cancelAppointmentMutation,
    handleNoShowClick,
    isNoShowDialogOpen,
  } = useCancelHandlers({
    lifeId,
  });

  const appointmentsData = appointmentsResults.data?.data?.data;
  const filteredAppointments = appointmentsData
    ?.filter((item) => item?.attributes?.status !== "CANCELLED")
    .sort((a, b) => {
      return (
        new Date(a?.attributes?.start || "").getTime() -
        new Date(b?.attributes?.start || "").getTime()
      );
    });

  const handleRowClick = (index: number, appointment?: AppointmentResource) => {
    setIsActiveRow((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      // reset textfield input if row is closed
      if (newState[index] === false) {
        handleEncounterChange("", index);
      }
      setOpenedAppointment(appointment);
      return newState;
    });
  };

  const handoeOnClickJoinCall = (appointment: AppointmentResource) => {
    const virtualService =
      appointment?.attributes?.virtual_service?.address_url;
    if (virtualService) {
      window.open(virtualService, "_blank");
    }
  };

  const handleOnClickOpenCopilot = () => {
    window.open("https://copiloto.carecode.com.br/", "_blank");
  };

  const handleExpandAppointments = () => {
    setIsAppointmentsExpanded((prev) => !prev);
  };

  const { files, filesCardData, handleOnSelectFiles } = useFileCardHandlers();

  const handleOnChangeFiles = (removingFiles: FileCardData[]) => {
    const filteredFiles = files.filter(
      (file) =>
        file?.name ===
        removingFiles?.find((item) => file?.name === item?.name)?.name
    );
    const dataTransfer = new DataTransfer();

    filteredFiles.forEach((file) => {
      dataTransfer.items.add(file);
    });

    handleOnSelectFiles(dataTransfer.files);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          mb: 1,
          gap: 1,
        }}
        onClick={handleExpandAppointments}
      >
        <Box mr={1}>
          <Typography variant="caption">AGENDAMENTOS</Typography>
        </Box>
        <Badge
          data-testid="appointments-counter-badge"
          badgeContent={filteredAppointments?.length || "0"}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: customColors.Support01,
              color: customColors.White,
            },
          }}
        />
        {appointmentsResults?.isLoading ? (
          <CircularProgress
            size={12}
            sx={{ ml: 1 }}
            data-testid="appointments-loading"
          />
        ) : isAppointmentsExpanded ? (
          <ExpandLessIcon />
        ) : (
          <ExpandMoreIcon />
        )}
      </Box>
      <TimelineScrollList>
        {isAppointmentsExpanded && (
          <Box sx={{ padding: 1 }}>
            {filteredAppointments?.map((appointment, index) => {
              const isLastIndex = index === filteredAppointments.length - 1;
              return (
                <Box mb={2} key={index}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ConsultationIcon />
                    <Box>
                      <Typography
                        variant="body3"
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        Teleconsulta
                        {getIsToday(appointment?.attributes?.start || "") && (
                          <ChipTag
                            bgColor={customColors.Support01}
                            label="Hoje"
                            size="small"
                          />
                        )}
                      </Typography>
                    </Box>
                    <Box marginLeft="auto" minHeight="31px">
                      {isActiveRow[index] && (
                        <ConsultationActions
                          onClickJoinCall={() =>
                            handoeOnClickJoinCall(appointment)
                          }
                          onClickOpenCopilot={handleOnClickOpenCopilot}
                          onClickCancelAppointment={() =>
                            handleCancelClick(index)
                          }
                          onClickNoShow={() => handleNoShowClick(index)}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box pb={1} width="100%">
                    <Box>
                      <Box>
                        <Typography variant="button" fontWeight={"bold"}>
                          Consulta em:{" "}
                        </Typography>
                        <Typography variant="button">
                          {formatDate(appointment?.attributes?.start || "")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="button" fontWeight={"bold"}>
                          Motivo da consulta:{" "}
                        </Typography>
                        <Typography variant="button">
                          {appointment?.attributes?.reason}
                        </Typography>
                      </Box>
                      {isActiveRow[index] && (
                        <Box mt={2}>
                          <TextField
                            multiline
                            rows={2}
                            fullWidth
                            variant="outlined"
                            name="encounter-textfield"
                            role="input"
                            placeholder="Descreva a consulta/evolução médica"
                            value={encounterText[index]}
                            onChange={(event) =>
                              handleEncounterChange(event?.target?.value, index)
                            }
                          />
                          <Box my={2}>
                            <FileCardList
                              files={filesCardData}
                              onChangeFiles={handleOnChangeFiles}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              mt: 1,
                              justifyContent: "flex-end",
                              gap: 1,
                            }}
                          >
                            <CustomFileInput
                              handleOnSelectFiles={handleOnSelectFiles}
                            >
                              <Button
                                variant="outlined"
                                disabled={
                                  createEncounterDocumentsMutation?.isPending ||
                                  createEncounterMutation?.isPending
                                }
                                startIcon={<AttachFileIcon />}
                                endIcon={
                                  createEncounterDocumentsMutation?.isPending && (
                                    <CircularProgress
                                      size={20}
                                      data-testid="create-encounter-documents-loading"
                                    />
                                  )
                                }
                              >
                                Anexar arquivos
                              </Button>
                            </CustomFileInput>
                            <Button
                              variant="contained"
                              disabled={createEncounterMutation?.isPending}
                              onClick={() =>
                                notShowCreateEncounterConfirmDialogAgain
                                  ? handleSaveEncounter({
                                      appointment,
                                      attachments: files,
                                      index,
                                      successCallback: () =>
                                        handleRowClick(index, appointment),
                                    })
                                  : handleOpenCreateEncounterDialog(index)
                              }
                              endIcon={
                                createEncounterMutation?.isPending && (
                                  <CircularProgress
                                    size={20}
                                    data-testid="create-encounter-loading"
                                  />
                                )
                              }
                            >
                              Salvar prontuário
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box
                      onClick={() => handleRowClick(index, appointment)}
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        cursor: "pointer",
                      }}
                    >
                      <Link fontSize={"small"}>
                        {isActiveRow[index] ? "Ver menos" : "Ver mais"}
                      </Link>
                    </Box>
                  </Box>
                  {!isLastIndex && <Divider />}
                  {!notShowCreateEncounterConfirmDialogAgain &&
                    createEncounterConfirmDialogOpen[index] && (
                      <CreateEncounterConfirmDialog
                        dialogProps={{
                          open: createEncounterConfirmDialogOpen[index],
                          onClose: () => closeCreateEncounterDialog(index),
                          fullWidth: true,
                        }}
                        onClickBack={() => closeCreateEncounterDialog(index)}
                        onClickProceed={() => {
                          closeCreateEncounterDialog(index);
                          handleSaveEncounter({
                            appointment,
                            attachments: files,
                            index,
                            successCallback: () =>
                              handleRowClick(index, appointment),
                          });
                        }}
                        isLoading={createEncounterMutation.isPending}
                      />
                    )}
                  {isCancelDialogOpen[index] && (
                    <CancelAppointmentDialog
                      dialogProps={{
                        open: isCancelDialogOpen[index],
                        onClose: () => handleCancelClick(index),
                        fullWidth: true,
                      }}
                      onClickBack={() => handleCancelClick(index)}
                      onClickProceed={(reasons) =>
                        handleProceedCancel({
                          appointment,
                          index,
                          onCancelledCallback: () => handleRowClick(index),
                          reasons,
                          status: "CANCELLED",
                        })
                      }
                      isCancelLoading={cancelAppointmentMutation.isPending}
                    />
                  )}
                  {isNoShowDialogOpen[index] && (
                    <NoShowDialog
                      dialogProps={{
                        open: isNoShowDialogOpen[index],
                        onClose: () => handleNoShowClick(index),
                        fullWidth: true,
                      }}
                      onClickBack={() => handleNoShowClick(index)}
                      onClickProceed={() => {
                        handleProceedCancel({
                          appointment,
                          index,
                          onCancelledCallback: () => {},
                          reasons: [],
                          status: "NOSHOW",
                        });
                      }}
                      isCancelLoading={cancelAppointmentMutation.isPending}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </TimelineScrollList>
    </Box>
  );
};
