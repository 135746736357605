import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { customColors } from "../../theme/colors";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { FileTypes } from "../../screens/Chat/types";
import { useAuthenticateMedia } from "../../screens/Chat/components/ChatView/hooks/useAuthenticateMedia";
import { Link } from "react-router-dom";

const ContainerStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "260px",
  height: "64px",
  gap: "8px",
  borderRadius: "32px",
  padding: "0 16px",
  backgroundColor: customColors.BoxBackgroundColor,
};

const FileIconStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "32px",
  height: "32px",
  borderRadius: "8px",
  backgroundColor: customColors.PurpleLight,
};

const fullWidthStyles = { width: "100%" };

const largeContainerStyles = {
  height: "auto",
  paddingY: 3,
};

const largeFileIconStyles = { width: "56px", height: "56px" };

export type FileCardData = {
  name: string;
  type: FileTypes;
  isLoading?: boolean;
  uuid: string;
  subtitle?: string;
  size?: "small" | "medium" | "large";
  documentLink?: string;
  isRemovable?: boolean;
};

type FileCardProps = FileCardData & {
  handleOnRemove?: (uuid: string) => void;
  fullWidth?: boolean;
  children?: React.ReactNode;
};

export const FileCard = ({
  name,
  type,
  isLoading,
  uuid,
  handleOnRemove,
  fullWidth,
  subtitle,
  size = "small",
  documentLink = "",
  children,
}: FileCardProps) => {
  const authenticateMediaResponse = useAuthenticateMedia({
    url: documentLink,
  });

  const currentIsLoading = isLoading || authenticateMediaResponse.isLoading;

  const getIconByType = (type: string) => {
    switch (type) {
      case "image/gif":
      case "image/jpeg":
      case "image/jpg":
      case "image/png":
      case "image/webp":
        return (
          <ImageOutlinedIcon
            sx={{ color: customColors.White }}
            fontSize={size}
          />
        );
      case "application/pdf":
        return (
          <PictureAsPdfOutlinedIcon
            sx={{ color: customColors.White }}
            fontSize={size}
          />
        );
    }
  };

  const getFileExtensionByType = (type: string) => {
    return type.split("/")[1].toUpperCase();
  };

  return (
    <Box
      sx={[
        ContainerStyles,
        Boolean(fullWidth) && fullWidthStyles,
        Boolean(size === "large") && largeContainerStyles,
      ]}
    >
      <Box
        component={Link}
        to={`/media/?publicUrl=${encodeURIComponent(
          documentLink
        )}&mediaType=${encodeURIComponent(type)}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ display: "flex", gap: 1, alignItems: "center", width: "100%" }}
        data-testid={`attachment-icon-${type}`}
      >
        {currentIsLoading ? (
          <CircularProgress size={16} />
        ) : (
          <Box
            sx={[
              FileIconStyles,
              Boolean(size === "large") && largeFileIconStyles,
            ]}
          >
            {getIconByType(type)}
          </Box>
        )}

        <Box
          sx={[
            {
              display: "flex",
              flexDirection: "column",
              width: "160px",
              height: "40px",
            },
            Boolean(fullWidth) && fullWidthStyles,
          ]}
        >
          <Tooltip title={name} placement="top">
            <Typography
              variant={size === "large" ? "button" : "caption"}
              fontWeight="medium"
              noWrap
              width={"150px"}
            >
              {name}
            </Typography>
          </Tooltip>

          <Typography variant="caption">
            {subtitle || getFileExtensionByType(type)}
          </Typography>
        </Box>
      </Box>
      {children}
      {handleOnRemove && (
        <IconButton
          onClick={() => handleOnRemove(uuid)}
          sx={{ padding: "2px" }}
        >
          <CancelRoundedIcon sx={{ color: customColors.PrimaryNeutral }} />
        </IconButton>
      )}
    </Box>
  );
};
