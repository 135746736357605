import { Box } from "@mui/material";
import { ReactNode } from "react";

type TTimelineScrollListProps = {
  children: ReactNode;
};

export const TimelineScrollList = (props: TTimelineScrollListProps) => {
  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 350px)",
        overflowY: "auto",
      }}
    >
      {props.children}
    </Box>
  );
};
