import { useState } from "react";
import { FormGroupContainer } from "../../../../components/Form/FormGroupContainer";
import { FormSectionHeader } from "../../../../components/Form/FormSectionHeader";
import { UpdateSectionContainer } from "../UpdateSectionContainer";
import AddIcon from "@mui/icons-material/Add";
import { useRelativesContext } from "../../../../contexts/RegistrationContexts/RelativesContext";
import { Box, Button } from "@mui/material";
import RelativesCard from "../RelativesCard";
import { API_LIVES_GET_TYPE, KinShipEnum } from "../../types";
import { RelativesModal } from "../RelativesModal";

type HandleCreateRelativeProps = {
  life: API_LIVES_GET_TYPE;
  kinship: { life_kinship: string; relative_kinship: string };
};

export const NewLifeRelativesFormSection = () => {
  const [openRelativesModal, setOpenRelativesModal] = useState(false);
  const { newLifeRelatives: relatives, setNewLifeRelatives: setRelatives } =
    useRelativesContext();

  const handleRemoveRelativeCard = (relativeId: string) => {
    const currentRelatives = [...relatives];
    const relativeToRemove = relatives.find(
      (relative) => relative?.relative_id === relativeId
    );

    setRelatives([
      ...currentRelatives.filter(
        (relative) => relative?.relative_id !== relativeToRemove?.relative_id
      ),
    ]);
  };

  const handleCreateRelative = ({
    life,
    kinship,
  }: HandleCreateRelativeProps) => {
    const currentRelatives = [...relatives];
    const newRelative = {
      life_kinship: kinship.life_kinship as KinShipEnum,
      relative_kinship: kinship.relative_kinship as KinShipEnum,
      relative_id: life.id,
      first_name: life?.attributes?.first_name,
      last_name: life?.attributes?.last_name,
    };
    setRelatives([...currentRelatives, newRelative]);
    setOpenRelativesModal(false);
  };

  return (
    <FormGroupContainer formMode={"create"}>
      <FormSectionHeader header="Relação familiar" />
      <UpdateSectionContainer
        formMode={"create"}
        handleOnChangeMode={() => {}}
        loading={false}
        handleOnSave={() => {}}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: 2, marginBottom: "24px" }}>
            {relatives?.map((item, index) => (
              <RelativesCard
                key={index}
                kinship={item?.relative_kinship as KinShipEnum}
                relativeId={item.relative_id}
                fullName={`${item?.first_name || ""} ${item?.last_name || ""}`}
                handleOnRemove={(id: string) => handleRemoveRelativeCard(id)}
                isOpen={true}
                loadingUpdate={false}
              />
            ))}
          </Box>
        </Box>
        <RelativesModal
          open={openRelativesModal}
          handleCreateRelative={handleCreateRelative}
          handleOnClose={() => setOpenRelativesModal(false)}
        />
        <Button
          variant="outlined"
          onClick={() => setOpenRelativesModal(true)}
          sx={{
            width: "100%",
            height: "40px",
            margintTop: "10px",
            backgroundColor: "transparent",
          }}
          startIcon={
            <AddIcon
              sx={{ color: "secondary.contrastText" }}
              fontSize="large"
            />
          }
        >
          Vincular com outra vida
        </Button>
      </UpdateSectionContainer>
    </FormGroupContainer>
  );
};
