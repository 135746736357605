// import { WatchLaterOutlined } from "@mui/icons-material";
import { SvgIconComponent } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

type TScreenHeaderProps = {
  title: string;
  icon: SvgIconComponent;
};

export const ScreenHeader = (props: TScreenHeaderProps) => {
  const IconComponent = props.icon;
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        gap: 1,
        alignItems: "center",
        marginBottom: 3,
        marginLeft: 3,
      }}
    >
      <IconComponent sx={{ fontSize: "32px", color: "secondary.main" }} />

      <Typography variant="h3" color="secondary.main">
        {props.title}
      </Typography>
    </Box>
  );
};
