type CarecodeLogoProps = {
  width?: number;
};

export const CarecodeLogo = ({ width = 214 }: CarecodeLogoProps) => {
  const getHeight = () => String(width * (32 / 214));

  return (
    <svg
      width={width}
      height={getHeight()}
      viewBox="0 0 251 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2167_4298)">
        <path
          d="M20.9573 0.845703C9.40517 0.845703 0 10.2509 0 21.803C0 33.3551 9.40517 42.7602 20.9573 42.7602C32.5094 42.7602 41.9145 33.3551 41.9145 21.803C41.9145 10.2509 32.5176 0.845703 20.9573 0.845703ZM31.1139 29.2676C32.6828 29.7714 32.8149 31.9513 31.2707 32.5211C30.0321 32.9752 28.7192 33.2477 27.4393 33.2477C21.1307 33.2477 15.9946 28.1116 15.9946 21.803C15.9946 15.4943 21.1307 10.3582 27.4393 10.3582C28.7192 10.3582 30.0321 10.6307 31.2707 11.0849C32.7984 11.6464 32.6993 13.8346 31.1469 14.3218C30.8249 14.4209 30.5028 14.553 30.1973 14.7181C27.654 16.0558 25.92 18.7312 25.92 21.7947C25.92 24.8582 27.6623 27.5419 30.2055 28.8796C30.5028 29.0364 30.8001 29.1603 31.1139 29.2594V29.2676ZM38.851 22.3067C38.5951 24.8087 36.4812 26.7574 33.9131 26.7574C31.1799 26.7574 28.9504 24.5279 28.9504 21.7947C28.9504 19.0615 31.1799 16.832 33.9131 16.832C36.4812 16.832 38.5951 18.789 38.851 21.2828C38.851 21.4479 38.8758 21.6213 38.8758 21.7865C38.8758 21.9516 38.8593 22.125 38.851 22.2902V22.3067ZM3.03872 21.803C3.03872 11.9189 11.0732 3.88442 20.9573 3.88442C22.1546 3.88442 23.3849 4.04957 24.5988 4.33858C26.4071 4.76796 26.3741 7.39382 24.541 7.69934C23.3602 7.89752 22.1546 8.26084 20.9573 8.86363C16.2175 11.2418 12.9558 16.1549 12.9558 21.8112C12.9558 27.4675 16.2175 32.3724 20.949 34.7588C22.1546 35.3699 23.3767 35.7332 24.5575 35.9314C26.3906 36.2369 26.4237 38.838 24.6235 39.2756C23.4015 39.5729 22.1546 39.738 20.949 39.738C11.0649 39.738 3.03046 31.6953 3.03046 21.8195L3.03872 21.803Z"
          fill="#543250"
        />
        <path
          d="M167.361 12.9923C169.516 12.9923 171.589 13.7768 173.191 15.1971C173.265 15.2631 173.356 15.2961 173.447 15.2961C173.537 15.2961 173.628 15.2631 173.694 15.1971L176.84 12.051C176.915 11.9849 176.948 11.8858 176.948 11.7868C176.948 11.6877 176.906 11.6051 176.84 11.539C174.256 9.15265 170.895 7.83972 167.361 7.83972C163.084 7.83972 159.087 9.76369 156.412 13.1245C152.266 18.3184 152.39 25.7748 156.709 30.8448C159.376 33.9744 163.257 35.7745 167.369 35.7745C170.895 35.7745 174.264 34.4616 176.849 32.0752C176.915 32.0091 176.956 31.9265 176.956 31.8274C176.956 31.7284 176.923 31.6293 176.849 31.5632L173.703 28.4171C173.636 28.3511 173.546 28.3181 173.455 28.3181C173.364 28.3181 173.273 28.3511 173.199 28.4171C171.589 29.8374 169.524 30.6219 167.369 30.6219C164.892 30.6219 162.514 29.5732 160.846 27.74C157.815 24.4123 157.815 19.2102 160.846 15.8824C162.514 14.0493 164.892 13.0006 167.369 13.0006L167.361 12.9923Z"
          fill="#543250"
        />
        <path
          d="M190.762 7.83142C183.166 7.83142 177.055 14.0988 177.055 21.8029C177.055 29.5071 183.166 35.7744 190.762 35.7744C198.359 35.7744 204.47 29.5071 204.47 21.8029C204.47 14.0988 198.359 7.83142 190.762 7.83142ZM190.762 30.8778C185.791 30.8778 182.216 26.7409 182.216 21.8029C182.216 16.865 185.791 12.7281 190.762 12.7281C195.733 12.7281 199.309 16.865 199.309 21.8029C199.309 26.7409 195.733 30.8778 190.762 30.8778Z"
          fill="#543250"
        />
        <path
          d="M216.781 8.59114H208.293C207.583 8.59114 207.005 9.16916 207.005 9.8793V33.7267C207.005 34.4368 207.583 35.0148 208.293 35.0148H216.476C223.552 35.0148 229.655 29.2594 229.523 21.5718C229.399 14.2887 223.949 8.58289 216.781 8.58289V8.59114ZM224.296 22.2076C224.081 26.4932 220.877 29.92 216.558 29.92H213.016C212.545 29.92 212.174 29.5402 212.174 29.0777V14.5282C212.174 14.0575 212.554 13.686 213.016 13.686H216.79C220.126 13.686 224.585 16.4109 224.296 22.2158V22.2076Z"
          fill="#543250"
        />
        <path
          d="M238.432 13.6859H250.645C250.843 13.6859 251 13.529 251 13.3309V8.95443C251 8.75626 250.843 8.59937 250.645 8.59937H238.341C234.948 8.59937 232.198 11.3491 232.198 14.7429V28.8878C232.198 32.2816 234.948 35.0313 238.341 35.0313H250.645C250.843 35.0313 251 34.8744 251 34.6762V30.2998C251 30.1016 250.835 29.9447 250.645 29.9447H238.432C237.838 29.9447 237.359 29.4658 237.359 28.8713V25.3454C237.359 24.7508 237.838 24.2719 238.432 24.2719H248.581C248.779 24.2719 248.936 24.115 248.936 23.9168V19.5404C248.936 19.3422 248.779 19.1853 248.581 19.1853H238.432C237.838 19.1853 237.359 18.7064 237.359 18.1119V14.7842C237.359 14.1896 237.838 13.7107 238.432 13.7107V13.6859Z"
          fill="#543250"
        />
        <path
          d="M123.109 8.39294H115.818C110.922 8.39294 106.933 12.3813 106.933 17.2779V34.5193C106.933 34.8166 107.181 35.0643 107.478 35.0643H111.822C112.119 35.0643 112.367 34.8166 112.367 34.5193V17.1458C112.367 15.1888 113.96 13.5951 115.926 13.5951H123.118C123.415 13.5951 123.663 13.3474 123.663 13.0501V8.94619C123.663 8.64892 123.415 8.4012 123.118 8.4012L123.109 8.39294Z"
          fill="#543250"
        />
        <path
          d="M62.302 13.8593C62.6736 13.6694 63.0535 13.5125 63.4498 13.3969C64.3499 13.1244 65.2664 12.9923 66.1748 12.9923C68.3299 12.9923 70.4025 13.7767 72.0045 15.197C72.0788 15.2631 72.1696 15.2961 72.2605 15.2961C72.3513 15.2961 72.4421 15.2631 72.5082 15.197L75.6542 12.0509C75.7286 11.9849 75.7616 11.8858 75.7616 11.7867C75.7616 11.6876 75.7203 11.605 75.6542 11.539C73.0697 9.1526 69.7089 7.83142 66.1748 7.83142C62.1286 7.83142 58.4211 9.49941 55.7209 12.5381C50.9729 17.8724 51.0885 26.1381 55.9769 31.3567C58.644 34.2055 62.269 35.7744 66.1748 35.7744C69.7007 35.7744 73.0697 34.4615 75.6542 32.0751C75.7203 32.0091 75.7616 31.9265 75.7616 31.8274C75.7616 31.7283 75.7286 31.6292 75.6542 31.5632L72.5082 28.4171C72.4421 28.351 72.3595 28.318 72.2605 28.318C72.1614 28.318 72.0788 28.351 72.0045 28.4171C70.3943 29.8374 68.3217 30.6218 66.1748 30.6218C63.8214 30.6218 61.6084 29.7053 59.9404 28.0455C57.9586 26.0637 57.0338 23.2232 57.4715 20.4405C57.9091 17.6164 59.6762 15.2218 62.302 13.8676V13.8593Z"
          fill="#543250"
        />
        <path
          d="M137.502 7.83142C129.905 7.83142 124.323 14.0988 124.323 21.8029C124.323 29.9117 129.914 35.7744 137.502 35.7744C142.605 35.7744 146.478 33.3963 149.17 30.0438C149.31 29.8621 149.277 29.5979 149.087 29.4658L145.57 26.939C145.396 26.8152 145.165 26.8482 145.033 27.0051C143.348 28.9869 140.896 30.8613 137.502 30.8613C133.737 30.8613 131.078 28.863 129.839 25.783C129.682 25.3866 129.567 24.9325 129.567 24.6682C129.567 23.7021 130.194 23.6443 131.276 23.6443H150.045C150.334 23.6443 150.59 23.4214 150.615 23.1324C150.656 22.6947 150.681 22.2488 150.681 21.8029C150.681 14.0988 145.272 7.83142 137.502 7.83142ZM143.687 18.855H131.4C130.351 18.855 128.848 19.2184 130.739 16.5595C132.242 14.4456 134.389 12.7363 137.576 12.7363C140.764 12.7363 143.076 14.5282 144.562 16.7742C146.098 19.0945 144.983 18.8468 143.687 18.8468V18.855Z"
          fill="#543250"
        />
        <path
          d="M88.0156 35.7745C82.8299 35.7745 78.9159 32.5789 78.9159 27.8309C78.9159 23.8426 81.7482 19.0202 88.2881 19.0202C90.4598 19.0202 95.3812 18.9542 95.3812 18.9542C96.6611 18.9542 97.1813 18.1449 97.1813 17.3192C97.1813 13.9089 93.2012 13.0501 90.8726 13.0501C88.1972 13.0501 85.3567 14.0328 83.3171 15.742C83.2428 15.7998 83.1602 15.8329 83.0694 15.8329C82.9703 15.8329 82.8795 15.7916 82.8051 15.7173L79.7499 12.4308C79.6839 12.3565 79.6426 12.2574 79.6508 12.1583C79.6508 12.0592 79.7004 11.9767 79.7747 11.9106C82.7721 9.32605 86.9091 7.83972 90.8974 7.83972C95.9592 7.83972 102.408 10.2509 102.408 16.9229C102.408 20.2754 101.566 24.0407 95.5876 24.0407H88.4862C85.6292 24.0407 84.3245 25.6592 84.3245 27.5749C84.3245 29.3089 85.7365 30.8613 88.1725 30.8613C92.7305 30.8613 92.681 25.6344 97.6107 25.6344C99.4355 25.6344 102.78 26.7244 102.78 32.4633C102.78 33.4624 102.763 34.701 102.763 34.7093C102.763 34.9075 102.598 35.0643 102.4 35.0643H97.9822C97.8832 35.0643 97.7923 35.0231 97.7263 34.957C97.6602 34.8909 97.6189 34.8001 97.6189 34.701C97.6189 34.701 97.6354 32.1743 97.6189 31.6128C97.6189 31.2164 97.4207 30.9026 96.9996 30.9026C96.0335 30.9026 95.6041 35.7827 88.0156 35.7827V35.7745Z"
          fill="#543250"
        />
      </g>
      <defs>
        <clipPath id="clip0_2167_4298">
          <rect
            width="251"
            height="41.9145"
            fill="white"
            transform="translate(0 0.845703)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
