import "./App.css";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import { AuthContextProvider } from "./contexts/AuthContext";
import Wrapper from "./components/Wrapper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  fetchAndActivate,
  getRemoteConfig,
  getBoolean,
} from "firebase/remote-config";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { FEATURE_FLAGS } from "./utils/featureFlags";
import "./infra/sentry";
import { SocketContextProvider } from "./contexts/SocketContext";
import { store } from "./store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import ptBrLocale from "date-fns/locale/pt-BR";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: Infinity,
    },
  },
});

type FeatureFlagProps =
  | {
      [key: string]: boolean | string;
    }
  | undefined;

function App() {
  const [isAppReady, setIsAppReady] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagProps>();

  useEffect(() => {
    const remoteConfig = getRemoteConfig();
    fetchAndActivate(remoteConfig).finally(() => {
      const isLifeSummaryEnabled = getBoolean(
        remoteConfig,
        FEATURE_FLAGS.IS_LIFE_SUMMARY_ENABLED
      );
      const isTimelineEnabled = getBoolean(
        remoteConfig,
        FEATURE_FLAGS.IS_TIMELINE_ENABLED
      );
      setFeatureFlags({
        [FEATURE_FLAGS.IS_LIFE_SUMMARY_ENABLED]: isLifeSummaryEnabled,
        [FEATURE_FLAGS.IS_TIMELINE_ENABLED]: isTimelineEnabled,
      });

      setIsAppReady(true);
    });
  }, []);

  return isAppReady ? (
    <>
      <Provider store={store}>
        <AuthContextProvider value={{ featureFlags }}>
          <SocketContextProvider>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBrLocale}
              >
                <Wrapper>
                  <RouterProvider router={router} />
                </Wrapper>
              </LocalizationProvider>
            </QueryClientProvider>
          </SocketContextProvider>
        </AuthContextProvider>
      </Provider>
    </>
  ) : (
    <div className="flex items-center justify-center w-full h-full">
      <CircularProgress />
    </div>
  );
}

export default App;
