import axios from "axios";
import { getAuth } from "firebase/auth";

export const api = axios.create({
  baseURL: "https://carechat-gw-2xbsgeq5.ue.gateway.dev",
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      (error?.response?.status === 401 || error?.code === "ERR_NETWORK") &&
      !originalRequest?._retry
    ) {
      const auth = getAuth();
      const refreshedToken = await auth.currentUser?.getIdToken(true);

      originalRequest.headers["Authorization"] = `Bearer ${refreshedToken}`;
      originalRequest._retry = true;
      return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);

export const setAPIAuthToken = (token: string) => {
  api.defaults.headers["Authorization"] = `Bearer ${token}`;
};
