import { ReactElement } from "react";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import React from "react";

type TCustomTimelineSeparatorProps = {
  children: ReactElement;
};

export const CustomTimelineSeparator = (
  props: TCustomTimelineSeparatorProps
) => {
  return (
    <TimelineSeparator>
      <TimelineConnector />
      <TimelineDot
        sx={{ backgroundColor: "common.neutral03", boxShadow: "none" }}
      >
        {React.cloneElement(props.children, {
          fontSize: "small",
          sx: { color: "secondary.main" },
        })}
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
  );
};
