import { Box, Typography } from "@mui/material";
import { TMessage } from "../../../slices/conversationSlice";
import {
  getDateDiffInHours,
  renderChatDateLabel,
} from "../../../utils/dateUtils";
import { MessageDataType } from "../../../types";

type TChatDateProps = {
  message: TMessage;
  allMessages: TMessage[] | undefined;
};

export const ChatDate = (props: TChatDateProps) => {
  const previousMessage =
    props?.allMessages?.[props?.allMessages?.indexOf?.(props.message) - 1];

  const currentMessageData = props?.message?.originalMessage
    ?.data as MessageDataType;

  const previousCreatedAt = previousMessage?.originalMessage?.created_at || "";
  const currentCreatedAt = props?.message?.originalMessage?.created_at || "";

  const numberOfHours = getDateDiffInHours(currentCreatedAt, previousCreatedAt);

  if (numberOfHours > 16) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 6,
        }}
      >
        <Typography variant="body3" data-testid="chat-date">
          {renderChatDateLabel(currentMessageData?.attributes?.created_at)}
        </Typography>
      </Box>
    );
  }
  return null;
};
