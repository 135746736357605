export enum CommandsTypesEnum {
  SELECT_TEMPLATE = "select_template",
  CREATE_HEALTH_SURVEY = "create_health_survey",
  MERGE_LIFE = "merge_life",
}

export enum CommandsLabelsEnum {
  SELECT_TEMPLATE = "Selecionar o modelo de mensagem",
  CREATE_HEALTH_SURVEY = "Gerar link do questionário de saúde",
  MERGE_LIFE = "Vincular cadastro",
}

export const commands = [
  {
    label: CommandsLabelsEnum.SELECT_TEMPLATE,
    icon: "category_outlined_icon",
    onChooseCommand: CommandsTypesEnum.SELECT_TEMPLATE,
  },
  {
    label: CommandsLabelsEnum.CREATE_HEALTH_SURVEY,
    icon: "assignment_outlined_icon",
    onChooseCommand: CommandsTypesEnum.CREATE_HEALTH_SURVEY,
  },
  {
    label: CommandsLabelsEnum.MERGE_LIFE,
    icon: "merge_outlined_icon",
    onChooseCommand: CommandsTypesEnum.MERGE_LIFE,
  },
];
