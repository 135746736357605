import React, { useRef } from "react";
import { Box } from "@mui/material";

const ACCEPTED_TYPES = "application/pdf,image/*";

type CustomFileInputProps = {
  disabled?: boolean;
  handleOnSelectFiles: (fileList: FileList) => void;
  children: React.ReactNode;
};

export const CustomFileInput = ({
  handleOnSelectFiles,
  children,
}: CustomFileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    handleOnSelectFiles(files);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <Box>
      {React.cloneElement(children as React.ReactElement, {
        onClick: () => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        },
      })}
      <Box
        type="file"
        component="input"
        data-testid="custom-file-input"
        ref={inputRef}
        accept={ACCEPTED_TYPES}
        onChange={handleOnSelectFile}
        multiple
        sx={{ display: "none" }}
      />
    </Box>
  );
};
