import { useQuery } from "@tanstack/react-query";
import { api } from "../../../api";
import { Attachments } from "../../Chat/types";
import { getFilterObject } from "../helpers/chatTimelineHelpers";
import { TFilterParams } from "../helpers/chatTimelineHelpers";

type TUseGetAttachmentsParams = TFilterParams & {
  lifeId: string;
  enabled?: boolean;
};

export const useGetAttachments = (params?: TUseGetAttachmentsParams) => {
  const lifeId = params?.lifeId || "";

  const isEnabled = params?.enabled;

  return useQuery({
    queryKey: ["attachments", lifeId, params],
    queryFn: async () => {
      const response = await api.get<{ data: Attachments[] }>(
        "/api/v1/attachments",
        {
          params: {
            ...getFilterObject(params as TFilterParams, "attachmentList"),
            life_id: lifeId,
          },
        }
      );
      return response;
    },
    gcTime: 0,
    enabled: Boolean(lifeId && isEnabled),
  });
};
