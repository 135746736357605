import { Box, Typography } from "@mui/material";
import { TMediaComponentProps } from "../BalonContent";
import { FileMessageFooter } from "./FileMessageFooter";

export const DocumentMessage = (props: TMediaComponentProps) => {
  const url = props.publicUrl;
  const fileName = url?.split("/").pop() || "";

  return (
    <Box my={1}>
      <a
        href={props.signedMediaUrl}
        target="_blank"
        rel="noreferrer noreferrer"
      >
        <Typography variant="body2">Abrir documento 📑</Typography>
      </a>
      <FileMessageFooter fileName={fileName} fileType="application/pdf" />
    </Box>
  );
};
