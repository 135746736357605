import { TextField } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { TFilterDocumentsForm } from "../../DocumentList";
import { Search } from "@mui/icons-material";

export const FreeTextFilter = (props: UseFormReturn<TFilterDocumentsForm>) => {
  return (
    <TextField
      {...props.register("freeTextFilter")}
      placeholder="Busque por tema ou palavra chave"
      InputProps={{
        endAdornment: <Search sx={{ color: "common.neutral06" }} />,
      }}
    />
  );
};
