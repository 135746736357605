import { useQuery } from "@tanstack/react-query";
import { api } from "../../../api";

// type TemplateType = {
//   id: string;
//   type: string;
//   attributes: {
//     id: string;
//     name: string;
//     description: string;
//     content: string;
//   };
// };

// const mockedTemplates: TemplateType[] = [
//   {
//     id: "1",
//     type: "message-template",
//     attributes: {
//       id: "1",
//       name: "NPS para membros",
//       description: "Pesquisa de NPS para Membros",
//       content:
//         "Oi {{name}}, tudo bem?\r\n\r\nQueremos saber como tem sido sua experiência geral com a carecode nos últimos meses. \r\nSua opinião é crucial para continuarmos aprimorando nossos serviços!🤗\r\nClique no link abaixo para nos contar o que achou:{{1}}",
//     },
//   },
//   {
//     id: "2",
//     type: "message-template",
//     attributes: {
//       id: "2",
//       name: "Nota para último atendimento",
//       description: "Solicitação de nota do último atendimento",
//       content:
//         "Olá {{name}}, como vai?\r\n\r\nQueremos saber como foi o seu último atendimento com a carecode. \r\nSua opinião é crucial para continuarmos aprimorando nossos serviços!🤗\r\nClique no link abaixo para nos contar como foi:{{1}}",
//     },
//   },
//   {
//     id: "3",
//     type: "message-template",
//     attributes: {
//       id: "3",
//       name: "Acompanhamento de saúde",
//       description: "Template para acompanhamento de saúde",
//       content:
//         "Olá {{name}}, tudo bem?\r\n\r\nVejo que nesse momento você parece estar aguardando o {{exame}}. O que você acha de agendar uma teleconsulta com o nosso time para avaliarmos melhor a situação e entender se conseguimos te ajudar a antecipar a sua {{consulta}}?🤗\r\nClique no link abaixo para agendar a sua teleconsulta:{{1}}",
//     },
//   },
//   {
//     id: "4",
//     type: "message-template",
//     attributes: {
//       id: "4",
//       name: "Agendamento de consulta",
//       description: "Template para agendamento de consulta",
//       content:
//         "Olá {{name}}, tudo bem?\r\n\r\nVejo que nesse momento você parece estar aguardando para agendar uma consulta. O que você acha de agendar uma teleconsulta com o nosso time para avaliarmos melhor a situação? Clique no link abaixo para agendar uma {{consulta}}?",
//     },
//   },
// ];

const getTemplates = async () => {
  try {
    const response = await api.get("/api/v1/message-templates?limit=100");
    return response;
  } catch (err) {
    console.log("Error: ", err);
    throw new Error("Erro ao tentar recuperar os templates.");
  }
};

export const useGetTemplates = () => {
  return useQuery({
    queryKey: ["get-message-templates"],
    queryFn: getTemplates,
  });
};
