import { Box, Typography } from "@mui/material";
import Icon from "@mui/material/Icon";

type PageHeaderProps = {
  title: string;
  icon?: string;
};

const PageHeader = ({ title, icon }: PageHeaderProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: "16px",
        paddingTop: "16px",
      }}
    >
      {icon && (
        <Icon sx={{ fontSize: "32px", color: "secondary.main" }}>{icon}</Icon>
      )}
      <Typography variant="h3" color="secondary.main">
        {title}
      </Typography>
    </Box>
  );
};

export default PageHeader;
