import {
  Select,
  SelectChangeEvent,
  TextField,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { countries } from "../formUtils";
import { ChangeEvent, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { customColors } from "../../../theme/colors";
import { PhoneNumberType } from "../../../contexts/RegistrationContexts/GeneralDataContext";

type InputPhoneProps = {
  handleChangePhoneNumber: (id: number, phone: PhoneNumberType) => void;
  handleRemovePhoneNumber?: (id: number) => void;
  id: number;
  phoneNumber: PhoneNumberType;
  required?: boolean;
  formMode: "create" | "edit" | null;
};

const InputPhone = ({
  handleChangePhoneNumber,
  handleRemovePhoneNumber,
  id,
  phoneNumber,
  required = false,
  formMode,
}: InputPhoneProps) => {
  const [localPhoneNumber, setLocalPhoneNumber] = useState<string>("");
  const [internationalPhoneCode, setInternationalPhoneCode] =
    useState<string>("55");

  useEffect(() => {
    if (formMode === "edit") {
      if (localPhoneNumber?.length > 0 && localPhoneNumber?.length < 11) {
        return;
      }
      if (phoneNumber?.attributes.number?.length > 0) {
        setLocalPhoneNumber(phoneNumber?.attributes.number?.slice(-11));
      }
    } else {
      setLocalPhoneNumber(phoneNumber?.attributes.number.split("-")[1]);
    }
  }, [localPhoneNumber, phoneNumber, formMode]);

  useEffect(() => {
    if (formMode === "edit") {
      return;
    }
    if (phoneNumber?.attributes.number) {
      setLocalPhoneNumber(phoneNumber?.attributes.number.split("-")[1]);
    }
  }, [phoneNumber]);

  const handleInternationalPhoneChange = (e: SelectChangeEvent<string>) => {
    setInternationalPhoneCode(e.target.value);

    if (localPhoneNumber) {
      handleChangePhoneNumber(id, {
        id: phoneNumber.id,
        type: "phone_numbers",
        attributes: { number: `${e.target.value}${localPhoneNumber}` },
      });
    }
  };

  const handleLocalPhoneChange = (value: string) => {
    const localPhone = value.replace(/[^0-9]/g, "");

    setLocalPhoneNumber(localPhone);
    const countryCode = internationalPhoneCode.replace(/[^0-9]/g, "");
    if (localPhone) {
      handleChangePhoneNumber(id, {
        id: phoneNumber?.id,
        type: "phone_numbers",
        attributes: { number: `${countryCode}-${localPhone}` },
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: 2,
        width: "100%",
      }}
    >
      <Select
        id={uuid()}
        value={internationalPhoneCode}
        variant="outlined"
        sx={{
          width: "140px",
          textAlign: "center",
          textOverflow: "ellipsis",
        }}
        onChange={handleInternationalPhoneChange}
      >
        {countries.map((ddi) => (
          <MenuItem
            key={uuid()}
            value={ddi.dialCode}
            sx={{
              alignItems: "center",
              justifyContent: "flex-start",
              display: "flex",
              width: "auto",
            }}
          >
            {`${ddi.dialCode} - ${ddi.name}`}
          </MenuItem>
        ))}
      </Select>

      <TextField
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleLocalPhoneChange(e.target.value)
        }
        label="Telefone"
        variant="outlined"
        value={localPhoneNumber}
        required={required}
        sx={{ width: "230px" }}
      />

      {formMode === "edit" && handleRemovePhoneNumber && (
        <Box
          sx={{
            width: "40px",
            height: "40px",
          }}
        >
          <IconButton onClick={() => handleRemovePhoneNumber(id)}>
            <DeleteIcon
              fontSize="small"
              sx={{ color: customColors.PurpleMain }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default InputPhone;
