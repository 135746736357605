import { Box, Typography } from "@mui/material";
import { LifeSummaryAPIResponse } from "../../../types";
import { format } from "../../../utils/dateUtils";
import {
  TGender,
  TIdentityGender,
  getAddressType,
  getGender,
  getIdentityGender,
  getLifeFullName,
  getKinshipType,
} from "../../ConversationList/conversationListHelpers";

export const useComplementaryData = (
  lifeData: LifeSummaryAPIResponse["data"] | undefined
) => {
  return [
    // TODO: we dont have this info on BE yet
    // {
    //   title: "Estado civil:",
    //   value: "-",
    // },
    {
      title: "Sexo:",
      value:
        getGender(lifeData?.attributes?.gender as TGender) ||
        "Não providenciado",
    },
    {
      title: "Gênero:",
      value:
        getIdentityGender(
          lifeData?.attributes?.gender_identity as TIdentityGender
        ) || "Não providenciado",
    },
    {
      title: "Nome:",
      value: getLifeFullName(lifeData?.attributes || {}) || "Não providenciado",
    },
    {
      title: "Nome social:",
      value: lifeData?.attributes?.preferred_name || "Não providenciado",
    },
    {
      title: "CPF:",
      value: lifeData?.attributes?.registration_number || "Não providenciado",
    },
    {
      title: "Vínculo familiar:",
      value:
        lifeData?.attributes.relatives?.length === 0
          ? "-"
          : lifeData?.attributes.relatives?.map((relative) => {
              return (
                <Box key={relative?.id}>
                  <Box ml={1}>
                    <Typography fontWeight={400} variant="button">
                      {`Nome: ${relative.attributes.relative?.attributes.first_name} ${relative.attributes.relative?.attributes.last_name}`}
                    </Typography>
                  </Box>
                  <Box ml={1}>
                    <Typography fontWeight={400} variant="button">
                      {`Relação: ${getKinshipType(
                        relative.attributes.relative_kinship!
                      )}`}
                    </Typography>
                  </Box>
                </Box>
              );
            }),
    },
    // TODO: we dont have this info on BE yet
    // {
    //   title: "RG:",
    //   value: "-",
    // },
    {
      title: "Email:",
      value: lifeData?.attributes?.email || "Não providenciado",
    },
    {
      title: "Data de nascimento:",
      value: lifeData?.attributes?.birth_date
        ? format(new Date(lifeData?.attributes?.birth_date || ""), "dd/MM/yyyy")
        : "Não providenciado",
    },
    {
      title: "Telefones:",
      value:
        lifeData?.attributes?.phone_numbers
          ?.map?.((phone) => phone.attributes?.number)
          .join(", ") || "Não providenciado",
    },
    // TODO: it's not possible to mark a phone number as primary yet
    // {
    //   title: "Telefone secundario:",
    //   value: "-",
    // },
    // TODO: we dont have this info on BE yet
    // {
    //   title: "Nome da mãe:",
    //   value: "-",
    // },
    {
      title: "Endereço:",
      value:
        lifeData?.attributes?.addresses?.map((address) => {
          return (
            <Box ml={1} key={address?.id}>
              {address?.attributes?.type && (
                <Box>
                  <Typography fontWeight={700} variant="button">
                    {`Tipo: ${getAddressType(
                      address?.attributes?.type || "Não providenciado"
                    )}`}
                  </Typography>
                </Box>
              )}
              {address?.attributes?.address_line_1 && (
                <Box>
                  <Typography variant="button">
                    {address?.attributes?.address_line_1}
                  </Typography>
                </Box>
              )}
              {address?.attributes?.address_line_2 && (
                <Box>
                  <Typography variant="button">
                    {address?.attributes?.address_line_2}
                  </Typography>
                </Box>
              )}
              {address?.attributes?.neighborhood && (
                <Box>
                  <Typography variant="button">
                    {`Bairro ${address?.attributes?.neighborhood}`}
                  </Typography>
                </Box>
              )}
              {address?.attributes?.city && (
                <Box>
                  <Typography variant="button">
                    {address?.attributes?.city}
                  </Typography>
                </Box>
              )}
              {address?.attributes?.state && (
                <Box>
                  <Typography variant="button">
                    {address?.attributes?.state}
                    {" / "}
                    {address?.attributes?.country}
                  </Typography>
                </Box>
              )}
            </Box>
          );
        }) || "-",
    },
    {
      title: "Nome do benefício:",
      value:
        lifeData?.attributes?.coverages?.[0]?.attributes?.benefit?.attributes
          ?.name || "-",
    },
    {
      title: "Produto:",
      value:
        lifeData?.attributes?.coverages?.[0]?.attributes?.benefit?.attributes
          ?.product || "-",
    },
    {
      title: "Carterinha:",
      value: lifeData?.attributes?.coverages?.[0]?.attributes?.number || "-",
    },
    {
      title: "Plano:",
      value:
        lifeData?.attributes?.coverages?.[0]?.attributes?.benefit?.attributes
          ?.category || "-",
    },
    // TODO: we dont have this info on BE yet
    // {
    //   title: "Empresa:",
    //   value: "-",
    // },
    // {
    //   title: "Cargo:",
    //   value: "-",
    // },
  ];
};
