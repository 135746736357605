import { TMediaComponentProps } from "../BalonContent";
import { AddAttachmentTag } from "../../../../../../ChatTimeline/components/AddAttachmentTag";

export const AddMediaTag = (props: TMediaComponentProps) => {
  const attachmentTaggedItems =
    props.messageData?.attributes?.message_attachment?.attributes?.attachment
      ?.attributes?.tagged_items;

  const attachmentId =
    props.messageData?.attributes?.message_attachment?.attributes?.attachment
      ?.id;

  return (
    <AddAttachmentTag
      inferredTags={attachmentTaggedItems || []}
      attachmentId={attachmentId}
    />
  );
};
