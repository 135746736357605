import {
  API_LIFE_BY_ID_TYPE,
  EmployerType,
  CoverageType,
  BenefitType,
  API_EMPLOYERS_TYPE,
} from "./types";
import { type RelativesType } from "../../contexts/RegistrationContexts/RelativesContext";
import { format } from "date-fns";

export const getEmployersAndBenefits = (
  employers: API_EMPLOYERS_TYPE
): EmployerType[] => {
  return employers.map((employer) => {
    return {
      id: employer.id,
      name: employer.attributes?.name,
      benefits: employer.attributes?.benefits.map((benefit) => {
        return {
          id: benefit.id,
          name: benefit.attributes?.name,
          product: benefit.attributes?.product,
        };
      }),
    };
  });
};

export const getCoveragesFromLife = (life: API_LIFE_BY_ID_TYPE) => {
  return life.data[0].attributes?.coverages.map((coverage) => {
    return {
      coverageId: coverage.id,
      benefitId: coverage.attributes?.benefit.id,
    };
  });
};

export const getHolderCoverageId = (
  benefit: BenefitType,
  relatives: RelativesType[]
) => {
  let holderConverageId = null;

  relatives.forEach((relative: RelativesType) => {
    const validCoverageId = relative?.attributes?.coverages?.find(
      (coverage: CoverageType) => {
        return coverage.benefitId === benefit.benefitId;
      }
    );
    if (validCoverageId) {
      holderConverageId = validCoverageId.coverageId;
    }
  });

  return holderConverageId;
};

export const getEmployersByRelatives = (
  employers: EmployerType[],
  relatives: RelativesType[]
) => {
  const relativesBenefits = relatives
    .map((relative: RelativesType) =>
      relative?.attributes?.coverages?.map(
        (coverage: CoverageType) => coverage.benefitId
      )
    )
    .flat();

  const allowedEmployers = employers.filter((employer: EmployerType) => {
    const benefits = employer.benefits.filter((benefit) =>
      relativesBenefits.includes(benefit.id)
    );

    if (benefits.length === 0) {
      return false;
    }
    return true;
  });

  return allowedEmployers;
};

export const getBenefitsIdsByEmployers = (employers: EmployerType[]) => {
  return employers
    .map((employer) => employer.benefits.map((benefit) => benefit.id))
    .flat();
};

export const getInputBenefitsByEmployersAndRelatives = (
  benefitsIds: string[],
  relatives: RelativesType[],
  employers: EmployerType[]
) => {
  const allowedEmployers = getEmployersByRelatives(employers, relatives);

  const allowedBenefits = allowedEmployers
    .map((employer) => {
      return employer.benefits.filter((benefit) => {
        return benefitsIds.includes(benefit.id);
      });
    })
    .flat();

  return allowedBenefits.length === 0 ? [""] : allowedBenefits.map(() => "");
};

export const getCardNumbers = (
  employers: EmployerType[],
  benefits: BenefitType[]
) => {
  const benefitsIds = getBenefitsIdsByEmployers(employers);

  const cardNumbers = benefits
    .filter((benefit) => benefitsIds.includes(benefit.benefitId))
    .map((benefit) => benefit.cardNumber);

  return cardNumbers.length === 0 ? [""] : cardNumbers;
};

export const formatCorrectBirthDate = (value: string) => {
  const dateArray = value.split("/");
  let date: string | Date = value;
  if (
    dateArray.length === 3 &&
    dateArray[2].replace(/[^0-9]/g, "").length === 4
  ) {
    const day = Number(dateArray[0]);
    const month = Number(dateArray[1]) - 1;
    const year = Number(dateArray[2]);

    date = format(new Date(year, month, day), "dd-MM-yyyy");
  }

  return date;
};

export const formatISODate = (date: string): string => {
  const isoDate = new Date(date);
  const day = isoDate.getDate();
  const month = isoDate.getMonth() + 1;
  const year = isoDate.getFullYear();

  const formattedDate = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year.toString()}`;

  return formattedDate;
};

export function deepEqual<T>(obj1: T, obj2: T): boolean {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1) as Array<keyof T>;
  const keys2 = Object.keys(obj2) as Array<keyof T>;

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
