import { useState } from "react";
import { useCancelAppointmentMutation } from "../../../hooks/useCancelAppointmentMutation";
import { AppointmentResource, AppointmentStatus } from "../../../timelineTypes";
import { useRemoveAppointmentFromQueryData } from "./useRemoveAppointmentFromQueryData";

type TProceedCancelParams = {
  appointment: AppointmentResource;
  index: number;
  onCancelledCallback?: () => void;
  reasons: string[];
  status: AppointmentStatus;
};

type TUseCancelHandlersParams = {
  lifeId: string;
};

export const useCancelHandlers = (params: TUseCancelHandlersParams) => {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean[]>([]);
  const [isNoShowDialogOpen, setIsNoShowDialogOpen] = useState<boolean[]>([]);
  const { cancelAppointmentMutation } = useCancelAppointmentMutation();
  const { removeAppointmentFromQueryData } =
    useRemoveAppointmentFromQueryData();

  const handleCancelClick = (index: number) => {
    setIsCancelDialogOpen((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const closeCancelDialog = (index: number) => {
    setIsCancelDialogOpen((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };

  const closeNoShowDialog = (index: number) => {
    setIsNoShowDialogOpen((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };

  const handleNoShowClick = (index: number) => {
    setIsNoShowDialogOpen((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleProceedCancel = async (
    proceedCancelParams: TProceedCancelParams
  ) => {
    try {
      const { appointment, reasons, index, onCancelledCallback } =
        proceedCancelParams;
      await cancelAppointmentMutation.mutateAsync({
        appointmentId: appointment?.id,
        reason: reasons?.join?.(";") || "",
        status: proceedCancelParams.status,
      });
      closeCancelDialog(index);
      closeNoShowDialog(index);
      onCancelledCallback?.();
      removeAppointmentFromQueryData({
        lifeId: params.lifeId,
        appointmentId: appointment?.id,
      });
    } catch (_) {
      console.log("Error cancelling appointment");
    }
  };

  return {
    handleCancelClick,
    handleProceedCancel,
    isCancelDialogOpen,
    isNoShowDialogOpen,
    cancelAppointmentMutation,
    handleNoShowClick,
  };
};
