import { MessageDataType, MessagesAttributes } from "../../types";

import { TMessageUIEvent } from "../conversationSlice";

const messageDeliveryFieldsPriority = [
  { fieldName: "failed_at", priority: 1 },
  { fieldName: "read_at", priority: 2 },
  { fieldName: "delivered_at", priority: 3 },
  { fieldName: "sent_at", priority: 4 },
  { fieldName: "queued_at", priority: 5 },
  // created_at is the property that all messages have, if no other state is present so this is the default
  { fieldName: "created_at", priority: 6 },
];

export const getMessageByMostRecentDeliveryField = (
  messages: TMessageUIEvent[]
): TMessageUIEvent | null => {
  return messages.reduce(
    (selected: TMessageUIEvent | null, currentEventValue) => {
      if (!selected) {
        return currentEventValue;
      }

      const attributes = (
        currentEventValue?.originalMessage?.data as MessageDataType
      )?.attributes;
      const currentPriority = getPriority(attributes);

      const selectedAttributes = (
        selected.originalMessage?.data as MessageDataType
      )?.attributes;
      const selectedPriority = getPriority(selectedAttributes);

      if (currentPriority < selectedPriority) {
        return currentEventValue;
      } else {
        return selected;
      }
    },
    null
  );
};

const getPriority = (attributes: MessagesAttributes) => {
  for (const state of messageDeliveryFieldsPriority) {
    if (attributes[state.fieldName]) {
      return state.priority;
    }
  }

  return Infinity; // Default priority if no state matches
};
