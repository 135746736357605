import { getMessageCurrentFilters } from "../components/ConversationList/getMessageCurrentFilters";
import {
  ChatDataType,
  MessageDataType,
  MessageEvenType,
  SocketEventData,
  ThreadDataType,
  ThreadEventType,
} from "../types";
import { formatChatData } from "./conversationFormatting";
import {
  EMessageDirection,
  MessageStatus,
  TConversation,
  TMessage,
  TMessageUIEvent,
  TThreadUIEvent,
} from "./conversationSlice";

export const getConversationByChatID = (
  conversationList: TConversation[],
  chatId: string
) => {
  const conversationIndex = conversationList.findIndex(
    (item) => item?.chatData?.id === chatId
  );

  const hasFoundIndex = conversationIndex !== -1;

  return {
    hasFoundIndex,
    conversationIndex,
  };
};

export const getMessageDirection = (
  message: MessageDataType
): EMessageDirection => {
  const messageData = message;
  return messageData?.attributes?.sender?.type === "lives"
    ? EMessageDirection.receiving
    : EMessageDirection.sending;
};

export const getMessageStatus = (message: MessageDataType): MessageStatus => {
  const statusMapper = {
    read: message?.attributes?.read_at,
    delivered:
      message?.attributes?.delivered_at ||
      message?.attributes?.sender?.type === "lives",
    failed: message?.attributes?.failed_at,
    sent: message?.attributes?.sent_at,
    sending: true, // default status
  };

  for (const [status, condition] of Object.entries(statusMapper)) {
    if (condition) {
      return MessageStatus[status as keyof typeof MessageStatus];
    }
  }

  return MessageStatus.sending;
};

type TGetFilteredConversationsParams = {
  chatList: ChatDataType[];
  currentFilters: string[];
  conversationList: TConversation[];
};

export const getFilteredConversations = (
  params: TGetFilteredConversationsParams
) => {
  const { chatList = [], currentFilters = [], conversationList = [] } = params;
  return chatList
    .map((conversation) => {
      const foundItem = conversationList.find((item) => {
        return (
          item.chatData?.id === conversation.id &&
          JSON.stringify(item.currentFilters) === JSON.stringify(currentFilters)
        );
      });
      if (foundItem) {
        return false;
      }
      return conversation;
    })
    .filter(Boolean) as ChatDataType[];
};

export const addOrEditConversation = (
  conversationList: TConversation[],
  params: {
    conversation: ChatDataType;
    chatList: ChatDataType[];
    currentFilters: string[];
  }
) => {
  const { conversation, currentFilters } = params;
  const { conversationIndex, hasFoundIndex } = getConversationByChatID(
    conversationList,
    conversation?.id || ""
  );
  const currentConversastion = conversationList[conversationIndex];

  if (hasFoundIndex) {
    conversationList[conversationIndex] = {
      chatData: formatChatData(conversation),
      messages: currentConversastion?.messages,
      currentFilters: currentFilters,
    };
  } else {
    conversationList.push({
      chatData: formatChatData(conversation),
      messages: currentConversastion?.messages || [],
      currentFilters,
    });
  }
};

export const syncConversationFiltersWithChatList = (
  chatList: ChatDataType[],
  conversationList: TConversation[],
  currentLoggedUserId: string
) => {
  return conversationList.map((conversation) => {
    const foundOnChatList = chatList.find(
      (chat) => chat?.id === conversation.chatData?.id
    );
    if (!foundOnChatList) {
      const currentFilters = getMessageCurrentFilters(
        {
          status: conversation.chatData?.attributes?.status,
          assignees: conversation?.chatData?.attributes?.assignees,
        },
        currentLoggedUserId
      );
      return {
        ...conversation,
        currentFilters: currentFilters || [],
      };
    }
    return conversation;
  });
};

export function isMessageSocketEvent(
  eventData?: SocketEventData
): eventData is MessageDataType {
  return (eventData as MessageDataType).type === "messages";
}

export function isThreadSocketEvent(
  eventData?: SocketEventData
): eventData is ThreadDataType {
  return (eventData as ThreadDataType).type === "threads";
}

export function isTMessageUIEvent(
  message?: TMessage | null
): message is TMessageUIEvent {
  return Object.values(MessageEvenType).includes(
    message?.originalMessage?.event as MessageEvenType
  );
}

export function isTThreadUIEvent(message: TMessage): message is TThreadUIEvent {
  return Object.values(ThreadEventType).includes(
    message?.originalMessage?.event as ThreadEventType
  );
}
