import {
  EncounterResource,
  ThreadResource,
  TimelineEventSchema,
} from "./timelineTypes";

export const isThreadTimelineResource = (
  currentEvent: TimelineEventSchema["event"]
): currentEvent is ThreadResource => {
  return currentEvent.type === "threads";
};

export const isEncounterTimelineResource = (
  currentEvent: TimelineEventSchema["event"]
): currentEvent is EncounterResource => {
  return currentEvent.type === "encounters";
};

export const isAppointmentTimelineResource = (
  currentEvent: TimelineEventSchema["event"]
): currentEvent is EncounterResource => {
  return currentEvent.type === "appointments";
};
