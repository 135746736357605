import { Box, Button, Stack, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import Icon from "@mui/material/Icon";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuItems from "../../utils/navigation";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomIconButton } from "../CustomIconButton";

const stackStyle = {
  padding: "3.2rem 1.4rem",
  display: "flex",
  flexDirection: "column",
  gap: 1,
  alignItems: "flex-start",
};

const sidebarButtonStyle = {
  textAlign: "left",
  justifyContent: "flex-start",
};

const getDisabledText = (disabled?: boolean) => (): SxProps<Theme> => ({
  color: (theme) => (disabled ? theme.palette.text.disabled : "inherit"),
});

const Sidebar: React.FC = () => {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const isActiveRoute = (route: string) => {
    const matchRoute = pathname.split("/");

    return matchRoute.includes(route.split("/")[0]);
  };

  return (
    <Box
      width={open ? 200 : 70}
      borderRight={2}
      borderColor={"#292929"}
      flexDirection="column"
      alignItems="flex-start"
      display="flex"
      paddingTop={2}
      sx={{
        transition: "all 0.3s",
        overflow: "hidden",
        minHeight: "100vh",
        height: "100%",
      }}
      data-testid="sidebar"
    >
      <Box ml={1.5}>
        <CustomIconButton
          onClick={toggleSidebar}
          data-testid="sidebar-toggle-button"
          variant="contained"
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </CustomIconButton>
      </Box>
      <Stack
        direction="column"
        spacing={2}
        alignItems="flex-start"
        justifyContent="space-between"
        flex={1}
      >
        <Stack
          sx={{
            ...stackStyle,
            opacity: open ? 1 : 0,
          }}
        >
          {MenuItems.map((item) => (
            <Button
              fullWidth
              key={item.label}
              onClick={() => navigate(`/${item.route}`)}
              disabled={item.disabled}
              size="large"
              variant={isActiveRoute(item.route) ? "contained" : "text"}
              sx={sidebarButtonStyle}
              startIcon={
                <Icon sx={getDisabledText(item.disabled)()}>{item.icon}</Icon>
              }
            >
              <Typography sx={getDisabledText(item.disabled)()} variant="body2">
                {item.label}
              </Typography>
            </Button>
          ))}
        </Stack>
        <Stack sx={stackStyle}>
          <Button
            sx={[
              sidebarButtonStyle,
              !open && { paddingLeft: (theme) => theme.spacing(0.5) },
            ]}
            startIcon={<InfoIcon />}
          >
            {open && <Typography variant="body2">FAQ</Typography>}
          </Button>
          <Button
            sx={[
              sidebarButtonStyle,
              !open && { paddingLeft: (theme) => theme.spacing(0.5) },
            ]}
            startIcon={<SettingsIcon />}
          >
            {open && <Typography variant="body2">Gerenciar</Typography>}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Sidebar;
