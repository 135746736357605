import { DataGrid as MuiDataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { LivesColumns } from "./LivesColumns";
import { customColors } from "../../../../theme/colors";
import { LivesType, GenderEnum } from "../../types";
import { Box } from "@mui/material";

const getGenderLabel = (gender: string) => {
  switch (gender) {
    case GenderEnum.FEMALE:
      return "Feminino";
    case GenderEnum.MALE:
      return "Masculino";
    case GenderEnum.OTHER:
      return "Outro";
    default:
      return "Sexo não especificado";
  }
};

const getCurrentAge = (birthDate: string): string => {
  if (!birthDate) return "";
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }
  return String(age) + " anos";
};

type DataGridProps = {
  lives: LivesType[];
  pageSize?: number;
  maxPageLimit?: number;
  paginationModel: GridPaginationModel;
  handleOnChangePage: (model: GridPaginationModel) => void;
};

const renderNameOrPhoneNumber = (life: LivesType) => {
  const firstName = life.firstName || "";
  const lastName = life.lastName || "";
  const phoneNumber = life.phoneNumbers?.[0] || "";

  let displayInfo = `${firstName} ${lastName}|${getGenderLabel(
    life.gender
  )}|${getCurrentAge(life.birthDate)}`;

  if (!firstName && !lastName) {
    displayInfo = `${phoneNumber}|${getGenderLabel(
      life.gender
    )}|${getCurrentAge(life.birthDate)}`;
  }

  return displayInfo;
};

export const DataGrid = ({
  lives,
  paginationModel,
  pageSize = 5,
  maxPageLimit = 10,
  handleOnChangePage,
}: DataGridProps) => {
  const DataGridRows = lives.map((life) => ({
    id: life.id,
    chatId: life?.chat_id,
    firstName: renderNameOrPhoneNumber(life),
  }));

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        "& .super-app-theme--row": {
          backgroundColor: customColors.PrimaryNeutral,
        },
      }}
    >
      <MuiDataGrid
        rows={DataGridRows}
        columns={LivesColumns}
        autoHeight
        localeText={{ noRowsLabel: "Nenhum paciente encontrado." }}
        initialState={{
          pagination: {
            paginationModel,
          },
        }}
        slots={{
          columnHeaders: () => null,
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={(model: GridPaginationModel) =>
          handleOnChangePage(model)
        }
        pageSizeOptions={[pageSize, maxPageLimit]}
        sx={{
          boxShadow: 0,
          border: 0,
          borderLeft: 0,
          "& .MuiDataGrid-row": {
            borderBottomColor: customColors.Neutral04,
          },
          "& .MuiDataGrid-cell": {
            borderBottom: 0,
          },
        }}
      />
    </Box>
  );
};
