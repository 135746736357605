import { Box, Button, Typography, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { RelativesModal } from "../RelativesModal";
import RelativesCard from "../RelativesCard";
import {
  useRelativesContext,
  type RelativesType,
} from "../../../../contexts/RegistrationContexts/RelativesContext";
import { useUpdateRelatives } from "../../hooks/useRelatives";
import { useState, useEffect, useCallback } from "react";
import { FormSectionHeader } from "../../../../components/Form/FormSectionHeader";
import { FormGroupContainer } from "../../../../components/Form/FormGroupContainer";
import { UpdateSectionContainer } from "../UpdateSectionContainer";
import { InformationSectionContainer } from "../InformationSectionContainer";
import { renderNotifier } from "../../../../providers/Notifier";
import { messages } from "../../../../strings/messages";
import { errors as messageErrors } from "../../../../strings/errors";
import { API_LIVES_GET_TYPE, KinShipEnum } from "../../types";

type UpdateRelativesFormSectionProps = {
  formMode: "create" | "edit" | null;
  enabled?: boolean;
  lifeId: string | null;
  handleOnActivateEditMode: (active: boolean) => void;
};

type HandleCreateRelativeProps = {
  life: API_LIVES_GET_TYPE;
  kinship: { life_kinship: string; relative_kinship: string };
};

const normalizeRelatives = (relatives: RelativesType[]) => {
  return relatives.map((item) => {
    const { relative, ...restAttributes } = item.attributes;
    return {
      ...item,
      attributes: restAttributes,
    };
  });
};

export const UpdateRelativesFormSection = ({
  enabled = true,
  formMode,
  lifeId,
  handleOnActivateEditMode,
}: UpdateRelativesFormSectionProps) => {
  const [loadingRelatives, setLoadingRelatives] = useState(false);
  const [isEditionOpen, setEditionOpen] = useState(formMode === "create");
  const [openRelativesModal, setOpenRelativesModal] = useState(false);

  const { relatives, cachedRelatives, setRelatives, setCachedRelatives } =
    useRelativesContext();

  const {
    isSuccess: updateSuccess,
    isError: updateError,
    isPending: updatePending,
    mutate: updateRelatives,
  } = useUpdateRelatives();

  const isLoadingRelatives = useCallback(() => {
    setLoadingRelatives(true);

    const timer = setTimeout(() => {
      setLoadingRelatives(false);
    }, 2000);

    if (relatives.length > 0) {
      clearTimeout(timer);
      setLoadingRelatives(false);
    }
  }, [relatives.length]);

  useEffect(() => {
    isLoadingRelatives();
  }, [isLoadingRelatives]);

  const handleOnChangeMode = useCallback(
    (active: boolean) => {
      setEditionOpen(!isEditionOpen);
      handleOnActivateEditMode(active);
    },
    [handleOnActivateEditMode, isEditionOpen]
  );

  useEffect(() => {
    if (updateSuccess) {
      renderNotifier(messages.relativesSuccessfullyUpdated, {
        variant: "success",
      });
      setCachedRelatives([...relatives!]);
      handleOnActivateEditMode(false);
      handleOnChangeMode(false);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateError) {
      renderNotifier(messageErrors.generic, { variant: "error" });
      handleOnChangeMode(false);
    }
  }, [handleOnChangeMode, updateError]);

  useEffect(() => {
    setEditionOpen(formMode === "create");
  }, [formMode]);

  const handleRemoveRelativeCard = (relativeId: string) => {
    const currentRelatives = [...relatives];
    const relativeToRemove = relatives.find(
      (relative) => relative?.attributes?.relative_id === relativeId
    );

    setRelatives([
      ...currentRelatives.filter(
        (relative) =>
          relative?.attributes?.relative_id !==
          relativeToRemove?.attributes?.relative_id
      ),
    ]);
  };

  const handleCreateRelative = ({
    life,
    kinship,
  }: HandleCreateRelativeProps) => {
    const currentRelatives = [...relatives];
    const newRelative: RelativesType = {
      type: "relatives",
      attributes: {
        life_kinship: kinship.life_kinship as KinShipEnum,
        relative_kinship: kinship.relative_kinship as KinShipEnum,
        relative_id: life.id,
        life_id: lifeId!,
        relative: {
          id: lifeId!,
          type: "lives",
          attributes: {
            first_name: life?.attributes?.first_name,
            last_name: life?.attributes?.last_name,
          },
        },
      },
    };
    setRelatives([...currentRelatives, newRelative]);
    setOpenRelativesModal(false);
  };

  return (
    <FormGroupContainer formMode={formMode}>
      <FormSectionHeader header="Relação familiar" />
      {isEditionOpen ? (
        <UpdateSectionContainer
          formMode={formMode}
          handleOnChangeMode={() => handleOnChangeMode(false)}
          loading={updatePending}
          handleOnSave={() => {
            updateRelatives({
              relatives: [...normalizeRelatives(relatives)],
              cachedRelatives: [...normalizeRelatives(cachedRelatives)],
            });
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: 2, marginBottom: "24px" }}>
              {relatives.map((item, index) => (
                <RelativesCard
                  key={index}
                  kinship={item?.attributes?.relative_kinship as KinShipEnum}
                  relativeId={item?.attributes?.relative_id}
                  fullName={`${
                    item?.attributes?.relative?.attributes?.first_name || ""
                  } ${item?.attributes?.relative?.attributes?.last_name || ""}`}
                  handleOnRemove={(id: string) => handleRemoveRelativeCard(id)}
                  isOpen={isEditionOpen}
                  loadingUpdate={updatePending}
                />
              ))}
            </Box>
          </Box>
          <RelativesModal
            open={openRelativesModal}
            handleCreateRelative={handleCreateRelative}
            handleOnClose={() => setOpenRelativesModal(false)}
          />
          <Button
            variant="outlined"
            onClick={() => setOpenRelativesModal(true)}
            sx={{
              width: "100%",
              height: "40px",
              margintTop: "10px",
              backgroundColor: "transparent",
            }}
            startIcon={
              <AddIcon
                sx={{ color: "secondary.contrastText" }}
                fontSize="large"
              />
            }
          >
            Vincular com outra vida
          </Button>
        </UpdateSectionContainer>
      ) : (
        <InformationSectionContainer
          handleOnChangeMode={() => handleOnChangeMode(true)}
          enabled={enabled}
        >
          {loadingRelatives ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {relatives.length === 0 && (
                <Typography variant="body2">Não providencido</Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  flexWrap: "wrap",
                }}
              >
                {relatives.map((item, index) => (
                  <RelativesCard
                    key={index}
                    relativeLink={`/pacientes/${item?.attributes?.relative_id}`}
                    kinship={item?.attributes?.relative_kinship as KinShipEnum}
                    relativeId={item?.attributes?.relative_id}
                    fullName={`${
                      item?.attributes?.relative?.attributes?.first_name || ""
                    } ${
                      item?.attributes?.relative?.attributes?.last_name || ""
                    }`}
                    handleOnRemove={(id: string) =>
                      handleRemoveRelativeCard(id)
                    }
                    loadingUpdate={updatePending}
                    isOpen={isEditionOpen}
                  />
                ))}
              </Box>
            </>
          )}
        </InformationSectionContainer>
      )}
    </FormGroupContainer>
  );
};
