import { Box, Typography } from "@mui/material";

type TMessageStatusTextProps = {
  errorCode?: string;
  errorMessage?: string;
};
export const MessageErrorText = (props: TMessageStatusTextProps) => {
  return (
    <Box>
      <Typography variant="body2" color="error">
        {props.errorCode} - {props.errorMessage}
      </Typography>
    </Box>
  );
};
