import { useQuery } from "@tanstack/react-query";
import { api } from "../../../api";

import { LivesResponse } from "../types/TaskTypes";

type TUseGetLivesQueryParams = {
  searchQuery: string;
};

export const useGetLivesQuery = ({ searchQuery }: TUseGetLivesQueryParams) => {
  const getLivesQuery = useQuery({
    queryKey: ["get-lives", searchQuery],
    queryFn: async () => {
      return api.get<LivesResponse>("/api/v1/lives", {
        params: {
          query: searchQuery,
        },
      });
    },
  });

  return getLivesQuery;
};
