import { Button, CircularProgress } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import ScreenContainer from "../../../components/ScreenContainer";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect, useState } from "react";
import {
  KINSHIP_INVERSE_MAP,
  validatePhoneNumber,
  formatPhoneNumber,
} from "../formUtils";
import GeneralDataFormSection from "../RegistrationFormSections/GeneralDataFormSection";
import BenefitsFormSection from "../RegistrationFormSections/BenefitsFormSection";
import PhoneFormSection from "../RegistrationFormSections/PhoneFormSection";
import { CreateAddress } from "../RegistrationFormSections/Address";
import {
  UpdateRelativesFormSection,
  NewLifeRelativesFormSection,
} from "../RegistrationFormSections/RelativesFormSection";
import { FormSchema } from "../formSchema";
import { Box } from "@mui/material";
import { validateCPF } from "../formUtils";
import { renderNotifier } from "../../../providers/Notifier";
import { messages } from "../../../strings/messages";
import { errors as messageErrors } from "../../../strings/errors";
import {
  useAddNewLife,
  useGetEmployers,
  useGetLifeById,
} from "../hooks/useLives";
import {
  API_LIVES_POST_TYPE,
  BenefitType,
  GenderEnum,
  GenderIdentityEnum,
  HealthPlanRoleTypeEnum,
  KinShipEnum,
} from "../types";
import { getEmployersAndBenefits, getHolderCoverageId } from "../helpers";
import { useBenefitsContext } from "../../../contexts/RegistrationContexts/BenefitsContext";
import {
  NewLifeRelativesType,
  useRelativesContext,
} from "../../../contexts/RegistrationContexts/RelativesContext";
import { useAddressesContext } from "../../../contexts/RegistrationContexts/AddressesContext";
import { useGeneralDataContext } from "../../../contexts/RegistrationContexts/GeneralDataContext";
import { useParams } from "react-router-dom";
import { RegistrationWrapper } from "../RegistrationFormSections/Wrappers/RegistrationWrapper";

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const queryParams: { [key: string]: string } = {};
  params.forEach((value, key) => {
    queryParams[key] = value;
  });
  return queryParams;
};

const getFormContainerStyle = (formMode: "create" | "edit" | null) => ({
  display: formMode ? "flex" : "none",
  width: `${formMode === "create" ? "100%" : "1200px"}`,
  height: "auto",
  alignItems: `${formMode === "create" ? "center" : "flex-start"}`,
  gap: 8,
  flexFlow: `${formMode === "create" ? "column nowrap" : "row wrap"}`,
  padding: "40px",
});

const NewRegistration = () => {
  const [lifeId, setLifeId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [formStyle, setFormStyle] = useState<"info" | "edit">("edit");
  const [formMode, setFormMode] = useState<"create" | "edit" | null>(null);
  const [isAnyActiveFormSection, setIsAnyActiveFormSection] = useState(false);

  const params = useParams();

  const relativesProps = useRelativesContext();
  const benefitsProps = useBenefitsContext();
  const { addresses, resetAddressesState, setAddresses, setCacheAddresses } =
    useAddressesContext();
  const generalDataProps = useGeneralDataContext();

  const { data: lifeData, isLoading: lifeLoading } = useGetLifeById(params?.id);

  const queryParams = getQueryParams();

  if (queryParams?.cpf && queryParams?.cpf.length === 11) {
    generalDataProps.setCpf(queryParams.cpf);
  }

  useEffect(() => {
    console.log({ params });
    if (params?.id) {
      setFormStyle("info");
    }
  }, [params]);

  useEffect(() => {
    if (lifeData?.data?.data) {
      const targetLife = lifeData.data.data;
      setLifeId(targetLife.id);

      // setup general data
      generalDataProps.setCpf(targetLife.attributes?.registration_number);
      generalDataProps.setFirstName(targetLife.attributes?.first_name || "");
      generalDataProps.setLastName(targetLife.attributes?.last_name || "");
      generalDataProps.setSocialName(
        targetLife.attributes?.preferred_name || ""
      );
      generalDataProps.setEmail(targetLife.attributes?.email || "");
      generalDataProps.setGender(targetLife.attributes?.gender || "");
      generalDataProps.setGenderIdentity(
        targetLife.attributes?.gender_identity || ""
      );
      if (targetLife.attributes?.birth_date) {
        generalDataProps.setBirthDate(
          targetLife.attributes.birth_date.split("-").reverse().join("/")
        );
      } else {
        generalDataProps.setBirthDate("");
      }
      generalDataProps.setChatId(targetLife?.chatId || null);

      // setup addresses
      if (targetLife.attributes?.addresses.length > 0) {
        setAddresses([...targetLife.attributes.addresses]);
        setCacheAddresses([...targetLife.attributes.addresses]);
      }

      // setup phone numbers
      generalDataProps.setPhoneNumbers(targetLife.attributes.phone_numbers);

      // setup relatives
      if (targetLife?.attributes?.relatives) {
        relativesProps.setRelatives([...targetLife.attributes.relatives]);
        relativesProps.setCachedRelatives([...targetLife.attributes.relatives]);
      }

      // setup benefits
      type CoverageType = {
        id: string;
        attributes: {
          number: string;
          benefit: {
            id: string;
            attributes: {
              employer: {
                attributes: {
                  name: string;
                };
              };
              name: string;
              product: string;
            };
          };
          holder_coverage_id: string;
          activationDate: string;
          life_id: string;
        };
      };

      const lifeBenefits: BenefitType[] = targetLife.attributes.coverages.map(
        (coverage: CoverageType) => ({
          coverageId: coverage.id,
          cardNumber: coverage.attributes.number,
          benefitId: coverage.attributes.benefit.id,
          employer:
            coverage.attributes.benefit.attributes.employer.attributes.name,
          healthPlanRole:
            coverage.attributes.holder_coverage_id ===
            coverage.attributes.life_id
              ? "HOLDER"
              : "DEPENDENT",
          benefit: coverage.attributes.benefit.attributes.name,
          product: coverage.attributes.benefit.attributes.product,
          activationDate: coverage.attributes.activationDate,
        })
      );
      benefitsProps.setExistingBenefits(lifeBenefits);
    } else {
      resetForm();
    }
  }, [lifeData]);

  useEffect(() => {
    if (!lifeLoading && lifeData) {
      setFormMode("edit");
    } else if (!lifeLoading && !lifeData) {
      setFormMode("create");
    }
  }, [lifeLoading, lifeData]);

  const { data: employersResponse } = useGetEmployers(
    benefitsProps.allEmployers
  );

  const {
    mutateAsync: createLive,
    error: createLifeError,
    isSuccess,
    isPending,
  } = useAddNewLife();

  useEffect(() => {
    if (employersResponse?.data) {
      const employersAndBenefits = getEmployersAndBenefits(
        employersResponse?.data.data
      );

      benefitsProps.setAllEmployers(employersAndBenefits);
    }
  }, [employersResponse]);

  useEffect(() => {
    if (createLifeError) {
      renderNotifier(messageErrors.errorCreatingLife, {
        variant: "error",
        persist: true,
      });
    }
  }, [createLifeError]);

  useEffect(() => {
    if (isSuccess) {
      renderNotifier(messages.lifeSuccessfullyCreated, {
        variant: "success",
        persist: true,
      });
      resetForm();
    }
  }, [isSuccess]);

  useEffect(() => {
    setLoading(isPending);
  }, [isPending]);

  type FormType = z.infer<typeof FormSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<FormType>({
    resolver: zodResolver(FormSchema),
  });

  async function submitRecord(data: FormType) {
    if (!validateCPF(generalDataProps.cpf)) {
      generalDataProps.setCpfError("Digite um cpf válido");
      return;
    }

    let validPhoneNumbers: { number: string }[] = [];

    if (generalDataProps.phoneNumbers.length > 0) {
      validPhoneNumbers = generalDataProps.phoneNumbers
        .filter((phone) => {
          return validatePhoneNumber(phone.attributes.number);
        })
        .map((phone) => ({
          number: formatPhoneNumber(phone.attributes.number),
        }));
    }

    const validCoverages = [
      ...benefitsProps.benefits,
      ...benefitsProps.existingBenefits,
    ].map((benefit: BenefitType) => ({
      number: benefit.cardNumber,
      benefit_id: benefit.benefitId,
      holder_coverage_id: getHolderCoverageId(
        benefit,
        relativesProps.relatives
      ),
    }));

    const validRelatives: NewLifeRelativesType[] =
      relativesProps.newLifeRelatives.map((relative) => {
        return {
          life_kinship: KINSHIP_INVERSE_MAP[
            relative.life_kinship as keyof typeof KinShipEnum
          ] as KinShipEnum,
          relative_kinship: relative.relative_kinship,
          relative_id: relative.relative_id,
        };
      });

    const validBirthDate = data?.birthDate
      ? data.birthDate.split("/").reverse().join("-")
      : null;

    const payload: API_LIVES_POST_TYPE = {
      type: "lives",
      attributes: {
        first_name: data.firstName,
        last_name: data.lastName,
        preferred_name: data.socialName || "",
        registration_number: generalDataProps.cpf.replace(/[^0-9]/g, ""),
        birth_date: validBirthDate,
        email: data.email,
        gender: data.gender || GenderEnum.NOT_SPECIFIED,
        gender_identity: data.sex || GenderIdentityEnum.NOT_SPECIFIED,
        addresses: addresses?.map((addresses) => ({
          address_line_1: addresses?.attributes?.address_line_1,
          address_line_2: addresses?.attributes?.address_line_2,
          zip_code: addresses?.attributes?.zip_code,
          neighborhood: addresses?.attributes?.neighborhood,
          city: addresses?.attributes?.city,
          state: addresses?.attributes?.state,
          country: addresses?.attributes?.country || "Brasil",
          type: addresses?.attributes?.type,
        })),
        phone_numbers: validPhoneNumbers,
        coverages: [...validCoverages],
        relatives: [...validRelatives],
      },
    };

    createLive(payload);
  }

  const resetForm = () => {
    generalDataProps.resetGeneralDataState();
    resetAddressesState();
    benefitsProps.resetBenefitsContext();
    relativesProps.resetRelativesContext();
    clearErrors();
  };

  const commonFormSectionProps = {
    formMode: formMode,
    enabled: !isAnyActiveFormSection,
    lifeId,
    handleOnActivateEditMode: (active: boolean) =>
      setIsAnyActiveFormSection(active),
  };

  return (
    <ScreenContainer>
      {formStyle === "edit" ? (
        <PageHeader title="Novo paciente" icon="chat_bubble_outline_icon" />
      ) : (
        <PageHeader title="Informações de cadastro" icon="description_icon" />
      )}
      {formMode && (
        <RegistrationWrapper formMode={formMode}>
          <form onSubmit={handleSubmit(submitRecord)} noValidate>
            <Box sx={{ ...getFormContainerStyle(formMode) }}>
              {formMode === "edit" ? (
                <UpdateRelativesFormSection {...commonFormSectionProps} />
              ) : (
                <NewLifeRelativesFormSection />
              )}
              <GeneralDataFormSection
                errors={errors}
                register={register}
                {...commonFormSectionProps}
              />
              <PhoneFormSection {...commonFormSectionProps} />
              <CreateAddress
                lifeId={lifeId}
                formMode={formMode}
                enabled={!isAnyActiveFormSection}
                handleOnActivateEditMode={(active: boolean) =>
                  setIsAnyActiveFormSection(active)
                }
              />

              <BenefitsFormSection {...commonFormSectionProps} />
              {formStyle === "edit" && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: "90%" }}
                  disabled={
                    relativesProps.relatives.length === 0 &&
                    benefitsProps.healthPlanRole ===
                      HealthPlanRoleTypeEnum.DEPENDENT
                  }
                >
                  {loading ? (
                    <CircularProgress color="info" size={24} />
                  ) : (
                    "Cadastrar"
                  )}
                </Button>
              )}
            </Box>
          </form>
        </RegistrationWrapper>
      )}
    </ScreenContainer>
  );
};

export default NewRegistration;
