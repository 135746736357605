import { Box, IconButton, Modal as MUIModal, Typography } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { customColors } from "../../theme/colors";

const DEFAULT_MODAL_WIDTH = "900px";
const DEFAULT_MODAL_HEIGHT = "600px";

const modalContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "0 50px 25px 50px",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  bgcolor: customColors.BoxBackgroundColor,
  boxShadow: 24,
  borderRadius: "30px",
};

type ModalProps = {
  open: boolean;
  title?: string;
  width?: string;
  minHeight?: string;
  handleOnClose?: () => void;
  children: React.ReactNode;
};
export const Modal = ({
  open,
  handleOnClose,
  children,
  title,
  width = DEFAULT_MODAL_WIDTH,
  minHeight = DEFAULT_MODAL_HEIGHT,
}: ModalProps) => {
  return (
    <MUIModal open={open} onClose={handleOnClose}>
      <Box sx={{ ...modalContainerStyle, width, minHeight }}>
        {handleOnClose && (
          <IconButton
            onClick={handleOnClose}
            sx={{ position: "absolute", right: "10px", top: "10px" }}
          >
            <Close fontSize="large" />
          </IconButton>
        )}
        <Box
          sx={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {title && (
            <Box sx={{ margin: "25px 0 20px 0" }}>
              <Typography
                id="modal-modal-title"
                variant="h2"
                sx={{
                  width: "100%",
                  fontWeight: "normal",
                  fontSize: "24px",
                }}
              >
                {title}
              </Typography>
            </Box>
          )}
          {children}
        </Box>
      </Box>
    </MUIModal>
  );
};
