import { Box } from "@mui/material";
import { TMediaComponentProps } from "../BalonContent";
import { TextMessage } from "./TextMessage";

export const AudioMessage = (props: TMediaComponentProps) => {
  return (
    <Box>
      <Box>
        <audio controls data-testid="chat-audio">
          <source src={props.signedMediaUrl} type="audio/ogg" />
        </audio>
      </Box>
      <Box my={1}>
        <TextMessage {...props} />
      </Box>
    </Box>
  );
};
