export const errors = {
  generic: "Ocorreu um erro inesperado. Por favor, tente novamente",
  fetchingLivesError: "Erro ao buscar vidas",
  fetchingZipCodeError: "Erro ao buscar CEP",
  errorClosingTheChat:
    "Ocorreu um erro ao encerrar a conversa. Tente novamente mais tarde",
  errorGeneratingTheSummary:
    "Ocorreu um erro ao gerar o resumo. Tente novamente mais tarde",
  errorGeneratingTheThreads:
    "Ocorreu um erro ao gerar as threads. Tente novamente mais tarde",
  errorUpdatingPhone: "Erro ao atualizar o telefone",
  errorRemovingPhone: "Erro ao remover o telefone",
  errorCreatingPhone: "Erro ao adicionar o telefone",
  errorCreatingLife: "Erro ao criar a vida",
  errorUpdatingAddresses: "Erro ao tentar atualizar um ou mais endereços",
  errorRemovingAddresses: "Erro ao tentar remover um ou mais endereços",
  errorCreatingAddresses: "Erro ao tentar criar um ou mais endereços",
};
