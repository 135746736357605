import { Components } from "@mui/material";
import { muiThemeColors } from "../colors";

export const MuiButtonNode: Components["MuiButton"] = {
  styleOverrides: {
    root: {
      borderRadius: "32px",
      textTransform: "none",
      boxShadow: "none",

      "&:hover": {
        boxShadow: "none",
      },

      "&.MuiButton-text": {
        color: muiThemeColors.primary.main,
      },
      "&.Mui-disabled": {
        backgroundColor: "#C2C2C2",
        color: "#545454",
      },
    },
  },
};
