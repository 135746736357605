import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "../../../../../api";
import {
  TagsType,
  InferredTagsType,
  SummaryDataType,
  MessageProps,
} from "../../../types";
import { transformFilesToFormData } from "../../../../../utils/formDataUtils";

enum CHAT_STATUS {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

const toggleChat = async (chatId: string, status: CHAT_STATUS) => {
  return await api.patch(`/api/v1/chats/${chatId}`, {
    data: {
      id: chatId,
      type: "chats",
      attributes: {
        status,
      },
    },
  });
};

type SummaryType = {
  attributes: {
    summary: string;
    title: string;
  };
};

type FetchSummaryProps = {
  type: "messages";
  attributes: {
    author: string;
    content: string;
    date: string;
  };
};

type ConversationSummaryStateProps = {
  messagesIds: string[];
  summary: string | null;
  title: string | null;
  lifeId: string | null;
  tags: TagsType[];
};

const conversationSummaryState: ConversationSummaryStateProps = {
  messagesIds: [],
  tags: [],
  summary: null,
  title: null,
  lifeId: null,
};

const fetchSummary = async (messages: FetchSummaryProps[]) => {
  try {
    const summary = await api.post("/api/v1/assistants/summary", {
      data: messages,
    });

    return summary.data.data as SummaryType;
  } catch (_) {
    throw new Error();
  }
};

const generateThreads = async (
  summary: string,
  tags: string[],
  chatId: string
) => {
  return await api.post("/api/v1/threads", {
    data: {
      type: "threads",
      attributes: {
        messages_id: conversationSummaryState.messagesIds,
        summary,
        title: conversationSummaryState.title,
        life_id: conversationSummaryState.lifeId,
        chat_id: chatId,
        tags,
      },
    },
  });
};

export type TFetchTags = {
  entity: "THREAD" | "LIFE" | "ATTACHMENT";
};
export const fetchTags = async ({ entity }: TFetchTags) => {
  try {
    const response = (await api.get("/api/v1/tags", {
      params: {
        // TODO: Add pagination
        limit: 100,
      },
    })) as {
      data: { data: TagsType[] };
    };
    // const response = (await getTags()) as { data: { data: TagsType[] } }; // for mock pourposes

    const tagsFromEntityThreads = response?.data?.data?.filter?.((tag) => {
      const currentEntity = tag?.attributes?.entity || "THREAD";
      return currentEntity === (entity || "THREAD");
    });

    return tagsFromEntityThreads as TagsType[];
  } catch (e) {
    console.log("Error fetching tags", e);
    throw new Error();
  }
};

type FetchTagsProps = {
  messages: {
    type: "messages";
    attributes: { author: string; content: string; date: string };
  }[];
  tags: TagsType[];
};

const fetchInferredTags = async ({ messages, tags }: FetchTagsProps) => {
  try {
    const response = await api.post("api/v1/assistants/tags", {
      data: {
        messages,
        tags,
      },
    });

    // const response = (await getInferredTags()) as {
    //   // for mock pourposes
    //   data: { data: InferredTagsType[] };
    // };

    return response.data.data;
  } catch (e) {
    console.log("Error fetching inferred tags", e);
    throw new Error();
  }
};

const fetchOrphanThreads = async (chatId: string) => {
  return await api.get(`api/v1/threads/${chatId}/orphan-messages`);
};

const generateSummary = async ({
  lifeId,
  chatId,
  existingOrphanThreads,
}: {
  lifeId: string;
  chatId: string;
  existingOrphanThreads?: MessageProps[];
}): Promise<{ data: SummaryDataType } | { data: [] }> => {
  conversationSummaryState.messagesIds = [];
  conversationSummaryState.lifeId = lifeId;
  let orphanThreads: MessageProps[] = existingOrphanThreads || [];

  if (!existingOrphanThreads) {
    // Get an array of orhpans threads...
    const orphanThreadsResponse = await fetchOrphanThreads(chatId);
    orphanThreads = orphanThreadsResponse?.data?.data;
  }

  if (orphanThreads instanceof Error) {
    throw new Error();
  }

  if (orphanThreads.length > 0) {
    enum AuthorEnum {
      PATIENT = "patient",
      NURSE = "nurse",
      DOCTOR = "doctor",
    }

    // Extract messages from orphan threads...
    const messagesIds: string[] = [];
    const messages: FetchSummaryProps[] = orphanThreads!.map(
      (message: MessageProps) => {
        messagesIds.push(message.id);
        return {
          type: "messages",
          attributes: {
            author:
              message.attributes?.sender?.type === "users"
                ? AuthorEnum.NURSE
                : AuthorEnum.DOCTOR,
            content: message.attributes?.content || "",
            date: message.attributes?.created_at || "",
          },
        };
      }
    );

    conversationSummaryState.messagesIds = messagesIds;

    // Fetch the summary passing the messages...
    const summary = (await fetchSummary(messages)) as SummaryType;

    // Fetch the available tags and the inferred tags...
    const availableTags = await fetchTags({ entity: "THREAD" });

    const inferredTags = await fetchInferredTags({
      messages,
      tags: availableTags?.map?.((tag: TagsType) => ({
        ...tag,
        type: tag?.type,
        attributes: {
          ...tag?.attributes,
          label: tag?.attributes?.label || "",
          description: tag?.attributes?.description || "",
        },
      })),
    });

    if (summary.attributes?.summary) {
      conversationSummaryState.title = summary.attributes?.title;
    }

    // Filter the available tags according to the inferred tags...
    const normalizedInferredTags = availableTags.filter((tag) =>
      inferredTags.some((inferredTag: InferredTagsType) => {
        if (inferredTag.attributes?.label === tag.attributes?.label) {
          return tag;
        }
        return false;
      })
    );

    conversationSummaryState.tags = normalizedInferredTags;

    return {
      data: {
        summary: {
          title: summary.attributes?.title,
          content: summary.attributes?.summary,
        },
        tags: availableTags,
        inferredTags: normalizedInferredTags,
      },
    };
  }
  return { data: [] };
};

const addAttachment = async (files: File[], chatId: string) => {
  try {
    const formData = transformFilesToFormData(files, "files");

    const response = await api.post(
      `/api/v1/chats/${chatId}/attachments`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    return response.data.data;
  } catch (e) {
    console.log("Error sending attachments", e);
    throw new Error();
  }
};

type MergeLifeProps = {
  lifeId: string;
  lifeIdToMerge: string;
};

export const useCloseChat = (chatId: string) => {
  return useMutation({
    mutationKey: ["close-chat"],
    mutationFn: () => toggleChat(chatId!, CHAT_STATUS.CLOSED),
  });
};

export const useOpenChat = (chatId: string) => {
  return useMutation({
    mutationKey: ["open-chat"],
    mutationFn: () => toggleChat(chatId!, CHAT_STATUS.OPEN),
  });
};

export const useGetSummary = () => {
  return useMutation({
    mutationKey: ["generate-summary-and-threads"],
    mutationFn: ({
      chatId,
      lifeId,
      existingOrphanThreads,
    }: {
      chatId: string;
      lifeId: string;
      existingOrphanThreads?: MessageProps[];
    }) => generateSummary({ lifeId, chatId, existingOrphanThreads }),
    retryDelay: 15000,
    retry: 2,
  });
};

export const useGenerateThreads = () => {
  return useMutation({
    mutationKey: ["generate-threads"],
    mutationFn: ({
      summary,
      tags,
      chatId,
    }: {
      summary: string;
      tags: string[];
      chatId: string;
    }) => generateThreads(summary, tags, chatId),
  });
};

export const useGetOrphanThreads = (chatId: string, enabled: boolean) => {
  return useQuery({
    queryKey: ["get-orphan-threads"],
    queryFn: () => fetchOrphanThreads(chatId),
    enabled,
  });
};

export const useAddAttachment = () => {
  return useMutation({
    mutationKey: ["add-attachment"],
    mutationFn: ({ files, chatId }: { files: File[]; chatId: string }) =>
      addAttachment(files, chatId),
  });
};

export const useMergeLife = () => {
  return useMutation({
    mutationKey: ["merge-life"],
    mutationFn: async ({ lifeId, lifeIdToMerge }: MergeLifeProps) => {
      const response = await api.post(`api/v1/lives/${lifeId}/merge`, {
        data: {
          id: lifeId,
          type: "lives",
          attributes: {
            new_life_id: lifeIdToMerge,
          },
        },
      });

      return response;
    },
  });
};
