import { signOut, getAuth } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import { useEffect, useState } from "react";
import { CarecodeLogo } from "./svg";
import { Button } from "@mui/material";
import ExitIcon from "@mui/icons-material/ExitToApp";

function Topbar() {
  const [name, setName] = useState<string | null>(null);
  const [photoURL, setPhotoURL] = useState<string | null>(null);

  const navigate = useNavigate();
  const auth = getAuth();
  const { user } = useAuthContext();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/signin");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      setPhotoURL(user?.photoURL);
      setName(user.displayName);
    }
  }, [user]);

  return (
    <div className="w-full pt-[15px] pb-0 px-[15px] flex items-center justify-between">
      <Link to="/">
        <CarecodeLogo width={160} />
      </Link>
      <div className="flex gap-4 items-center">
        {photoURL && (
          <img
            className="w-[37px] h-[37px] bg-white rounded-[50%]"
            src={photoURL}
            alt={`${name}'s photo`}
          />
        )}
        <Button
          variant="outlined"
          onClick={handleSignOut}
          endIcon={<ExitIcon />}
        >
          Sair
        </Button>
      </div>
    </div>
  );
}

export default Topbar;
