import { useEffect } from "react";
import { api } from "../../../api";
import { ChatDataType, TMetaGetConversations } from "../types";
import { useQuery } from "@tanstack/react-query";
import { createConversationList } from "../slices/conversationSlice";
import { useAppDispatch } from "../../../store";
import {
  TAttendenceFilters,
  useGetAttendenceFilters,
} from "./useGetAttendenceFilters";
import { useMeQuery } from "../../../api/hooks/useMeQuery";

type TUseGetConversationParams = {
  skip?: boolean;
};

const normalizeAttendenceFilters = (attendenceFilters: TAttendenceFilters) => {
  const attendenceFiltersCopy = { ...attendenceFilters };
  delete attendenceFiltersCopy.status_open;
  delete attendenceFiltersCopy.status_closed;

  return {
    ...attendenceFiltersCopy,
    status: attendenceFilters.status_closed === "true" ? "CLOSED" : "OPEN",
  };
};

export const useGetConversations = (params?: TUseGetConversationParams) => {
  const dispatch = useAppDispatch();

  const attendenceFilters = useGetAttendenceFilters();

  const isEnabled =
    !params?.skip &&
    Object.keys(normalizeAttendenceFilters(attendenceFilters)).filter(Boolean)
      .length > 0;

  const conversationListQuery = useQuery({
    queryKey: ["conversation-history", attendenceFilters],
    queryFn: async () => {
      const response = await api.get<{
        data: ChatDataType[];
        meta: TMetaGetConversations;
      }>("/api/v1/chats", {
        params: normalizeAttendenceFilters(attendenceFilters),
      });
      return {
        ...response,
        currentFilters: Object.keys(attendenceFilters),
      };
    },
    enabled: isEnabled,
    gcTime: 0,
  });

  const { meQueryData } = useMeQuery();

  const { data: result } = conversationListQuery;

  useEffect(() => {
    if (result) {
      const conversationHistoryData = result.data?.data || [];

      dispatch(
        createConversationList({
          chatList: conversationHistoryData,
          currentLoggedUserId: meQueryData?.id || "",
        })
      );
    }
  }, [dispatch, meQueryData?.id, result]);

  return conversationListQuery;
};
