import { Box, Typography } from "@mui/material";
import { TBalonContentProps } from "../BalonContent";

export const TextMessage = (props: TBalonContentProps) => {
  return (
    <Box>
      <Typography
        data-testid={`chat-balon-${props.direction}`}
        variant="body2"
        dangerouslySetInnerHTML={{
          __html:
            props?.messageData?.attributes?.content?.replace(/\n/g, "<br />") ||
            "",
        }}
      />
    </Box>
  );
};
