export const messages = {
  lifeSuccessfullyUpdated: "Vida atualizada com sucesso",
  lifeSuccessfullyCreated: "Vida criada com sucesso",
  addressSuccessfullyUpdated: "Endereços atualizados com sucesso",
  relativesSuccessfullyUpdated: "Relação familiar atualizada com sucesso",
  relativeSuccessfullyRemoved: "Familiar removido com sucesso",
  benefitsSuccessfullyUpdated: "Benefício removido com sucesso",
  benefitSuccessfullyCreated: "Benefício criado com sucesso",
  benefitSuccessfullyRemoved: "Benefício removido com sucesso",
  summarySuccessfullyGenerated: "Resumo gerado com sucesso",
  phoneSuccessfullyUpdated: "Telefone atualizado com sucesso",
  phoneSuccessfullyRemoved: "Telefone removido com sucesso",
  phoneSuccessfullyCreated: "Telefone adicionado com sucesso",
};
