import { useEffect, useState } from "react";
import { Box, Alert, Typography, CircularProgress } from "@mui/material";
import { InputSearch } from "../../../../../../../components/Form/InputSearch";
import {
  useMessageTemplatesContext,
  TemplateType,
} from "../../../../../../../contexts/MessagesContexts/MessageTemplatesContext";
import { TemplateCard } from "../../TemplateCard";

type ChatTemplateProps = {
  handleOnChooseTemplate: (value: string) => void;
};

export const ChatTemplates = ({
  handleOnChooseTemplate,
}: ChatTemplateProps) => {
  const { templates, isLoading, error } = useMessageTemplatesContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [localTemplates, setLocalTemplates] = useState<TemplateType[]>([]);

  const handleSearchTemplate = (term: string) => {
    const filteredTemplates: TemplateType[] = templates.filter((template) => {
      return (
        template.attributes?.content
          .toLowerCase()
          .includes(term.toLowerCase()) ||
        template.attributes?.description
          .toLowerCase()
          .includes(term.toLowerCase())
      );
    });
    setLocalTemplates(filteredTemplates);
    setSearchTerm(term);
  };

  useEffect(() => {
    setLocalTemplates(templates);
  }, [templates]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ margin: "0 0 20px 0" }}>
        <Typography
          id="modal-modal-title"
          variant="h2"
          sx={{
            width: "100%",
            fontWeight: "normal",
            fontSize: "24px",
          }}
        >
          Modelos de mensagem
        </Typography>
      </Box>
      <Alert severity="warning" variant="filled">
        <Typography variant="h5">
          Altere os campos indicados antes do envio.
        </Typography>
        <Typography variant="body3">
          Ao selecionar um modelo, verifique se tem campos sinalizados entre
          "chaves duplas" e modifique os campos indicados antes de enviar a
          mensagem.
        </Typography>
      </Alert>
      <InputSearch
        label="Buscar tema"
        value={searchTerm}
        handleOnChange={handleSearchTemplate}
        disabled={isLoading}
      />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          padding: "20px 10px",
          overflowY: "auto",
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              overflow: "auto",
              padding: "0 10px 10% 0",
              height: "350px",
            }}
          >
            {error ? (
              <Typography
                variant="h4"
                color="error"
                sx={{
                  textAlign: "center",
                  alignSelf: "center",
                  width: "100%",
                }}
              >
                {error}
              </Typography>
            ) : (
              <>
                {localTemplates.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                    }}
                  >
                    {localTemplates.map((template) => (
                      <TemplateCard
                        key={template.id}
                        description={template.attributes?.description}
                        content={template.attributes?.content}
                        handleOnClick={handleOnChooseTemplate}
                      />
                    ))}
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
