import { useParams } from "react-router-dom";
import { useGetCurrentChat } from "./useGetCurrentChat";

export const useGetCurrentLifeId = () => {
  const params = useParams();

  const currentChat = useGetCurrentChat();

  const lifeId =
    params?.lifeId || currentChat?.chatData?.attributes?.life?.id || "";

  return lifeId;
};
