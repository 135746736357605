import { Box, CircularProgress, Button } from "@mui/material";

type UpdateSectionContainerProps = {
  formMode: "create" | "edit" | null;
  children: React.ReactNode;
  enabled?: boolean;
  loading?: boolean;
  handleOnChangeMode: () => void;
  handleOnSave: () => void;
};

export const UpdateSectionContainer = ({
  formMode,
  children,
  loading = false,
  handleOnChangeMode,
  handleOnSave,
}: UpdateSectionContainerProps) => {
  return (
    <Box
      sx={{
        border: `${
          formMode === "create" ? 0 : "1px solid ${customColors.Neutral05}"
        }`,
        borderRadius: "32px",
        padding: "24px",
        paddingTop: "8px",
        width: "100%",
        display: "inline-flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {formMode === "edit" && (
        <Box sx={{ position: "relative", top: "-32px", right: "-24px" }}>
          <Box
            sx={{
              position: "absolute",
              top: "-24px",
              right: 0,
              display: "flex",
              gap: "8px",
            }}
          >
            <Button
              onClick={handleOnSave}
              sx={{
                width: "130px",
              }}
              variant="contained"
              size="small"
            >
              {loading ? <CircularProgress color="info" size={24} /> : "Salvar"}
            </Button>
            <Button
              onClick={handleOnChangeMode}
              sx={{
                width: "130px",
              }}
              variant="outlined"
              size="small"
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      )}
      {children}
    </Box>
  );
};
