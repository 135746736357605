import { useEffect, useState } from "react";
import { TEmoji } from "../../../../../../../components/CustomEmojiPicker/CustomEmojiPicker";

type TUseEmojiHandlersParams = {
  inputRef: React.RefObject<HTMLInputElement>;
  message: string;
  handleMessageChange: (message: string) => void;
};

export const useEmojiHandlers = ({
  inputRef,
  message,
  handleMessageChange,
}: TUseEmojiHandlersParams) => {
  const [isEmojiSelectionOpen, setIsEmojiSelectionOpen] = useState(false);

  const handleEmojiClick = (emoji: TEmoji) => {
    setIsEmojiSelectionOpen(false);

    if (inputRef.current) {
      const start = inputRef.current.selectionStart || 0;
      const end = inputRef.current.selectionEnd || message?.length;

      const updatedMessage =
        message.slice(0, start) + emoji.native + message.slice(end);
      handleMessageChange(updatedMessage);

      inputRef?.current?.focus();
      inputRef.current?.setSelectionRange?.(start, end);
    }
  };

  const handleToggleEmoji = () => {
    setIsEmojiSelectionOpen(!isEmojiSelectionOpen);
  };

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        setIsEmojiSelectionOpen(false);
      }
    });
  }, []);

  return {
    isEmojiSelectionOpen,
    handleToggleEmoji,
    handleEmojiClick,
  };
};
