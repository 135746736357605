import { validateCPF } from "../../formUtils";

export enum DataTypeEnum {
  Name = "Person Name",
  CPF = "CPF",
  PhoneNumber = "Phone Number",
  Error = "Error",
  Generic = "Generic",
}

const INVALID_CPF_MESSAGE =
  "Parece que você está tentando digitar um CPF, mas está inválido.";
const INVALID_DATA_TYPE_MESSAGE = "O tipo de dado informado não é válido.";
export const getDataType = (
  value: string
): { type: DataTypeEnum; value: string } => {
  const nameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ]{3,}(?:\s[a-zA-ZÀ-ÖØ-öø-ÿ]{3,})*$/;
  const cpfRegex = /^\d{11}$/;
  const phoneRegex = /^(?=.*\d{10,})[\d()]*$/;

  if (nameRegex.test(value)) {
    return { type: DataTypeEnum.Name, value };
  } else if (cpfRegex.test(value)) {
    const isValidCPF = validateCPF(value);
    if (!isValidCPF) {
      return { type: DataTypeEnum.Error, value: INVALID_CPF_MESSAGE };
    }
    return { type: DataTypeEnum.CPF, value };
  } else if (phoneRegex.test(value)) {
    return { type: DataTypeEnum.PhoneNumber, value };
  } else if (value === "") {
    return { type: DataTypeEnum.Name, value };
  } else {
    return { type: DataTypeEnum.Error, value: INVALID_DATA_TYPE_MESSAGE };
  }
};
