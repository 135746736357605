import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  List,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { options } from "./attendenceMenuOptions";
import { useGetCurrentFilterParam } from "../../../../hooks/useGetCurrentFilterParam";
import { TMetaGetConversations } from "../../../../types";
import { TAttendenceFilters } from "../../../../hooks/useGetAttendenceFilters";

type TAttendenceMenuProps = {
  counters?: TMetaGetConversations["counters"];
  isLoading?: boolean;
};

export const AttendencesMenu = (props: TAttendenceMenuProps) => {
  const menuStatus = useGetCurrentFilterParam();
  const [_, setSearchParams] = useSearchParams();

  const [accordionOpen, setAccordionOpen] = useState(true);

  const handleSelect = (status: string, filters: TAttendenceFilters) => {
    setSearchParams({ attendencesMenu: status, ...filters });
  };

  const optionsWithCounters = options.map((option) => {
    const currentStatus = option.status === "status" ? "closed" : option.status;
    return {
      ...option,
      count:
        props?.counters?.[
          currentStatus as keyof TMetaGetConversations["counters"]
        ] || 0,
    };
  });

  return (
    <Accordion
      disableGutters
      defaultExpanded
      expanded={accordionOpen}
      onChange={() => setAccordionOpen(!accordionOpen)}
      sx={{
        background: "transparent",
        backgroundImage: "none",
        border: 0,
        boxShadow: "none",
        marginBottom: accordionOpen ? 0 : 2,
      }}
    >
      <AccordionSummary
        sx={{
          height: "30px",
          minHeight: "30px",
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: "secondary.contrastText" }} />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="h6" fontWeight="medium">
          Atendimentos
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0, margin: 0 }}>
        <List>
          {optionsWithCounters.map((option) => {
            return (
              <Box
                key={option.label}
                onClick={() =>
                  !props.isLoading &&
                  handleSelect(option.status, option.filters)
                }
                sx={[
                  {
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    marginBottom: 1,
                    padding: 0.5,
                    paddingX: 2,
                    borderRadius: "32px",
                  },
                  option.status === menuStatus && {
                    backgroundColor: "primary.main",
                  },
                ]}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box display="flex" mr={1}>
                    {React.cloneElement(option.icon, {
                      sx: [
                        { color: "secondary.contrastText" },
                        option.status === menuStatus && {
                          color: "primary.contrastText",
                        },
                      ],
                    })}
                  </Box>
                  <Typography
                    variant="body1"
                    sx={[
                      option.status === menuStatus && {
                        color: "primary.contrastText",
                      },
                    ]}
                  >
                    {option.label}
                  </Typography>
                </Box>
                <Box>
                  {props.isLoading ? (
                    <CircularProgress size={12} />
                  ) : (
                    <Typography
                      variant="h6"
                      sx={[
                        option.status === menuStatus && {
                          color: "primary.contrastText",
                        },
                      ]}
                    >
                      {option.count}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
