import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { ScreenHeader } from "../../components/ScreenHeader";
import { ConversationList } from "./components/ConversationList/ConversationList";
import { ChatView } from "./components/ChatView";
import { useGetConversations } from "./hooks/useGetConversations";

export const ChatArea = () => {
  useGetConversations();
  const params = useParams();
  const currentChatId = params?.id || "";

  return (
    <>
      <Box minWidth={"294px"}>
        <ScreenHeader title="Conversas" icon={ChatBubbleOutlineIcon} />
        <ConversationList />
      </Box>
      <Box sx={{ width: "100%" }}>
        <ChatView key={currentChatId} />
      </Box>
    </>
  );
};
