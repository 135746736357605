import { GoogleIcon } from "../../../icons";
import { GoogleProvider } from "../../../infra/firebase";
import { signInWithPopup, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useEffect } from "react";

type GoogleButtonProps = {
  callbackURL: string;
  tenantId: string;
};

const GoogleButton = ({ callbackURL, tenantId }: GoogleButtonProps) => {
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (tenantId === "aurora-qcwms") {
      auth.tenantId = tenantId;
    } else {
      auth.tenantId = null;
    }
  }, [auth, tenantId]);

  const handleGoogleLogin = () => {
    console.log({ tenantId, authTenantId: auth.tenantId });
    signInWithPopup(auth, GoogleProvider)
      .then((_) => {
        navigate(callbackURL);
      })
      .catch((_) => {
        console.log("Error signing in with Google");
      });
  };

  return (
    <Button
      color="primary"
      variant="contained"
      sx={{
        width: "294px",
        height: "40px",
        borderRadius: 20,
        textTransform: "none",
        fontSize: "1.0rem",
        fontWeight: "bold",
      }}
      onClick={handleGoogleLogin}
      startIcon={<GoogleIcon />}
    >
      Continuar com o Google
    </Button>
  );
};

export default GoogleButton;
