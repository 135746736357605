import { useQuery } from "@tanstack/react-query";
import { TimelineEventsResponse } from "../timelineTypes";
import { api } from "../../../api";

type TUseGetTimelineEventsQueryParams = {
  lifeId: string;
};

export const useGetTimelineEventsQuery = (
  params: TUseGetTimelineEventsQueryParams
) => {
  const timelineEventsQuery = useQuery({
    queryKey: ["timeline-events", params.lifeId],
    queryFn: async () => {
      return api.get<TimelineEventsResponse>("api/v1/timeline", {
        params: {
          life_id: params.lifeId,
        },
      });
    },
  });

  return timelineEventsQuery;
};
