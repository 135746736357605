import { customColors, muiThemeColors } from "../colors";
import { DEFAULT_BORDER_RADIUS } from "../design-tokens";

export const MuiSelectNode = {
  styleOverrides: {
    root: {
      borderRadius: DEFAULT_BORDER_RADIUS,
      borderColor: "red",
      color: customColors.Neutral07,
      fontSize: "16px",
      height: "45px",
      paddingLeft: "12px",

      "& .MuiSelect-outlined": {
        " .MuiOutlinedInput-input": {
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "red !important",
        },
      },

      "& .MuiSelect-icon": {
        fontSize: "20px",
        marginRight: "15px",
        color: muiThemeColors.secondary.contrastText,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: muiThemeColors.secondary.contrastText,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: muiThemeColors.secondary.contrastText,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: muiThemeColors.secondary.contrastText,
      },

      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: muiThemeColors.secondary.contrastText,
        color: customColors.OffWhite,
        backgroundColor: customColors.BoxBackgroundColor,
      },
    },
  },
};
