export const transformFilesToFormData = (
  files: File[],
  formDataName: string
): FormData => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append(formDataName, file);
  });
  return formData;
};
