import { BenefitsContextProvider } from "../contexts/RegistrationContexts/BenefitsContext";
import { RelativesContextProvider } from "../contexts/RegistrationContexts/RelativesContext";
import { AddressesContextProvider } from "../contexts/RegistrationContexts/AddressesContext";
import { GeneralDataContextProvider } from "../contexts/RegistrationContexts/GeneralDataContext";
import { LivesContextProvider } from "../contexts/RegistrationContexts/LivesContext";

type FormProviderProps = {
  children: React.ReactNode;
};

export const FormProvider = ({ children }: FormProviderProps) => (
  <BenefitsContextProvider>
    <RelativesContextProvider>
      <AddressesContextProvider>
        <GeneralDataContextProvider>
          <LivesContextProvider>{children}</LivesContextProvider>
        </GeneralDataContextProvider>
      </AddressesContextProvider>
    </RelativesContextProvider>
  </BenefitsContextProvider>
);
