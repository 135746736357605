import { Box } from "@mui/material";
import {
  ChatTemplates,
  ChatCommands,
  CommandsTypesEnum,
} from "./ChatModalContents";
import { SearchLife } from "./ChatModalContents/SearchLife/SearchLife";
import { ModalContentType } from "../../ChatView";
import { CustomDialog } from "../../../../../../components/CustomDialog/CustomDialog";

export type CommandLabelType =
  | "select_template"
  | "create_health_survey"
  | "merge_life";

export type CommandType = {
  command: CommandLabelType;
  callback?: (value: string) => void;
}[];

type ChatModalProps = {
  open: boolean;
  contentType: ModalContentType;
  isChatClosed: boolean;
  canBeMerged: boolean;
  loading: boolean;
  handleOnClose: () => void;
  setTemplatesCallback: (value: string) => void;
  handleOnChangeModalContentType: (value: ModalContentType) => void;
  commands: CommandType;
};

export const ChatModal = ({
  open,
  contentType,
  isChatClosed,
  canBeMerged,
  loading,
  handleOnClose,
  setTemplatesCallback,
  handleOnChangeModalContentType,
  commands,
}: ChatModalProps) => {
  const handleOnCloseModal = () => {
    handleOnChangeModalContentType("commands");
    handleOnClose();
  };

  const handleOnChooseCommand = (commandName: CommandsTypesEnum) => {
    const command = commands.find((command) => command.command === commandName);

    if (command?.command === "select_template") {
      handleOnChangeModalContentType("templates");
    } else if (command?.command === "merge_life") {
      handleOnChangeModalContentType("search_life");
    }
  };

  const getContentByType = (content: ModalContentType) => {
    switch (content) {
      case "templates":
        return <ChatTemplates handleOnChooseTemplate={setTemplatesCallback} />;
      case "search_life":
        return <SearchLife handleOnClose={handleOnCloseModal} />;
      default:
      case "commands":
        return (
          <ChatCommands
            loading={loading}
            isChatClosed={isChatClosed}
            canBeMerged={canBeMerged}
            handleOnChooseCommand={handleOnChooseCommand}
          />
        );
    }
  };

  return (
    <CustomDialog open={open} onClose={handleOnCloseModal} fullWidth>
      <Box>{getContentByType(contentType)}</Box>
    </CustomDialog>
  );
};
