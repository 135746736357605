import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Notifier } from "./providers/Notifier";
// import { getTheme } from "@carecode-health/pulse-ds";
import { customTheme } from "./theme";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Notifier />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
