import TimelineContent from "@mui/lab/TimelineContent";
import { Box, Button, Typography } from "@mui/material";
import { formatDate } from "../../Chat/utils/dateUtils";
import { ThreadResource } from "../timelineTypes";
import { ChipTag } from "../../../components/ChipTag";
import { ChatBubbleOutline, ExpandLess, ExpandMore } from "@mui/icons-material";
import { CustomTimelineSeparator } from "./CustomTimelineSeparator";

type TThreadTimelineEventProps = {
  threadEvent: ThreadResource;
  isExpandedRow: boolean;
  index: number;
  handleExpand: (index: number) => void;
};

export const ThreadTimelineEvent = (props: TThreadTimelineEventProps) => {
  const event = props.threadEvent;

  return (
    <>
      <CustomTimelineSeparator>
        <ChatBubbleOutline />
      </CustomTimelineSeparator>
      <TimelineContent sx={{ padding: 2 }}>
        <Box>
          <Typography variant="caption">
            {formatDate(event?.attributes?.created_at || "")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body3" fontWeight="bold">
            {event?.attributes?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            my: 1,
            maxWidth: "70%",
            flexWrap: "wrap",
          }}
        >
          {event?.attributes?.tagged_items?.map((tag, tagIndex) => (
            <ChipTag
              key={tagIndex}
              label={tag?.attributes?.label}
              bgColor={tag?.attributes?.color || "#000"}
            />
          ))}
        </Box>
        <Box
          sx={[
            !props.isExpandedRow && {
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "3em",
              lineHeight: "1.5em",
              margin: 0,
            },
          ]}
        >
          <Typography variant="button">{event?.attributes?.summary}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="text"
            color="primary"
            endIcon={props.isExpandedRow ? <ExpandLess /> : <ExpandMore />}
            onClick={() => props.handleExpand(props.index)}
          >
            Ver mais
          </Button>
        </Box>
      </TimelineContent>
    </>
  );
};
