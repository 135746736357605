import HafferSQXHMedium from "../fonts/haffer/ttf/HafferSQXH-Medium.ttf";
import HafferSQXHBold from "../fonts/haffer/ttf/HafferSQXH-Bold.ttf";
import HafferSQXHRegular from "../fonts/haffer/ttf/HafferSQXH-Regular.ttf";

export const MuiCssBaseLine = {
  styleOverrides: `
			@font-face {
				font-family: 'HafferSQXH-Medium';
				font-style: normal;
				font-display: swap;
				font-weight: 700;
				src: url(${HafferSQXHMedium}) format('truetype');
			}
			@font-face {
				font-family: 'HafferSQXH-Bold';
				font-weight: 900;
				src: url(${HafferSQXHBold}) format('truetype');
			}
			@font-face {
				font-family: 'HafferSQXH-Regular';
				font-style: normal;
				font-display: swap;
				font-weight: 400;
				src: url(${HafferSQXHRegular}) format('truetype');
			}
	`,
};
