import { SystemUser } from "./hooks/useGetSystemUsers";

export const removeDuplicatedAssignUsers = (
  acc: SystemUser[],
  value: SystemUser
) => {
  const hasName = acc?.find(
    (item) => item?.attributes?.name === value?.attributes?.name
  );
  if (!hasName) {
    acc.push(value);
  }
  return acc;
};
