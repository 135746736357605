import { useQuery } from "@tanstack/react-query";
import { SocketEvent } from "../types";
import { AxiosResponse } from "axios";
import { api } from "../../../api";

type TUseChatEventsParams = {
  currentChatId: string;
  page: number;
  enableMessageHistory: boolean;
};

export const useGetChatEvents = (params: TUseChatEventsParams) => {
  return useQuery<AxiosResponse<{ data: SocketEvent[] }>>({
    queryFn: () => {
      return api.get(`/api/v1/chat-events`, {
        params: {
          chat_id: params.currentChatId,
          limit: 80,
          page: params.page,
        },
      });
    },
    queryKey: ["message-history", params.page, params.currentChatId],
    enabled: params.enableMessageHistory,
    gcTime: Infinity,
    staleTime: Infinity,
  });
};
