export const customColors = {
  Support01: "#FFAA00",
  OffWhite: "#F5F3F1",
  OrangeMain: "#FF5D00",
  OrangeDark: "#b24100",
  OrangeLight: "#ff7d33",
  Neutral03: "#e6e6e6",
  Neutral04: "#d8d7d7",
  Neutral05: "#CACACA",
  Neutral06: "#ADADAD",
  Neutral07: "#717171",
  Neutral08: "#545454",
  Neutral09: "#292929",
  PurpleMain: "#543250",
  PurpleDark: "#2D1E2F",
  PurpleLight: "#765b73",
  BoxBackgroundColor: "#E5E2E0",
  White: "#FFFFFF",
  PrimaryNeutral: "#ADADAD",
  SecondaryNeutral: "#616161",
  MainSuccess: "#2E7D32",
  WarningBackground: "#FDF0E5",
  ErrorMain: "#D32F2F",
  ErrorDark: "#C62828",
  ErrorLight: "#EF5350",
  WarningMain: "#EF6C00",
  WarningDark: "#E65100",
  WarningLight: "#FF9800",
  InfoMain: "#0288D1",
  InfoDark: "#01579B",
  InfoLight: "#03A9F4",
  SuccessMain: "#2E7D32",
  SuccessDark: "#1B5E20",
  SuccessLight: "#4CAF50",
  MicrosoftButtonColor: "#2163A8",
  TextSecondary: "rgba(0,0,0,.6)",
};

export const muiThemeColors = {
  primary: {
    main: customColors.PurpleMain,
    dark: customColors.PurpleDark,
    light: customColors.PurpleLight,
    contrastText: customColors.White,
    neutral: customColors.PrimaryNeutral,
  },
  secondary: {
    main: customColors.OrangeMain,
    dark: customColors.OrangeDark,
    light: customColors.OrangeLight,
    contrastText: customColors.Neutral07,
    neutral: customColors.SecondaryNeutral,
  },
  error: {
    main: customColors.ErrorMain,
    dark: customColors.ErrorDark,
    light: customColors.ErrorLight,
  },
  warning: {
    main: customColors.WarningMain,
    dark: customColors.WarningDark,
    light: customColors.WarningLight,
  },
  info: {
    main: customColors.InfoMain,
    dark: customColors.InfoDark,
    light: customColors.InfoLight,
  },
  success: {
    main: customColors.MainSuccess,
    dark: customColors.SuccessDark,
    light: customColors.SuccessLight,
  },
};
