import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { TagsType } from "../../../../../types";
import { ChipTag } from "../../../../../../../components/ChipTag";

type SelectTagsProps = {
  tags: TagsType[];
  inferredTags: TagsType[];
  disabled?: boolean;
  handleOnChange:
    | React.Dispatch<React.SetStateAction<TagsType[]>>
    | ((
        value: TagsType[],
        reason: AutocompleteChangeReason,
        option: AutocompleteChangeDetails<TagsType> | undefined
      ) => void);
  fullWidth?: boolean;
  freeSolo?: boolean;
  inputStyles?: SxProps<Theme>;
  value?: TagsType[];
};

export const SelectTags = ({
  tags,
  inferredTags,
  handleOnChange,
  disabled = false,
  fullWidth,
  freeSolo = false,
  inputStyles,
  value,
}: SelectTagsProps) => {
  return (
    <Autocomplete
      fullWidth={fullWidth}
      freeSolo={freeSolo}
      multiple
      clearIcon={null}
      id="tags-outlined"
      options={tags}
      getOptionLabel={(option) => (option as TagsType)?.attributes?.label || ""}
      defaultValue={inferredTags}
      value={value}
      filterSelectedOptions
      disabled={disabled}
      onChange={(_, value, reason, option) => {
        handleOnChange(value as TagsType[], reason, option);
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <ChipTag
            {...getTagProps({ index })}
            key={index}
            variant="filled"
            label={option.attributes?.label}
            deleteIcon={<CancelIcon data-testid="delete-tag-icon" />}
            bgColor={option.attributes?.color || "#000"}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          sx={inputStyles}
          label="Adicionar tags"
          data-testid="add-tags-input"
        />
      )}
    />
  );
};
