import React, { createContext, useState, useContext } from "react";
import { CoverageType, KinShipEnum } from "../../screens/Registration/types";

export type RelativesType = {
  type?: string;
  id?: string;
  attributes: {
    life_kinship: string;
    relative_kinship: string;
    relative_id: string;
    life_id: string;
    relative?: {
      id: string;
      type?: string;
      attributes: {
        first_name: string;
        last_name: string;
      };
    };
    coverages?: CoverageType[];
  };
};

export type NewLifeRelativesType = {
  life_kinship: KinShipEnum;
  relative_kinship: KinShipEnum;
  relative_id: string;
  first_name?: string;
  last_name?: string;
};

type ContextType = {
  relatives: RelativesType[];
  cachedRelatives: RelativesType[];
  newLifeRelatives: NewLifeRelativesType[];
  setRelatives: React.Dispatch<React.SetStateAction<RelativesType[]>>;
  setCachedRelatives: React.Dispatch<React.SetStateAction<RelativesType[]>>;
  setNewLifeRelatives: React.Dispatch<
    React.SetStateAction<NewLifeRelativesType[]>
  >;
  resetRelativesContext: () => void;
};

const RelativesContext = createContext<ContextType | undefined>(undefined);

export const RelativesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [relatives, setRelatives] = useState<RelativesType[]>([]);
  const [cachedRelatives, setCachedRelatives] = useState<RelativesType[]>([]);
  const [newLifeRelatives, setNewLifeRelatives] = useState<
    NewLifeRelativesType[]
  >([]);

  const resetRelativesContext = () => {
    setRelatives([]);
    setCachedRelatives([]);
    setNewLifeRelatives([]);
  };

  return (
    <RelativesContext.Provider
      value={{
        relatives,
        cachedRelatives,
        newLifeRelatives,
        setRelatives,
        setCachedRelatives,
        setNewLifeRelatives,
        resetRelativesContext,
      }}
    >
      {children}
    </RelativesContext.Provider>
  );
};

export const useRelativesContext = () => {
  const context = useContext(RelativesContext);
  if (!context) {
    throw new Error(
      "useBenefitsContext must be used within a RelativesContextProvider"
    );
  }
  return context;
};
