import { useState } from "react";
import { useCreateEncounterMutation } from "../../../hooks/useCreateEncounterMutation";
import { AppointmentResource } from "../../../timelineTypes";
import { renderNotifier } from "../../../../../providers/Notifier";
import { useRemoveAppointmentFromQueryData } from "./useRemoveAppointmentFromQueryData";
import { useCreateEncounterDocumentsMutation } from "../../../hooks/useCreateEncounterDocumentsMutation";

type TSaveEncounterParams = {
  appointment: AppointmentResource;
  index: number;
  successCallback?: () => void;
  attachments?: File[];
};

type TUseEncounterHandlersParams = {
  lifeId: string;
};

export const useEncounterHandlers = (params: TUseEncounterHandlersParams) => {
  const [encounterText, setEncounterText] = useState<string[]>([]);
  const [openedAppointment, setOpenedAppointment] = useState<
    AppointmentResource | undefined
  >();
  const [
    createEncounterConfirmDialogOpen,
    setCreateEncounterConfirmDialogOpen,
  ] = useState<boolean[]>([]);

  const handleOpenCreateEncounterDialog = (index: number) => {
    setCreateEncounterConfirmDialogOpen((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  const closeCreateEncounterDialog = (index: number) => {
    setCreateEncounterConfirmDialogOpen((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };

  const notShowCreateEncounterConfirmDialogAgain =
    localStorage.getItem("notShowCreateEncounterConfirmDialogAgain") === "true";

  const { removeAppointmentFromQueryData } =
    useRemoveAppointmentFromQueryData();

  const { createEncounterMutation } = useCreateEncounterMutation();
  const { createEncounterDocumentsMutation } =
    useCreateEncounterDocumentsMutation();

  const handleSaveEncounter = async (
    saveEncounterParams: TSaveEncounterParams
  ) => {
    const { appointment, index, successCallback, attachments } =
      saveEncounterParams;
    try {
      const encounterResponse = await createEncounterMutation.mutateAsync({
        medicalNotes: encounterText[index],
        reason: appointment?.attributes?.reason,
        appointmentId: appointment.id,
        participants: appointment?.attributes?.participants || [],
        virtualService: appointment?.attributes?.virtual_service,
      });
      if (Number(attachments?.length) > 0) {
        await createEncounterDocumentsMutation.mutateAsync({
          encounterId: encounterResponse?.data?.data?.id || "",
          attachments: attachments || [],
        });
      }
      removeAppointmentFromQueryData({
        lifeId: params.lifeId,
        appointmentId: appointment?.id,
      });
      successCallback?.();
    } catch (e) {
      console.log(e);
      renderNotifier("Erro ao salvar a consulta", {
        variant: "error",
      });
    }
  };

  const handleEncounterChange = (value: string, index: number) => {
    setEncounterText((prev) => {
      const newState = [...prev];
      newState[index] = value;
      return newState;
    });
  };

  return {
    handleSaveEncounter,
    encounterText,
    openedAppointment,
    setOpenedAppointment,
    handleEncounterChange,
    createEncounterMutation,
    createEncounterDocumentsMutation,
    handleOpenCreateEncounterDialog,
    createEncounterConfirmDialogOpen,
    closeCreateEncounterDialog,
    notShowCreateEncounterConfirmDialogAgain,
  };
};
