import { z } from "zod";
import { validateEmail } from "./formUtils";

export const FormSchema = z.object({
  firstName: z
    .string()
    .min(3, "O campo Primeiro nome deve conter ao menos 3 characters."),
  lastName: z
    .string()
    .min(3, "O campo Último nome deve conter ao menos 3 characters."),
  socialName: z.string().optional(),
  email: z
    .string()
    .refine((value) => (value ? validateEmail(value) : true), "Email inválido.")
    .optional(),
  birthDate: z
    .string()
    .refine(
      (value) => {
        const dateArray = value.split("/");
        const date = new Date(
          Number(dateArray[2]),
          Number(dateArray[1]) - 1,
          Number(dateArray[0])
        );
        return value ? !isNaN(Date.parse(date.toString())) : true;
      },
      {
        message: "Forneça uma data de nascimento válida.",
      }
    )
    .optional(),
  gender: z.string().optional(),
  sex: z.string().optional(),
});
