import { Box } from "@mui/material";
import { PlaylistAddCheckOutlined } from "@mui/icons-material";
import { ScreenHeader } from "../../components/ScreenHeader";
import { TasksTable } from "./components/TasksTable";
import { TasksActions } from "./components/TasksActions";

export const TasksScreen = () => {
  return (
    <Box sx={{ mt: 4, padding: 2 }}>
      <Box sx={{ display: "flex" }}>
        <ScreenHeader title="Tarefas" icon={PlaylistAddCheckOutlined} />
        <Box sx={{ mb: 2 }}>
          <TasksActions />
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <TasksTable />
      </Box>
    </Box>
  );
};
