import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import AddCommentOutlined from "@mui/icons-material/AddCommentOutlined";
import { GridActionButtonWrapper } from "./GridActionButtonWrapper";
import { GridRowId } from "@mui/x-data-grid";
import { useCreateChatMutation } from "../../hooks/useCreateChatMutation";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useAppDispatch } from "../../../../store";
import { createConversationList } from "../../../Chat/slices/conversationSlice";
import { ChatDataType } from "../../../Chat/types";
import { renderNotifier } from "../../../../providers/Notifier";
import { useMeQuery } from "../../../../api/hooks/useMeQuery";

type TCreateChatButtonProps = {
  chatId: GridRowId;
  lifeId: GridRowId;
};

export const CreateChatButton = ({
  chatId,
  lifeId,
}: TCreateChatButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { createChatMutation } = useCreateChatMutation({ lifeId });
  const { meQueryData } = useMeQuery();

  const handleChatCreate = async () => {
    if (chatId) {
      navigate(`/chat/${chatId}`);
      return;
    }

    try {
      const response = await createChatMutation.mutateAsync();
      if (!response) {
        renderNotifier("Erro ao criar chat", { variant: "error" });
        return;
      }

      const createdChatId = response?.data?.data?.id;
      dispatch(
        createConversationList({
          chatList: [response?.data?.data as unknown as ChatDataType],
          currentLoggedUserId: meQueryData?.id || "",
        })
      );
      navigate(`/chat/${createdChatId}`);
    } catch (_) {
      renderNotifier("Erro ao criar chat", { variant: "error" });
    }
  };

  const CurrentIcon = chatId
    ? ChatBubbleOutlineOutlinedIcon
    : AddCommentOutlined;

  return (
    <GridActionButtonWrapper>
      {createChatMutation?.isPending ? (
        <CircularProgress size={20} />
      ) : (
        <CurrentIcon
          onClick={handleChatCreate}
          fontSize="small"
          color="primary"
          sx={{
            cursor: "pointer",
          }}
          data-testid={`chat-button-${chatId}`}
        />
      )}
    </GridActionButtonWrapper>
  );
};
