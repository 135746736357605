import { useQuery } from "@tanstack/react-query";
import { getAuth } from "firebase/auth";

type TUseAuthenticateMediaParams = {
  url: string;
};

export const useAuthenticateMedia = (params: TUseAuthenticateMediaParams) => {
  const isFetchMediaEnabled = Boolean(params.url);

  return useQuery({
    queryKey: ["authenticate-media", params.url],
    queryFn: async () => {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      const response = await fetch(params.url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseBlobUrl = await response.blob();
      return URL.createObjectURL(responseBlobUrl);
    },
    enabled: isFetchMediaEnabled,
    retry: false,
  });
};
