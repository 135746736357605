import { Box, Button } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { CustomFileInput } from "../../../../../../components/CustomFileInput";

type ChatAttatchFileProps = {
  disabled: boolean;
  handleOnSelectFiles: (fileList: FileList) => void;
};

export const ChatAttatchFile = ({
  disabled,
  handleOnSelectFiles,
}: ChatAttatchFileProps) => {
  return (
    <Box>
      <CustomFileInput
        handleOnSelectFiles={(files) => {
          handleOnSelectFiles(files);
        }}
      >
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          data-testid="attach-file-button"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: 0,
            margin: 0,
            padding: "8px",
            borderRadius: "8px",
            minWidth: "auto",
            outline: "none",
            transform: "rotate(90deg)",
            "&:hover": {
              border: 0,
            },
            "&:disabled": {
              backgroundColor: "transparent",
            },
          }}
          disabled={disabled}
        >
          <InsertLinkIcon fontSize="small" />
        </Button>
      </CustomFileInput>
    </Box>
  );
};
