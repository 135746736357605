import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material";
import { TagsType } from "../../../types";

import { SelectTags } from "../../ChatView/components/CloseChatModal/components";

import { useToggleTagMutation } from "../lifeApi/useToggleTagMutation";
import { useGetAllTagsQuery } from "../lifeApi/useGetAllTagsQuery";
import { useGetCurrentLifeId } from "../../../hooks/useGetCurrentLifeId";

type TSummaryTagListProps = {
  tags?: TagsType[];
};

export const SummaryTagList = (props: TSummaryTagListProps) => {
  const toggleTagOnLifeMutation = useToggleTagMutation();
  const allTagsQuery = useGetAllTagsQuery({ entity: "LIFE" });

  const lifeId = useGetCurrentLifeId();

  const tagsResponse = allTagsQuery.data;

  const handleSelectTags = async (
    _: TagsType[],
    reason: AutocompleteChangeReason,
    option: AutocompleteChangeDetails<TagsType> | undefined
  ) => {
    toggleTagOnLifeMutation.mutate({
      reason,
      lifeId,
      data: {
        type: "tag-items",
        attributes: {
          tag_id: option?.option?.id || "",
        },
      },
    });
  };

  return (
    <SelectTags
      freeSolo
      fullWidth
      inferredTags={props.tags || []}
      value={props.tags || []}
      tags={tagsResponse || []}
      handleOnChange={handleSelectTags}
      disabled={toggleTagOnLifeMutation.isPending}
      inputStyles={{
        "& .MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderStyle: "solid",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderStyle: "none",
          "&:hover": {
            borderStyle: "solid",
          },
        },
      }}
    />
  );
};
