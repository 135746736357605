import { ThemeOptions } from "@mui/material";
import {
  MuiCssBaseLine,
  PaletteNode,
  MuiButtonNode,
  TypographyNode,
  MuiTextFieldNode,
  MuiFormHelperTextNode,
  MuiSelectNode,
  MuiMenuItemNode,
  MuiTabNode,
  MuiAlertNode,
  MuiChipNode,
  MuiTypographyNode,
  MuiIconButtonNode,
  MuiDialogNode,
} from "./mui-theme-nodes";
import { MuiInputLabelNode } from "./mui-theme-nodes/MuiInputLabelNode";

export const themeNodes: ThemeOptions = {
  palette: PaletteNode,
  typography: TypographyNode,
  components: {
    MuiCssBaseline: MuiCssBaseLine,
    MuiButton: MuiButtonNode,
    MuiTextField: MuiTextFieldNode,
    MuiFormHelperText: MuiFormHelperTextNode,
    MuiDialog: MuiDialogNode,
    MuiSelect: MuiSelectNode,
    MuiMenuItem: MuiMenuItemNode,
    MuiTab: MuiTabNode,
    MuiAlert: MuiAlertNode,
    MuiInputLabel: MuiInputLabelNode,
    MuiChip: MuiChipNode,
    MuiTypography: MuiTypographyNode,
    MuiIconButton: MuiIconButtonNode,
  },
};
