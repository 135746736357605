import { Box } from "@mui/material";

type ScreenContainerProps = {
  children: React.ReactNode;
};

const ScreenContainer = ({ children }: ScreenContainerProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "0 20px 0 20px",
    }}
  >
    {children}
  </Box>
);

export default ScreenContainer;
