import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogProps,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { CustomDialog } from "../../../components/CustomDialog/CustomDialog";
import { useState } from "react";

type TCreateEncounterConfirmDialogProps = {
  dialogProps: DialogProps;
  onClickBack: () => void;
  onClickProceed: () => void;
  isLoading?: boolean;
};

export const CreateEncounterConfirmDialog = (
  props: TCreateEncounterConfirmDialogProps
) => {
  const [selectedValues, setSelectedValues] = useState({
    notShowCreateEncounterConfirmDialogAgain: false,
  });

  const handleBack = () => {
    props?.onClickBack?.();
  };

  const handleProceed = () => {
    if (selectedValues.notShowCreateEncounterConfirmDialogAgain) {
      localStorage.setItem("notShowCreateEncounterConfirmDialogAgain", "true");
    } else {
      localStorage.setItem("notShowCreateEncounterConfirmDialogAgain", "false");
    }

    props?.onClickProceed?.();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValues({
      ...selectedValues,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <CustomDialog {...props.dialogProps} onClose={() => handleBack()}>
      <Box>
        <Box mb={2}>
          <Typography variant="h4">
            Após salvar não será possível editar
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body2">
            Ao salvar o prontuário não será possível editar posteriormente.
            Deseja prosseguir?
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Box width="141px">
            <Button
              fullWidth
              variant="outlined"
              onClick={handleBack}
              size="small"
            >
              Voltar
            </Button>
          </Box>
          <Box width="196px">
            <Button
              fullWidth
              variant="contained"
              onClick={handleProceed}
              disabled={props.isLoading}
              endIcon={
                props.isLoading ? (
                  <CircularProgress
                    size={20}
                    data-testid="create-encounter-confirm-dialog-loading"
                  />
                ) : null
              }
              size="small"
            >
              Sim, salvar prontuário
            </Button>
          </Box>
        </Box>
        <Box mt={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    selectedValues.notShowCreateEncounterConfirmDialogAgain
                  }
                  onChange={handleChange}
                  name="notShowCreateEncounterConfirmDialogAgain"
                />
              }
              label={
                <Typography variant="body2">Não mostrar novamente</Typography>
              }
            />
          </FormGroup>
        </Box>
      </Box>
    </CustomDialog>
  );
};
