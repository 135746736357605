import { createBrowserRouter } from "react-router-dom";
import RootLayout from "./root";
import { ChatScreen } from "../screens/Chat";
import Home from "../screens/Home";
import { SignIn } from "../screens/SignIn";

import Registration from "../screens/Registration/";
import NewRegistration from "../screens/Registration/NewRegistration";
import PrivateInternalComponent from "./PrivateInternalComponent";
import { FormProvider, MessageTemplatesContextProvider } from "../providers";
import { ChatTimeline } from "../screens/ChatTimeline";
import { AuthenticatedMedia } from "../screens/AuthenticatedMedia";
import { TasksScreen } from "../screens/TasksScreen";

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: (
          <PrivateInternalComponent>
            <Home />
          </PrivateInternalComponent>
        ),
      },
      {
        path: "pacientes/",
        element: (
          <PrivateInternalComponent>
            <FormProvider>
              <Registration />
            </FormProvider>
          </PrivateInternalComponent>
        ),
      },
      {
        path: "pacientes/novo-paciente/",
        element: (
          <PrivateInternalComponent>
            <FormProvider>
              <NewRegistration />
            </FormProvider>
          </PrivateInternalComponent>
        ),
      },
      {
        path: "pacientes/:id",
        element: (
          <PrivateInternalComponent>
            <FormProvider>
              <NewRegistration />
            </FormProvider>
          </PrivateInternalComponent>
        ),
      },
      {
        path: "signin/",
        element: <SignIn />,
      },
      {
        path: "chat/*",
        element: (
          <PrivateInternalComponent>
            <MessageTemplatesContextProvider>
              <ChatScreen />
            </MessageTemplatesContextProvider>
          </PrivateInternalComponent>
        ),
        children: [
          {
            path: ":id",
            element: (
              <PrivateInternalComponent>
                <ChatScreen />
              </PrivateInternalComponent>
            ),
          },
          {
            path: ":lifeId/timeline",
            element: (
              <PrivateInternalComponent>
                <ChatTimeline />
              </PrivateInternalComponent>
            ),
          },
        ],
      },
      {
        path: "tasks/*",
        element: (
          <PrivateInternalComponent>
            <TasksScreen />
          </PrivateInternalComponent>
        ),
      },
      {
        path: "media/*",
        element: (
          <PrivateInternalComponent>
            <AuthenticatedMedia />
          </PrivateInternalComponent>
        ),
      },
    ],
  },
]);

export default router;
