import { useMutation } from "@tanstack/react-query";
import { api } from "../../../api";
import { transformFilesToFormData } from "../../../utils/formDataUtils";

export const useCreateEncounterDocumentsMutation = () => {
  const createEncounterDocumentsMutation = useMutation({
    mutationKey: ["create-encounter-documents"],
    mutationFn: async (params: {
      encounterId: string;
      attachments: File[];
    }) => {
      const formData = transformFilesToFormData(params.attachments, "files");

      return api.post(
        `/api/v1/encounter-documents/${params.encounterId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    },
  });

  return { createEncounterDocumentsMutation };
};
