import { Box, Link } from "@mui/material";
import { TextMessage } from "./TextMessage";
import { TMediaComponentProps } from "../BalonContent";
import { FileMessageFooter } from "./FileMessageFooter";

export const ImageMessage = (props: TMediaComponentProps) => {
  const url = props.publicUrl;
  const fileName = url?.split("/").pop() || "";

  return (
    <Box>
      <Box my={1}>
        <Link
          href={props.signedMediaUrl}
          target="_blank"
          rel="noreferrer noreferrer"
          sx={{ width: "100%", display: "block" }}
        >
          <img width="100%" src={props.signedMediaUrl} alt="chat-image" />
        </Link>
      </Box>
      <Box width="100%">
        <FileMessageFooter fileName={fileName} fileType="image/*" />
      </Box>
      <Box my={1}>
        <TextMessage {...props} />
      </Box>
    </Box>
  );
};
