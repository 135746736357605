import { ChangeEvent, ClipboardEvent, useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { validateCPF } from "../../../../../../../Registration/formUtils";
import {
  useGetLife,
  useGetLifeById,
} from "../../../../../../../Registration/hooks/useLives";
import { useMergeLife } from "../../../../hooks/useChat";
import { renderNotifier } from "../../../../../../../../providers/Notifier";
import { useGetCurrentLifeId } from "../../../../../../hooks/useGetCurrentLifeId";
import { customColors } from "../../../../../../../../theme/colors";
import { LifeCapsule } from "./LifeCapsule";
import { MergeLifeIcon } from "../../../../../../../../icons/MergeLifeIcon";

type SearchLifeProps = {
  handleOnClose: () => void;
};

export const SearchLife = ({ handleOnClose }: SearchLifeProps) => {
  const [localCpf, setLocalCpf] = useState("");
  const [validCPF, setValidCPF] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState("");
  const [notFoundError, setNotFoundError] = useState("");
  const [agree, setAgree] = useState(false);

  const lifeId = useGetCurrentLifeId();
  const navigate = useNavigate();

  const { data: anonymousLifeData } = useGetLifeById(lifeId);

  const {
    isSuccess: successMergeLife,
    data: mergeLifeData,
    isError: errorMergeLife,
    isPending: pendingMergeLife,
    mutate: mergeLife,
  } = useMergeLife();

  useEffect(() => {
    if (successMergeLife && mergeLifeData?.data?.data) {
      renderNotifier("A vida foi vinculada com sucesso.", {
        variant: "success",
        persist: true,
      });

      if (mergeLifeData.data.data?.attributes?.chat_id) {
        navigate(`/chat/${mergeLifeData?.data?.data?.attributes?.chat_id}`);
      }
    }
  }, [mergeLifeData, navigate, successMergeLife]);

  useEffect(() => {
    if (errorMergeLife) {
      renderNotifier("Erro ao tentar vincular a vida.", {
        variant: "error",
        persist: true,
      });
    }
  }, [errorMergeLife]);

  const {
    data: getLifeData,
    error: getLifeError,
    isLoading: lifeLoading,
  } = useGetLife(validCPF, isReady);

  const firstName = getLifeData?.data?.data?.[0]?.attributes?.first_name || "";
  const lastName = getLifeData?.data?.data?.[0]?.attributes?.last_name || "";
  const registrationNumber =
    getLifeData?.data?.data?.[0]?.attributes?.registration_number || "";
  const lifeIdToMerge = getLifeData?.data?.data?.[0]?.id || "";
  const phoneNumber =
    getLifeData?.data?.data?.[0]?.attributes?.phone_numbers?.[0]?.attributes
      ?.number || "";
  const anonymousPhoneNumber =
    anonymousLifeData?.data?.data?.attributes?.phone_numbers?.[0]?.attributes
      ?.number;

  useEffect(() => {
    if (getLifeError) {
      renderNotifier("Erro ao tentar encontrar a vida.", {
        variant: "error",
        persist: true,
      });
    }
  }, [getLifeError]);

  useEffect(() => {
    if (getLifeData?.data.data[0]) {
      setNotFoundError("");
      setError("");
    } else if (getLifeData?.data.data.length === 0) {
      setError("");
      setNotFoundError("CPF não encontrado");
    }
  }, [getLifeData]);

  const handleChangeCpf = (value: string) => {
    setNotFoundError("");
    setIsReady(false);
    const cpfStr = value.replace(/\D/g, "");

    setLocalCpf(cpfStr);

    if (validateCPF(cpfStr)) {
      setValidCPF(cpfStr);
    } else {
      setValidCPF("");
      if (cpfStr.length === 11) {
        setError("Forneça um CPF válido");
      }
    }
  };

  const handleOnPaste = (value: string) => {
    handleChangeCpf(value);
  };

  const handleSubmit = () => {
    if (!validCPF) {
      return;
    }
    setIsReady(true);
  };

  const handleSave = () => {
    mergeLife({ lifeId, lifeIdToMerge });
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "0 0 8px 0",
        flexDirection: "column",
        gap: 2,
        width: "100%",
      }}
    >
      <Typography variant="h4" color={customColors.Neutral08}>
        Vincular cadastro
      </Typography>
      <Typography variant="body2" pl={1.5} color={customColors.TextSecondary}>
        Selecione o CPF de contato do paciente para vincular o telefone no
        cadastro.
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <TextField
            label="Selecione o CPF para vincular"
            variant="outlined"
            error={!!error}
            helperText={error}
            value={localCpf}
            disabled={pendingMergeLife}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChangeCpf(e.target.value)
            }
            onPaste={(e: ClipboardEvent<HTMLInputElement>) => {
              const data = e.clipboardData.getData("text");
              handleOnPaste(data);
            }}
            InputProps={{
              endAdornment: lifeLoading ? (
                <CircularProgress size={16} />
              ) : (
                <InputAdornment position="end">
                  <IconButton
                    disabled={localCpf.length < 11 && !validCPF}
                    onClick={handleSubmit}
                  >
                    <Search
                      fontSize="medium"
                      sx={{ color: "rgba(0,0,0,.54)" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 11 }}
          />
          {notFoundError && (
            <Box>
              <Typography
                variant="caption"
                pl={1.5}
                color={customColors.TextSecondary}
              >
                CPF não encontrado, deseja cadastrar?
              </Typography>
              <Button
                variant="text"
                color="primary"
                onClick={() =>
                  window.open(
                    `/pacientes/novo-paciente?cpf=${validCPF}`,
                    "_blank"
                  )
                }
                sx={{
                  fontSize: "12px",
                  textDecoration: "underline",
                }}
              >
                Cadastre aqui
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {getLifeData && !notFoundError && (
        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
              }}
            >
              <LifeCapsule phoneNumber={anonymousPhoneNumber} />
              <LifeCapsule
                firstName={firstName}
                lastName={lastName}
                phoneNumber={phoneNumber}
                registrationNumber={registrationNumber}
              />
            </Box>
            <MergeLifeIcon />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setAgree(!agree)}
                name="agree"
                disabled={pendingMergeLife}
              />
            }
            label={
              <Typography variant="button" color={customColors.Neutral08}>
                Estou ciente que ao continuar as mensagens do chat atual serão
                perdidas
              </Typography>
            }
          />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          disabled={pendingMergeLife}
          onClick={handleOnClose}
          sx={{
            width: "94px",
            height: "30px",
            fontSize: "14px",
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!validCPF || !agree}
          sx={{
            width: "152px",
            height: "30px",
            fontSize: "14px",
          }}
        >
          {pendingMergeLife ? (
            <CircularProgress size={16} sx={{ color: customColors.White }} />
          ) : (
            "Vincular cadastro"
          )}
        </Button>
      </Box>
    </Box>
  );
};
