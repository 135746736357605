import {
  Box,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from "@mui/material";
import { CarecodeLogo } from "../../components/svg";
import { GoogleButton } from "../../components/auth/GoogleButton";
import { MicrosoftButton } from "../../components/auth/MicrosoftButton";
import { useEffect, useState } from "react";

export function SignIn() {
  const [tenantId, setTenantId] = useState<string>("");

  useEffect(() => {
    return () => setTenantId("");
  }, []);
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-6 bg-[url('/images/bg.png')] bg-cover">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: "24px",
          maxWidth: "600px",
        }}
      >
        <CarecodeLogo />
        <Typography variant="h1" align="center" mt={3} color="secondary.main">
          Seu copiloto médico
        </Typography>
        <Typography variant="h1" color="primary.main" align="center" mb={5}>
          para cuidados em saúde de alta performance
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box>
            <GoogleButton callbackURL="/" tenantId={tenantId} />
          </Box>
          <Box>
            <MicrosoftButton callbackURL="/" tenantId={tenantId} />
          </Box>
        </Box>

        <Box sx={{ width: "294px" }}>
          <FormControl fullWidth>
            <InputLabel id="organization">Organização</InputLabel>
            <Select
              labelId="organization"
              label="Organização"
              variant="outlined"
              defaultValue={tenantId}
              onChange={(e: SelectChangeEvent<string>) => {
                if (!e.target.value || e.target.value === "carecode") {
                  setTenantId("");
                } else {
                  setTenantId("aurora-qcwms");
                }
                localStorage.setItem("tenantId", e.target.value);
              }}
            >
              <MenuItem value={"carecode"}>Carecode</MenuItem>
              <MenuItem value={"aurora-qcwms"}>Aurora</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ marginTop: "40px" }}>
          <Typography
            variant="h6"
            color="secondary.contrastText"
            align="center"
            sx={{ fontWeight: 400 }}
          >
            Ao continuar, você confirma que entende e aceita os{" "}
            <a href="#" className="decoration-white underline">
              Termos e condições
            </a>{" "}
            e a{" "}
            <a href="#" className="decoration-white underline">
              Política de privacidade
            </a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
