import { useMutation } from "@tanstack/react-query";
import { api } from "../../../api";
import { AppointmentSchema, EncounterResponse } from "../timelineTypes";

export type TCreateEncounterMutationParams = {
  medicalNotes: string;
  reason: AppointmentSchema["reason"];
  appointmentId: string;
  participants: AppointmentSchema["participants"];
  virtualService: AppointmentSchema["virtual_service"];
};

export const useCreateEncounterMutation = () => {
  const createEncounterMutation = useMutation({
    mutationKey: ["create-encounter"],
    mutationFn: (
      createEncounterMutationParams: TCreateEncounterMutationParams
    ) => {
      const normalizedParticipants =
        createEncounterMutationParams?.participants?.map?.((item) => ({
          ...item?.attributes,
        }));

      return api.post<EncounterResponse>(`/api/v1/encounters`, {
        data: {
          type: "encounters",
          attributes: {
            medical_notes: createEncounterMutationParams.medicalNotes,
            appointment_id: createEncounterMutationParams.appointmentId,
            status: "COMPLETED",
            encounter_class: "INPATIENT",
            reason: createEncounterMutationParams.reason,
            participants: normalizedParticipants,
            virtual_service: createEncounterMutationParams.virtualService,
          },
        },
      });
    },
    onError: (error) => {
      console.warn("Error creating encounter", JSON.stringify(error?.stack));
    },
  });

  return { createEncounterMutation };
};
