import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogProps,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { CustomDialog } from "../../../components/CustomDialog/CustomDialog";
import { useState } from "react";
import {
  cancellationReasonsObj,
  cancellationReasonsUI,
} from "../helpers/chatTimelineHelpers";

type TCancelAppointmentDialogProps = {
  dialogProps: DialogProps;
  onClickBack: () => void;
  onClickProceed: (param: string[]) => void;
  isCancelLoading?: boolean;
};

export const CancelAppointmentDialog = (
  props: TCancelAppointmentDialogProps
) => {
  const [isCancelReasonOpen, setIsCancelReasonOpen] = useState(false);
  const [hasSelectedReason, setHasSelectedReason] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [selectedValues, setSelectedValues] = useState(cancellationReasonsObj);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValues({
      ...selectedValues,
      [event.target.name]: event.target.checked,
    });
  };

  const handleOpenCancelReason = () => {
    setIsCancelReasonOpen(true);
  };

  const handleBack = () => {
    props?.onClickBack?.();
    // to avoid screen flickering when closing the dialog, first we close then we reset the state
    setTimeout(() => {
      setIsCancelReasonOpen(false);
    }, 300);
  };

  const handleProceedCancel = () => {
    setHasSubmitted(true);
    const hasSelectedReason = Object.values(selectedValues).some(
      (value) => value
    );
    if (!hasSelectedReason) {
      setHasSelectedReason(false);
      return;
    }

    setHasSelectedReason(true);

    const reasons =
      Object.entries(selectedValues)
        .map(([key, value]) => {
          return value ? key : "";
        })
        .filter(Boolean) || [];

    props?.onClickProceed?.(reasons);
  };

  return (
    <CustomDialog {...props.dialogProps} onClose={() => handleBack()}>
      <Box mb={2}>
        <Typography variant="h4" fontWeight="regular">
          Cancelamento de consulta
        </Typography>
      </Box>
      {!isCancelReasonOpen ? (
        <>
          <Box mb={2}>
            <Typography variant="body2">
              Deseja cancelar a consulta da paciente?
            </Typography>
          </Box>
          <DialogActions>
            <Button size="small" onClick={handleBack} variant="outlined">
              Voltar
            </Button>
            <Button
              size="small"
              onClick={handleOpenCancelReason}
              disabled={props?.isCancelLoading}
              variant="contained"
              endIcon={
                props?.isCancelLoading ? <CircularProgress size={20} /> : null
              }
            >
              Sim, cancelar consulta
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <Box mb={3}>
            <Typography variant="body2">
              Deseja cancelar a consulta da paciente?
            </Typography>
          </Box>
          <Box mb={4}>
            <FormGroup>
              {Object.entries(cancellationReasonsUI).map(
                ([reasonKey, reasonLabel], index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={
                          selectedValues[
                            reasonKey as keyof typeof selectedValues
                          ]
                        }
                        onChange={handleChange}
                        name={reasonKey}
                      />
                    }
                    label={
                      <Typography variant="body2">{reasonLabel}</Typography>
                    }
                  />
                )
              )}
            </FormGroup>
            {hasSubmitted && !hasSelectedReason && (
              <FormHelperText>
                <Typography variant="button" color="error">
                  Por favor, selecione um motivo
                </Typography>
              </FormHelperText>
            )}
          </Box>
          <DialogActions>
            <Box sx={{ width: "89px" }}>
              <Button
                fullWidth
                size="small"
                onClick={handleProceedCancel}
                disabled={props?.isCancelLoading}
                variant="contained"
                endIcon={
                  props?.isCancelLoading ? (
                    <CircularProgress
                      size={20}
                      data-testid="cancel-appointment-loading"
                    />
                  ) : null
                }
              >
                Salvar
              </Button>
            </Box>
          </DialogActions>
        </>
      )}
    </CustomDialog>
  );
};
