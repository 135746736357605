import { GridColDef, GridRowId } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { GridActionButtonWrapper } from "./GridActionButtonWrapper";
import { CreateChatButton } from "./CreateChatButton";

const StyledCell = ({ value }: { value: string }) => {
  const cellValues = value.split("|");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography variant="body2">{cellValues[0]}</Typography>
      <Typography variant="subtitle2">
        {cellValues[1]}, {cellValues[2]}
      </Typography>
    </Box>
  );
};

const EditButton = ({ id }: { id: GridRowId }) => {
  const navigate = useNavigate();
  return (
    <GridActionButtonWrapper>
      <Link to={`/pacientes/${id}`}>
        <EditIcon
          fontSize="small"
          color="primary"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/pacientes/${id}`)}
        />
      </Link>
    </GridActionButtonWrapper>
  );
};

const ProfileButton = ({ id }: { id: GridRowId }) => {
  const url = id ? `/chat/${id}/timeline` : "";
  return (
    <GridActionButtonWrapper>
      <Link to={url}>
        <InsertDriveFileOutlinedIcon
          fontSize="small"
          color="primary"
          sx={{
            cursor: "pointer",
            color: url ? "primary" : "common.neutral06",
          }}
          data-testid={`profile-edit-button-${id}`}
        />
      </Link>
    </GridActionButtonWrapper>
  );
};

export const LivesColumns: GridColDef[] = [
  {
    field: "firstName",
    flex: 1,
    headerClassName: "super-app-theme--header",
    renderCell: (params) => <StyledCell value={params.value} />,
  },
  {
    field: "updateProfile",
    headerClassName: "super-app-theme--header",
    width: 50,
    renderCell: (params) => <EditButton id={params.id} />,
  },
  {
    field: "goToChat",
    headerClassName: "super-app-theme--header",
    width: 50,
    renderCell: (params) => {
      return (
        <CreateChatButton chatId={params?.row?.chatId} lifeId={params?.id} />
      );
    },
  },
  {
    field: "goToProfile",
    headerClassName: "super-app-theme--header",
    width: 50,
    renderCell: (params) => <ProfileButton id={params?.id || ""} />,
  },
];
